import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {DropFilesDirective} from './directives/drop-files.directive';
import {ModalMostrarTodoComponent} from './modals/modal-mostrar-todo/modal-mostrar-todo.component';
import {ModalHelperComponent} from './modals/modal-helper/modal-helper/modal-helper.component';
import {ModalInactividadComponent} from './modals/modal-inactividad/modal-inactividad.component';
import {CountdownModule} from 'ngx-countdown';
import {DigitOnlyDirective} from './directives/digit-only.directive';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { SafePipe } from './pipes/safe.pipe';
import { FileViewverComponent } from './modals/file-viewver/file-viewver.component';
import { DetailContainerDirective } from './directives/detail-container.directive';
import { LoadingModalComponent } from './modals/loading-modal/loading-modal.component';
import { DiffModalComponent } from './modals/diff-modal/diff-modal.component';
import { NgxDiffModule } from 'ngx-diff';
import { InputRestrictionDirective } from './directives/input-restriction.directive';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    DropFilesDirective,
    DigitOnlyDirective,
    ModalMostrarTodoComponent,
    ModalHelperComponent,
    ModalInactividadComponent,
    CounterInputComponent,
    SafePipe,
    FileViewverComponent,
    DetailContainerDirective,
    LoadingModalComponent,
    DiffModalComponent,
    InputRestrictionDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    CountdownModule,
    ReactiveFormsModule,
    NgxDiffModule,
  ],
  exports: [
    BreadcrumbComponent,
    DropFilesDirective,
    DetailContainerDirective,
    DigitOnlyDirective,
    InputRestrictionDirective,
    ModalMostrarTodoComponent,
    ModalHelperComponent,
    CounterInputComponent,
    ModalInactividadComponent,
    SafePipe,
    FileViewverComponent,
    DiffModalComponent
  ],
  entryComponents: [
    ModalMostrarTodoComponent,
    ModalHelperComponent,
    ModalInactividadComponent,
    FileViewverComponent,
    LoadingModalComponent,
    DiffModalComponent
  ],
  providers: [
  ]
})
export class CommonsModule {
}
