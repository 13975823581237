import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { createRequestOption } from '../helpers/request-util';

@Injectable({
  providedIn: 'root'
})
export class CamaraService {

  public resourceUrl = environment.apiUrl + 'camera/camara';
  constructor(public http: HttpClient) { }

  create(camara: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, camara);
  }

  update(camara: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, camara);
  }

  find(id: string): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, { params: options });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

  findByName(name: string, limit: number) {
    if (name === '') {
      return of([]);
    }

    return this.http.get<any[]>(`${this.resourceUrl}/find_by_name?name=${name}&limit=${limit}`)
    .pipe(
      map(response => {
        return response;
      })
    );
  }

  findManual() {

    return this.http.get<any[]>(`${this.resourceUrl}/manuales`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

}
