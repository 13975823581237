import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ClaseServicioService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';


@Component({
  selector: 'app-new-edit-clase-servicio',
  templateUrl: './new-edit-clase-servicio.component.html',
  styleUrls: ['./new-edit-clase-servicio.component.scss']
})
export class NewEditClaseServicioComponent implements OnInit {

  @Input() private claseServicio: any;
  private claseServicioForm: FormGroup;
  permisos: Array<any>;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    public webStorageCustomService: WebStorageCustomService,
    private formBuilder: FormBuilder,
    public claseServicioService: ClaseServicioService,
  ) {
  }

  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }

    this.initForms();
    if (this.claseServicio) {
      this.patchValues(this.claseServicio);
    }

  }

  private initForms() {

    this.claseServicioForm = this.formBuilder.group({
      id: null,
      fechaCreacion: null,
      fechaActualizacion: null,
      eliminado: false,
      descripcion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      idSimitMovilixa: [null, [Validators.required, Validators.minLength(1)]],
    });

  }

  private patchValues(claseServicio: Object) {
    this.claseServicioForm.patchValue(claseServicio);
  }

  public async modalclose() {
    if (this.claseServicioForm.valid) {
      const itemF = await this.createOrUpdateItem(this.claseServicioForm.value, this.claseServicio ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(claseServicio: any, exits: any): Promise<any> {
    if (!exits) {
      return this.claseServicioService.create(claseServicio).toPromise();
    }
    if (exits) {
      return this.claseServicioService.update(claseServicio).toPromise();
    }
  }

}
