import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {WebStorageCustomService} from '../commons/providers/web-custom-storage.service';

// Constants
import {CONSULTA_RUNT_URL} from '../commons/constants/paths';
// Types
import {ConsultaRunt} from '../commons/interfaces/types/consulta-runt';

interface TokenResponse {
  token: string;
  codigoRespuesta: string;
  mensajeRespuesta: string;
  fechaMensaje: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConsultaRuntService {

  constructor(private http: HttpClient) {
  }

  // generarToken(imei: string, idUsuario: string): Observable<TokenResponse> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${Cookie.get('access_token')}`
  //     })
  //   };
  //   return this.http.post<TokenResponse>(`${CONSULTA_RUNT_URL}/seguridad/token?imei=${imei}&idUsuario=${idUsuario}`, null, httpOptions);
  // }

  getInformacion(imei: string, idUsuario: string, token: string, params: { [key: string]: any }): Observable<ConsultaRunt> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Runt-Token': token,
    //     'Authorization': `Bearer ${Cookie.get('access_token')}`
    //   })
    // };
    return this.http.post<ConsultaRunt>(
      `${CONSULTA_RUNT_URL}/consulta/informacion`,
      params
    );
  }

}
