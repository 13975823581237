import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthService, TipoEstadoCamaraService} from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';
import {NewEditTipoEstadoCamaraComponent} from './modals/new-edit-tipo-estado-camara/new-edit-tipo-estado-camara.component';
import {ExportAsConfig, ExportAsService} from 'ngx-export-as';
import {ModalMostrarTodoComponent} from '../../commons/modals/modal-mostrar-todo/modal-mostrar-todo.component';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-tipo-estado-camara',
  templateUrl: './tipo-estado-camara.component.html',
  styleUrls: ['./tipo-estado-camara.component.scss']
})
export class TipoEstadoCamaraComponent implements OnInit {

  public filter: FormGroup;
  public items: Array<any>;
  public predicate: string;
  public reverse: boolean = true;
  public page: number;
  public itemsPerPage: number;
  public totalItems: number;
  public queryCount: number;
  public totalPages: number;
  public modulo = 'Tipo_estado_camara';
  public showTable = false;
  private services: any;
  cargarTodo = false;
  cantidadItems = 10;
  permisos: Array<any>;

  constructor(
    public formBuilder: FormBuilder,
    public modalService: NgbModal,
    public webStorageCustomService: WebStorageCustomService,
    public authService: AuthService,
    public tipoEstadoCamaraService: TipoEstadoCamaraService,
    public toastService: CustomToastrService,
    private exportAsService: ExportAsService
  ) {
    this.services = {
      Tipo_estado_camara: this.tipoEstadoCamaraService
    };
  }

   ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.initVariables();
  }

  /** initialize reactive form */


  /** initialize variable */
  public initVariables() {
    this.items = [];
    this.page = 1;
    this.itemsPerPage = this.cantidadItems;
    this.predicate = 'id';
    this.loadAll();
  }

  /** load all data from endpoints */
  public loadAll() {

    this.services[this.modulo]
      .query({
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: this.sort()
      })
      .subscribe(
        (response) => this.paginateItem(response),
        (error) => this.onError(error)
      );
  }

  public transition() {
    this.loadAll();
  }

  public sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.loadAll();
  }

  public sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    return result;
  }

  public paginateItem(data: any) {

    if (data.totalElements > 0) {
      this.showTable = true;
    } else {
      this.showTable = false;
    }
    this.totalItems = data.totalElements as number;
    this.queryCount = data.numberOfElements as number;
    this.totalPages = data.totalPages as number;
    this.items = data.content as Array<any>;
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  public openModal(permiso: string, item?: Object) {

    if (this.validatePermission(permiso)) {
      const modal: NgbModalRef = this.modalService.open(NewEditTipoEstadoCamaraComponent, {size: 'sm', backdrop: 'static'});
      modal.componentInstance.modulo = this.modulo;
      if (item) {
        modal.componentInstance.item = item;
      }
      modal.result.then(
        (success: Object) => {
          this.toastService.showToast('success', 'Información', 'Se han guardado los cambios.');
          this.loadAll();
        },
        (error: any) => {
          // console.error(error);
          // this.toastService.showToast('error', 'Ha sucedido algún problema', 'Administración de items');
        }
      );
    } else {
      this.toastService.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de Camaras');
    }
  }

  public deleteItem(item: Object) {
    if (this.validatePermission('tipo-estado-camara.eliminar')) {

      this.services[this.modulo].delete(item['id']).subscribe(
        (response: any) => {
          this.toastService.showToast('success', 'eliminada satisfactoriamente', 'Administración de Camaras');
          this.loadAll();
        },
        (error) => {
          console.error('error', error.error.details.indexOf('constraint') > -1);
          if (error.error.details.indexOf('constraint') > -1) {
            this.toastService.showToast('error',
              'Error',
              `Error al eliminar el ${this.modulo} asegurese que no sea usado en algun modulo antes de eliminarlo`);

          } else {
            this.toastService.showToast('error', 'Ha sucedido algún problema', 'Administración de Camaras');

          }
        }
      );

    } else {
      this.toastService.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de ciudades');
    }
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }cambiarItem(e: any) {
    this.showTable = false;
    this.modulo = e.nextId;
    this.initVariables();

  }


  export(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'table',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'lista_' + this.modulo);

  }

  todo() {

    this.cargarTodo = !this.cargarTodo;

    if (this.cargarTodo) {
      this.mostrarTodoModal();
    } else {
      this.itemsPerPage = this.cantidadItems;
      this.loadAll();
    }
  }

  public mostrarTodoModal() {
    const modal: NgbModalRef = this.modalService.open(ModalMostrarTodoComponent, {size: 'sm'});
    modal.componentInstance.texto = this.totalItems;
    modal.result.then(
      (success: Object) => {
        this.itemsPerPage = this.totalItems;
        this.loadAll();
      },
      (error: any) => {
        this.cargarTodo = !this.cargarTodo;
        this.loadAll();
      }
    );

  }
}
