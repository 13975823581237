import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CiudadService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ListaBlancaService} from '../../../../providers/lista-blanca.service';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-new-edit-lista-blanca',
  templateUrl: './new-edit-lista-blanca.component.html',
  styleUrls: ['./new-edit-lista-blanca.component.scss']
})
export class NewEditListaBlancaComponent implements OnInit {

  @Input() private listaBlanca: any;
  private ciudades: Array<any>;
  private listaBlancaForm: FormGroup;
  ciudadLoading = true;
  permisos: Array<any>;

  constructor(
    private modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public webStorageCustomService: WebStorageCustomService,
    private ciudadService: CiudadService,
    private listaBlancaService: ListaBlancaService,
    private toastService: CustomToastrService,
  ) {
  }

  async ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.initForms();
    await this.ciudadesActivos();
    if (this.listaBlanca) {
      this.listaBlancaForm.patchValue(this.listaBlanca);
    }
  }

  private async ciudadesActivos() {
    this.ciudadLoading = true;
    await this.ciudadService.activos()
      .toPromise()
      .then(
        response => {
          if (response.length > 0) {
            this.ciudades = response;
          }
          this.ciudadLoading = false;
        },
        error => {
          console.error(error);
        }
      );
  }

  private initForms() {
    this.listaBlancaForm = this.formBuilder.group({
      ciudad: this.formBuilder.group({
        id: [null, Validators.required]
      }),
      eliminado: false,
      fechaActualizacion: null,
      fechaCreacion: null,
      id: null,
      observacion: ['', Validators.minLength(4)],
      placa: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  private async modalclose() {
    if (this.listaBlancaForm.valid) {
      this.listaBlancaForm.get('placa').patchValue((this.listaBlancaForm.get('placa').value as string).toUpperCase());
      const itemF = await this.createOrUpdateItem(this.listaBlancaForm.value, this.listaBlanca ? true : false);
      this.toastService.showToast('success', 'Se ha guardado con exito', 'Administración de lista blanca');
      this.modal.close({}); // itemF
    }
  }

  private async createOrUpdateItem(infraccion: any, exits: any): Promise<any> {
    if (!exits) {
      return this.listaBlancaService.create(infraccion).toPromise();
    }
    if (exits) {
      return this.listaBlancaService.update(infraccion).toPromise();
    }
  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }

}
