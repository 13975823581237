import {Injectable} from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import {WebStorageCustomService} from '../commons/providers/web-custom-storage.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

// Own
// Services
import {UsuarioService} from './usuario.service';
import {NotificacionesService} from './notificaciones.service';
import {EventEmitterService} from './event-emitter.service';
// Types
import {Usuario} from '../models/usuario';
import {Response} from '../commons/interfaces/types/response';
import {Notificacion} from '../commons/interfaces/types/notificacion';
import {environment} from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  public notifications: Notificacion[] = [];
  private resourceUrl = environment.apiUrl + 'websocket-service';
  private serverUrl = '';
  public stompClient;
  private sessionId: string;
  private connectInterval: any;
  private canTotalNotificaciones: number;

  constructor(private _usuarioService: UsuarioService,
              private _notificacionesService: NotificacionesService,
              private _eventEmitterService: EventEmitterService,
              private webStorageCustomService: WebStorageCustomService,
              private _http: HttpClient) {
    this.serverUrl = `${this.resourceUrl}/secured/room?access_token=${this.webStorageCustomService.getFromLocal('access_token')}`;

  }

  initializeWebSocketConnection() {

    const ws = new SockJS(this.serverUrl, null, {transports:["xhr-streaming", "xhr-polling"]});
    this.stompClient = Stomp.over(ws);
    const that = this;
    this.stompClient.connect({}, (frame) => {

      // Clear interval
      if (this.connectInterval) {
        clearInterval(this.connectInterval);
        this.connectInterval = undefined;
      }

      // Extraer el ID de la conexion
      let url = this.stompClient.ws._transport.url;
      /*url = url.replace(
        `ws://notificacion-server.gerenciaurbana.org:8689/secured/room`, '');
      url = url.replace('/websocket', '');
      console.log('URL HASTA AHORA ', url);
      url = url.replace(/^[0-9]+\//, '');
      console.log('SESSION ID: ' + url);
      const sessionId = url;

      // Socket Id
      this.sessionId = sessionId;*/

      // Update user socket connectionId
      let usuario = this.webStorageCustomService.getFromLocal('usuario') ? <Usuario>JSON.parse(this.webStorageCustomService.getFromLocal('usuario')) : null;
      if (usuario !== null) {
        console.log('USUARIO ', usuario);
        // this.updateUserConnection(usuario.id, this.sessionId);
        // Get user notifications
        this.getUserNotifications(usuario.id);
      }

      // Subscribe messages
      that.stompClient.subscribe('/secured/user/queue/specific-user'
        + '-user' + usuario.id, (msgOut) => {
        // handle messages
        console.log('Recibí ', console.log(msgOut));
        if (JSON.parse(msgOut.body) === true) {
          this.notifications.forEach((notification: Notificacion) => {
            notification.readed = true;
          });
        } else {
          this.notifications.unshift(JSON.parse(msgOut.body));
          this._eventEmitterService.setNotificacion(JSON.parse(msgOut.body));
          console.log('Notificaciones ', this.notifications);
        }
      });

      // Subscribe mark messages
      /* that.stompClient.subscribe('/secured/user/mark_messages/specific-user'
        + '-user' + usuario.id, (item) => {
        // handle messages
        this.notifications.forEach((notification: Notificacion) => {
          notification.readed = true;
        });
      }); */

      // Send message
      /*setTimeout(() => {
        this.sendMessage(JSON.stringify({
          userId: 1,
          id: 1,
          title: 'sunt aut facere repellat provident occaecati excepturi optio reprehenderit',
          body: 'quia et suscipit suscipit recusandae consequuntur expedita et cum reprehenderit '
          }), sessionId);
      }, 1500);*/

    }, (err) => {
      console.log('CONEXION PERDIDA: ', err);

      // Restart vars
      this.sessionId = undefined;

      if (!this.connectInterval) {
        this.connectInterval = setInterval(() => {
          this.initializeWebSocketConnection();
        }, 3000);
      }

    });

  }

  disconnect(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.stompClient && this.checkConnection()) {
        this.stompClient.disconnect(async () => {
          // Update user socket connectionId
          // let usuario = this.webStorageCustomService.getFromLocal('usuario') ? <Usuario>JSON.parse(this.webStorageCustomService.getFromLocal('usuario')) : null;
          // if (usuario !== null) {
          //   await this.updateUserConnection(usuario.id, null)
          //     .then((response) => {
          //       console.log('RESOLVED ', response);
          //       resolve('Id conexion de socket actualizada');
          //     })
          //     .catch((error) => {
          //       console.log('REJECTED ', error);
          //       reject('Error actualizando usuario (socketConnectionId)');
          //     });
          // }
          resolve();
        });
        this.stompClient = undefined;
      } else {
        this.stompClient = null;
        resolve('Stomp client null or undefined');
      }
    });
  }

  countNotifications(): number {
    return this.notifications.filter(e => !e.readed).length;
  }

  getNotifications(): any[] {
    const notifications = [];
    for (let index = 0; index < this.notifications.length; index++) {
      const notification = this.notifications[index];
      if (index <= 4) {
        notifications.push(notification);
      } else {
        break;
      }
    }
    return notifications;
  }

  setNotificationsAsReaded(estado: boolean, usuario: any) {
    this._notificacionesService.updateEstados(estado, usuario)
      .subscribe((res) => {
        console.log('NOTIFICACIONES ACTUALIZADAS');
        this.markMessagesAsReaded();
      }, (err) => {
        console.log('ERROR ACTUALIZAR NOTIFICACIONES');
      });
  }

  private updateUserConnection(userId: number, socketConnectionId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (userId) {
        let user = null;
        await this._usuarioService.find(userId).toPromise()
          .then((response) => {
            user = response;
          });

        if (user) {
          user.socketConnectionId = socketConnectionId;
          await this._usuarioService.update(user).toPromise()
            .then((response) => {
              console.log('SOCKET CONNECTION UPDATED ', response);
              resolve('Id de conexion de socket actualizada');
            })
            .catch((err) => {
              console.log('ERROR AL ACTUALIZAR EL SOCKET CONNECTION ID ', err);
              reject('Error al actualizar el usuario');
            });
        } else {
          reject('No se pudo consultar el usuario');
        }
      } else {
        reject('No se recibió el id de usuario');
      }
    });
  }

  public getCanNotificaciones(): number {
    return this.canTotalNotificaciones;
  }

  private sendMessage(msg: Object, simpSessionId: string) {
    this.stompClient.send('/spring-security-mvc-socket/secured/room', {simpSessionId}, msg);
  }

  private getUserNotifications(userId: number) {
    this._notificacionesService.query(userId, {
      page: 0,
      size: 5,
      sort: ['id' + ',' + 'desc']
    })
      .subscribe((response: Response) => {
        console.log('Las notificaciones ', response);
        this.notifications = response.content;
        this.canTotalNotificaciones = response.totalElements;
      });
  }

  public checkConnection() {
    return this.stompClient.connected;
  }

  private markMessagesAsReaded() {
    let usuario = this.webStorageCustomService.getFromLocal('usuario') ? <Usuario>JSON.parse(this.webStorageCustomService.getFromLocal('usuario')) : null;
    if (usuario !== null) {
      this._http.post(`${this.resourceUrl}/api/mark_messages_readed?userId=${usuario.id}`, null)
        .subscribe((res) => {
          console.log('SENDED');
        }, (err) => {
          console.log('ERROR SENDING');
        });
    }
  }

}
