import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';

@Injectable({
  providedIn: 'root'
})
export class ComparendoService {

  public resourceUrl = environment.apiUrl + 'comparendo/comparendo';

  // public resourceUrl = "http://comparendo-microservice.gerenciaurbana.org:8084/" + 'comparendo';

  constructor(public http: HttpClient) {
  }

  create(comparendo: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, comparendo);
  }

  update(comparendo: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, comparendo);
  }

  updateComparendoOcupado(comparendo: any): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/updateComparendoOcupado`, comparendo);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  findPrevNextComparendo(req: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.resourceUrl}/findPrevNextComparendo`, {params: options});
  }

  randomComparendo(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/randomComparendo`);
  }

  randomComparendoCreados(idComparendoActual: any): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/randomComparendoCreados/${idComparendoActual}`);
  }

  randomComparendoEstados(idComparendoActual: any, estadoTipoComparendoActual: any): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/randomComparendoEstados/${idComparendoActual}/${estadoTipoComparendoActual}`);
  }

  randomComparendoCreadosUsuario(idUsuario: any, idComparendoActual: any): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/randomComparendoCreados/${idUsuario}/${idComparendoActual}`);
  }

  randomComparendoEstadosUsuario(idUsuario: any, idComparendoActual: any, estadoTipoComparendoActual: any): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/randomComparendoEstados/${idUsuario}/${idComparendoActual}/${estadoTipoComparendoActual}`);
  }

  liberarComparendo(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/liberarComparendo/${id}`);
  }

  updatePlaca(id: number, placa: string, idUsuario: number): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/${id}/placa/${idUsuario}`, placa);
  }

  findNumberSubpoena(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/numberSubpoena`);
  }

  findDiffToNull(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/diffToNull`);
  }

  findEqualsToNull(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/equalsToNull`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, {params: options});
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

  findByImageJpg(fileName: string): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/findByImageName/${fileName}`);
  }

  findByIfExistImageOrXmlByName(fileName: string): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/findIfExistImageOrXmlByName/${fileName}`);
  }

  queryComparendosPublic(req?: any, recatpchaResponse?: string): Observable<any> {
    const options = createRequestOption(req);
    const headers: HttpHeaders = new HttpHeaders({'Recaptcha-Response': recatpchaResponse});
    return this.http.get<any[]>(`${this.resourceUrl}/query_comparendos_public`, {params: options, headers});
  }

  reporteMaquinaImpuestos(req: { [key: string]: any }): Observable<{ [key: string]: any }[]> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.resourceUrl}/reporte_maquina_impuestos`, {params: options});
  }

  comparendoManual(body: any): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/comparendo_manual`, body);
  }

  generaPdf(comparendo: any): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/generaPdf`, comparendo);
  }

  validarPDF(comparendo: any): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/validar`, comparendo);
  }

  descertificar(comparendo: any): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/descertificar`, comparendo);
  }

  porCertificar(comparendo: any): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/porCertificar`, comparendo);
  }

}
