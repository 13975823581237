import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-helper',
  templateUrl: './modal-helper.component.html',
  styleUrls: ['./modal-helper.component.scss']
})
export class ModalHelperComponent implements OnInit {

  @Input() titulo: string = '';
  @Input() mensaje: string = '';
  @Input() headerClass: string = 'bg-primary';
  @Input() enableDismissButton = false;
  @Input() dissmisButtonClass: string = 'btn-default';
  @Input() confirmButtonClass: string = 'btn-success';
  @Input() icon: string;
  @Input() iconClass: string;

  constructor(public modal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  public async modalclose() {
    this.modal.close({estado: 'Ok'});
  }

}
