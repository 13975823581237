import {Component, OnInit, Output, ElementRef, EventEmitter, ViewChild, OnDestroy} from '@angular/core';
import { FormControl } from '@angular/forms';

// Services
import { ComparendoService } from '../../../providers';
// Types
import { Comparendo } from '../../../commons/interfaces/types/comparendos';
import { Response } from '../../../commons/interfaces/types/response';
// constants
import { RECAPTCHA_KEY } from '../../../commons/constants/app';
import { ESTADOCOMPARENDOS } from '../../../commons/constants/app'

@Component({
  selector: 'app-formulario-consulta',
  templateUrl: './formulario-consulta.component.html',
  styleUrls: ['./formulario-consulta.component.scss']
})
export class FormularioConsultaComponent implements OnInit, OnDestroy {
  placaControl: FormControl;
  cedulaControl: FormControl;
  comparendos: Comparendo[];
  dateLastQuery: Date;
  placaQuery: string;
  documentoQuery: string;
  siteKey = RECAPTCHA_KEY;
  captchaResponse: string;
  @Output()
  queryComparendos = new EventEmitter<{
    result: Comparendo[];
    placa: string;
    documento: string;
    date: Date;
  }>();
  @ViewChild('recaptcha') recaptcha: ElementRef<any>;
  recaptchaError = false;
  constructor(private comparendoService: ComparendoService) { }

  ngOnInit() {
    this.initControls();
  }

  private initControls() {
    this.placaControl = new FormControl('');
    this.cedulaControl = new FormControl('');
    this.placaControl.valueChanges.subscribe((val) => {
      if (val) {
        this.placaControl.setValue((val as String).toUpperCase(), { emitEvent: false });
      }
    });
  }

  aplicarFiltros() {
    this.consultarComparendos();
  }

  consultarComparendos() {
    // Reset query values
    this.placaQuery = undefined;
    this.documentoQuery = undefined;

    const placa = this.placaControl.value;
    const cedula = this.cedulaControl.value;

    const query = {
      size: 10000000
    };

    if (placa && placa.length > 0) {
      query['placa'] = placa;
      this.placaQuery = placa;
    }

    if (cedula && cedula.length > 0) {
      query['cedula'] = cedula;
      this.documentoQuery = cedula;
    }

    this.recaptchaError = false;

    this.comparendoService.queryComparendosPublic(query, this.captchaResponse)
      .subscribe((response: Response) => {
        this.dateLastQuery = new Date();
        this.comparendos = response.content;
        this.comparendos = this.comparendos.filter((comparendo: Comparendo) => comparendo.tipoEstadoComparendo.id == ESTADOCOMPARENDOS.idComparendoCertificado);
        this.queryComparendos.emit({
          result: this.comparendos,
          placa: this.placaQuery,
          documento: this.documentoQuery,
          date: this.dateLastQuery
        });
      }, (error) => {
        console.log('Error ', error);
        this.captchaResponse = undefined;
        if (error && error.error.error === 'recaptcha_error') {
          console.log('Error validacion recaptcha ', error);
          this.recaptchaError = true;
        }
        (this.recaptcha as any).reset();
      });
  }

  validateFiltersValue(): boolean {
    return (this.placaControl.value && this.placaControl.value.length > 0) ||
    (this.cedulaControl.value && this.cedulaControl.value.length > 0);
  }

  resolvedCaptcha(result: string) {
    console.log('result ', result);
    this.captchaResponse = result;
  }

  ngOnDestroy() {
    console.log(this.recaptcha);
    (this.recaptcha as any).elementRef.nativeElement.remove();
  }

  scrollTest() {
    console.log('Load more items');
  }

}
