import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CiudadService, DepartamentoService, PaisService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-new-edit-item',
  templateUrl: './new-edit-item.component.html',
  styleUrls: ['./new-edit-item.component.scss']
})
export class NewEditItemComponent implements OnInit {

  @Input() private item: any;
  private itemForm: FormGroup;

  @Input() private modulo: string;

  private services: any;
  private items: any;
  permisos: Array<any>;

  idFounded = false;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    private formBuilder: FormBuilder,
    public ciudadService: CiudadService,
    public webStorageCustomService: WebStorageCustomService,
    public departamentoService: DepartamentoService,
    public paisService: PaisService,
  ) {
    this.services = {
      Pais: this.paisService,
      Departamento: this.departamentoService,
      Ciudad: this.ciudadService
    };
  }

  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }

    this.initForms();
    this.load();
    if (this.item) {
      this.patchValues(this.item);
    }

  }

  private initForms() {

    if (this.modulo === 'Ciudad') {
      this.itemForm = this.formBuilder.group({
        id: null,
        fechaCreacion: null,
        fechaActualizacion: null,
        eliminado: [false],
        nombre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
        identificacion: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
        estado: [false],
        departamento: [null, [Validators.required]]
      });

    } else if (this.modulo === 'Departamento') {

      this.itemForm = this.formBuilder.group({
        id: null,
        fechaCreacion: '',
        fechaActualizacion: '',
        eliminado: [false],
        nombre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
        identificacion: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
        estado: [false],
        pais: [null, [Validators.required]]
      });
    } else {

      this.itemForm = this.formBuilder.group({
        id: null,
        fechaCreacion: '',
        fechaActualizacion: '',
        eliminado: [false],
        nombre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
        identificacion: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
        estado: [false]
      });

    }

  }

  private patchValues(item: Object) {
    this.itemForm.patchValue(item);
  }

  public async modalclose() {
    if (this.itemForm.valid) {
      const itemF = await this.createOrUpdateItem(this.itemForm.value, this.item ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(item: any, exits: any): Promise<any> {
    if (!exits) {
      return this.services[this.modulo].create(item).toPromise();
    }
    if (exits) {
      return this.services[this.modulo].update(item).toPromise();
    }
  }

  trackItemById(index: number, item: any) {
    return item.id;
  }

  load() {
    if (this.modulo === 'Ciudad') {
      this.services['Departamento'].activos().subscribe(data => {

        this.items = data;
      }, (err) => {
        this.toastService.showToast('error', `Error al cargar la lista de Departamentos`, 'Administración de ciudades');
      });
    } else if (this.modulo === 'Departamento') {
      this.services['Pais'].activos().subscribe(data => {
        this.items = data;
      }, (err) => {
        this.toastService.showToast('error', `Error al cargar la lista de Paises`, 'Administración de ciudades');
      });
    }


  }

  async findByIdentificacion() {
    this.idFounded = false;
    if (this.itemForm.get('identificacion').value !== null && this.itemForm.get('identificacion').value !== '') {

      if (this.itemForm.get('id').value != null) {

        if (this.itemForm.get('identificacion').value !== this.item.identificacion) {
          await this.services[this.modulo].find(this.itemForm.get('identificacion').value).toPromise().then((res) => {
            console.log('res', res);
            this.idFounded = true;
            this.toastService.showToast('error', null, 'Ya existe ' + this.modulo + ' con el codigo ' + this.itemForm.get('identificacion').value);


          }, (err) => {
            console.log('err', err);
            this.idFounded = false;

          });
        }

      } else {

        await this.services[this.modulo].find(this.itemForm.get('identificacion').value).toPromise().then((res) => {
          console.log('res', res);
          this.idFounded = true;
          this.toastService.showToast('error', null, 'Ya existe ' + this.modulo + ' con el codigo ' + this.itemForm.get('identificacion').value);


        }, (err) => {
          this.idFounded = false;

          console.log('err', err);

        });

      }


    }
  }
}
