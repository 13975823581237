import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  NgZone,
  OnDestroy
} from '@angular/core';
import { ColumnDefinitions } from '../../../commons/interfaces/types/column-definitions';
import { Chart } from 'chart.js';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { DatePipe } from '@angular/common';
import { CustomToastrService } from '../../../commons/providers/custom-toastr.service';
import { Observable } from 'rxjs';

// Types
import { Response } from '../../../commons/interfaces/types/response';
// Constants
import { SHOW_ENTRIES_OPTIONS } from '../../../commons/constants/app';

@Component({
  selector: 'app-resultado-reporte',
  templateUrl: './resultado-reporte.component.html',
  styleUrls: ['./resultado-reporte.component.scss']
})
export class ResultadoReporteComponent implements OnInit, OnDestroy {
  @ViewChild('resultChart') resultChart: ElementRef;

  @Input() columnDefinitions: ColumnDefinitions[];
  @Input() queryFunction;
  @Input() dataChartOptions;
  @Input() pagination;
  @Input() paginationLength;
  @Input() rows;
  @Input() columnDefinitionsFunction: Function;
  @Input() dataChartOptionsFunction: Function;
  @Input() prefijoNombreReporte: string;
  @Input() reportActions: { actions: {
    text: string;
    action: (_context?: any) => void;
  }[], disabledFunction?: (_context: any) => boolean; };

  @Output() resultResolved = new EventEmitter<any>();
  @Output() disabledDownload = new EventEmitter<boolean>();
  @Output() actionEmitter = new EventEmitter<{
    param: string;
    query?: Object;
  }>();

  // Filters
  @Input() queryParams;

  predicate = 'id';
  reverse: any;
  page: number = null;
  totalPage: number = null;
  totalItems: number = null;
  data: any[];

  chartImage: any;

  private chartBaseObject = {
    type: 'bar',
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: [],
          data: []
        }
      ]
    },
    options: {
      legend: { display: false, position: 'bottom' },
      title: {
        display: true,
        text: ''
      },
      colors: [],
      animation: null,
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
            // OR //
            beginAtZero: true,   // minimum value will be 0.
            precision: 0
          }
        }]
      }
    },
  };
  private colors: string[] = [
    '#800000',
    '#FFA500',
    '#008000',
    '#800080',
    '#008080',
    '#000080',
    '#CD5C5C',
    '#FF4500',
    '#FAFAD2',
    '#4B0082',
    '#556B2F',
    '#8B4513',
    '#2F4F4F',
    '#A0522D',
    '#8B0000',
    '#FF1493',
    '#32CD32',
    '#DC143C',
    '#FA8072',
    '##3e95cd'
  ];
  tempPaginationLength: number;
  isQuerying = false;
  isListingAll = false;

  public showEntriesOptions: number[] = SHOW_ENTRIES_OPTIONS;

  // Selected items
  selectedItems: any;

  customVars: any;

  _context = this;
  constructor(private zone: NgZone,
    private exportAsService: ExportAsService,
    private customToastrService: CustomToastrService,
    public datePipe: DatePipe) { }

  ngOnInit() {
    console.log('ON RESULT REPORTE');
    this.initVariables();
    if (this.dataChartOptions) {
      this.dataChartOptions.options.animation = {
        onComplete: () => {
          this.chartImage = (this.resultChart as any).toBase64Image();
        }
      };
      this.buildChart();
    }
    if (this.queryFunction !== undefined) {
      this.query();
    }
    if (this.rows) {
      this.data = this.rows;
      this.buildChart();
      this.resultResolved.emit();
      this.disabledDownload.emit(false);
    }
    this.zone.run(() => { });
  }

  ngOnDestroy() {
    this.disabledDownload.emit(true);
  }

  public selectedOptionChanged(itemsPerPage: number) {
    if (this.queryFunction) {
      this.paginationLength = itemsPerPage;
      this.page = 1;
      this.transition();
    }
  }

  public emitAction(param: string, query?: Object) {
    this.actionEmitter.emit({
      param,
      query
    });
  }

  async query(param?: any) {
    if (!this.isQuerying && !this.isListingAll) {
      this.isQuerying = true;
      this.isListingAll = false;
      if (this.pagination) {
        this.queryParams['page'] = this.page - 1;
        this.queryParams['size'] = this.paginationLength;
      }

      let _tempPaginationLength;

      if (this.tempPaginationLength) {
        _tempPaginationLength = this.tempPaginationLength;
        this.tempPaginationLength = undefined;
      }
      console.log('query(param?: any)');

      await this.queryFunction(this.queryParams).then((response: Response) => {
        if (this.pagination) {
          this.dataChartOptions = undefined;
          this.totalItems = response.totalElements;
          this.totalPage = Math.ceil(response.totalElements / this.paginationLength);
          this.rows = undefined;
          this.data = response.content;
          if (this.columnDefinitionsFunction) {
            this.columnDefinitions = this.columnDefinitionsFunction(this.data);
          }
          if (this.dataChartOptionsFunction) {
            console.log('/****** LA FUNCION DE CHART EXISTE ****////');
            this.dataChartOptions = this.dataChartOptionsFunction(this.data, this.columnDefinitions, this.chartBaseObject, this.colors);

            this.dataChartOptions.options.animation = {
              onComplete: () => {
                this.chartImage = (this.resultChart as any).toBase64Image();
              }
            };

            if (this.resultChart.nativeElement) {
              this.buildChart();
            } else {
              (this.resultChart as any).data = this.dataChartOptions.data;
              (this.resultChart as any).update();
            }
          }
        }
        console.log('BEFORE EMIT');
        this.resultResolved.emit();
        this.isQuerying = false;
      })
        .catch((error) => {
          console.log('ERROR ', error);
          this.customToastrService.showToast('error', '', 'Error al generar reporte');
          if (_tempPaginationLength) {
            this.tempPaginationLength = _tempPaginationLength;
          }
          this.isQuerying = false;
          this.resultResolved.emit();
        });
      this.disabledDownload.emit(false);
    }
  }

  listPaginated() {
    if (!this.isQuerying) {
      this.isListingAll = false;
      this.paginationLength = this.tempPaginationLength;
      this.query();
      this.zone.run(function() {});
    }
  }

  listAll() {
    if (!this.isQuerying) {
      console.log('listAll()');
      this.isQuerying = true;
      this.isListingAll = true;
      this.zone.run(function() {});
      this.queryParams['page'] = 0;
      this.queryParams['size'] = this.totalItems;

      this.queryFunction(this.queryParams).then((response: Response) => {
        this.totalItems = response.totalElements;
        this.totalPage = 1;
        this.tempPaginationLength = this.paginationLength;
        this.paginationLength = response.totalElements;
        this.rows = undefined;
        this.data = response.content;
        this.isQuerying = false;
      })
        .catch((error) => {
          this.isQuerying = false;
        });
    }
  }

  transition() {
    this.find();
  }

  public initVariables() {
    this.page = 1;
    this.predicate = 'maquinas';
  }

  public descargarReporte(tipo: string) {
    const stringDate: string = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss');
    this.downloadChartImage(stringDate);
    this.export(stringDate, tipo);
  }

  find() {
    this.query();
  }

  buildChart() {
    try {
      this.resultChart = new Chart(this.resultChart.nativeElement.getContext('2d'), this.dataChartOptions);
    } catch (err) {
      console.log('Error construir chart');
    }
  }

  export(stringDate: string, tipo?: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo ? tipo : 'xlsx',
      elementId: 'table',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, `${this.prefijoNombreReporte}`);
  }

  downloadChartImage(stringDate: string) {
    const a = window.document.createElement('a');
    a.target = '_blank';
    a.download = `${this.prefijoNombreReporte}_${stringDate}.png`;
    a.href = this.chartImage;

    // Append anchor to body.
    document.body.appendChild(a);
    a.click();

    // Remove anchor from body
    document.body.removeChild(a);
  }

  public isChecked(column: ColumnDefinitions, item: any): boolean {
    let checked = false;
    if (this.selectedItems) {
      if (typeof column.selection === 'string') {
        checked = this.selectedItems.indexOf(item[column.selection]) !== -1;
      } else {
        const obj = {};
        column.selection.forEach((key: string) => {
          obj[key] = item[key];
        });
        return JSON.stringify(this.selectedItems).indexOf(JSON.stringify(obj)) !== -1;
      }
    }
    return checked;
  }

  public headerIsChecked(column: ColumnDefinitions) {
    let isChecked = true;
    if (this.data.length < 1) {
      isChecked = false;
    } else {
      for (const item of this.data) {
        if (!this.isChecked(column, item)) {
          isChecked = false;
          break;
        }
      }
    }
    return isChecked;
  }

  public selectItems($event: any, column: ColumnDefinitions) {
    for (const item of this.data) {
      this.selectItem($event, column, item);
    }
    console.log(this.selectedItems);
  }

  public selectItem($event: any, column: ColumnDefinitions, item: any) {
    if ($event.target.checked) {
      if (column.selectMany) {
        if (!this.selectedItems) {
          this.selectedItems = [];
        }
        if (typeof column.selection === 'string') {
          if (this.selectedItems.indexOf(item[column.selection]) === -1) {
            this.selectedItems.push(item[column.selection]);
          }
        } else {
          const obj = {};
          column.selection.forEach((key: string) => {
            obj[key] = item[key];
          });
          this.selectedItems.push(obj);
        }
      } else {
        if (typeof column.selection === 'string') {
          this.selectedItems = item[column.selection];
        } else {
          const obj = {};
          column.selection.forEach((key: string) => {
            obj[key] = item[key];
          });
          this.selectedItems = obj;
        }
      }
    } else {
      if (column.selectMany) {
        if (typeof column.selection === 'string') {
          if (this.selectedItems.indexOf(item[column.selection]) !== -1) {
            this.selectedItems.splice(this.selectedItems.indexOf(item[column.selection]), 1);
          }
        } else {
          const obj = {};
          column.selection.forEach((key: string) => {
            obj[key] = item[key];
          });
          // console.log('current items', JSON.stringify(this.selectedItems));
          // console.log('object ', JSON.stringify(obj));
          let indexFounded = null;
          for (let index = 0; index < this.selectedItems.length; index++) {
            const element = this.selectedItems[index];
            if (JSON.stringify(element) === JSON.stringify(obj)) {
              indexFounded = index;
            }
          }
          if (indexFounded) {
            this.selectedItems.splice(indexFounded, 1);
          }
        }
      } else {
        this.selectedItems = null;
      }
    }
    console.log(this.selectedItems);
  }

}
