import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {InfraccionService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';

@Component({
  selector: 'app-new-edit-infraccion',
  templateUrl: './new-edit-infraccion.component.html',
  styleUrls: ['./new-edit-infraccion.component.scss']
})
export class NewEditInfraccionComponent implements OnInit {

  @Input() private infraccion: any;
  private infraccionForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    private formBuilder: FormBuilder,
    public infraccionService: InfraccionService,
  ) {
  }

   ngOnInit() {

    this.initForms();
    if (this.infraccion) {
      this.patchValues(this.infraccion);
    }

  }

  private initForms() {

    this.infraccionForm = this.formBuilder.group({
      id: null,
      fechaCreacion: null,
      fechaActualizacion: null,
      eliminado: false,
      manual: false,
      codigo: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(8)]],
      descripcion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      valor: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      minimumDaysInterval: ['', [Validators.required]]
    });
    this.infraccionForm.get('minimumDaysInterval').valueChanges.subscribe(val => {
      if (val) {
        const numbers: string[] = val.match(/\d/g);
        let newValue = null;
        if (numbers && numbers.length) {
          newValue = numbers.join('');
        }
        this.infraccionForm.get('minimumDaysInterval').setValue(newValue, { emitEvent: false });
      }
    });
  }

  private patchValues(infraccion: Object) {
    this.infraccionForm.patchValue(infraccion);
  }

  public async modalclose() {
    if (this.infraccionForm.valid) {
      const itemF = await this.createOrUpdateItem(this.infraccionForm.value, this.infraccion ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(infraccion: any, exits: any): Promise<any> {
    if (!exits) {
      return this.infraccionService.create(infraccion).toPromise();
    }
    if (exits) {
      return this.infraccionService.update(infraccion).toPromise();
    }
  }

}
