import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthService, DataService, ExclusionRangoService, NumeroRangoService, RangoService} from '../../../../providers';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NewExclusionComponent} from '../../modals/new-exclusion/new-exclusion.component';
import {ExportAsConfig, ExportAsService} from 'ngx-export-as';
import {ModalMostrarTodoComponent} from '../../../../commons/modals/modal-mostrar-todo/modal-mostrar-todo.component';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-ver',
  templateUrl: './ver.component.html',
  styleUrls: ['./ver.component.scss']
})
export class VerComponent implements OnInit {

  private rango: any = {
    activo: false,
    ciudad: {},
    eliminado: false,
    fechaActualizacion: '',
    fechaCreacion: '',
    id: null,
    rangoFinal: null,
    rangoInicial: null
  };
  private exclusiones: any = [];
  private totalItemsExclusiones: number = null;
  private queryCountExclusiones: number = null;
  private itemsPerPageExclusiones: number = null;
  private pageExclusiones: number = null;
  private predicateExclusiones: any;
  private reverseExclusiones: any;
  private totalPageExclusiones: number = null;
  private showTableExclusiones: boolean;

  private numeros: any = [];
  private totalItemsNumeros: number = null;
  private queryCountNumeros: number = null;
  private itemsPerPageNumeros: number = null;
  private pageNumeros: number = null;
  private predicateNumeros: any;
  private reverseNumeros: any;
  private totalPageNumeros: number = null;
  private showTableNumeros: boolean;
  private rangoInfoNumeros: any = {};
  private rangoId: any;
  cargarTodoNumeros = false;
  cantidadItems = 25;
  cargarTodoExclusiones = false;
  permisos: Array<any>;


  constructor(private dataService: DataService,
              private rangoService: RangoService,
              private exclusionRangoService: ExclusionRangoService,
              public webStorageCustomService: WebStorageCustomService,
              private numeroRangoService: NumeroRangoService,
              private route: ActivatedRoute,
              private toast: CustomToastrService,
              private modalService: NgbModal,
              private authService: AuthService,
              private exportAsService: ExportAsService,
              private router: Router) {

    this.itemsPerPageExclusiones = this.cantidadItems;

    this.pageExclusiones = 1;
    this.predicateExclusiones = 'id';
    this.reverseExclusiones = true;

    this.itemsPerPageNumeros = this.cantidadItems;
    this.pageNumeros = 1;
    this.predicateNumeros = 'id';
    this.reverseNumeros = true;
    this.route.params.subscribe((res) => {

      this.rangoId = res.id;

    });

    this.rangoService.find(this.rangoId).subscribe(res2 => {
      this.rango = res2;
    });
  }

  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }

    this.loadExclusiones();
    this.loadNumeros();
    this.loadRangoInfoNumeros();
  }

  async loadExclusiones() {
    const query = {
      page: this.pageExclusiones - 1,
      size: this.itemsPerPageExclusiones,
      sort: this.sortExclusiones(),
      idRango: this.rangoId
    };

    await this.exclusionRangoService
      .query(query)
      .toPromise().then(
        (res: any) => {
          this.queryCountExclusiones = res.numberOfElements;
          this.totalPageExclusiones = res.totalPages;
          this.totalItemsExclusiones = res.totalElements;
          this.exclusiones = res.content;

          if (res.content.length > 0) {
            this.showTableExclusiones = true;
          } else {
            this.showTableExclusiones = false;
          }

        },
        (err) => {
          this.showTableExclusiones = false;
          this.onError(err.message);
        }
      );
  }

  sortExclusiones() {
    const result = [this.predicateExclusiones + ',' + (this.reverseExclusiones ? 'asc' : 'desc')];
    return result;
  }

  sortlistExclusiones(predicate: string) {
    this.predicateExclusiones = predicate;
    this.reverseExclusiones = !this.reverseExclusiones;
    this.loadExclusiones();
  }

  transitionExclusiones() {
    this.loadExclusiones();
  }

  async loadRangoInfoNumeros() {
    await this.numeroRangoService
      .rangoInfo(this.rangoId)
      .toPromise().then(
        (res: any) => {
          this.rangoInfoNumeros = res;
        },
        (err) => {
          this.onError(err.message);
        }
      );
  }

  async loadNumeros() {
    const query = {
      page: this.pageNumeros - 1,
      size: this.itemsPerPageNumeros,
      sort: this.sortNumeros(),
      idRango: this.rangoId
    };

    await this.numeroRangoService
      .query(query)
      .toPromise().then(
        (res: any) => {
          this.queryCountNumeros = res.numberOfElements;
          this.totalPageNumeros = res.totalPages;
          this.totalItemsNumeros = res.totalElements;
          this.numeros = res.content;

          if (res.content.length > 0) {
            this.showTableNumeros = true;
          } else {
            this.showTableNumeros = false;
          }

        },
        (err) => {
          this.showTableNumeros = false;
          this.onError(err.message);
        }
      );
  }

  sortNumeros() {
    const result = [this.predicateNumeros + ',' + (this.reverseNumeros ? 'asc' : 'desc')];
    return result;
  }

  sortlistNumeros(predicate: string) {
    this.predicateNumeros = predicate;
    this.reverseNumeros = !this.reverseNumeros;
    this.loadNumeros();
  }

  transitionNumeros() {
    this.loadNumeros();
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  public open(exclusion?: any, permiso?: string) {
    if (this.validatePermission(permiso)) {
      const modal: NgbModalRef = this.modalService.open(NewExclusionComponent, {size: 'sm'});
      modal.componentInstance.rango = this.rango;
      if (exclusion) {
        modal.componentInstance.exclusion = exclusion;

      }
      modal.result.then(
        (success: Object) => {
          this.loadExclusiones();
          this.loadNumeros();
          this.loadRangoInfoNumeros();
        },
        (error: any) => {
          // console.error(error);
        }
      );
    } else {
      this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de rangos');
    }
  }

  deleteExclusion(id: number) {
    if (this.validatePermission('rangos.eliminar')) {
      this.exclusionRangoService.delete(id).subscribe(res => {
        this.loadExclusiones();
        this.loadNumeros();
        this.loadRangoInfoNumeros();
      });
    } else {
      this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de rangos');
    }
  }

  exportExclusiones(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'tableExclusiones',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'lista_rango_exc');

  }

  exportNumeros(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'tableNumeros',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'lista_rango_num');

  }

  todoNumeros() {

    this.cargarTodoNumeros = !this.cargarTodoNumeros;

    if (this.cargarTodoNumeros) {
      this.mostrarTodoModalNumeros();
    } else {
      this.itemsPerPageNumeros = this.cantidadItems;
      this.loadNumeros();
    }


  }

  todoExclusiones() {

    this.cargarTodoExclusiones = !this.cargarTodoExclusiones;

    if (this.cargarTodoExclusiones) {
      this.mostrarTodoModalExclusiones();
    } else {
      this.itemsPerPageExclusiones = this.cantidadItems;
      this.loadExclusiones();
    }


  }

  public mostrarTodoModalNumeros() {
    const modal: NgbModalRef = this.modalService.open(ModalMostrarTodoComponent, {size: 'sm'});
    modal.componentInstance.texto = this.totalItemsNumeros;
    modal.result.then(
      (success: Object) => {
        this.itemsPerPageNumeros = this.totalItemsNumeros;
        this.loadNumeros();
      },
      (error: any) => {
        this.cargarTodoNumeros = !this.cargarTodoNumeros;
        this.loadNumeros();
      }
    );

  }


  public mostrarTodoModalExclusiones() {
    const modal: NgbModalRef = this.modalService.open(ModalMostrarTodoComponent, {size: 'sm'});
    modal.componentInstance.texto = this.totalItemsExclusiones;
    modal.result.then(
      (success: Object) => {
        this.itemsPerPageExclusiones = this.totalItemsExclusiones;
        this.loadExclusiones();
      },
      (error: any) => {
        this.cargarTodoExclusiones = !this.cargarTodoExclusiones;
        this.loadExclusiones();
      }
    );

  }

  updateRango(evento: any) {
    console.log('evento', evento);
    console.log('rango', this.rango);
    this.rangoService.update(this.rango).subscribe((res: any) => {
      this.toast.showToast('success', 'Informacion', 'Se ha actualizado el rango correctamente');
    });

  }
}
