import {Component, OnInit} from '@angular/core';
import {EntityChangeLogService} from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';
import {ActivatedRoute} from '@angular/router';
import {NgbDateParserFormatter, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {ExportAsConfig, ExportAsService} from 'ngx-export-as';
import {ModalMostrarTodoComponent} from '../../commons/modals/modal-mostrar-todo/modal-mostrar-todo.component';
import {DiffModalComponent} from '@app/commons/modals/diff-modal/diff-modal.component';
import {AppService} from '@app/app.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  fechaInicio: any;
  fechaFin: any;
  totalItems: number = null;
  queryCount: number = null;
  itemsPerPage: number = null;
  page: number = null;
  predicate: any;
  reverse: any;
  entityChangeLogs: any;
  totalPage: number = null;
  filtroSelect: any[];
  filterInput: any = '';
  filterUser: any = null
  showTable = false;
  cargarTodo = false;
  cantidadItems = 25;

  selectsJson: any = {
    usuario: [],
    microservicio: [],
    entidad: [],
    evento: []
  };

  selectData = [];

  constructor(
    private entityChangeLogService: EntityChangeLogService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private toast: CustomToastrService,
    private appService: AppService,
    private exportAsService: ExportAsService
  ) {

    this.itemsPerPage = this.cantidadItems;
    this.page = 1;
    this.predicate = 'id';
    this.filtroSelect = [
      {
        nombre: 'Microservicio',
        filtro: 'microservicio'
      },
      {
        nombre: 'Entidad',
        filtro: 'entidad'
      },
      {
        nombre: 'Tipo evento',
        filtro: 'tipoEvento'
      },
      {
        nombre: 'Usuario',
        filtro: 'usuario'
      }
      ,
      {
        nombre: 'Fecha Creación',
        filtro: 'fecha'
      }
    ];
    this.appService.pageTitle = 'Logs';

  }


  ngOnInit() {
    this.loadAll();
    this.loadSelects();
  }

  async loadAll() {
    const query = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort()
    };

    if (this.filterUser !== null) {

      if (this.filterUser && this.filterUser.filtro !== 'fecha' && this.filterUser.filtro !== null) {
        query[this.filterUser.filtro] = this.filterInput;
      } else if (this.filterUser.filtro === 'fecha') {
        query['fechaInicio'] = this.parserFormatter.format(this.fechaInicio);
        query['fechaFin'] = this.parserFormatter.format(this.fechaFin);
      }

    }


    await this.entityChangeLogService
      .query(query)
      .toPromise().then(
        (res: any) => {
          this.queryCount = res.numberOfElements;
          this.totalPage = res.totalPages;
          this.totalItems = res.totalElements;
          this.entityChangeLogs = res.content;

          if (res.content.length > 0) {
            this.showTable = true;
          } else {
            this.showTable = false;
          }

        },
        (err) => {
          this.showTable = false;
          this.onError(err.message);
        }
      );
  }

  sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.loadAll();
  }

  transition() {
    this.loadAll();
  }

  sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    return result;
  }


  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  filtrar() {
    this.loadAll();
  }


  export(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'table',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'reporte');

  }

  todo() {

    this.cargarTodo = !this.cargarTodo;

    if (this.cargarTodo) {
      this.mostrarTodoModal();
    } else {
      this.itemsPerPage = this.cantidadItems;
      this.loadAll();
    }


  }


  public mostrarTodoModal() {
    const modal: NgbModalRef = this.modalService.open(ModalMostrarTodoComponent, {size: 'sm'});
    modal.componentInstance.texto = this.totalItems;
    modal.result.then(
      (success: Object) => {
        this.itemsPerPage = this.totalItems;
        this.loadAll();
      },
      (error: any) => {
        this.cargarTodo = !this.cargarTodo;
        this.loadAll();
      }
    );

  }

  public mostrarCambios(entity: any) {
    const modal: NgbModalRef = this.modalService.open(DiffModalComponent, {size: 'lg'});
    modal.componentInstance.entity = entity;
    modal.result.then(
      (success: Object) => {

      },
      (error: any) => {

      }
    );

  }

  async loadSelects() {
    await this.entityChangeLogService
      .findSelects()
      .toPromise().then(
        (res: any) => {
          this.selectsJson = res;


        },
        (err) => {
          this.showTable = false;
          this.onError(err.message);
        }
      );
  }

  change(event: any) {

    if (event) {
      if (event.filtro !== 'fecha') {

        this.selectData = this.selectsJson[event.filtro];
      }
    }


  }
}

