import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-mostrar-todo',
  templateUrl: './modal-mostrar-todo.component.html',
  styleUrls: ['./modal-mostrar-todo.component.scss']
})
export class ModalMostrarTodoComponent implements OnInit {

  @Input() private texto: any;

  constructor(
    public modal: NgbActiveModal
  ) {

  }

   ngOnInit() {

  }


  public async modalclose() {
    this.modal.close({estado: 'Ok'});
  }
}


