import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { createRequestOption } from '../helpers/request-util';

@Injectable({
  providedIn: 'root'
})
export class TipoVehiculoService {

  public resourceUrl = environment.apiUrl + 'comparendo/tipo_vehiculo';

  constructor(public http: HttpClient) {
  }

  create(tipoVehiculo: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, tipoVehiculo);
  }

  update(tipoVehiculo: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, tipoVehiculo);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, {params: options});
  }

  listAll(): Observable<any> {
    return this.http.get<any[]>(`${this.resourceUrl}/list_all`);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

  findByFullDescripcion(descripcion: string, limit: number) {
    if (descripcion === '') {
      return of([]);
    }

    return this.http.get<any[]>(`${this.resourceUrl}/find_descripcion?descripcion=${descripcion}&limit=${limit}`)
    .pipe(
      map(response => {
        return response;
      })
    );
  }

}
