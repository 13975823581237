import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';
import {AppService} from '@app/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
    '../../../vendor/styles/pages/authentication.scss'
  ]
})

export class LoginComponent implements OnInit {

  private badCredentials = false;
  private loginForm: FormGroup;

  username = '';
  password = '';
  rememberMe = false;


  constructor(public formBuilder: FormBuilder,
              public router: Router,
              private appService: AppService,
              public authService: AuthService,
              private toast: CustomToastrService) {
    this.appService.pageTitle = 'TrafficSoft - Login';
  }

  ngOnInit() {

  }

  async login() {
    await this.authService.authorize(this.username, this.password).toPromise().then(
      async (response) => {
        await this.authService.login(response).toPromise().then(
          data => {
            this.authService.saveToken(response, this.rememberMe);
            this.badCredentials = false;
            this.authService.saveUser(data);
            this.router.navigate(['/' + this.authService.findModuloPrincipal()]);
          },
          err => {
            if (err.error.error_description === 'Usuario Bloqueado por favor contactar con el administrador') {
              this.toast.showToast('error', 'Error.', 'Usuario Bloqueado por favor contactar con el administrador');
            } else {
              this.toast.showToast('error', 'Error.', 'Error al iniciar sesion,credenciales invalidas');

            }

          }
        );
      },
      err => {
        console.error('Invalid Credentials', err);
        if (err.error.error_description === 'Usuario Bloqueado por favor contactar con el administrador') {
          this.toast.showToast('error', 'Error.', 'Usuario Bloqueado por favor contactar con el administrador');
        } else {
          this.toast.showToast('error', 'Error.', 'Error al iniciar sesion,credenciales invalidas');

        }

      }
    );
  }

  recordarContasena() {
    this.router.navigate(['/recordar-contrasena']);
  }

  closeAlert() {
    this.badCredentials = false;
  }

}
