import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { Observable, of, Subscription, Subscriber } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { CustomToastrService } from '@app/commons/providers/custom-toastr.service';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

// Services
import { UploadFileService } from '@app/providers/uploadFile.service';
import { ComparendoService } from '@app/providers/comparendo.service';
import { CamaraService } from '@app/providers/camara.service';
import { InfraccionService } from '@app/providers/infraccion.service';
import { ArchivoService } from '@app/providers/archivo.service';
import { ArchivoXmlService } from '@app/providers/archivoXml.service';
import { EstadoComparendoUsuarioService } from '@app/providers';
import { WebStorageCustomService } from '@app/commons/providers/web-custom-storage.service';

// Utils
import { makeDateInstance } from '@app/commons/utils/general';
// Types
import { Comparendo } from '@app/commons/interfaces/types/comparendos';
import { Usuario } from '@app/models/usuario';

// Constants
import { VIDEO_FORMATS_ALLOWED } from '@app/commons/constants/app';
import { NgbTime } from '@ng-bootstrap/ng-bootstrap/timepicker/ngb-time';

interface FileItem {
  file: File;
  preview?: any;
  success?: boolean;
  error?: boolean;
  completed?: boolean;
  errorMessage?: string;
  savedUrl?: string;
  status?: number;
  isSaving?: boolean;
}

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: [
    '../../../vendor/libs/ngx-dropzone-wrapper/ngx-dropzone-wrapper.scss',
    './upload-files.component.scss'
  ]
})
export class UploadFilesComponent implements OnInit, OnDestroy {
  files: FileItem[] = [];
  isLoading = false;
  validate = false;
  dragOver = false;
  totalItems: number = null;
  queryCount: number = null;
  itemsPerPage: number = null;
  page: number = null;
  predicate: any;
  reverse: any;
  totalPage: number = null;
  showTable = false;
  cargarTodo = false;
  cantidadItems = 5;
  idUsuario = null;
  nombreArchivo: any = null;

  totalItems2: number = null;
  queryCount2: number = null;
  itemsPerPage2: number = null;
  page2: number = null;
  predicate2: any;
  reverse2: any;
  totalPage2: number = null;
  showTable2 = false;
  cargarTodo2 = false;
  cantidadItems2 = 5;

  // Carga manual
  isRTL = false;
  cargaManual = false;
  listaArchivos: any = [];
  listaArchivosXml: any = [];
  maquinaControl: FormControl;
  maquinaSelected: any;
  infraccionControl: FormControl;
  infraccionSelected: any;
  fechaControl: FormControl;
  horaControl: FormControl;
  placaControl: FormControl;

  camaraLoading = false;
  private camaras: any[] = [];
  public infracciones: any[] = [];
  public infraccionesLoading: boolean = false;

  private intervalReloadDataTable: any;
  private intervalTime = 3000;

  private archivosSubscription: Subscription;
  private archivosXmlSubscription: Subscription;

  private maxFilesCargaManual = 4;
  private maxSizeMG = 30;

  constructor(private customToastrService: CustomToastrService,
    private uploadFileService: UploadFileService,
    private comparendoService: ComparendoService,
    private camaraService: CamaraService,
    private archivoService: ArchivoService,
    private archivoXmlService: ArchivoXmlService,
    private infraccionService: InfraccionService,
    private estadoComparendoUsuarioService: EstadoComparendoUsuarioService,
    private webStorageCustomService: WebStorageCustomService,
    private zone: NgZone) {

    this.itemsPerPage = this.cantidadItems;
    this.page = 1;
    this.predicate = 'id';

    this.itemsPerPage2 = this.cantidadItems2;
    this.page2 = 1;
    this.predicate2 = 'id';

    this.idUsuario =
      this.webStorageCustomService.checkFromLocal('usuario') ? JSON.parse(this.webStorageCustomService.getFromLocal('usuario')).id : null;;
  }

  ngOnInit() {

    this.cargarArchivos();
    this.cargarArchivosXml();
    this.initControls();
    this.loadInfracciones();
    this.loadCamaras();
    this.setDataTableInterval();
  }

  private initControls() {
    this.maquinaControl = new FormControl();
    this.infraccionControl = new FormControl();
    this.fechaControl = new FormControl();
    this.horaControl = new FormControl();
    this.placaControl = new FormControl(null, Validators.compose([Validators.minLength(5), Validators.maxLength(7), Validators.required]));
    this.placaControl.valueChanges.subscribe(val => {
      if (val) {
        this.placaControl.setValue((val as String).toUpperCase(), { emitEvent: false });
      }
    });
  }

  changeCargaManual(currentValue: boolean) {
    console.log('CURENT VALUE ', currentValue);
    if (currentValue) {
      if (this.files.length > 1) {
        this.files = [];
      }
    }
  }

  pushFilesToList(files: FileList) {
    console.log('ARCHIVOS ', this.files);
    Array.from(files).forEach((file: File) => {
      // Validates if the file isnt in the array
      if (!(this.files.filter(_file => _file.file.name === file.name).length > 0)) {
        console.log('Pushuear');
        const fileItem = { file, preview: null, completed: false, error: null, errorMessage: null };
        this.files.push(fileItem);

        const isImage = this.isImage(file);
        if (isImage) {
          this.setImagePreview(fileItem);
        }

        // Validations
        if (fileItem.file.type.indexOf('jpg') === -1 &&
        fileItem.file.type.indexOf('png') === -1 &&
          fileItem.file.type.indexOf('jpeg') === -1 &&
          fileItem.file.type.indexOf('xml') === -1 &&
          VIDEO_FORMATS_ALLOWED.indexOf(fileItem.file.type.split('/')[1]) === -1
        ) {
          console.log('No tiene el formato');
          fileItem.completed = true;
          fileItem.error = true;
          fileItem.errorMessage = 'El archivo no es del formato soportado';
        } else if ((fileItem.file.size / 1000000) > this.maxSizeMG) {
          fileItem.completed = true;
          fileItem.error = true;
          fileItem.errorMessage = `El archivo supera el límite de ${this.maxSizeMG}MG`;
        }
      }
      console.log('La lista de archivos ', this.files);
    });
  }

  private loadCamaras() {

    this.camaraLoading = true;
    this.camaraService.findManual().subscribe(data => {
      this.camaras = data;
      this.camaraLoading = false;

    }, (err) => this.onError(err.message)
    );
  }

  private loadInfracciones() {

    this.infraccionesLoading = true;
    this.infraccionService.allManuales().subscribe(data => {
      this.infracciones = data;
      this.infraccionesLoading = false;

    }, (err) => this.onError(err.message)
    );
  }


  cargarTablas() {
    this.cargarArchivos();
    this.cargarArchivosXml();
  }


  cargarArchivosXml() {
    const query = {
      page: this.page2 - 1,
      size: this.itemsPerPage2,
      sort: this.sort2()
    };
    this.archivosXmlSubscription = this.archivoXmlService.query(query).subscribe((response: any) => {

      this.queryCount2 = response.numberOfElements;
      this.totalPage2 = response.totalPages;
      this.totalItems2 = response.totalElements;
      this.listaArchivosXml = response.content;

      if (response.content.length > 0) {
        this.showTable2 = true;
      } else {
        this.showTable = false;
      }
    });
  }

  cargarArchivos() {
    const query = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort()
    };
   this.archivosSubscription = this.archivoService.query(query).subscribe((response: any) => {
      this.queryCount = response.numberOfElements;
      this.totalPage = response.totalPages;
      this.totalItems = response.totalElements;
      this.listaArchivos = response.content;

      if (response.content.length > 0) {
        this.showTable = true;
      } else {
        this.showTable = false;
      }
    });
  }

  sortlist2(predicate: string) {
    this.predicate2 = predicate;
    this.reverse2 = !this.reverse2;
    this.cargarArchivosXml();
  }

  transition2() {
    this.cargarArchivosXml();
  }

  sort2() {
    const result = [this.predicate2 + ',' + (this.reverse2 ? 'asc' : 'desc')];
    return result;
  }

  sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.cargarArchivos();
  }

  transition() {
    this.cargarArchivos();
  }

  sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    return result;
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  pushFilesToListCargaManual(files: FileList) {
    console.log('ARCHIVOS ', files);
    Array.from(files).forEach((file: File) => {

      console.log('DEL FILTRO ', (this.files.filter((_file) => {
        return _file.file.name === file.name ||
          (!_file.error && !_file.completed);
      })));

      // Validates if the file isnt in the array
      if (!(this.files.filter((_file) => {
        return _file.file.name === file.name ||
          (!_file.error && !_file.completed);
      }).length > this.maxFilesCargaManual - 1)/* && this.isImage(file)*/) {
        const fileItem = { file, preview: null, completed: false, error: null, errorMessage: null };
        this.files.push(fileItem);

        const isImage = this.isImage(file);
        this.setImagePreview(fileItem);

        console.log('[ARCHIVO] ', fileItem);

        // Validations
        if (fileItem.file.type.indexOf('jpg') === -1 &&
        fileItem.file.type.indexOf('png') === -1 &&
          fileItem.file.type.indexOf('jpeg') === -1 &&
          VIDEO_FORMATS_ALLOWED.indexOf(fileItem.file.type.split('/')[1]) === -1
        ) {
          console.log('No tiene el formato');
          fileItem.completed = true;
          fileItem.error = true;
          fileItem.errorMessage = 'El archivo no es del formato soportado';
        } else if ((fileItem.file.size / 1000000) > this.maxSizeMG) {
          fileItem.completed = true;
          fileItem.error = true;
          fileItem.errorMessage = `El archivo supera el límite de ${this.maxSizeMG}MG`;
        }
      }
      console.log('La lista de archivos ', this.files);
    });
  }

  async removeFileItem(index: number) {
    if (!this.cargaManual) {
      if (this.files[index].savedUrl) {
        await this.uploadFileService.deleteUpload(this.files[index].savedUrl).toPromise()
          .then(() => {
            console.log('Archivo removido');
          }, () => {
            console.log('Error al eliminar el archivo');
          });
      }
      this.files.splice(index, 1);
    } else {
      this.files.splice(index, 1);
    }
    console.log('Despues de quitar ', this.files);
    this.isLoading = false;
    this.zone.run(() => {
    });
  }

  setImagePreview(fileItem: FileItem) {
    const reader = new FileReader();

    reader.onloadend = function () {
      fileItem.preview = reader.result;
    };

    if (fileItem) {
      reader.readAsDataURL(fileItem.file);
    } else {
      fileItem.preview = '';
    }
  }

  isImage(file: File): boolean {
    return file.type.indexOf('jpg') !== -1 || file.type.indexOf('jpeg') !== -1  || file.type.indexOf('png') !== -1;
  }

  getFileSize(file: File) {
    const totalBytes = file.size;
    if (totalBytes < 1000000) {
      var _size = Math.floor(totalBytes / 1000) + 'KB';
      return (_size);
    } else {
      var _size = Math.floor(totalBytes / 1000000) + 'MB';
      return (_size);
    }
  }

  selectFilesInput($event) {
    if (this.cargaManual) {
      this.pushFilesToListCargaManual($event.target.files);
    } else {
      this.pushFilesToList($event.target.files);
    }
    $event.target.value = '';
  }

  checkThereAreFiles() {
    return this.files.length < 1;
  }

  async confirmUploadFiles() {
    this.isLoading = true;
    const filesToSave = this.files.filter(file => !file.completed || file.error);
    const xmlFiles = filesToSave.filter(file => file.file.type.toLowerCase().indexOf('xml') !== -1);
    const otherFiles = filesToSave.filter(file => {

      return file.file.type.toLowerCase().indexOf('xml') === -1 &&
      file.file.name.toLowerCase().indexOf('crop') === -1 &&
      file.file.name.toLowerCase().indexOf('_2.jpg') === -1;
    });
    const cropJpgFiles = filesToSave.filter(file => file.file.name.toLowerCase().indexOf('crop') !== -1 ||
      file.file.name.toLowerCase().indexOf('_2.jpg') !== -1 ||
      file.file.name.toLowerCase().indexOf('.png') !== -1);
    let lastFiles = xmlFiles.concat(otherFiles);
    lastFiles = lastFiles.concat(cropJpgFiles);
    console.log('Final files saved ', lastFiles);
    console.log('xmlFiles ', xmlFiles);
    console.log('otherFiles ', otherFiles); 
    console.log('cropJpgFiles ', cropJpgFiles);
    if (!this.cargaManual) {
      for (const file of lastFiles) {
        await this.comparendoService.findByIfExistImageOrXmlByName(file.file.name).toPromise()
          .then((response) => {
            console.log("response 123123", response);
            
            if (response.length > 0) {
              file.completed = true;
              file.error = true;
              file.errorMessage = 'El archivo ya ha sido subido';
            }
          })
          .catch((err) => {
            console.log(err);
          });

        if (!file.error) {
          file.isSaving = true;
          try {
            await this.uploadFileService.upload(file.file, 'upload', (progress: number) => {
              file.status = progress;
            }, (response) => {
              file.completed = true;
              file.success = true;
              file.savedUrl = response.data.relativeUrl;
              file.isSaving = false;
  
              setTimeout(() => {
                this.files = this.files.filter(file => file.success !== true);
              }, 5000);
  
              if(response.data.filename.includes('.xml') || response.data.filename.includes('.XML')){
                this.archivoXmlService.createUpload({
                  nombre: response.data.filename,
                  eliminado: false,
                  usuario: { id: this.idUsuario }}).subscribe((resp) => {
                });
              }else{
                this.archivoService.createUpload({
                  nombre: response.data.filename,
                  eliminado: false, usuario: { id: this.idUsuario }}).subscribe((resp) => {
                });
              }
  
              console.log('response ', response);
  
            }, () => {
              file.completed = true;
              file.error = true;
              file.errorMessage = 'Error al subir el archivo';
              file.isSaving = false;
            });
          } catch (err) {
            console.error('Error subir archivo ', file);
          }
          this.zone.run(function() {});
        }
      }
    } else {
      // Carga manual formato de valores
      const fecha = this.cargaManualFormatDate(this.fechaControl.value, this.horaControl.value);
      // const responsesSaveFile: any[] = [];

      this.saveFilesManually(lastFiles)
      .then((responsesSaveFile) => {
        console.log('RESPONSES AT END ', responsesSaveFile);
        this.crearComparendo(responsesSaveFile, fecha);
      })
      .catch((responsesSaveFile) => {
        responsesSaveFile.forEach((response) => {
          this.uploadFileService.deleteUpload(response.data.relativeUrl)
          .subscribe((_response) => {
            console.log('Se elimino archivo ', _response);
          }, (_error) => {
            console.log('Error eliminar archivo ', _error);
          });
        });
      });

    }

    this.isLoading = false;
  }

  private crearComparendo(responses: any[], fecha: Date) {
    // Comparendo
    const comparendo: Comparendo = {
      fechaInfraccion: fecha,
      usuarioId: this.idUsuario,
      camaraId: this.maquinaSelected.id,
      infraccion: this.infraccionSelected,
      placa: this.placaControl.value,
      eliminado: false
    };
    // File info
    const fileInfo: { fileName: string; fileUrl: string; fileDirectory: string }[] = [];
    responses.forEach((response) => {
      fileInfo.push({
        fileName: response.data.filename,
        fileUrl: response.data.url,
        fileDirectory: response.data.relativeUrl.split('/var/www')[1]
      });
    });

    const body = {
      comparendo,
      fileInfo
    };
    console.log('BODY ', body);
    this.comparendoService.comparendoManual(body)
      .subscribe((_response) => {
        console.log('COMPARENDO MANUAL OK ', _response);
        this.customToastrService.showToast('success', '', 'Comparendo creado');
        this.fechaControl.setValue(null, { emitEvent: false });
        this.horaControl.setValue(null, { emitEvent: false });
        this.placaControl.setValue(null, { emitEvent: false });
        this.files = [];
      }, (error) => {
        this.customToastrService.showToast('error', '', 'Error al crear comparendo');
        console.log('ERROR COMPARENDO MANUAL ', error);
        this.files = [];
      });
  }

  filesToSave() {
    return this.files.filter(file => !file.completed || !file.error).length < 1;
  }

  filesDropped($event: FileList) {
    if (!this.cargaManual) {
      this.dragOver = false;
      this.pushFilesToList($event);
    } else {
      if (this.cargaManualEnabled()) {
        this.dragOver = false;
        this.pushFilesToListCargaManual($event);
      }
    }
  }

  onFilesHovered($event) {
    if (!this.cargaManual) {
      this.dragOver = $event;
    } else {
      if (this.cargaManualEnabled()) {
        this.dragOver = $event;
      }
    }
  }

  /*getMaquinas = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => { }),
      switchMap(term =>
        this.camaraService.findByNameManual(term, 5).pipe(
          tap(() => { }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => { })
    )*/

  getInfracciones = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => {
      }),
      switchMap(term =>
        this.infraccionService.findByDescripcion(term, 5).pipe(
          tap(() => {
          }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => {
      })
    );

  formatterMaquina = (m: any) => m.nombre;

  formatterInfraccion = (i: any) => i.descripcion;

  private cargaManualEnabled(): boolean {
    return this.maquinaSelected &&
      this.infraccionSelected &&
      this.fechaControl.value &&
      this.horaControl.value &&
      this.placaControl.value &&
      this.placaControl.valid;
  }

  private cargaManualFormatDate(fecha: NgbDateStruct, hora: NgbTimeStruct): Date {
    const newFecha: Date = new Date(Date.UTC(fecha.year, (fecha.month - 1), fecha.day, hora.hour, hora.minute, hora.second));
    return newFecha;
  }

  private saveFilesManually(files: FileItem[]): Promise<any> {
    const responsesSaveFile: any[] = [];
    return new Promise(async (_resolve, _reject) => {
      for (let x = 0; x < files.length; x++) {

        const file = files[x];
        await this.comparendoService.findByIfExistImageOrXmlByName(file.file.name).toPromise()
          .then((response) => {
            if (response.length > 0) {
              file.completed = true;
              file.error = true;
              file.errorMessage = 'El archivo ya ha sido subido';
            }
          })
          .catch((err) => {
            console.log(err);
          });

        if (!file.error) {
          file.isSaving = true;
          await this.uploadManually(file)
            .then((response) => {
              responsesSaveFile.push(response);
            })
            .catch((err) => {
              console.log('Error carga manual "upload": ', err);
            });
        }
        console.log('RESPONSES SAVE FILE ', responsesSaveFile);
        this.zone.run(function() {});
      }

      if (responsesSaveFile.length < files.length) {
        _reject(responsesSaveFile);
      } else {
        _resolve(responsesSaveFile);
      }
    });
  }

  uploadManually(file: FileItem): Promise<any> {
    const promiseUpload: Promise<any> = new Promise((_resolve, _reject) => {
      let responseFile;
      this.uploadFileService.upload(file.file, 'carga_manual', (progress: number) => {
        file.status = progress;
      }, (_response) => {
        console.log('RESPUESTA SUBIR ARCHIVO ', _response);
        responseFile = _response;
        file.completed = true;
        file.success = true;
        file.savedUrl = responseFile.data.relativeUrl;
        file.isSaving = false;
        _resolve(responseFile);
        // responsesSaveFile.push(responseFile);
        // console.log('Responses save file >>>>> ', responsesSaveFile);
      }, (_error) => {
        console.log('Error al subir el archivo ', _error);
        file.completed = true;
        file.error = true;
        if (_error.data.errorMessage) {
          file.errorMessage = _error.data.errorMessage;
        } else {
          file.errorMessage = 'Error al subir el archivo';
        }
        file.isSaving = false;
        _reject(_error);
      });
    });
    return promiseUpload;
  }

  trackByFn(item: any) {
    return item.id;
  }

  ngOnDestroy() {
    this.clearDataTableInterval();
  }

  private setDataTableInterval(): void {
    if (!this.intervalReloadDataTable) {
      this.intervalReloadDataTable = setInterval(() => {
        if (this.archivosSubscription.closed) {
          this.cargarArchivos();
        } else {
          console.log('última solicitud no resuelta cargarArchivos()');
        }
        if (this.archivosXmlSubscription.closed) {
          this.cargarArchivosXml();
        } else {
          console.log('última solicitud no resuelta cargarArchivosXml()');
        }
      }, this.intervalTime);
    }
  }

  private clearDataTableInterval(): void {
    if (this.intervalReloadDataTable) {
      clearInterval(this.intervalReloadDataTable);
      this.intervalReloadDataTable = null;
    }
  }

  public controlsInvalid(): boolean {
    if (this.cargaManual) {
      return this.placaControl.invalid ||
      !this.horaControl.value ||
      !this.fechaControl.value ||
      !this.maquinaSelected ||
      !this.infraccionSelected;
    } else {
      return false;
    }
  }

  private saveFileAfterUpload(response: any) {
    console.log('Validar si guardar el registro ');
    if (response.data.url) {
      if (response.data.relativeUrl) {
        if (response.data.filename) {
          console.log('Parametros OK ', response.data);
          this.createArchivoOrXml(response.data.fileName, response.data.url, response.data.relativeUrl);
        } else {
          console.log('No esta presente el filename ', response.data);
        }
      } else {
        console.log('No esta presente el directorio ', response.data);
      }
    } else {
      console.log('No esta presente la url ', response.data);
    }
  }

  private createArchivoOrXml(fileName: string, url: string, relativeUrl: string): void {
    const validateIfRowExists = function (isXmlParam: boolean, filename: string): Promise<any> {
      return new Promise(async (resolve, reject) => {

        let length = null;
        let errorValidation = null;

        if (isXmlParam) {
          // do something
          await this.archivoXmlService.encontrarPorNombreYEliminado(filename).toPromise()
          .then(res => {
            length = res.length;
          })
          .catch(err => {
            errorValidation = err;
          });
        } else {
          // do something
          await this.archivoService.encontrarPorNombreYEliminado(filename).toPromise()
          .then(res => {
            length = res.length;
          })
          .catch(err => {
            errorValidation = err;
          });
        }

        // Valida registros por nombre
        if (length) {
          console.log(`Cantidad de registros con nombre de archivo ${filename} y eliminado = ${false} `, length);
          resolve(length < 1);
        } else {
          reject(errorValidation);
        }
      });
    };

    const usuario =
      this.webStorageCustomService.getFromLocal('usuario') ?
        <Usuario>JSON.parse(this.webStorageCustomService.getFromLocal('usuario')) : null;
    if (!usuario) {
      console.log('Ningun usuario encontrado en webstorage ', usuario);
    }
    const isXml: boolean = fileName.toUpperCase().indexOf('.XML') !== -1;

    validateIfRowExists(isXml, fileName).then(res => {
      if (res) {
        console.log('Se puede crear el registro ', res);

        const registroArchivo = {
          nombre: fileName,
          eliminado: false,
          url: url,
          directorio: relativeUrl,
          fechaActualizacion: new Date(),
          fechaCreacion: new Date(),
          usuarioId: usuario ? usuario.id : null
        };

        console.log('registro a salvar ', registroArchivo);

        if (isXml) {
          console.log('Crear registro en ArchivoXml');
          this.archivoXmlService.create(registroArchivo)
          .subscribe((response) => {
            console.log('Registro ', JSON.stringify(registroArchivo, null, 2), ' respuesta ', response);
          }, (err) => {
            console.log('Error al guardar registro ', JSON.stringify(registroArchivo, null, 2), ' respuesta ', err);
          });
        } else {
          console.log('Crear registro en Archivo');
          this.archivoService.create(registroArchivo)
          .subscribe((response) => {
            console.log('Registro ', JSON.stringify(registroArchivo, null, 2), ' respuesta ', response);
          }, (err) => {
            console.log('Error al guardar registro ', JSON.stringify(registroArchivo, null, 2), ' respuesta ', err);
          });
        }

      } else {
        console.log('No se puede crear el registro ', res);
      }
    }).catch((err) => {
      console.log(`Error al validar si existe registro del archivo ${fileName}`, err);
    });
  }

}
