import {AfterViewInit, Component, OnInit, ViewChild, HostListener, NgZone} from '@angular/core';
import {ModalHelperService} from '../modal-helper/modal-helper.service';
import {CountdownComponent} from 'ngx-countdown';
import {AuthService} from '../../../providers';
import {CustomToastrService} from '../../providers/custom-toastr.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-inactividad',
  templateUrl: './modal-inactividad.component.html',
  styleUrls: ['./modal-inactividad.component.scss']
})
export class ModalInactividadComponent implements AfterViewInit {

  private logout = false;

  @ViewChild('countdown') counter: CountdownComponent;

  constructor(
    private authService: AuthService,
    private toast: CustomToastrService,
    private zone: NgZone,
    private modalHelperService: ModalHelperService,
    private modal: NgbActiveModal) {
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {

      this.counter.begin();

    });
  }

  finishTest() {

    this.zone.run(() => {

      this.counter.stop();

    });
    this.logout = true;
    this.authService.logout();
    this.toast.showToast('info', 'Información', 'Sesion cerrada por inactividad');

  }

  cancelar() {
    this.counter.stop();
    this.modal.close({estado: 'Ok'});
  }

  modalclose() {
    this.modal.close({estado: 'Ok'});
  }


}
