import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';

// Types
import { PicoPlaca } from '../commons/interfaces/types/pico-placa';

@Injectable({
  providedIn: 'root'
})
export class PicoPlacaService {

  public resourceUrl = environment.apiUrl + 'comparendo/restriccion_pico_placa';
  constructor(public http: HttpClient) { }

  create(picoPlaca: PicoPlaca): Observable<any> {
    return this.http.post<any>(this.resourceUrl, picoPlaca);
  }

  update(picoPlaca: PicoPlaca): Observable<any> {
    return this.http.put<any>(this.resourceUrl, picoPlaca);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any>(this.resourceUrl, { params: options });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

  get(id): Observable<PicoPlaca> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

}
