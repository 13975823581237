import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, ExclusionRangoService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import JSBI from 'jsbi';
import {createCounterRangeValidator} from '../../../../commons/counter-input/counter-input.component';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-new-exclusion',
  templateUrl: './new-exclusion.component.html',
  styleUrls: ['./new-exclusion.component.scss']
})

export class NewExclusionComponent implements OnInit {

  @Input() private exclusion: any;
  @Input() private rango: any;

  crearExclusionForm: FormGroup;
  itemsPerPage: any;
  page: any;
  predicate: any;
  ciudades: any[] = [];
  permisos: Array<any>;
  constructor(
    private exclusionService: ExclusionRangoService,
    public webStorageCustomService: WebStorageCustomService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private toast: CustomToastrService,
    private authService: AuthService,
    public modal: NgbActiveModal,
  ) {

    console.log('JSBI', JSBI.BigInt('99999999999999999999'));

    this.itemsPerPage = 15;
    this.page = 1;
    this.predicate = 'rangoInicial';

  }

   ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.crearExclusionForm = this.formBuilder.group({
      eliminado: false,
      id: null,
      rango: null,
      estado: true,
      rangoInicial: [this.rango.rangoInicial, [Validators.required, createCounterRangeValidator(this.rango.rangoFinal, this.rango.rangoInicial)]],
      rangoFinal: [this.rango.rangoInicial, [Validators.required, createCounterRangeValidator(this.rango.rangoFinal, this.rango.rangoInicial)]],
      fechaCreacion: [''],
      fechaActualizacion: ['']
    });
    this.crearExclusionForm.get('rango').patchValue(this.rango);
    if (this.exclusion) {
      this.patchValues(this.exclusion);
    } else {
      this.crearExclusionForm.get('rangoInicial').patchValue(this.rango.rangoInicial);
      this.crearExclusionForm.get('rangoFinal').patchValue(this.rango.rangoInicial);

    }
  }

  private patchValues(exclusion: Object) {
    this.crearExclusionForm.patchValue(exclusion);
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }


  async guardar() {

    await this.createOrUpdateItem(this.crearExclusionForm.value, this.exclusion ? true : false).then(response => {
      this.toast.showToast('success', '', 'La exclusion se ha creado satisfactoriamente');
      this.modal.close({response});
    }, error2 => {

      if (error2.status === 400) {
        this.toast.showToast('error', error2.error.message, error2.error.details);
      } else {
        this.toast.showToast('error', 'Error.', 'Error ha ocurrido un error al crear la exclusion.');
      }

    });

  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }private async createOrUpdateItem(exclusion: any, exits: any): Promise<any> {
    if (!exits) {
      return this.exclusionService.create(exclusion).toPromise();
    }
    if (exits) {
      return this.exclusionService.update(exclusion).toPromise();
    }
  }


}
