import {Component, NgZone, OnInit} from '@angular/core';
import {Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {AppService} from './app.service';
import {LayoutService} from './layout/layout.service';
import {NgIdleHelperService} from './commons/providers/ng-idle-helper.service';
import {AuthService} from './providers';

import { Observable } from 'rxjs';
import { ExportAsService } from 'ngx-export-as';

import * as XLSX from 'xlsx';

ExportAsService.prototype.get = function (config) {
  // structure method name dynamically by type
  const func = config.type !== 'csv' ? 'get' + config.type.toUpperCase() : 'getCSVCustom';
  // if type supported execute and return
  if (this[func]) {
    return this[func](config);
  }

  // throw error for unsupported formats
  return Observable.create((observer) => { observer.error('Export type is not supported.'); });
};

(ExportAsService.prototype as any).getCSVCustom = function (config) {
  return Observable.create((observer) => {
    const element: HTMLElement = document.getElementById(config.elementId);
    const csv = [];
    const rows: any = element.querySelectorAll('table tr');
    for (let index = 0; index < rows.length; index++) {
      const rowElement = rows[index];
      const row = [];
      const cols = rowElement.querySelectorAll('td, th');
      for (let colIndex = 0; colIndex < cols.length; colIndex++) {
        const col = cols[colIndex];
        row.push(col.innerText);
      }
      csv.push(row.join(';'));
    }

    const universalBOM = '\uFEFF';

    const csvContent = `data:text/csv; charset=utf-8,${encodeURIComponent(universalBOM + csv.join('\n'))}`;

    const a = window.document.createElement('a');
    a.setAttribute('href', csvContent);
    a.setAttribute('download', config.fileName);
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);

    // if (config.download) {
    //   this.downloadFromDataURL(config.fileName, csvContent);
      observer.next();
    // } else {
    //   observer.next(csvContent);
    // }
    observer.complete();
  });
};

(ExportAsService.prototype as any).getXLS = function(config): Observable<string | null> {
  return Observable.create((observer) => {

    const element: HTMLElement = document.getElementById(config.elementId);
    const ws3 = XLSX.utils.table_to_sheet(element, config.options);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws3, config.fileName);
    const out = XLSX.write(wb, { type: 'base64' });
    const xlsContent = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;base64,' + out;
    if (config.download) {
      this.downloadFromDataURL(config.fileName, xlsContent);
      observer.next();
    } else {
      observer.next(xlsContent);
    }
    observer.complete();
  });
}
// Services

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [':host { display: block; }']
})

export class AppComponent implements OnInit {

  constructor(private router: Router,
              private appService: AppService,
              private zone: NgZone,
              private layoutService: LayoutService,
              private ngIdleHelperService: NgIdleHelperService,
              private authService: AuthService
  ) {
    // Subscribe to router events to handle page transition
    this.router.events.subscribe(this.navigationInterceptor.bind(this));

    // Disable animations and transitions in IE10 to increase performance
    if (typeof document['documentMode'] === 'number' && document['documentMode'] < 11) {
      const style = document.createElement('style');
      style.textContent = `
            * {
                -ms-animation: none !important;
                animation: none !important;
                -ms-transition: none !important;
                transition: none !important;
            }`;
      document.head.appendChild(style);
    }
  }

  private navigationInterceptor(e: RouterEvent) {
    if (e instanceof NavigationStart) {

      // Set loading state
      document.body.classList.add('app-loading');
      this.authService.reloadPermisos();
    }

    if (e instanceof NavigationEnd) {
      // Scroll to top of the page
      this.appService.scrollTop(0, 0);
    }

    if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
      // On small screens collapse sidenav
      if (this.layoutService.isSmallScreen() && !this.layoutService.isCollapsed()) {
        setTimeout(() => this.layoutService.setCollapsed(true, true), 10);
      }

      // Remove loading state
      document.body.classList.remove('app-loading');
    }
  }

  ngOnInit() {

    this.ngIdleHelperService.start();
  }

}

