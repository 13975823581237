import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Layout1Component} from './layout/layout-1/layout-1.component';
import {
  CamaraComponent,
  CambiarContrasenaComponent,
  CiudadComponent,
  ConsultasExternasComponent,
  ConsultasExternasPublicComponent,
  ConsultasInternasComponent,
  ConsultasRuntComponent,
  DashboardComponent,
  LoginComponent,
  NotFoundComponent,
  PerfilComponent,
  PicoPlacaFichaComponent,
  PicoPlacaListComponent,
  ProtectedComponent,
  RadaresComponent,
  RecordarContrasenaComponent,
  ReportesComponent,
  RolesComponent,
  UploadFilesComponent,
  UsuariosComponent,
  ListaNotificacionesComponent
} from './components';
import {AuthenticatedGuard} from './guards/authenticated/authenticated.guard';
import {CertificarComparendosComponent} from './components/comparendos/certificar-comparendos/certificar-comparendos.component';
import {InfraccionesComponent} from './components/infracciones/infracciones.component';
import {TipoVehiculoComponent} from './components/tipo-vehiculo/tipo-vehiculo.component';
import {ClaseServicioComponent} from './components/clase-servicio/clase-servicio.component';
import {ModalidadesTransporteComponent} from './components/modalidades-transporte/modalidades-transporte.component';
import {TransportePasajerosComponent} from './components/transporte-pasajeros/transporte-pasajeros.component';
import {TipoInfractorComponent} from './components/tipo-infractor/tipo-infractor.component';
import {RadioAccionComponent} from './components/radio-accion/radio-accion.component';
import {TipoDocumentoComponent} from './components/tipo-documento/tipo-documento.component';
import {DivipoComponent} from './components/divipo/divipo.component';
import {ListaBlancaComponent} from './components/lista-blanca/lista-blanca.component';
import {RangoComponent} from './components/rango/rango.component';
import {VerComponent} from './components/rango/children/ver/ver.component';
import {ObservacionComponent} from './components/observacion/observacion.component';
import {AdminComponent} from './components/admin/admin.component';
import {LogsComponent} from './components/logsReport/logs.component';
import {InfractorComponent} from './components/infractor/infractor.component';
import {UnauthenticatedGuard} from './guards/unauthenticated/unauthenticated.guard';
import {SecretariaComponent} from './components/secretaria/secretaria.component';
import {ParametrizacionComponent} from './components/parametrizacion/parametrizacion.component';
import { ARIA_DESCRIBER_PROVIDER_FACTORY } from '@angular/cdk/a11y';
import { TipoEstadoCamaraComponent } from './components/tipo-estado-camara/tipo-estado-camara.component';
import { VerComparendosComponent } from './components/comparendos/ver-comparendos/ver-comparendos.component';


// *******************************************************************************
// Layouts

// *******************************************************************************
// Pages

// *******************************************************************************
// Routes

const routes: Routes = [
  {
    path: 'dashboard',
    component: Layout1Component,
    canActivate: [AuthenticatedGuard],
    children: [
      {path: 'dashboard', component: DashboardComponent}
    ]
  },
  {
    path: 'administracion-usuarios',
    component: Layout1Component,
    canActivate: [AuthenticatedGuard],
    children: [
      {path: 'usuarios', component: UsuariosComponent},
      {path: 'roles', component: RolesComponent},
      {path: 'infractores', component: InfractorComponent}

    ]
  },
  {
    path: 'administracion-radares',
    component: Layout1Component,
    canActivate: [AuthenticatedGuard],
    children: [
      {path: 'radares', component: RadaresComponent},
      {path: 'camaras', component: CamaraComponent},
      {path: 'tipo-estado-camara', component: TipoEstadoCamaraComponent},
    ]
  },
  {
    path: 'parametros-sistema',
    component: Layout1Component,
    canActivate: [AuthenticatedGuard],
    children: [
      {path: 'ciudades', component: CiudadComponent},
      {path: 'secretarias', component: SecretariaComponent},
      {path: 'parametrizacion', component: ParametrizacionComponent},
      {path: 'infracciones', component: InfraccionesComponent},
      {path: 'tipo-documento', component: TipoDocumentoComponent},
      {path: 'divipo', component: DivipoComponent},
      {path: 'lista-blanca', component: ListaBlancaComponent},
    ]
  },
  {
    path: 'parametros-comparendos',
    component: Layout1Component,
    children: [
      {path: 'tipo-vehiculos', component: TipoVehiculoComponent, canActivate: [AuthenticatedGuard]},
      {path: 'modalidad-transporte', component: ModalidadesTransporteComponent, canActivate: [AuthenticatedGuard]},
      {path: 'clase-servicio', component: ClaseServicioComponent, canActivate: [AuthenticatedGuard]},
      {path: 'transporte-pasajeros', component: TransportePasajerosComponent, canActivate: [AuthenticatedGuard]},
      {path: 'tipo-infractor', component: TipoInfractorComponent, canActivate: [AuthenticatedGuard]},
      {path: 'radio-accion', component: RadioAccionComponent, canActivate: [AuthenticatedGuard]},
      {path: 'rangos', component: RangoComponent, canActivate: [AuthenticatedGuard]},
      {path: 'rangos/:id', component: VerComponent},
      {path: 'observacion', component: ObservacionComponent, canActivate: [AuthenticatedGuard]},
      {path: 'pico-placa', component: PicoPlacaListComponent, canActivate: [AuthenticatedGuard]},
      {path: 'pico-placa/ficha', component: PicoPlacaFichaComponent},
      {path: 'pico-placa/:id', component: PicoPlacaFichaComponent}
    ]
  },
  {
    path: 'consultas',
    component: Layout1Component,
    canActivate: [AuthenticatedGuard],
    children: [
      {path: 'consultas/consultas-internas', component: ConsultasInternasComponent, canActivate: [AuthenticatedGuard]},
      {path: 'consultas/consultas-externas', component: ConsultasExternasComponent},
      {path: 'consultas/consultas-runt', component: ConsultasRuntComponent}
    ]
  },
  {
    path: 'reportes',
    component: Layout1Component,
    canActivate: [AuthenticatedGuard],
    children: [
      { path: 'reportes', component: ReportesComponent },
      { path: 'reportes-consultas-externas', component: ReportesComponent, data: { noValidateUrl: true } }
    ]
  },
  {
    path: 'notificaciones',
    component: Layout1Component,
    // canActivate: [AuthenticatedGuard],
    children: [
      { path: '', component: ListaNotificacionesComponent },
    ]
  },
  {
    path: 'administracion-sistema',
    component: Layout1Component,
    canActivate: [AuthenticatedGuard],
    children: [
      {path: 'spring-boot-admin', component: AdminComponent},
      {path: 'logs', component: LogsComponent}

      /* {path: 'secretarias', component: ReportesComponent}, */
    ]
  },
  {path: 'perfil', component: PerfilComponent},
  {
    path: 'consultas-externas-public',
    component: ConsultasExternasPublicComponent
  },
  {
    path: 'upload-files',
    component: Layout1Component,
    // canActivate: [AuthenticatedGuard],
    children: [
      {path: '', component: UploadFilesComponent}
    ]
  },
  {
    path: 'certificar-comparendos',
    component: Layout1Component,
    // canActivate: [AuthenticatedGuard],
    children: [
      {path: '', component: CertificarComparendosComponent}
    ]
  },
  {
    path: 'ver-comparendos/:id',
    component: Layout1Component,
    // canActivate: [AuthenticatedGuard],
    children: [
      {path: '', component: VerComparendosComponent}
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
     canActivate: [UnauthenticatedGuard]
  },
  {
    path: 'recordar-contrasena',
    component: RecordarContrasenaComponent
  },
  {
    path: 'cambiar-contrasena',
    component: CambiarContrasenaComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'protected',
    component: ProtectedComponent
  },
  {path: '', redirectTo: 'dashboard/dashboard', pathMatch: 'full'},
  {path: '**', redirectTo: 'not-found', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
