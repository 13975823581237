import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

/**
  <app-show-entries
    [entriesOptions]="[5,10,15,20]"
    [positionRight]="true"
    (selectedOptionChanged)="paginate($event)"
  ></app-show-entries>
*/

@Component({
  selector: 'app-show-entries',
  templateUrl: './show-entries.component.html',
  styleUrls: ['./show-entries.component.scss']
})
export class ShowEntriesComponent implements OnInit {
  @Input() entriesOptions: number[];
  @Input() positionRight: boolean;
  @Output() selectedOptionChanged = new EventEmitter<number>();
  selectedOption: FormControl;
  constructor() { }

  ngOnInit() {
    this.initControls();
    this.setDefaultOption();
    console.log('entry');
  }

  private initControls() {
    this.selectedOption = new FormControl();
    this.selectedOption.valueChanges
    .subscribe(val => {
      if (val) {
        this.selectedOptionChanged.emit(val);
      }
    });
  }

  private setDefaultOption() {
    if (this.entriesOptions) {
      this.selectedOption.setValue(this.entriesOptions[0] ? this.entriesOptions[0] : null, { emitEvent: false });
    }
  }

}
