import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  AuthService,
  CiudadService,
  RolService,
  TipoDocumentoService,
  UploadFileService,
  UsuarioArchivoService,
  UsuarioService,
  UsuarioTipoArchivoService
} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';
import {FileViewverComponent} from '../../../../commons/modals/file-viewver/file-viewver.component';
import {ifTrue} from 'codelyzer/util/function';

@Component({
  selector: 'app-new-edit-usuario',
  templateUrl: './new-edit-usuario.component.html',
  styleUrls: ['./new-edit-usuario.component.scss'],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]

})
export class NewEditUsuarioComponent implements OnInit {
  @Input() private usuario: any;
  @Input() private fromUsers: boolean;
  @Input() private ver: boolean;
  crearUsuarioForm: FormGroup;
  tiposDocumento: any;
  roles: any;
  ciudades: any;
  ciudadLoading = true;

  selfEdit: boolean;
  permisos: Array<any>;
  private usuarioTipoArchivo: Array<any>;
  private certificadores: Array<any>;

  constructor(
    private ciudadService: CiudadService,
    private usuarioService: UsuarioService,
    private rolService: RolService,
    private usuarioTipoArchivoService: UsuarioTipoArchivoService,
    private usuarioArchivoService: UsuarioArchivoService,
    private uploadService: UploadFileService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private tipoDocumentoService: TipoDocumentoService,
    private toast: CustomToastrService,
    private router: Router,
    private authService: AuthService,
    private webStorageCustomService: WebStorageCustomService,
    public modal: NgbActiveModal,
  ) {
    this.crearUsuarioForm = this.formBuilder.group({
      certificador: null,
      password: null,
      estado: [true],
      ciudadId: null,
      agenteValidador: this.formBuilder.group({
        id: null
      }),
      eliminado: false,
      fechaCreacion: null,
      fechaActualizacion: null,
      certificaHasta: '',
      id: null,
      usuario: ['', [Validators.required, Validators.minLength(3)]],
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      apellido: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      rol: ['', [Validators.required]],
      tipoDocumento: [{}, Validators.required],
      identificacion: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(18)]],
      telefono: ['', [Validators.minLength(7), Validators.maxLength(12)]],
      placa: ['']
    });

  }


  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }

    this.selfEdit = (this.usuario != undefined && (JSON.parse(this.webStorageCustomService.getFromLocal('usuario')).id === this.usuario.id) && !this.fromUsers);
    this.getCertificadores();
    this.getUsuariosTipoarchivo();
    this.loadCiudades();
    this.getRoles();
    this.getTiposDocumento();

    if (this.usuario !== undefined) {

      if (this.usuario.certificaHasta !== null) {
        this.usuario.certificaHasta = new Date(this.usuario.certificaHasta);
      }
      if (this.usuario.agenteValidador == null) {

        this.usuario.agenteValidador = {
          id: null
        };
      }

      console.log('usuario', this.usuario);
      this.crearUsuarioForm.patchValue(this.usuario);

    }
    this.rolChange();
  }

  getRoles() {
    this.rolService
      .query()
      .subscribe(
        (res) => this.roles = res.content,
        (err) => {
          this.toast.showToast('error', 'Error.', 'Error al obtener la lista de roles');
        }
      );

  }

  getTiposDocumento() {
    this.tipoDocumentoService.query()
      .subscribe(
        (res) => this.tiposDocumento = res.content,
        (err) => {
          this.toast.showToast('error', 'Error.', 'Error al obtener la lista de tipos de documento');
        }
      );

  }


  getCertificadores() {

    let params = null;
    if (this.usuario !== undefined) {

      params = {
        id: this.usuario.id
      };
    }
    this.usuarioService.findCertificadores(params)
      .subscribe(
        (res) => this.certificadores = res,
        (err) => {
          this.toast.showToast('error', 'Error.', 'Error al obtener la lista de tipos de documento');
        }
      );

  }

  getUsuariosTipoarchivo() {
    this.usuarioTipoArchivoService.query()
      .subscribe(
        (res) => {
          console.log('usuarioTipoArchivo', res.content);
          this.usuarioTipoArchivo = res.content;

          this.usuarioTipoArchivo.forEach((tipoArchivo) => {

            if (this.usuario != null) {
              this.usuarioArchivoService.findByTipoArchivoId(tipoArchivo.id, this.usuario.id)
                .subscribe(
                  (response) => {
                    console.log('usuarioTipoArchivo', response.content);
                    tipoArchivo.archivo = response;
                  },
                  (err) => {
                  }
                );
            }

          });
        },
        (err) => {
          this.toast.showToast('error', 'Error.', 'Error al obtener la lista de tipos de archivo');
        }
      );

  }


  deleteArchivo(item: any) {

    this.usuarioArchivoService.delete(item.id)
      .subscribe(
        (response) => {

          this.getUsuariosTipoarchivo();
        },
        (err) => {
        }
      );
  }

  async guardar() {

    let copy = {...this.crearUsuarioForm.value};
    if (this.crearUsuarioForm.get('agenteValidador') && this.crearUsuarioForm.get('agenteValidador').value !== null && this.crearUsuarioForm.get('agenteValidador').get('id').value === null) {

      delete copy.agenteValidador;
    }
    if (this.crearUsuarioForm.get('id').value !== null) {

      // if (this.validatePermission('usuarios.editar')) {


      await this.usuarioService.update(copy).toPromise().then(response => {
        if (this.crearUsuarioForm.get('rol').value.esAgente === true) {

          this.saveArchivos(response.id);
        }
        this.toast.showToast('success', '', 'Se ha actualizado el usuario satisfactoriamente');
        this.modal.close({response});
      }, error1 => {
        this.toast.showToast('error', 'Error.', error1.error.details);

      });
      // } else {
      //   this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de usuarios');
      // }


    } else {
      if (this.validatePermission('usuarios.crear')) {
        await this.usuarioService.create(copy).toPromise().then(response => {

          if (this.crearUsuarioForm.get('rol').value.esAgente === true) {

            this.saveArchivos(response.id);
          }
          this.toast.showToast('success', '', 'Se ha creado el usuario satisfactoriamente');
          this.modal.close({response});
        }, error2 => {
          this.toast.showToast('error', 'Error.', error2.error.details);
        });
      } else {
        this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de usuarios');
      }
    }


  }


  saveArchivos(idUsuario) {
    this.usuarioTipoArchivo.forEach(async (tipoArchivo) => {


      if (tipoArchivo.archivo) {
        if (tipoArchivo.archivo.file !== null && tipoArchivo.archivo.file !== undefined) {
          let formData = new FormData();
          formData.append('file', tipoArchivo.archivo.file);
          formData.append('idUsuario', idUsuario);
          formData.append('idTipoArchivo', tipoArchivo.id);
          await this.uploadService.uploadFileUsuario(formData).toPromise().then(res => {

            tipoArchivo.archivo = res;
            delete tipoArchivo.archivo.file;
          });
        }
      }


    });
  }

  trackRolById(index: number, item: any) {
    return item.id;
  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }

  trackTipoDocumentoById(index: number, item: any) {
    return item.id;
  }

  rolChange() {

    if (this.crearUsuarioForm.get('certificador').value === false) {


      this.crearUsuarioForm.controls['placa'].setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(7)]);
      this.crearUsuarioForm.controls['certificaHasta'].setValidators([Validators.required]);
      this.crearUsuarioForm.controls['placa'].updateValueAndValidity();
      this.crearUsuarioForm.controls['certificaHasta'].updateValueAndValidity();
      this.crearUsuarioForm.controls['agenteValidador'].get('id').clearValidators();
      this.crearUsuarioForm.controls['agenteValidador'].get('id').updateValueAndValidity();
    } else if (this.crearUsuarioForm.get('certificador').value === true) {


      this.crearUsuarioForm.controls['agenteValidador'].get('id').setValidators([Validators.required]);
      this.crearUsuarioForm.controls['agenteValidador'].setValidators([Validators.required]);
      this.crearUsuarioForm.controls['agenteValidador'].get('id').updateValueAndValidity();
      this.crearUsuarioForm.controls['agenteValidador'].updateValueAndValidity();
      this.crearUsuarioForm.controls['placa'].clearValidators();
      this.crearUsuarioForm.controls['certificaHasta'].clearValidators();
      this.crearUsuarioForm.controls['placa'].updateValueAndValidity();
      this.crearUsuarioForm.controls['certificaHasta'].updateValueAndValidity();

    } else {

      this.crearUsuarioForm.controls['placa'].clearValidators();
      this.crearUsuarioForm.controls['certificaHasta'].clearValidators();
      this.crearUsuarioForm.controls['placa'].updateValueAndValidity();
      this.crearUsuarioForm.controls['certificaHasta'].updateValueAndValidity();
      this.crearUsuarioForm.controls['agenteValidador'].get('id').clearValidators();
      this.crearUsuarioForm.controls['agenteValidador'].get('id').updateValueAndValidity();
      this.crearUsuarioForm.controls['agenteValidador'].updateValueAndValidity();
    }

  }

  processFile(imageInput: any, tipoArchivo: any) {

    const file: File = imageInput.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {

      let formData = new FormData();
      formData.append('file', file);
      tipoArchivo.archivo = {
        usuario: {
          id: null
        },
        tipoArchivo: tipoArchivo,
        file: file,
        nombre: file.name,
        extension: file.type,
        url: event.target.result
      };


    });
    reader.readAsDataURL(file);
  }

  sendPassword() {
    this.usuarioService.resetPassword(this.crearUsuarioForm.get('email').value).subscribe(
      data => {

        this.toast.showToast('success', 'Información.', 'Se ha enviado un enlace al correo del usuario.');
      },
      err => {
        this.toast.showToast('error', 'Error.', 'Se ha ocurrido un error al enviar el enlace al correo del usuario.');
      }
    );
  }


  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  loadCiudades() {
    this.ciudadLoading = true;
    this.ciudadService.activos().subscribe(data => {
      this.ciudades = data;
      this.ciudadLoading = false;

    }, (err) => this.onError(err.message));
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }


  public open(archivo: any) {


    let modal = this.modalService.open(FileViewverComponent, {backdrop: 'static', centered: true, size: 'lg'});

    modal.componentInstance.archivo = archivo;

    modal.result.then(
      (success: Object) => {

      },
      (error: any) => {
        // console.error(error);
      }
    );

  }
}
