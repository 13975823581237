import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';
import {WebStorageCustomService} from '../commons/providers/web-custom-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  public resourceUrl = environment.apiUrl + 'user/imagenes/upload';
  public resourceUrlUsuario = environment.apiUrl + 'user/archivos/usuarios/upload';
  public resourceUrlArchivos = environment.apiUrl + 'user/archivos/';

  // public resourceUrl = 'http://user-microservice.gerenciaurbana.org:8082/' + 'imagenes/upload';

  constructor(private http: HttpClient,
              private webStorageCustomService: WebStorageCustomService) {
  }

  uploadFile(file: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, file);
  }


  uploadFileUsuario(file: any): Observable<any> {
    return this.http.post<any>(this.resourceUrlUsuario, file);

  }

  subirArchivo(file: File): Observable<any> {
    const data = new FormData();
    data.append('file', file);
    return this.http.post<any>(this.resourceUrl, data);
  }

  upload(file: File, path: string, onLoad?: Function, onSuccess?: Function, onError?: Function): Promise<any> {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append('file', file);

      const request = new XMLHttpRequest();
      request.onreadystatechange = function () {
        if (request.readyState === 4) {
          let resp: { status: number | string; data: any };
          try {
            resp = {
              status: request.status,
              data: JSON.parse(request.response)
            };
          } catch (e) {
            resp = {
              status: 'error',
              data: 'Unknown error occurred: [' + request.responseText + ']'
            };
          }
          if (resp.status === 200) {
            onSuccess(resp);
            resolve(resp);
          } else {
            onError(resp);
            reject(resp);
          }

        }
      };

      request.upload.addEventListener('progress', function (e) {
        onLoad(Math.ceil(e.loaded / e.total) * 100);
      }, false);

      // request.open('POST', `http://10.30.0.21:8082/archivos/upload`);
      request.open('POST', `${this.resourceUrlArchivos}${path}`);
      request.setRequestHeader('Authorization', `Bearer ${this.webStorageCustomService.getFromLocal('access_token')}`);
      request.send(data);
    });
  }

  descargarEvidenciasDeComparendos(comparendosIds: any[] | string): Observable<any> {
    if (typeof comparendosIds === 'object') {
      comparendosIds = comparendosIds.toString();
    }
    return this.http.get<any>(`${environment.apiUrl}user/evidencias_comparendos?ids=${comparendosIds}`);
  }

  descargarPdfsDeComparendos(comparendosIds: any[] | string): Observable<any> {
    if (typeof comparendosIds === 'object') {
      comparendosIds = comparendosIds.toString();
    }
    return this.http.get<any>(`${environment.apiUrl}user/pdfs_comparendos?ids=${comparendosIds}`);
  }

  deleteUpload(absolutePath: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}user/archivos/delete?path=${absolutePath}`, null);
  }

}

