import { Directive, EventEmitter, HostListener, Output, Host } from '@angular/core';

@Directive({
  selector: '[appDropFiles]'
})
export class DropFilesDirective {

  @Output()
  filesDropped: EventEmitter<FileList> = new EventEmitter();
  @Output()
  filesHovered: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();
    const transfer = $event.dataTransfer;
    this.filesDropped.emit(transfer.files);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();
    this.filesHovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    $event.preventDefault();
    this.filesHovered.emit(false);
  }

}
