import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, CiudadService, RolService, SecretariaService, TipoDocumentoService, UploadFileService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MouseEvent} from '@agm/core';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-new-edit-secretaria',
  templateUrl: './new-edit-secretaria.component.html',
  styleUrls: ['./new-edit-secretaria.component.scss']
})
export class NewEditSecretariaComponent implements OnInit {
  @Input() private secretaria: any;
  @Input() private ver: boolean;
  crearSecretariaForm: FormGroup;
  ciudades: any;
  markers: any[] = [];
  // google maps zoom level
  zoom = 13;
  // initial center position for the map
  lat = 4.709214222489045;
  lng = -74.0717949099976;
  private ciudadLoading = true;
  permisos: Array<any>;

  constructor(
    private ciudadService: CiudadService,
    private secretariaService: SecretariaService,
    private rolService: RolService,
    private uploadService: UploadFileService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private tipoDocumentoService: TipoDocumentoService,
    private toast: CustomToastrService,
    private router: Router,
    public webStorageCustomService: WebStorageCustomService,
    private authService: AuthService,
    public modal: NgbActiveModal,
  ) {
    this.crearSecretariaForm = this.formBuilder.group({
      file: null,
      fileLogoEntidades: null,
      logo: null,
      logoEntidades: null,
      direccion: ['', [Validators.required, Validators.minLength(3)]],
      estado: [true],
      ciudad: this.formBuilder.group({
        id: [null, Validators.required]
      }),
      eliminado: false,
      fechaCreacion: null,
      fechaActualizacion: null,
      id: null,
      lat: ['', Validators.required],
      pagina: [''],
      lng: ['', [Validators.required]],
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      diasSemana: ['', [Validators.required, Validators.minLength(3)]],
      horarioManana: ['', [Validators.required, Validators.minLength(3)]],
      horarioTarde: ['', [Validators.required, Validators.minLength(3)]],
      nit: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      emailSegundo: ['', [Validators.email]],
      telefono: ['', [Validators.minLength(7), Validators.maxLength(12)]],
      comparecer: ['', [Validators.minLength(3)]],
    });
  }


   ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }

    if (this.secretaria) {

      this.crearSecretariaForm.patchValue(this.secretaria);
      this.lat = this.secretaria.lat;
      this.lng = this.secretaria.lng;
    } else {

      this.lat = 4.709214222489045;
      this.lng = -74.0717949099976;
    }
    this.loadCiudades();
  }


  async guardar() {
    if (this.crearSecretariaForm.get('file').value !== null) {
      let formData = new FormData();
      formData.append('file', this.crearSecretariaForm.get('file').value);
      await this.uploadService.uploadFile(formData).toPromise().then(res => {
        this.crearSecretariaForm.get('logo').setValue(res.url);
      });
    }

    if (this.crearSecretariaForm.get('fileLogoEntidades').value !== null) {
      let formData = new FormData();
      formData.append('file', this.crearSecretariaForm.get('fileLogoEntidades').value);
      await this.uploadService.uploadFile(formData).toPromise().then(res => {
        this.crearSecretariaForm.get('logoEntidades').setValue(res.url);
      });
    }

    if (this.crearSecretariaForm.get('id').value !== null) {

      if (this.validatePermission('secretarias.editar')) {
        this.crearSecretariaForm.removeControl('file');
        this.crearSecretariaForm.removeControl('fileLogoEntidades');
        await this.secretariaService.update(this.crearSecretariaForm.value).toPromise().then(response => {
          this.toast.showToast('success', '', 'Se ha actualizado el secretaria satisfactoriamente');
          this.modal.close({response});
        }, error1 => {
          this.toast.showToast('error', 'Error.', 'Error al actualizar la información del secretaria');

        });
      } else {
        this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de secretarias');
      }


    } else {
      if (this.validatePermission('secretarias.crear')) {
        this.crearSecretariaForm.removeControl('file');
        this.crearSecretariaForm.removeControl('fileLogoEntidades');
        await this.secretariaService.create(this.crearSecretariaForm.value).toPromise().then(response => {
          this.toast.showToast('success', '', 'Se ha creado el secretaria satisfactoriamente');
          this.modal.close({response});
        }, error2 => {
          this.toast.showToast('error', error2.error.message, error2.error.details);
        });
      } else {
        this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de secretarias');
      }
    }

  }


  trackCiudadById(index: number, item: any) {
    return item.id;
  }

  processFile(imageInput: any, controls: { fileControlName: string; urlControlName: string; }) {

    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.crearSecretariaForm.get(controls.urlControlName).setValue(event.target.result);
      this.crearSecretariaForm.get(controls.fileControlName).setValue(file);
    });

    reader.readAsDataURL(file);
  }


  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }
  loadCiudades() {
    this.ciudadLoading = true;
    this.ciudadService.activos().subscribe(data => {
      this.ciudades = data;
      this.ciudadLoading = false;
    }, (err) => this.onError(err.message));
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {
    this.crearSecretariaForm.get('lat').setValue($event.coords.lat);
    this.crearSecretariaForm.get('lng').setValue($event.coords.lng);
  }

  markerDragEnd(m: any, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }


}

