import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, CamaraService, ComparendoService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MouseEvent} from '@agm/core';
import {CiudadService} from '../../../../providers/ciudad.service';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-edit-placa',
  templateUrl: './edit-placa.component.html',
  styleUrls: ['./edit-placa.component.scss']
})
export class EditPlacaComponent implements OnInit {
  @Input() private comparendo: any;
  crearComparendoForm: FormGroup;
  itemsPerPage: any;
  page: any;
  predicate: any;
  filtroSelect: any;
  velocidadesMaximas: any[] = [];
  ciudades: any[] = [];
  markers: any[] = [];
  campoPlaca: any;
  permisos: Array<any>;
  usuarioId: any;

  constructor(
    private comparendoService: ComparendoService,
    private ciudadService: CiudadService,
    public webStorageCustomService: WebStorageCustomService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private toast: CustomToastrService,
    private router: Router,
    private authService: AuthService,
    public modal: NgbActiveModal,
  ) {

    this.itemsPerPage = 15;
    this.page = 1;
    this.predicate = 'identificacion';
    this.filtroSelect = [
      {
        nombre: 'Nombre',
        filtro: 'nombre'
      },
      {
        nombre: 'Identificación',
        filtro: 'identificacion'
      }
      ,
      {
        nombre: 'Fecha Creación',
        filtro: 'fecha'
      }
    ];

    this.crearComparendoForm = this.formBuilder.group({

      id: null,
      camaraId: [''],
      comparendoOcupado: [''],
      eliminado: false,
      fechaActualizacion: [''],
      fechaCreacion: [''],
      fechaInfraccion: [''],
      jsonAlpr: [''],
      numero: [''],
      observacionDescripcion: [''],
      pdf: [''],
      placa: [''],
      precisionPlaca: [''],
      tipo: [''],
      usuarioId: [''],
      valor: [''],
      velocidad: [''],
      velocidadMax: [''],
      archivoXml: [''],
      claseServicio: [''],
      infraccion: [''],
      infractor: [''],
      modalidadTransporte: [''],
      observacion: [''],
      radioAccion: [''],
      tipoInfractor: [''],
      tipoVehiculo: [''],
      transportePasajero: [''],

      //Nuevos campos
      tipoEstadoComparendo: ['']

    });
  }

  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {
      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));
    }
    this.usuarioId = JSON.parse(this.webStorageCustomService.getFromLocal('usuario')).id;

    console.log('this.comparendo ', this.comparendo);

    if (this.comparendo) {
      this.crearComparendoForm.patchValue(this.comparendo);
      console.log('this.crearComparendoForm ', this.crearComparendoForm);
      this.campoPlaca = this.comparendo.placa;
    }

    this.crearComparendoForm.get('placa').valueChanges.subscribe(val => {
      if (val) {
        this.crearComparendoForm.get('placa').setValue((val as String).toUpperCase(), {emitEvent: false});
      }
    });
  }

  loadVelocidadMaxima() {
    for (let _i = 30; _i < 200; _i += 5) {
      this.velocidadesMaximas.push(_i);
    }
  }

  loadCiudad() {
    this.ciudadService.activos().subscribe(data => {

      this.ciudades = data;
    }, (err) => this.onError(err.message));
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  async guardar() {

    if (this.campoPlaca !== this.crearComparendoForm.get('placa').value) {
      /* this.crearComparendoForm.get('tipoEstadoComparendo').setValue(null); */
      /* if (this.crearComparendoForm.get('id').value !== null) { */
      /* if(this.crearComparendoForm.get('ubicacionImagenFecha').value == null ){
        this.crearComparendoForm.get('ubicacionImagenFecha').setValue(this.crearComparendoForm.get('fechaCreacion').value);
      }
      this.crearComparendoForm.removeControl('confirmarPlaca'); */
      
      await this.comparendoService.updatePlaca(this.crearComparendoForm.get('id').value, this.crearComparendoForm.get('placa').value.toUpperCase(), this.usuarioId)
        .toPromise().then(response => {
          this.toast.showToast('success', '', 'Se ha actualizado la placa satisfactoriamente');
          /* console.log('response >>>>>>>>>>>> ', response); */
          this.modal.close({response});
        }, error1 => {
          this.crearComparendoForm.get('placa').setValue(this.comparendo.placa);
          this.toast.showToast('error', 'Error.', 'Error al actualizar la información de la placa');
        });
      /* } else {
        await this.comparendoService.create(this.crearComparendoForm.value).toPromise().then(response => {
          this.toast.showToast('success', '', 'El comparendo se ha creado satisfactoriamente');
          this.modal.close({ response });
        }, error2 => {
          this.toast.showToast('error', 'Error.', 'Error ha ocurrido un error al crear la placa.');
        });
      } */
    } else {
      this.crearComparendoForm.get('placa').setValue(this.comparendo.placa);
      this.toast.showToast('error', 'Error.', 'La placa nueva no puede ser igual a la anterior.');
    }
  }

  trackCamaraById(index: number, item: any) {
    return item.id;
  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }

  trackVelocidadMaxima(index: number, item: any) {
    return item;
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }
}
