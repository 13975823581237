export * from './dashboard/dashboard.component';
export * from './login/login.component';
export * from './roles/roles.component';
export * from './roles/modals/edit-permisos/edit-permisos.component';
export * from './roles/modals/new-edit-rol/new-edit-rol.component';
export * from './usuarios/usuarios.component';
export * from './not-found/not-found.component';
export * from './protected/protected.component';
export * from './perfil/perfil.component';
export * from './cambiar-contrasena/cambiar-contrasena.component';
export * from './recordar-contrasena/recordar-contrasena.component';
export * from './camara/camara.component';
export * from './camara/modals/new-edit-camara/new-edit-camara.component';
export * from './radares/radares.component';
export * from './ciudad/ciudad.component';
export * from './consultas-externas/consultas-externas.component';
export * from './consultas-internas/consultas-internas.component';
export * from './consultas-runt/consultas-runt.component';
export * from './pico-placa-list/pico-placa-list.component';
export * from './pico-placa-ficha/pico-placa-ficha.component';
export * from './upload-files/upload-files.component';
export * from './consultas-externas-public/consultas-externas-public.component';
export * from './lista-notificaciones/lista-notificaciones.component';
export * from './parametrizacion/parametrizacion.component';
export * from './comparendos/ver-comparendos/ver-comparendos.component';

// Reportes
export * from './reportes/reportes.component';
export * from './reportes/resultado-reporte/resultado-reporte.component';
