import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AuthService, CiudadService, ComparendoService, ObservacionService, UsuarioService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';
import {ESTADOCOMPARENDOS} from '../../../../commons/constants/app';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Component({
  selector: 'app-descartar-comparendo',
  templateUrl: './descartar-comparendo.component.html',
  styleUrls: [
    '../../../../../vendor/libs/spinkit/spinkit.scss',
    './descartar-comparendo.component.scss']
})
export class DescartarComparendoComponent implements OnInit {


  @BlockUI() blockUIPage: NgBlockUI;
  @Input() private comparendo: any;
  @Input() private accionComparendo: boolean;
  @Input() private tipoAccion: any;
  crearComparendoForm: FormGroup;
  itemsPerPage: any;
  page: any;
  predicate: any;
  textAccion: string;
  textAccionButton: string;
  imgPath: string;
  itemsSelect: any[];
  selectAccion: any;
  persistiendo: boolean;

  // Id del usuario actual
  usuarioId = null;
  usuarioFirma = null;
  usuarioActual: any;
  permisos: Array<any>;

  readonly ESTADOCOMPARENDOS: typeof ESTADOCOMPARENDOS = ESTADOCOMPARENDOS;

  constructor(
    private observacionService: ObservacionService,
    private comparendoService: ComparendoService,
    private ciudadService: CiudadService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private toast: CustomToastrService,
    private router: Router,
    private authService: AuthService,
    private webStorageCustomService: WebStorageCustomService,
    private usuarioService: UsuarioService,
    public modal: NgbActiveModal,
  ) {

    this.itemsPerPage = 15;
    this.page = 1;
    this.predicate = 'id';
    this.persistiendo = false;

  }

  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.usuarioId = this.webStorageCustomService.checkFromLocal('usuario') ? JSON.parse(this.webStorageCustomService.getFromLocal('usuario')).id : null;

    this.usuarioConFirma(this.usuarioId);

    this.comparendo.checkbox = false;


    this.textoAccion();
    this.loadOpciones();
  }

  usuarioConFirma(usuarioId: any) {
    this.usuarioService.find(usuarioId).subscribe((result) => {
      this.usuarioActual = result;
    }), (err) => this.onError(err.message);
  }

  textoAccion() {
    this.accionComparendo ? this.textAccion = 'Para confirmar este comparendo debe asignar su firma.' : this.textAccion = 'Para no imponer este comparendo debe seleccionar una de las opciones que lo justifican:';
    this.accionComparendo ? this.textAccionButton = 'Certificar Comparendo' : this.textAccionButton = 'Descartar Comparendo';
    this.accionComparendo ? this.imgPath = 'assets/img/accept.png' : this.imgPath = 'assets/img/delete2.png';
  }

  loadOpciones() {
    this.observacionService.notEliminate().subscribe(data => {
      this.itemsSelect = data.content;
    }, (err) => this.onError(err.message));
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  async guardar() {
    var date = new Date();
    const estadoAnterior = this.comparendo.tipoEstadoComparendo.id;

    if (this.tipoAccion == '') {
      this.accionComparendo ? this.comparendo.tipoEstadoComparendo.id = ESTADOCOMPARENDOS.idComparendoCertificado : this.comparendo.tipoEstadoComparendo.id = ESTADOCOMPARENDOS.idComparendoRechazado;
    } else {
      this.comparendo.tipoEstadoComparendo.id = ESTADOCOMPARENDOS.idComparendoDescertificado;
    }

    this.comparendo.usuarioId = this.usuarioId;
    delete this.comparendo.checkbox;
    delete this.comparendo.comparendo;
    this.persistiendo = true;

    if (this.comparendo.tipoEstadoComparendo.id == ESTADOCOMPARENDOS.idComparendoRechazado || this.comparendo.tipoEstadoComparendo.id == ESTADOCOMPARENDOS.idComparendoDescertificado) {
      this.comparendo.observacionDescripcion = this.comparendo.observacion.descripcion;
    }

    if (this.comparendo.tipoEstadoComparendo.id == ESTADOCOMPARENDOS.idComparendoDescertificado) {

      this.comparendoService.descertificar(this.comparendo).subscribe(response => {

        this.toast.showToast('success', 'Informacion', 'Se descertificó el comparendo.');
        this.modal.close({response});
      }, (err) => {
        this.toast.showToast('error', 'Error al descertificar.', err.error.details);
        this.modal.dismiss({estadoAnterior});
      });
    } else {

      if (this.comparendo.tipoEstadoComparendo.id == ESTADOCOMPARENDOS.idComparendoCertificado) {

        if (this.usuarioActual.puedeCertificar) {
          this.blockUIPage.start();
          await this.comparendoService.update(this.comparendo).toPromise().then(response => {
            this.blockUIPage.stop();

            this.modal.close({response});
          }, error1 => {
            this.blockUIPage.stop();
            this.toast.showToast('error', 'Error al generar PDF.', error1.error.details);
            this.modal.dismiss({estadoAnterior});
          });
        } else {
          this.toast.showToast('error', 'Error.', 'El usuario no cuenta con los requisitos para certificar, por favor verifique.');
          this.modal.dismiss({estadoAnterior});
        }

      } else {

        await this.comparendoService.update(this.comparendo).toPromise().then(response => {
          this.modal.close({response});
        }, error1 => {
          this.toast.showToast('error', 'Error al generar PDF.', error1.error.details);
          this.modal.dismiss({estadoAnterior});
        });

      }

    }


  }

  trackCamaraById(index: number, item: any) {
    return item.id;
  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }

  trackVelocidadMaxima(index: number, item: any) {
    return item;
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }
}

