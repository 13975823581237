import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InfractorService {

  public resourceUrl = environment.apiUrl + 'comparendo/infractor';

  constructor(public http: HttpClient) {
  }

  create(infractor: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, infractor);
  }

  update(infractor: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, infractor);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, {params: options});
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

  findByFullName(name: string, limit: number) {
    if (name === '') {
      return of([]);
    }

    return this.http.get<any[]>(`${this.resourceUrl}/find_by_name?name=${name}&limit=${limit}`)
    .pipe(
      map(response => {
        return response;
      })
    );
  }

  getCiudadInfractor(id: string): Observable<any>  {
    return this.http.get<any>(`${this.resourceUrl}/ciudad_infractor/${id}`);
  }

}
