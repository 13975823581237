import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  ComparendoService,
  ParametrizacionService,
  RolService,
  EstadoComparendoUsuarioService
} from '@app/providers';
import {AppService} from '@app/app.service';
import {CustomToastrService} from '@app/commons/providers/custom-toastr.service';
import {LayoutService} from '@app/layout/layout.service';
import {BaseChartDirective} from 'ng2-charts/ng2-charts';
import {WebStorageCustomService} from '@app/commons/providers/web-custom-storage.service';
import {Router} from '@angular/router';
import {Comparendo} from '@app/commons/interfaces/types/comparendos';
import {Observable, Subscription} from 'rxjs';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

// Own
// Components
import {LoadingModalComponent} from '@app/commons/modals/loading-modal/loading-modal.component';
// Services
import {CargaComparendoExternalService} from '@app/providers/carga-comparendo-external.service';
// Constants
import {ESTADOCOMPARENDOS, KEYS_TO_MOVILIXA_RESPONSE_DESCRIPTION} from '@app/commons/constants/app';
// Utils
import {getParsedJson} from '@app/commons/utils/general';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

interface TableTab {
  id: number;
  text: string;
  showIf: (param?: any) => boolean;
  stateIds: number[];
}

@Component({
  selector: 'app-dashboard', // tslint:disable-line
  templateUrl: './dashboard.component.html',
  styleUrls: ['../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
    '../../../vendor/libs/spinkit/spinkit.scss',
    './dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit, OnInit, OnDestroy {
  isRTL: boolean;
  today = Date.now();
  @BlockUI() blockUIPage: NgBlockUI;

  // Datos del filtro
  totalItems: number = null;
  itemsPerPage: number = null;
  page: number = null;
  predicate = 'id';
  reverse: any;
  totalPage: number = null;

  // Datos de las tablas
  comparendoList: Array<any> = [];
  estado: any = null;
  estadoComparendos: any = null;
  nombreCiudad = '';
  certificarButtons: any = null;
  showCertificarButtons: boolean = null;

  // Datos del dashboard
  datacomparendosCertificadosHoy = 0;
  comparendosCertificadosMes = 0;
  comparendosRechazadosMes = 0;
  comparendosCertificadosTotal = 0;
  comparendosRechazadosTotal = 0;
  comparendosTotales = 0;
  metaDelMesAgentes: any = 0;
  porcentajeCertificadas: any = 0;
  porcentajeRechazadas: any = 0;
  porcentajeTotal: any = 0;

  // Id del usuario actual
  private usuarioId = null;
  private permisosUsuario = null;
  private usuario = null;
  // Permisos dashboard
  permisoCreadosPropios = null;
  permisosCreados = null;
  permisosPorCertificar = null;
  permisosCertificar = null;
  permisosRechazar = null;
  permisosEnviados = null;

  //Constantes
  readonly ESTADOCOMPARENDOS: typeof ESTADOCOMPARENDOS = ESTADOCOMPARENDOS;

  // Charts
  certificadosHoyChartData = [{
    data: [85, 15],
    borderWidth: 0
  }];
  certificadosHoyChartOptions = {
    scales: {
      xAxes: [{
        display: false,
      }],
      yAxes: [{
        display: false
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    cutoutPercentage: 94,
    responsive: false,
    maintainAspectRatio: false
  };
  certificadosHoyChartColors = [{
    backgroundColor: ['#fff', 'rgba(255,255,255,0.3)'],
    hoverBackgroundColor: ['#fff', 'rgba(255,255,255,0.3)'],
  }];

  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;
  tableTabs: TableTab[] = [{
    id: ESTADOCOMPARENDOS.idComparendoCreado,
    text: 'Creados',
    showIf: () => this.permisosCreados,
    stateIds: [ESTADOCOMPARENDOS.idComparendoCreado]
  }, {
    id: ESTADOCOMPARENDOS.idComparendoPorCertificar,
    text: 'Por certificar',
    showIf: () => this.permisosPorCertificar,
    stateIds: [ESTADOCOMPARENDOS.idComparendoPorCertificar]
  }, {
    id: ESTADOCOMPARENDOS.idComparendoCertificado,
    text: 'Certificados',
    showIf: () => this.permisosCertificar,
    stateIds: [ESTADOCOMPARENDOS.idComparendoCertificado]
  },
  {
    id: ESTADOCOMPARENDOS.idComparendoEnviadoMOVILIZA,
    text: 'Enviados Moviliza',
    showIf: () => this.permisosEnviados,
    stateIds: [ESTADOCOMPARENDOS.idComparendoEnviadoMOVILIZA]
  },
 /* {
    id: ESTADOCOMPARENDOS.idComparendoEnviadoSystrans,
    text: 'Enviados Systrans',
    showIf: () => this.permisosEnviados,
    stateIds: [ESTADOCOMPARENDOS.idComparendoEnviadoSystrans]
  },*/
   {
    id: parseInt(`${ESTADOCOMPARENDOS.idComparendoRechazado}${ESTADOCOMPARENDOS.idComparendoDescertificado}`, 10),
    text: 'Rechazados',
    showIf: () => this.permisosRechazar,
    stateIds: [ESTADOCOMPARENDOS.idComparendoRechazado, ESTADOCOMPARENDOS.idComparendoDescertificado]
  }
    // , {
    //   id: ESTADOCOMPARENDOS.idComparendoEnviadoMOVILIZA,
    //   text: 'Enviados Movilixa',
    //   showIf: () => true,
    //   stateIds: [ESTADOCOMPARENDOS.idComparendoEnviadoMOVILIZA]
    // }
  ];
  selectedTableTab: TableTab;

  /**
   * SEND TO SIMIT OR MOVILIXA PROPERTIES START
   */
  public selectAllRM = false;
  public isLoadingSendRM: boolean;
  public movilixaSendDetails: string[] = [];
  @ViewChild('movilixaSendDetailsModalContent') movilixaSendDetailsModalContent: ElementRef;

  // if selectAll is true use these
  private excludeIdsRM: number[] = [];
  // else use these
  private includeIdsRM: number[] = [];
  public cargandoComparendos = false;

  /**
   * SEND TO SIMIT OR MOVILIXA PROPERTIES END
   */

  constructor(private appService: AppService,
              private layoutService: LayoutService,
              public router: Router,
              private rolService: RolService,
              private comparendoService: ComparendoService,
              private parametrizacionService: ParametrizacionService,
              private webStorageCustomService: WebStorageCustomService,
              private estadoComparendoUsuarioService: EstadoComparendoUsuarioService,
              private toast: CustomToastrService,
              private modalService: NgbModal,
              private cargaComparendoExternalService: CargaComparendoExternalService) {
    this.appService.pageTitle = 'Dashboard';
    this.isRTL = appService.isRTL;

  }

  async ngOnInit() {
    this.usuario =
      this.webStorageCustomService.checkFromLocal('usuario') ? JSON.parse(this.webStorageCustomService.getFromLocal('usuario')) : null;
    this.usuarioId =
      this.webStorageCustomService.checkFromLocal('usuario') ? JSON.parse(this.webStorageCustomService.getFromLocal('usuario')).id : null;
    this.permisosUsuario =
      this.webStorageCustomService.checkFromLocal('permisos') ? JSON.parse(this.webStorageCustomService.getFromLocal('permisos')) : null;
    this.validarPermisos();
    this.permisoCreadosPropios = !(this.permisosUsuario.includes('dashboard.creados_propios')); // Permiso que hace ver en el dashboard solo informacion propia
    this.showCertificarButtons = (this.permisosUsuario.includes('dashboard.certificar'));
    this.validarPermisosDashboard();
    this.initVariables();
    this.findAllByConfirmada(null);
    this.comparendoMesActual();
    this.comparendosCertificadosHoy();
    this.comparendosTotal();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const resizeCharts = () => this.charts.forEach(chart => chart.chart.resize());

      // Initial resize
      resizeCharts();

      // For performance reasons resize charts on delayed resize event
      this.layoutService.on('resize.dashboard-1', resizeCharts);
    });
  }

  ngOnDestroy() {
    setTimeout(() => this.layoutService.off('resize.dashboard-1'));
  }

  validatePermission(accion: string): boolean {

    if (this.webStorageCustomService.checkFromLocal('permisos')) {
      if (JSON.parse(this.webStorageCustomService.getFromLocal('permisos')).length > 0) {
        return (JSON.parse(this.webStorageCustomService.getFromLocal('permisos')).includes('root.all') ||
          JSON.parse(this.webStorageCustomService.getFromLocal('permisos')).includes(accion));
      } else {
        return false;
      }

    } else {
      return false;
    }
  }

  findAllByConfirmada(event?: any, estado?: any): void {
    console.log(event);
    this.comparendoList = [];
    if (event != null) {
      const tabSelected: TableTab = this.tableTabs.find(tab => tab.id === event.nextId);

      console.log("tabSelected", tabSelected);


      if (this.selectedTableTab) {
        if (tabSelected && this.selectedTableTab.id !== tabSelected.id) {
          this.comparendoList = [];
          this.initVariables();
        }
      } else {
        this.initVariables();
      }

      if (tabSelected) {
        this.selectedTableTab = tabSelected;
        estado = tabSelected.stateIds;
      }

    } else {
      let defaultState: TableTab = null;
      for (let i = 0; i < this.tableTabs.length; i++) {
        if (this.tableTabs[i].showIf()) {
          defaultState = this.tableTabs[i];
          break;
        }
      }
      estado = defaultState ? defaultState.stateIds : [ESTADOCOMPARENDOS.idComparendoCreado];
    }
    const query = {
      page: this.page - 1,
      size: this.itemsPerPage,
      /* notNullPlaca: true, */
      filtroEspecial: true,
      sort: this.sort(),
      estado: estado
    };

    if (!this.permisoCreadosPropios) {
      query['usuarioId'] = this.usuarioId;
    }
    this.cargandoComparendos = true;
    this.comparendoService.query(query).subscribe(data => {
        this.totalPage = data.totalPages;
        this.totalItems = data.totalElements;
        this.comparendoList = data.content;
        this.cargandoComparendos = false;
      }, (err) => {
        this.cargandoComparendos = false;

        this.onError(err.message);
      }
    );

    this.parametrizacionService.find(5).subscribe(data => {
      this.metaDelMesAgentes = parseInt(data.valor || 100);
    });
  }

  private comparendosCertificadosHoy(): void {

    const date = new Date().toISOString().slice(0, 10);

    const queryIdComparendoCertificadosHoy = {
      estado: ESTADOCOMPARENDOS.idComparendoCertificado,
      fechaInicio: date,
      fechaFin: date
    };


    if (!this.permisoCreadosPropios) {
      queryIdComparendoCertificadosHoy['usuarioId'] = this.usuarioId;
    }

    this.estadoComparendoUsuarioService.countByEstado(queryIdComparendoCertificadosHoy).subscribe(data => {
        this.datacomparendosCertificadosHoy = data.count;
      }, (err) => this.onError(err.message)
    );

  }

  async certificarFunction() {

    if (this.webStorageCustomService.checkFromLocal('comparendoId') && this.webStorageCustomService.checkFromLocal('certificar')) {
      this.toast.showToast('info', 'Información', 'Tiene un comparendo pendiente,redirigiendo al comparendo');
      this.router.navigate(['/certificar-comparendos']);
    } else {
      await this.comparendoService.randomComparendo().toPromise().then(data => {

          this.webStorageCustomService.saveToLocal('comparendoId', data.id);
          this.webStorageCustomService.saveToLocal('certificar', true);
          this.router.navigate(['/certificar-comparendos']);

        }, (err) => {
          this.onError(err.message);
          this.toast.showToast('error', '', 'No hay comparendos por certificar');
        }
      );

    }
  }

  seeSubpoena(comparendo: any): void {

    this.router.navigate([`/ver-comparendos/${comparendo.id}`]);
  }

  estadoComparendo(estado?: any): string {
    switch (estado) {
      case ESTADOCOMPARENDOS.idComparendoCreado:
        return 'Creado';
      case ESTADOCOMPARENDOS.idComparendoPorCertificar:
        return 'Por certificar';
      case ESTADOCOMPARENDOS.idComparendoCertificado:
        return 'Certificado';
      case ESTADOCOMPARENDOS.idComparendoEnviadoSIMIT:
        return 'SIMIT';
      case ESTADOCOMPARENDOS.idComparendoDescertificado:
        return 'Descertificado';
      case ESTADOCOMPARENDOS.idComparendoEnviadoMOVILIZA:
        return 'Moviliza';
      case ESTADOCOMPARENDOS.idComparendoEnviadoSystrans:
        return 'Systrans';
      case ESTADOCOMPARENDOS.idComparendoRechazado:
        return 'Rechazado';
      default:
        return '';
    }
  }

  sortlist(predicate: string, event?: any): void {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.findAllByConfirmada(event);
  }

  transition(event?: any): void {
    this.findAllByConfirmada(event);
  }

  private comparendoMesActual(): void {

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10);

    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10);

    const queryIdComparendoCertificado = {
      estado: ESTADOCOMPARENDOS.idComparendoCertificado,
      fechaInicio: firstDay,
      fechaFin: lastDay
    };


    if (!this.permisoCreadosPropios) {
      queryIdComparendoCertificado['usuarioId'] = this.usuarioId;
    }

    this.estadoComparendoUsuarioService.countByEstado(queryIdComparendoCertificado).subscribe(data => {
        this.comparendosCertificadosMes = data.count;
        this.porcentajeCertificadas =
          this.metaDelMesAgentes !== NaN || this.metaDelMesAgentes !== null ?
            ((this.comparendosCertificadosMes * 100) / this.metaDelMesAgentes).toFixed(2) : '0';
        this.porcentajeTotal =
          this.porcentajeTotal += ((this.comparendosCertificadosMes * 100) / this.metaDelMesAgentes) !== NaN ?
            this.porcentajeTotal += ((this.comparendosCertificadosMes * 100) / this.metaDelMesAgentes) : 0.0;
      }, (err) => this.onError(err.message)
    );


    const queryIdComparendoRechazado = {
      estado: ESTADOCOMPARENDOS.idComparendoRechazado,
      fechaInicio: firstDay,
      fechaFin: lastDay
    };


    if (!this.permisoCreadosPropios) {
      queryIdComparendoRechazado['usuarioId'] = this.usuarioId;
    }

    this.estadoComparendoUsuarioService.countByEstado(queryIdComparendoRechazado).subscribe(data => {
        this.comparendosRechazadosMes = data.count;
        this.porcentajeRechazadas =
          this.metaDelMesAgentes !== NaN || this.metaDelMesAgentes !== null ?
            ((this.comparendosRechazadosMes * 100) / this.metaDelMesAgentes).toFixed(2) : '0';
        this.porcentajeTotal = this.porcentajeTotal += ((this.comparendosRechazadosMes * 100) / this.metaDelMesAgentes);
      }, (err) => this.onError(err.message)
    );

  }

  private validarPermisos(): void {
    this.rolService.getPermisos(this.usuario.rol.id).subscribe(data => {
        this.permisosUsuario = data;
      }, (err) => this.onError(err.message)
    );
  }

  private validarPermisosDashboard(): void {
    this.permisosCreados = this.usuarioTienePermiso('dashboard.creados');
    this.permisosPorCertificar = this.usuarioTienePermiso('dashboard.por_certificar');
    this.permisosCertificar = this.usuarioTienePermiso('dashboard.certificados');
    this.permisosRechazar = this.usuarioTienePermiso('dashboard.rechazados');
    this.permisosEnviados = this.usuarioTienePermiso('dashboard.enviados');
  }

  private comparendosTotal(): void {

    const queryComparendoCertificadoTotal = {
      estado: ESTADOCOMPARENDOS.idComparendoCertificado,
    };


    if (!this.permisoCreadosPropios) {
      queryComparendoCertificadoTotal['usuarioId'] = this.usuarioId;
    }

    this.estadoComparendoUsuarioService.countByEstado(queryComparendoCertificadoTotal).subscribe(data => {
        this.comparendosCertificadosTotal = data.count;
      }, (err) => this.onError(err.message)
    );


    const queryComparendoRechazadosTotal = {
      estado: ESTADOCOMPARENDOS.idComparendoRechazado,
    };


    if (!this.permisoCreadosPropios) {
      queryComparendoRechazadosTotal['usuarioId'] = this.usuarioId;
    }

    this.estadoComparendoUsuarioService.countByEstado(queryComparendoRechazadosTotal).subscribe(data => {
        this.comparendosRechazadosTotal = data.count;
      }, (err) => this.onError(err.message)
    );


    const queryComparendoTotal = {};

    if (!this.permisoCreadosPropios) {
      queryComparendoTotal['usuarioId'] = this.usuarioId;
    }

    this.estadoComparendoUsuarioService.countByEstado(queryComparendoTotal).subscribe(data => {
        this.comparendosTotales = data.count;
      }, (err) => this.onError(err.message)
    );

  }

  private initVariables() {
    this.page = 1;
    this.itemsPerPage = 50;
    this.predicate = 'id';
  }

  private sort(): any[] {
    return [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
  }

  private onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  /**
   * Valida si el usuario tiene un permiso
   * @param permiso
   */
  private usuarioTienePermiso(permiso: string): boolean {
    return permiso && this.permisosUsuario.includes(permiso);
  }

  /**
   * SEND TO SIMIT OR MOVILIXA METHODS START
   */

  /**
   * permite indicar si se van a enviar todos los comparendos o no
   * @param $event evento con estado de checkbox
   */
  public selectAllChangeRM($event): void {
    this.selectAllRM = $event.target.checked;
    this.includeIdsRM = [];
    this.excludeIdsRM = [];
    console.log('this.selectAllRM = ', this.selectAllRM);
    console.log('this.includeIdsRM = ', this.includeIdsRM);
    console.log('this.excludeIdsRM = ', this.excludeIdsRM);
  }

  /**
   * permite indicar si un comparendo se va a enviar o no
   * @param $event evento con estado de checkbox
   * @param comparendo comparendo para enviar o excluir
   */
  public selectSingleChangeRM($event, comparendo: Comparendo): void {
    const value = $event.target.checked;
    if (this.selectAllRM) {
      if (value) {
        const indexFound = this.excludeIdsRM.indexOf(comparendo.id);
        if (indexFound !== -1) {
          this.excludeIdsRM.splice(indexFound, 1);
        }
      } else {
        if (this.excludeIdsRM.indexOf(comparendo.id) === -1) {
          this.excludeIdsRM.push(comparendo.id);
        }
      }
    } else {
      if (value) {
        if (this.includeIdsRM.indexOf(comparendo.id) === -1) {
          this.includeIdsRM.push(comparendo.id);
        }
      } else {
        const indexFound = this.includeIdsRM.indexOf(comparendo.id);
        if (indexFound !== -1) {
          this.includeIdsRM.splice(indexFound, 1);
        }
      }
    }
    console.log('this.selectAllRM = ', this.selectAllRM);
    console.log('this.includeIdsRM = ', this.includeIdsRM);
    console.log('this.excludeIdsRM = ', this.excludeIdsRM);
  }

  /**
   * determina si un comparendo esta listo para enviar o no
   * @param comparendo item de cada fila
   */
  public checkedRM(comparendo: Comparendo): boolean {
    if (this.selectAllRM) {
      return this.excludeIdsRM.find(_comparendoId => _comparendoId === comparendo.id) === undefined;
    } else {
      return this.excludeIdsRM.find(_comparendoId => _comparendoId === comparendo.id) !== undefined;
    }
  }

  /**
   * Deshabilita boton de envio a moviliza o simit
   */
  public disabledSendButton(): boolean {
    if (this.selectAllRM) {
      return this.totalItems < 1 || this.totalItems === this.excludeIdsRM.length;
    } else {
      return this.includeIdsRM.length < 1;
    }
  }

  public sendToSert(): void {
    this.movilixaSendDetails = [];
    const body = {
      selectAllRM: this.selectAllRM
    };
    if (this.selectAllRM) {
      body['excludeIdsRM'] = this.excludeIdsRM;
    } else {
      body['includeIdsRM'] = this.includeIdsRM;
    }
    console.log('body ', body);
    this.blockUIPage.start();
    this.cargaComparendoExternalService.sendManySert(body).subscribe((res) => {
      console.log('OK sendToSert() ', res);
      this.movilixaSendDetails = this.parseMovilizaResponse(res);
      console.log('RESPUESTA SERT ', this.movilixaSendDetails);
      console.log('KEYS TO GET DESCRIPTION ', KEYS_TO_MOVILIXA_RESPONSE_DESCRIPTION);
      this.restartSendRM(true );
      setTimeout(() => {
        this.presentSendMovilixaDetailsModal();
      }, 1000);
    }, (error) => {
      console.log('ERROR sendToSert() ', error);
      this.restartSendRM(false);
    });

    let subscription: Subscription = null;
    this.isLoadingSendRM = true;
  }
  
  /**
   * Realiza envio de comparendos a moviliza
   */
  public sendToMovilixa(): void {
    this.movilixaSendDetails = [];
    const body = {
      selectAllRM: this.selectAllRM
    };
    if (this.selectAllRM) {
      // Object.defineProperty(body, 'excludeIdsRM', {
      //   enumerable: false,
      //   configurable: false,
      //   writable: true,
      //   value: this.excludeIdsRM
      // });
      body['excludeIdsRM'] = this.excludeIdsRM;
    } else {
      // Object.defineProperty(body, 'includeIdsRM', {
      //   enumerable: false,
      //   configurable: false,
      //   writable: true,
      //   value: this.includeIdsRM
      // });
      body['includeIdsRM'] = this.includeIdsRM;
    }
    console.log('body ', body);
    this.blockUIPage.start();
    // const observable: Observable<any[]> = this.cargaComparendoExternalService.sendManyMovilixa(body);
    this.cargaComparendoExternalService.sendManyMovilixa(body).subscribe((res) => {
      console.log('OK sendToMovilixa() ', res);
      this.movilixaSendDetails = this.parseMovilizaResponse(res);
      console.log('RESPUESTA MOVILIXA ', this.movilixaSendDetails);
      console.log('KEYS TO GET DESCRIPTION ', KEYS_TO_MOVILIXA_RESPONSE_DESCRIPTION);
      this.restartSendRM(true );
      setTimeout(() => {
        this.presentSendMovilixaDetailsModal();
      }, 1000);
    }, (error) => {
      console.log('ERROR sendToMovilixa() ', error);
      this.restartSendRM(false);
    });

    let subscription: Subscription = null;
    this.isLoadingSendRM = true;

    // const modalRef = this.modalService.open(LoadingModalComponent, {backdrop: 'static'});
    // (<LoadingModalComponent>modalRef.componentInstance).title = 'Cargando';
    // (<LoadingModalComponent>modalRef.componentInstance).body = 'Enviando a movilixa';
    // (<LoadingModalComponent>modalRef.componentInstance).closeMessage = 'Cancelar envio';
    // (<LoadingModalComponent>modalRef.componentInstance).unSubscribe.subscribe(() => {
    //   if (subscription && !subscription.closed) {
    //     console.log('Cancelar subscripcion ', subscription);
    //     subscription.unsubscribe();
    //     this.isLoadingSendRM = false;
    //   }
    // });

    // subscription = observable
    //   .subscribe((res) => {
    //     console.log('OK sendToMovilixa() ', res);
    //     this.movilixaSendDetails = this.parseMovilizaResponse(res);
    //     console.log('RESPUESTA MOVILIXA ', this.movilixaSendDetails);
    //     console.log('KEYS TO GET DESCRIPTION ', KEYS_TO_MOVILIXA_RESPONSE_DESCRIPTION);
    //     this.restartSendRM(true, modalRef);
    //     setTimeout(() => {
    //       this.presentSendMovilixaDetailsModal();
    //     }, 1000);
    //   }, (error) => {
    //     console.log('ERROR sendToMovilixa() ', error);
    //     this.restartSendRM(false, modalRef);
    //   });
  }

  liberarRango(comparendo: any) {
    console.log('yay');
    this.comparendoService.liberarComparendo(comparendo.id).subscribe((res) => {
      this.toast.showToast('success', 'Información', 'Comparendo Liberado');
      comparendo.comparendoOcupado = false;
    });

  }

  /**
   * Reinicia variables despues de envio exitoso o fallido a moviliza
   * @param status estado de la peticion
   * @param modalRef referencia del modal de carga
   */
  private restartSendRM(status: boolean): void {
    this.isLoadingSendRM = false;
    // modalRef.close();
    this.blockUIPage.stop();
    if (status) {
      this.selectAllRM = false;
      this.includeIdsRM = [];
      this.excludeIdsRM = [];
      this.findAllByConfirmada({nextId: 3}, null);
      this.toast.showToast('success', null, 'Comparendos enviados a moviliza');
    } else {
      this.toast.showToast('error', null, 'Error al enviar comparendos a moviliza');
    }
    console.log('this.isLoadingSendRM = ', this.isLoadingSendRM);
    console.log('this.selectAllRM = ', this.selectAllRM);
    console.log('this.includeIdsRM = ', this.includeIdsRM);
    console.log('this.excludeIdsRM = ', this.excludeIdsRM);
  }

  /**
   * Mostrar detalles de envio de comparendos
   */
  public presentSendMovilixaDetailsModal() {
    this.modalService.open(this.movilixaSendDetailsModalContent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg'
    }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  /**
   * Parsear respuesta de moviliza
   * @param response Respusta de moviliza
   */
  private parseMovilizaResponse(response: { [key: string]: any }[]): any[] {
    response = response.map(item => {
      if (item.movilixaRequest) {
        item.movilixaRequest = getParsedJson(item.movilixaRequest);
      }
      if (item.movilixaResponse) {
        item.movilixaResponse = getParsedJson(item.movilixaResponse);
      }
      return item;
    }) as any;
    return response;
  }

  /**
   * Obtener la descripcion de la respuesta de moviliza
   * @param response Respuesta de moviliza
   */
  public getMovilixaResponseDescription(response: { [key: string]: any }): string {
    let description = null;
    const keys: string[] = KEYS_TO_MOVILIXA_RESPONSE_DESCRIPTION.split('.');
    keys.forEach(key => {
      if (!description) {
        description = response[key];
      } else {
        description = description[key];
      }
    });
    if (typeof description === 'string') {
      return description;
    } else {
      try {
        return JSON.stringify(description, null, 2);
      } catch (error) {
        return '';
      }
    }
  }

  /**
   * SEND TO SIMIT OR MOVILIZA METHODS END
   */
}
