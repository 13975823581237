import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { createRequestOption } from '../helpers/request-util';
import { TreeviewItem } from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})
export class DivipoService {

  public resourceUrl = environment.apiUrl + 'camera/divipo';
  constructor(public http: HttpClient) { }

  create(divipo: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, divipo);
  }

  update(divipo: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, divipo);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, { params: options });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

}
