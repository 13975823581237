import { Component, OnInit, NgZone } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

// Services
import {
  ComparendoService,
  CamaraService,
  ConsultaRuntService,
  TipoDocumentoService,
  CiudadService,
  EstadoComparendoUsuarioService
} from '@app/providers';
// Types
import {Comparendo} from '../../commons/interfaces/types/comparendos';
import {Response} from '../../commons/interfaces/types/response';
import {ConsultaRunt} from '../../commons/interfaces/types/consulta-runt';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';
import {ESTADOCOMPARENDOS} from '../../commons/constants/app';
import {Router} from '@angular/router';
import {CustomToastrService} from '@app/commons/providers/custom-toastr.service';
import { TipoEstadoComparendo } from '@app/commons/interfaces/types/tipo-estado-comparendo';

declare const $: any;

@Component({
  selector: 'app-consultas-runt',
  templateUrl: './consultas-runt.component.html',
  styleUrls: ['./consultas-runt.component.scss']
})
export class ConsultasRuntComponent implements OnInit {
  queryOptions: { simit: boolean; runt: boolean; } = {simit: false, runt: false};
  totalItems: number = null;
  queryCount: number = null;
  itemsPerPage: number;
  page: number;
  predicate: string;
  reverse = false;
  camaras: any;
  mostrarTablaIdentificacion: any = false;
  mostrarTablaPlaca: any = false;
  totalPage: number = null;
  tipoDocumentoSelect: any;
  comparendos: Comparendo[] = [];
  placaControl: FormControl;
  cedulaControl: FormControl;
  tipoDocumentoControl: FormControl;

  // Consultas
  consultaRuntCheck: any = true;
  consultaSimitCheck: any = false;

  // Datos de las tablas
  confirmadas: any = null;
  estadoComparendos: any = null;
  nombreCiudad = '';

  consultaRunt: ConsultaRunt;
  consultaRuntMessage: string;
  usuarioId;

  readonly ESTADOCOMPARENDOS: typeof ESTADOCOMPARENDOS = ESTADOCOMPARENDOS;

  constructor(private comparendoService: ComparendoService,
              private camaraService: CamaraService,
              public router: Router,
              private toast: CustomToastrService,
              private consultaRuntService: ConsultaRuntService,
              private webStorageCustomService: WebStorageCustomService,
              private tipoDocumentoService: TipoDocumentoService,
              private ciudadService: CiudadService,
              private estadoComparendoUsuarioService: EstadoComparendoUsuarioService,
              private ngZone: NgZone) {
  }

  ngOnInit() {
    this.initVariables();
    this.initControls();
    this.consultarTipoDocumento();
    this.usuarioId = JSON.parse(this.webStorageCustomService.getFromLocal('usuario')).id;
  }

  private initControls() {
    this.placaControl = new FormControl('');
    this.cedulaControl = new FormControl('');
    this.tipoDocumentoControl = new FormControl(null);
    this.placaControl.valueChanges.subscribe((val) => {
      if (val) {
        this.placaControl.setValue((val as String).toUpperCase(), {emitEvent: false});
      }
    });
  }

  consultarTipoDocumento() {
    this.tipoDocumentoService.query({}).subscribe((data) => {
      this.tipoDocumentoSelect = data.content;
    });
  }

  seeSubpoena(comparendo: any): void {
    console.log("comparendo",comparendo)
    this.router.navigate([`/ver-comparendos/${comparendo.id}`]);
  }

  consultarComparendos() {
    return false;
    const placa = this.placaControl.value;
    const cedula = this.cedulaControl.value;
    const tipoDocumento = this.tipoDocumentoControl.value;

    const query = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')]
    };

    if (placa && placa.length > 0) {
      query['placa'] = placa;
    }

    if (cedula && cedula.length > 0) {
      query['cedula'] = cedula;
    }

    if (tipoDocumento && tipoDocumento.length > 0) {
      query['tipoDocumento'] = tipoDocumento;
    }

    this.comparendoService.query(query)
      .subscribe(async (response: Response) => {

        this.totalItems = response.totalElements;
        this.totalPage = response.totalPages;
        for (let comparendo of response.content) {
          (comparendo as any).camara = await this.ciudadComparendo(comparendo.camaraId);
          try {
            const lastState = await this.estadoComparendoUsuarioService.findLastByComparendoId(comparendo.id).toPromise();
            const tipoEstadoComparendo = lastState.tipoEstadoComparendo;
            comparendo.tipoEstadoComparendo = tipoEstadoComparendo;
          } catch (err) {
            console.log('Error last state ', err);
          }
        }
        this.ngZone.run(() => {});
        this.comparendos = response.content;
      });
  }

  estadoComparendo(estado?: boolean) {
    estado === true ? this.estadoComparendos = 'Comparendo certificado' :
      estado === null ? this.estadoComparendos = 'Comparendo por certificar' :
        this.estadoComparendos = 'Comparendo rechazado';
    return this.estadoComparendos;
  }

  ciudadComparendo(camaraId?: any) {
    return this.camaraService.find(camaraId).toPromise();
  }

  sortlist(predicate: string) {
    if (this.cedulaControl.value || this.placaControl.value) {
      this.predicate = predicate;
      this.reverse = !this.reverse;
      this.consultarComparendos();
    }
  }

  transition(page: number) {
    this.page = page;
    this.consultarComparendos();
  }

  async consultarRunt() {
    this.consultaRunt = null;

    const IMEI = '201.184.65.90';
    const IDUSUARIO = '900649817';
    let tokenResponse: string = null;

    // Generacion de token
    // await this.consultaRuntService.generarToken(IMEI, IDUSUARIO).toPromise()
    //   .then((response) => {
    //     tokenResponse = response.token;
    //   })
    //   .catch((error) => {
    //     console.log('ERROR ATENTICACION: ', error);
    //     this.consultaRuntMessage = error.mensajeRespuesta;
    //   });

    // Validación de token y posterior consulta
    // if (tokenResponse) {
    //
    // } else {
    //   console.log('Autenticación no exitosa');
    // }

    const params: {
      vehiculoPlaca?: string;
      nroDocumentoPersona?: string;
    } = {};

    if (this.placaControl.value && this.placaControl.value.length > 0) {
      params['vehiculoPlaca'] = this.placaControl.value;
      this.mostrarTablaPlaca = true;
    }
    if (this.cedulaControl.value && this.cedulaControl.value.length > 0) {
      params['nroDocumentoPersona'] = this.cedulaControl.value;
      this.mostrarTablaIdentificacion = true;
    }

    if (this.tipoDocumentoControl.value && this.tipoDocumentoControl.value.length > 0) {
      params['idtipodocumento'] = this.tipoDocumentoControl.value;
    }

    params['manual'] = true;
    params['usuarioId'] = this.usuarioId;

    await this.consultaRuntService.getInformacion(IMEI, IDUSUARIO, tokenResponse, Object.keys(params).length > 0 ? params : null).toPromise()
      .then(async (response: ConsultaRunt) => {

        // Set person state
        if ((response as any).objPersonaDto && (response as any).objPersonaDto.idMunicipio) {
          await this.ciudadService.find((response as any).objPersonaDto.idMunicipio).toPromise()
          .then((res) => {
            (response as any).objPersonaDto.idMunicipio = res.nombre;
          })
          .catch((err) => {
            console.error('Error al encontrar municipio', err);
          });
        }

        if (response.objVehiculoDto || (response as any).objPersonaDto) {
          this.consultaRunt = response;
          document.getElementById('tablePrueba').remove();
          var g = document.createElement('div');
          g.setAttribute('id', 'tablePrueba');
          g.setAttribute('class', 'tablaprueba');
          document.getElementById('contenedorTabla').appendChild(g);

          if (document.getElementById('tablePrueba') != null) {
            $.json2table(this.consultaRunt, 'Consulta').appendTo('#tablePrueba');
          }

          this.consultaRuntMessage = null;
        } else {
          this.consultaRuntMessage = 'La consulta no arrojó resultados';
        }
      })
      .catch((error) => {
        console.log('ERROR CONSULTA: ', error);
        this.consultaRuntMessage = error.mensajeRespuesta;
      });
  }

  validateFiltersValue(): boolean {
    return (((this.tipoDocumentoControl.value || this.cedulaControl.value)) ? this.tipoDocumentoControl.value && this.cedulaControl.value : (this.placaControl.value && this.placaControl.value.length > 0));
  }

  getEstadoSoat(estado?: boolean): string {
    if (estado !== undefined) {
      return estado ? 'Vigente' : 'Vencido';
    } else {
      return '';
    }
  }

  changeStateRUNT(change: any) {
    if (!change) {
      this.consultaRunt = null;
    }
  }

  aplicarFiltros() {
    this.consultarComparendos();
    if (this.consultaRuntCheck) {
      this.consultarRunt();
    } else {
      this.consultaRunt = null;
    }
  }

  showPdf(pdfs: string) {
    console.log(pdfs);
    const PDFS: string[] = JSON.parse(pdfs);
    window.open(PDFS[PDFS.length - 1], '_blank');
  }

  getClassForSubpoenaState(state: TipoEstadoComparendo): string {
    let stringClass;
    if (state) {
      if (state.id === ESTADOCOMPARENDOS.idComparendoCreado ||
        state.id === ESTADOCOMPARENDOS.idComparendoPorCertificar) {
        stringClass = 'align-middle text-info';
      } else if (state.id === ESTADOCOMPARENDOS.idComparendoCertificado ||
        state.id === ESTADOCOMPARENDOS.idComparendoEnviadoMOVILIZA ||
        state.id === ESTADOCOMPARENDOS.idComparendoEnviadoSIMIT) {
        stringClass = 'align-middle text-success';
      } else if (state.id === ESTADOCOMPARENDOS.idComparendoRechazado ||
        state.id === ESTADOCOMPARENDOS.idComparendoDescertificado) {
        stringClass = 'align-middle text-danger';
      }
    }
    return stringClass;
  }

  private initVariables() {
    this.page = 1;
    this.itemsPerPage = 15;
    this.predicate = 'id';
  }


  selectChange() {

    if (this.tipoDocumentoControl.value !== null) {


      this.cedulaControl.setValidators([Validators.required]);
      this.cedulaControl.updateValueAndValidity();

    } else {

      this.cedulaControl.clearValidators();
      this.cedulaControl.reset();
      this.cedulaControl.updateValueAndValidity();

    }

  }

}
