import {Component, OnInit} from '@angular/core';
import {AuthService, InfractorService, UploadFileService} from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';
import {ActivatedRoute} from '@angular/router';
import {NgbDateParserFormatter, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {ExportAsConfig, ExportAsService} from 'ngx-export-as';
import {ModalMostrarTodoComponent} from '../../commons/modals/modal-mostrar-todo/modal-mostrar-todo.component';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-infractor',
  templateUrl: './infractor.component.html',
  styleUrls: ['./infractor.component.scss']
})
export class InfractorComponent implements OnInit {

  totalItems: number = null;
  queryCount: number = null;
  itemsPerPage: number = null;
  page: number = null;
  predicate: any;
  reverse: any;
  infractores: any;
  totalPage: number = null;
  showTable = false;
  cargarTodo = false;
  cantidadItems = 25;
  permisos: Array<any>;

  constructor(
    private infractorService: InfractorService,
    private uploadService: UploadFileService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private toast: CustomToastrService,
    public webStorageCustomService: WebStorageCustomService,
    private authService: AuthService,
    private exportAsService: ExportAsService
  ) {
    this.itemsPerPage = this.cantidadItems;
    this.page = 1;
    this.predicate = 'id';
    this.reverse = false;
  }


  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.loadAll();
  }

  async loadAll() {
    const query = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort()
    };

    await this.infractorService
      .query(query)
      .toPromise().then(
        (res: any) => {
          this.queryCount = res.numberOfElements;
          this.totalPage = res.totalPages;
          this.totalItems = res.totalElements;
          this.infractores = res.content;
          if (res.content.length > 0) {
            this.showTable = true;
          } else {
            this.showTable = false;
          }
        },
        (err) => {
          this.showTable = false;
          this.onError(err.message);
        }
      );
  }

  sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.loadAll();
  }

  transition() {
    this.loadAll();
  }

  sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    return result;
  }


  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  // public open(infractor?: any, permiso?: string) {
  //   if (this.validatePermission(permiso)) {
  //     const modal: NgbModalRef = this.modalService.open(NewEditInfractorComponent, {size: 'sm', backdrop: 'static'});
  //     if (infractor) {
  //       infractor.file = null;
  //       modal.componentInstance.infractor = infractor;
  //     }
  //     modal.result.then(
  //       (success: Object) => {
  //         this.loadAll();
  //       },
  //       (error: any) => {
  //         // console.error(error);
  //       }
  //     );
  //   } else {
  //     this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de roles');
  //   }
  // }


  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  export(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'table',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'lista_infractores');

  }

  todo() {

    this.cargarTodo = !this.cargarTodo;

    if (this.cargarTodo) {
      this.mostrarTodoModal();
    } else {
      this.itemsPerPage = this.cantidadItems;
      this.loadAll();
    }


  }


  public mostrarTodoModal() {
    const modal: NgbModalRef = this.modalService.open(ModalMostrarTodoComponent, {size: 'sm'});
    modal.componentInstance.texto = this.totalItems;
    modal.result.then(
      (success: Object) => {
        this.itemsPerPage = this.totalItems;
        this.loadAll();
      },
      (error: any) => {
        this.cargarTodo = !this.cargarTodo;
        this.loadAll();
      }
    );

  }

}
