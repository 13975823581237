import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

// Types
import {PicoPlaca} from '../../commons/interfaces/types/pico-placa';
import {Response} from '../../commons/interfaces/types/response';
// Services
import {PicoPlacaService} from '../../providers/pico-placa.service';
import {TipoVehiculoService} from '../../providers/tipoVehiculo.service';
import {ClaseServicioService} from '../../providers/claseServicio.service';
import {makeDateInstance} from '../../commons/utils/general';
import {SecretariaService} from '../../providers';

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-pico-placa-list',
  templateUrl: './pico-placa-list.component.html',
  styleUrls: ['./pico-placa-list.component.scss']
})
export class PicoPlacaListComponent implements OnInit {
  keyWordControl: FormControl;
  vigencia: FormControl;
  picoPlacaList: PicoPlaca[] = [];
  paginationParams = {
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: null,
    totalPages: null
  };
  filters: { identificador: string; value: string; }[] = [{
    identificador: 'tipo_vehiculo',
    value: 'Tipo de Vehiculo',
  },
    {
      identificador: 'clase_servicio',
      value: 'Clase de Servicio',
    },
    {
      identificador: 'secretaria',
      value: 'Secretaria',
    }];
  selectedFilterControl: FormControl;
  filterValueControl: FormControl;
  dateRangeControl: FormControl;
  tiposDeVehiculoList: any[];
  secretariasList: any[];
  clasesDeServicioList: any[];
  predicate = '';
  reverse: boolean = false;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  showDatePicker = false;
  isEditing = false;

  constructor(private picoPlacaService: PicoPlacaService,
              private tipoVehiculoService: TipoVehiculoService,
              private secretariaService: SecretariaService,
              private claseServicioService: ClaseServicioService,
              private datePipe: DatePipe,
              private calendar: NgbCalendar,
              private router: Router) {
    /*this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);*/
  }


  ngOnInit() {
    this.selectedFilterControl = new FormControl('');
    this.filterValueControl = new FormControl('');
    this.dateRangeControl = new FormControl('');
    this.getPicoPlacaList(this.getFilters());
    this.getClasesDeServicio();
    this.getSecretarias();

    this.getTiposDeVehiculo();
    this.dateRangeControl.valueChanges
      .subscribe(val => {
        console.log(val);
      });
  }

  /*disabledApplyFilters(): boolean {
    return !this.selectedFilterControl.value && !this.filterValueControl.value; 
  }*/

  editarRestriccion(id: number) {
    console.log(id);
    this.router.navigate(['parametros-comparendos/pico-placa', id]);
  }

  changeState(picoPlaca: PicoPlaca) {
    this.isEditing = true;
    this.picoPlacaService.update(picoPlaca)
      .subscribe(() => {
        console.log('Editada ');
        this.isEditing = false;
      }, (err) => {
        console.log('Error al editar ', err);
        this.isEditing = false;
      });
  }

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  rangeDatePlaceholder(): string {
    if (this.fromDate && this.toDate) {
      return `${this.fromDate.day}/${this.fromDate.month}/${this.fromDate.year} - ${this.toDate.day}/${this.toDate.month}/${this.toDate.year}`;
    } else {
      return '';
    }
  }

  getFilters() {
    const filters = {};
    if (this.selectedFilterControl.value && this.filterValueControl.value) {
      filters[this.selectedFilterControl.value] = this.filterValueControl.value;
    }
    if (this.fromDate && this.toDate) {
      filters['desde_fecha'] = makeDateInstance(this.fromDate);
      filters['hasta_fecha'] = makeDateInstance(this.toDate);
    }
    return filters;
  }

  clearFilter(){
    this.selectedFilterControl.reset()
    this.filterValueControl.reset();
    this.getPicoPlacaList(this.getFilters());
  }

  getPicoPlacaList(params?: { [key: string]: any }) {
    this.showDatePicker = false;
    const query = {
      page: this.paginationParams.currentPage - 1,
      size: this.paginationParams.itemsPerPage,
      sort: [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')]
    };
    if (params) {
      Object.keys(params).forEach((key: string) => {
        query[key] = params[key];
      });
    }
    this.picoPlacaService.query(query)
      .subscribe((response: Response) => {
        this.paginationParams.totalItems = response.totalElements;
        this.paginationParams.totalPages = response.totalPages;
        this.picoPlacaList = response.content;
        console.log('this.picoPlacaList', this.picoPlacaList);
      }, (error) => {
        console.log('ERROR getPicoPlacaList: ', error);
      });
  }

  deleteRestriccion(id: number) {
    this.picoPlacaService.delete(id)
      .subscribe(() => {
        console.log('restriccion eliminada');
        this.getPicoPlacaList(this.getFilters());
      }, (error) => {
        console.log('ERROR deleteRestriccion: ', error);
      });
  }

  getDateAsString(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yy');
  }

  transition($event: number) {
    this.paginationParams.currentPage = $event;
    this.getPicoPlacaList(this.getFilters());
  }

  getInstancesForFiltering() {
    if (this.selectedFilterControl.value) {

      if (this.selectedFilterControl.value === 'tipo_vehiculo') {
        return this.tiposDeVehiculoList;
      } else if (this.selectedFilterControl.value === 'clase_servicio') {
        return this.clasesDeServicioList;
      } else {
        return this.secretariasList;
      }

    } else {
      return [];
    }
  }

  sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.getPicoPlacaList(this.getFilters());
  }

  private getTiposDeVehiculo() {
    this.tipoVehiculoService.listAll()
      .subscribe((_tiposDeVehiculoList) => {
        this.tiposDeVehiculoList = _tiposDeVehiculoList;
      }, (error) => {
        console.log('ERROR getTiposDeVehiculo: ', error);
      });
  }

  private getClasesDeServicio() {
    this.claseServicioService.listAll()
      .subscribe((_clasesDeServicioList) => {
        this.clasesDeServicioList = _clasesDeServicioList;
      }, (error) => {
        console.log('ERROR getClasesDeServicio: ', error);
      });
  }

  private getSecretarias() {
    this.secretariaService.listAll()
      .subscribe((_secretariasList: any[]) => {
        _secretariasList.forEach(value => {
          value.descripcion = value.nombre;
        });
        this.secretariasList = _secretariasList;
      }, (error) => {
        console.log('ERROR getSecretarias: ', error);
      });
  }


  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);

}
