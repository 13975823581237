import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';
import { createRequestOption } from '../helpers/request-util';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// Own
// Types
import { ColumnDefinitions } from '../commons/interfaces/types/column-definitions';

@Injectable({
  providedIn: 'root'
})
export class TiposServicioReportesService {
  public resourceUrl = environment.apiUrl + 'comparendo/' + 'clase_servicio';
  private chartBaseObject = {
    type: 'bar',
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: [],
          data: []
        }
      ]
    },
    options: {
      legend: { display: false, position: 'bottom' },
      title: {
        display: true,
        text: ''
      },
      colors: [],
      animation: null,
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
            // OR //
            beginAtZero: true,   // minimum value will be 0.
            precision: 0
          }
        }]
      }
    },
  };
  private colors: string[] = [
    '#800000',
    '#FFA500',
    '#008000',
    '#800080',
    '#008080',
    '#000080',
    '#CD5C5C',
    '#FF4500',
    '#FAFAD2',
    '#4B0082',
    '#556B2F',
    '#8B4513',
    '#2F4F4F',
    '#A0522D',
    '#8B0000',
    '#FF1493',
    '#32CD32',
    '#DC143C',
    '#FA8072',
    '##3e95cd'
  ];
  constructor(private http: HttpClient) { }

  // REPORTE TIPOS INFRACCION

  reporteTipoInfraccionData(req: { [key: string]: any }): Observable<{ [key: string]: any }[]> {
    const options = createRequestOption(req);
    return this.http.get<{ [key: string]: any }[]>(`${this.resourceUrl}/reporte_tipo_servicio_tipo_infraccion`, { params: options });
  }

  getReporteTipoInfraccionColumnDefinitions(data: any[]): ColumnDefinitions[] {
    const columns: ColumnDefinitions[] = [];
    if (data.length > 0) {
      const reference = data[0];
      Object.keys(reference).forEach((key: string) => {
        // if (key !== 'camaraId' && key !== 'codigo' && key !== 'nombre') {
          columns.push({
            'columnName': key,
            'valueGetter': (item: any) => item[key] !== null ? item[key] : '',
            'columnType': 0
          });
        // }
      });
    }
    let newColumnDefinitions = [];
      newColumnDefinitions.push(columns.filter(e => e.columnName === 'servicio')[0]);
      columns.forEach((column) => {
        if (column.columnName !== 'servicio') {
          newColumnDefinitions.push(column);
        }
      });
    return newColumnDefinitions;
  }

  getReporteTipoInfraccionDataChart(content: any[], columns: ColumnDefinitions[]): any {
    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    // chart.type = 'horizontalBar';
    chart.options.title.text = 'Tipos de infraccion';
    chart.options.legend.display = true;
    chart.data.datasets = [];
    content.forEach((element) => {
      chart.data.labels.push(element.servicio);
    });

    console.log('COLUMNAS TIPO INFRACCION ', columns);

    columns.forEach((column: ColumnDefinitions, index: number) => {
      const data = [];
      content.forEach((content) => {
        data.push(content[column.columnName]);
      });
      if (column.columnName !== 'servicio') {
        chart.data.datasets.push({
          'data': data,
          'backgroundColor': this.colors[index],
          'label': column.columnName
        });
      }
    });

    console.log('GENERAR GRAFICO CON ', content, 'CHART ', chart);
    return chart;
  }

}
