import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CargaComparendoExternalService {

   public resourceUrl = environment.apiUrl + 'comparendo/external';
  // public resourceUrl = 'http://localhost:8084/external';
  constructor(public http: HttpClient) {}

  sendManyMovilixa(body: { [key: string]: any }): Observable<any[]> {
    console.log('Body send to movilixa ', body);
    return this.http.post<any[]>(`${this.resourceUrl}/send_many_movilixa`, body);
  }

  sendManySert(body: {[key: string]: any}): Observable<any[]> {
    console.log('Body send to sert ', body);
    return this.http.post<any[]>(`${this.resourceUrl}/send_many_sert`, body);
  }
}
