/** Dependencies */
import {BrowserModule, Title} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AgmCoreModule} from '@agm/core';
import {ToastrModule} from 'ngx-toastr';
import {TourNgBootstrapModule} from 'ngx-tour-ng-bootstrap';
import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';
import {ContextMenuModule} from 'ngx-contextmenu';
import {BlockUIModule} from 'ng-block-ui';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {ConfirmationPopoverModule} from 'angular-confirmation-popover';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TreeviewModule} from 'ngx-treeview';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ChartsModule as Ng2ChartsModule} from 'ng2-charts/ng2-charts';
import {DatePipe, registerLocaleData, CurrencyPipe} from '@angular/common';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import {FileUploadModule} from 'ng2-file-upload';
import {RecaptchaComponent, RecaptchaModule} from 'ng-recaptcha';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NouisliderModule} from 'ng2-nouislider';
// import { myRxStompConfig } from './my-rx-stomp.config';
import {ExportAsModule} from 'ngx-export-as';
import {FileSaverModule} from 'ngx-filesaver';
/** NgBootstrap */
import {NgbActiveModal, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
/** App */
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutModule} from './layout/layout.module';
import {ThemeSettingsModule} from '../vendor/libs/theme-settings/theme-settings.module';
/** Pages */
import {
  CamaraComponent,
  CambiarContrasenaComponent,
  CiudadComponent,
  ConsultasExternasPublicComponent,
  DashboardComponent,
  EditPermisosComponent,
  ListaNotificacionesComponent,
  LoginComponent,
  NewEditRolComponent,
  NotFoundComponent,
  PerfilComponent,
  PicoPlacaFichaComponent,
  PicoPlacaListComponent,
  ProtectedComponent,
  RadaresComponent,
  RecordarContrasenaComponent,
  ReportesComponent,
  ResultadoReporteComponent,
  RolesComponent,
  UploadFilesComponent,
  UsuariosComponent
} from './components';
import {EvidenciasComponent} from './components/evidencias/evidencias.component';
import { ComparendoEstadosComponent } from '@app/components/comparendo-estados/comparendo-estados.component';

import {ImageCropperModule} from 'ng2-img-cropper';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
// *******************************************************************************
//
/** Helpers */
import {AuthenticatedGuard} from './guards/authenticated/authenticated.guard';
import {UnauthenticatedGuard} from './guards/unauthenticated/unauthenticated.guard';
import {JwtInterceptor} from './helpers/jwt.interceptor';
/** Custom commons componentes  */
import {CommonsModule} from './commons/commons.module';
import {NewEditUsuarioComponent} from './components/usuarios/modals/new-edit-usuario/new-edit-usuario.component';
import {NewEditCamaraComponent} from './components/camara/modals/new-edit-camara/new-edit-camara.component';
import {NewEditItemComponent} from './components/ciudad/modals/new-edit-item/new-edit-item.component';
import {CertificarComparendosComponent} from './components/comparendos/certificar-comparendos/certificar-comparendos.component';
import {TipoVehiculoComponent} from './components/tipo-vehiculo/tipo-vehiculo.component';
import {ClaseServicioComponent} from './components/clase-servicio/clase-servicio.component';
import {ModalidadesTransporteComponent} from './components/modalidades-transporte/modalidades-transporte.component';
import {TransportePasajerosComponent} from './components/transporte-pasajeros/transporte-pasajeros.component';
import {InfraccionesComponent} from './components/infracciones/infracciones.component';
import {NewEditInfraccionComponent} from './components/infracciones/modals/new-edit-infraccion/new-edit-infraccion.component';
import {NewEditTipoVehiculoComponent} from './components/tipo-vehiculo/modals/new-edit-tipo-vehiculo/new-edit-tipo-vehiculo.component';
import {AuthExpiredInterceptor} from './helpers/auth-expired.interceptor';
import {EditPlacaComponent} from './components/comparendos/modals/edit-placa/edit-placa.component';
import {NewEditClaseServicioComponent} from './components/clase-servicio/modals/new-edit-clase-servicio/new-edit-clase-servicio.component';
import {NewEditModalidadesTransporteComponent} from './components/modalidades-transporte/modals/new-edit-modalidades-transporte/new-edit-modalidades-transporte.component';
import {NewEditTransportePasajerosComponent} from './components/transporte-pasajeros/modals/new-edit-transporte-pasajeros/new-edit-transporte-pasajeros.component';
import {TipoInfractorComponent} from './components/tipo-infractor/tipo-infractor.component';
import {NewEditTipoInfractorComponent} from './components/tipo-infractor/modals/new-edit-tipo-infractor/new-edit-tipo-infractor.component';
import {NewExclusionComponent} from './components/rango/modals/new-exclusion/new-exclusion.component';
import {RadioAccionComponent} from './components/radio-accion/radio-accion.component';
import {NewEditRadioAccionComponent} from './components/radio-accion/modals/new-edit-radio-accion/new-edit-radio-accion.component';
import {TipoDocumentoComponent} from './components/tipo-documento/tipo-documento.component';
import {NewEditTipoDocumentoComponent} from './components/tipo-documento/modals/new-edit-tipo-documento/new-edit-tipo-documento.component';
import {DivipoComponent} from './components/divipo/divipo.component';
import {NewEditDivipoComponent} from './components/divipo/modals/new-edit-divipo/new-edit-divipo.component';
import {ListaBlancaComponent} from './components/lista-blanca/lista-blanca.component';
import {NewEditListaBlancaComponent} from './components/lista-blanca/modals/new-edit-lista-blanca/new-edit-lista-blanca.component';
import {RangoComponent} from './components/rango/rango.component';
import {NewRangoComponent} from './components/rango/modals/new-rango/new-rango.component';
import {VerComponent} from './components/rango/children/ver/ver.component';
import {ConsultasExternasComponent} from './components/consultas-externas/consultas-externas.component';
import {ConsultasRuntComponent} from './components/consultas-runt/consultas-runt.component';
import {ConsultasInternasComponent} from './components/consultas-internas/consultas-internas.component';
import {ObservacionComponent} from './components/observacion/observacion.component';
import {NewEditObservacionComponent} from './components/observacion/modals/new-edit-observacion/new-edit-observacion.component';
import {DescartarComparendoComponent} from './components/comparendos/modals/descartar-comparendo/descartar-comparendo.component';
import {ResultadoConsultaComponent} from './components/consultas-externas/resultado-consulta/resultado-consulta.component';
import {FormularioConsultaComponent} from './components/consultas-externas/formulario-consulta/formulario-consulta.component';
import {AdminComponent} from './components/admin/admin.component';
import {LogsComponent} from './components/logsReport/logs.component';
import {InfractorComponent} from './components/infractor/infractor.component';
import {ParametrizacionComponent} from './components/parametrizacion/parametrizacion.component';
import {SecretariaComponent} from './components/secretaria/secretaria.component';
import {NewEditSecretariaComponent} from './components/secretaria/modals/new-edit-secretaria/new-edit-secretaria.component';
import {EditParametrizacionComponent} from './components/parametrizacion/modals/edit-parametrizacion/edit-parametrizacion.component';
import {TipoEstadoCamaraComponent} from './components/tipo-estado-camara/tipo-estado-camara.component';
// Internaciolizacion datepicker
import {CustomDatepickerI18nService} from './providers/custom-datepicker-i18n.service';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {NewEditTipoEstadoCamaraComponent} from './components/tipo-estado-camara/modals/new-edit-tipo-estado-camara/new-edit-tipo-estado-camara.component';
import {NgSelectModule} from '@ng-select/ng-select';
import localeEs from '@angular/common/locales/es-CO';
import {ConsultasExternasLogComponent} from './components/consultas-externas-log/consultas-externas-log.component';
import {MomentModule} from 'angular2-moment';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';

import {VgCoreModule} from 'videogular2/core';
import {VgControlsModule} from 'videogular2/controls';
import {VgOverlayPlayModule} from 'videogular2/overlay-play';
import {VgBufferingModule} from 'videogular2/buffering';
import { VerComparendosComponent } from './components/comparendos/ver-comparendos/ver-comparendos.component';
RecaptchaComponent.prototype.ngOnDestroy = function () {
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
};


registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    UsuariosComponent,
    RecordarContrasenaComponent,
    CambiarContrasenaComponent,
    RolesComponent,
    NewEditRolComponent,
    EditPermisosComponent,
    NotFoundComponent,
    ProtectedComponent,
    PerfilComponent,
    NewEditUsuarioComponent,
    CamaraComponent,
    NewEditCamaraComponent,
    RadaresComponent,
    CiudadComponent,
    NewEditItemComponent,
    CertificarComparendosComponent,
    TipoVehiculoComponent,
    ClaseServicioComponent,
    ModalidadesTransporteComponent,
    TransportePasajerosComponent,
    InfraccionesComponent,
    NewEditInfraccionComponent,
    NewEditTipoVehiculoComponent,
    NewEditClaseServicioComponent,
    NewEditModalidadesTransporteComponent,
    NewEditTransportePasajerosComponent,
    EditPlacaComponent,
    TipoInfractorComponent,
    NewEditTipoInfractorComponent,
    RadioAccionComponent,
    NewEditRadioAccionComponent,
    TipoDocumentoComponent,
    NewEditTipoDocumentoComponent,
    DivipoComponent,
    NewEditDivipoComponent,
    ListaBlancaComponent,
    NewEditListaBlancaComponent,
    RangoComponent,
    NewRangoComponent,
    VerComponent,
    NewExclusionComponent,
    ConsultasExternasComponent,
    ConsultasInternasComponent,
    ConsultasRuntComponent,
    DescartarComparendoComponent,
    PicoPlacaListComponent,
    PicoPlacaFichaComponent,
    ObservacionComponent,
    NewEditObservacionComponent,
    UploadFilesComponent,
    ResultadoConsultaComponent,
    FormularioConsultaComponent,
    ConsultasExternasPublicComponent,
    ResultadoReporteComponent,
    ReportesComponent,
    AdminComponent,
    LogsComponent,
    InfractorComponent,
    ListaNotificacionesComponent,
    ParametrizacionComponent,
    SecretariaComponent,
    NewEditSecretariaComponent,
    EditParametrizacionComponent,
    TipoEstadoCamaraComponent,
    NewEditTipoEstadoCamaraComponent,
    ConsultasExternasLogComponent,
    EvidenciasComponent,
    ComparendoEstadosComponent,
    VerComparendosComponent,
  ],
  imports: [
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NouisliderModule,
    NgxJsonViewerModule,
    NgSelectModule,
    SwiperModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    Ng2ChartsModule,
    PerfectScrollbarModule,
    ImageCropperModule,
    RecaptchaModule,
    DropzoneModule,
    FileUploadModule,
    InfiniteScrollModule,
    ExportAsModule,
    FileSaverModule,
    // App
    AppRoutingModule,
    LayoutModule,
    ThemeSettingsModule,
    TreeviewModule.forRoot(),
    // Libs
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-lg btn-primary',
      cancelButtonClass: 'btn btn-lg btn-default'
    }),
    ToastrModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      cancelButtonType: 'default btn-sm',
      confirmButtonType: 'primary btn-sm'
    }),
    ContextMenuModule.forRoot(),
    TourNgBootstrapModule.forRoot(),
    AgmCoreModule.forRoot({
      /* NOTE: When using Google Maps on your own site you MUST get your own API key:
               https://developers.google.com/maps/documentation/javascript/get-api-key
               After you got the key paste it in the URL below. */
      apiKey: 'AIzaSyDJVUPAAWSzuLjOlhCOlv4I5kY4Fr_Vg9o'
    }),
    BlockUIModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CommonsModule
  ],
  providers: [
    Title,
    AuthenticatedGuard,
    NgbActiveModal,
    // StompService,
    UnauthenticatedGuard,
    {provide: LOCALE_ID, useValue: 'es-CO'},
    DatePipe,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true
    }, /*,
    {
      provide: InjectableRxStompConfig,
      useValue: myRxStompConfig
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig]
    }*/
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18nService
    }
  ],
  entryComponents: [
    NewEditRolComponent,
    EditPermisosComponent,
    NewEditUsuarioComponent,
    NewEditCamaraComponent,
    NewEditItemComponent,
    NewEditInfraccionComponent,
    NewEditTipoVehiculoComponent,
    NewEditClaseServicioComponent,
    NewEditModalidadesTransporteComponent,
    NewEditTransportePasajerosComponent,
    EditPlacaComponent,
    NewEditTipoInfractorComponent,
    NewEditRadioAccionComponent,
    NewEditTipoDocumentoComponent,
    NewEditListaBlancaComponent,
    NewEditDivipoComponent,
    NewRangoComponent,
    NewExclusionComponent,
    NewRangoComponent,
    DescartarComparendoComponent,
    NewEditObservacionComponent,
    NewEditSecretariaComponent,
    EditParametrizacionComponent,
    NewEditTipoEstadoCamaraComponent,
    ConsultasExternasLogComponent,
    ComparendoEstadosComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
