import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';

// Types
import { DetallePicoPlaca } from '../commons/interfaces/types/detalle-pico-placa';

@Injectable({
  providedIn: 'root'
})
export class DetallePicoPlacaService {

  public resourceUrl = environment.apiUrl + 'comparendo/detalle_restriccion_pico_placa';
  constructor(public http: HttpClient) { }

  create(detallePicoPlaca: DetallePicoPlaca): Observable<any> {
    return this.http.post<any>(this.resourceUrl, detallePicoPlaca);
  }

  createMany(detallesPicoPlaca: DetallePicoPlaca[]): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/save_many`, detallesPicoPlaca);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, { params: options});
  }

  findByRestriccionId(id: number): Observable<any> {
    return this.http.get<any[]>(`${this.resourceUrl}/find_by_restriccion/${id}`);
  }

  updateMany(detallesPicoPlaca: DetallePicoPlaca[]): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/update_many`, detallesPicoPlaca);
  }

  deleteMany(ids: string): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/delete_many?ids=${ids}`);
  }
}
