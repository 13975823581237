import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { TipoVehiculoService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';

@Component({
  selector: 'app-new-edit-tipo-vehiculo',
  templateUrl: './new-edit-tipo-vehiculo.component.html',
  styleUrls: ['./new-edit-tipo-vehiculo.component.scss']
})
export class NewEditTipoVehiculoComponent implements OnInit {

  @Input() private tipoVehiculo: any;
  private tipoVehiculoForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    private formBuilder: FormBuilder,
    public tipoVehiculoService: TipoVehiculoService,
  ) {
  }

  ngOnInit() {

    this.initForms();
    if (this.tipoVehiculo) {
      this.patchValues(this.tipoVehiculo);
    }

  }

  private initForms() {

    this.tipoVehiculoForm = this.formBuilder.group({
      id: null,
      fechaCreacion: null,
      fechaActualizacion: null,
      eliminado: false,
      descripcion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      idSimitMovilixa: [null, [Validators.required, Validators.minLength(1)]],
    });

  }

  private patchValues(tipoVehiculo: Object) {
    this.tipoVehiculoForm.patchValue(tipoVehiculo);
  }

  public async modalclose() {
    if (this.tipoVehiculoForm.valid) {
      const itemF = await this.createOrUpdateItem(this.tipoVehiculoForm.value, this.tipoVehiculo ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(tipoVehiculo: any, exits: any): Promise<any> {
    if (!exits) {
      return this.tipoVehiculoService.create(tipoVehiculo).toPromise();
    }
    if (exits) {
      return this.tipoVehiculoService.update(tipoVehiculo).toPromise();
    }
  }

}
