import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {AuthService} from '../../providers';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';


@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private webStorageCustomService: WebStorageCustomService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const promise: Promise<boolean> = new Promise((resolve, reject) => {

      if (!state.root.firstChild.children[0].data.noValidateUrl) {
        this.authService.checkToken().subscribe((data) => {

          if (!this.authService.hasPermision(state.root.firstChild.children[0] ? state.root.firstChild.children[0].routeConfig.path + '.ver' : state.url.replace('/', '') + '.ver')) {
            this.router.navigate(['/' + this.authService.findModuloPrincipal()]);
            resolve(false);
          } else {
            resolve(true);
          }

        }, (error) => {
          this.router.navigate(['/login']);
        });
      } else {
        resolve(true);
      }

    });
    return promise;
  }
}
