import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from '../helpers/request-util';
import { HttpClient } from '@angular/common/http';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { ESTADOCOMPARENDOS } from '@app/commons/constants/app';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@environment/environment';

// Own
// Types
import { ColumnDefinitions } from '@app/commons/interfaces/types/column-definitions';
import { TipoEstadoComparendo } from '@app/commons/interfaces/types/tipo-estado-comparendo';
// Services
import { UploadFileService } from '@app/providers/uploadFile.service';
// Components
import { LoadingModalComponent } from '@app/commons/modals/loading-modal/loading-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ComparendosReportesService {
  public resourceUrl = environment.apiUrl + 'comparendo/comparendo';
  private chartBaseObject = {
    type: 'bar',
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: [],
          data: []
        }
      ]
    },
    options: {
      legend: {display: false, position: 'bottom'},
      title: {
        display: true,
        text: ''
      },
      colors: [],
      animation: null,
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
            // OR //
            beginAtZero: true,   // minimum value will be 0.
            precision: 0
          }
        }]
      }
    },
  };
  private colors: string[] = [
    '#800000',
    '#FFA500',
    '#008000',
    '#800080',
    '#008080',
    '#000080',
    '#CD5C5C',
    '#FF4500',
    '#FAFAD2',
    '#4B0082',
    '#556B2F',
    '#8B4513',
    '#2F4F4F',
    '#A0522D',
    '#8B0000',
    '#FF1493',
    '#32CD32',
    '#DC143C',
    '#FA8072',
    '##3e95cd'
  ];
  dateFormat = 'yyyy/MM/ddThh:mm:ss';
  constructor(private http: HttpClient,
              private datePipe: DatePipe,
              private currencyPipe: CurrencyPipe,
              private uploadFileService: UploadFileService,
              private modalService: NgbModal) {
  }

  // REPORTE COMPARENDOS REALIZADOS

  reporteRealizadosData(req: { [key: string]: any }): Observable<{ [key: string]: any }> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.resourceUrl}/reporte_comparendos_realizados`, {params: options});
  }

  getReporteRealizadosColumnDefinitions = (): ColumnDefinitions[] => {
    const columns: ColumnDefinitions[] = [
      {
        'columnName': 'Seleccionar',
        'headerCheckbox': true,
        'selection': 'id',
        'selectMany': true,
        'columnType': 2,
        'getStyle': () => ({'cursor': 'pointer'})
      },
      {
        'columnName': 'Id',
        'valueGetter': (item) => item.id,
        'columnType': 0
      },
      {
        'columnName': 'Rango Consecutivo',
        'valueGetter': (item) => item.numero ? `'${item.numero}` : '',
        'columnType': 0
      },
      {
        'columnName': 'Fecha Infracción',
        'valueGetter': (item) => item.fechaInfraccion ?
          this.datePipe.transform(item.fechaInfraccion, this.dateFormat) : '',
        'columnType': 0
      },
      {
        'columnName': 'Fecha Creación',
        'valueGetter': (item) => item.fechaCreacion ?
          this.datePipe.transform(item.fechaCreacion, this.dateFormat) : '',
        'columnType': 0
      },
      {
        'columnName': 'Camara',
        'valueGetter': (item) => typeof item.camara === 'object' &&
        item.camara !== null && item.camara.nombre ? item.camara.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Camara id',
        'valueGetter': (item) => typeof item.camaraId ? item.camaraId : '',
        'columnType': 0
      },
      {
        'columnName': 'Ciudad',
        'valueGetter': (item) => typeof item.camara === 'object' &&
        item.camara !== null &&
        item.camara.ciudad ? item.camara.ciudad.nombre : item.camaraId,
        'columnType': 0
      },
      {
        'columnName': 'Ciudad id',
        'valueGetter': (item) => typeof item.camara === 'object' &&
        item.camara !== null &&
        item.camara.ciudad ? item.camara.ciudad.id : '',
        'columnType': 0
      },
      {
        'columnName': 'Clase Servicio',
        'valueGetter': (item) => typeof item.claseServicio === 'object' &&
        item.claseServicio !== null &&
        item.claseServicio.descripcion ? item.claseServicio.descripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Clase Servicio id',
        'valueGetter': (item) => typeof item.claseServicio === 'object' &&
        item.claseServicio !== null &&
        item.claseServicio.id ? item.claseServicio.id : '',
        'columnType': 0
      },
      {
        'columnName': 'Infraccion',
        'valueGetter': (item) => item.infraccion ? item.infraccion.descripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Infraccion código',
        'valueGetter': (item) => item.infraccion ? item.infraccion.codigo : '',
        'columnType': 0
      },
      {
        'columnName': 'Valor Infracción',
        'valueGetter': (item) => item.infraccion ? /*this.currencyPipe.transform(*/item.infraccion.valor/*, 'COP')*/ : '',
        'columnType': 0
      },
      {
        'columnName': 'Estado del Comparendo',
        'valueGetter': (item) => {

          if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoCreado) {

            return 'Creado';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoPorCertificar) {

            return 'Por certificar';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoCertificado) {

            return 'Certificado';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoEnviadoSIMIT) {

            return 'SIMIT';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoDescertificado) {

            return 'Descertificado';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoRechazado) {

            return 'Rechazado';
          }


        },
        'getClass': (item)  => {

          if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoCreado) {

            return 'align-middle text-default';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoPorCertificar) {

            return 'align-middle text-default';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoCertificado) {

            return 'align-middle text-success';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoEnviadoSIMIT) {

            return 'align-middle text-success';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoDescertificado) {

            return 'align-middle text-danger';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoRechazado) {

            return 'align-middle text-danger';
          }


        },
        'columnType': 0
      },
      {
        'columnName': 'Placa',
        'valueGetter': (item) => item.placa,
        'columnType': 0
      },
      {
        'columnName': 'Velocidad',
        'valueGetter': (item) => item.velocidad,
        'columnType': 0
      },
      {
        'columnName': 'Máquina',
        'valueGetter': (item) => typeof item.camara === 'object' &&
        item.camara !== null &&
        item.camara.nombre ? item.camara.nombre : item.camaraId,
        'columnType': 0
      },
      {
        'columnName': 'Modalidad transporte',
        'valueGetter': (item) => typeof item.modalidadTransporte === 'object' &&
        item.modalidadTransporte !== null &&
        item.modalidadTransporte.descripcion ? item.modalidadTransporte.descripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Modalidad transporte id',
        'valueGetter': (item) => typeof item.modalidadTransporte === 'object' &&
        item.modalidadTransporte !== null &&
        item.modalidadTransporte.id ? item.modalidadTransporte.id : '',
        'columnType': 0
      },
      {
        'columnName': 'Tipo vehículo',
        'valueGetter': (item) => typeof item.tipoVehiculo === 'object' &&
        item.tipoVehiculo !== null &&
        item.tipoVehiculo.descripcion ? item.tipoVehiculo.descripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Tipo vehículo id',
        'valueGetter': (item) => typeof item.tipoVehiculo === 'object' &&
        item.tipoVehiculo !== null &&
        item.tipoVehiculo.id ? item.tipoVehiculo.id : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombres usuario firmador',
        'valueGetter': (item) => item.usuario.usuarioFirmador && item.usuario.usuarioFirmador.nombre ?
          item.usuario.usuarioFirmador.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Placa usuario firmador',
        'valueGetter': (item) => item.usuario.usuarioFirmador  && item.usuario.usuarioFirmador.placa ?
          item.usuario.usuarioFirmador.placa : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombres usuario certificador',
        'valueGetter': (item) => item.usuarioCertificador ?
          `${item.usuarioCertificador.nombre} ${item.usuarioCertificador.apellido}` : '',
        'columnType': 0
      },
      {
        'columnName': 'Identificación usuario certificador',
        'valueGetter': (item) => item.usuarioCertificador ? item.usuarioCertificador.identificacion : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombres usuario generador pdf',
        'valueGetter': (item) => item.usuarioPorCertificar ?
          `${item.usuarioPorCertificar.nombre} ${item.usuarioPorCertificar.apellido}` : '',
        'columnType': 0
      },
      {
        'columnName': 'Identificación usuario generador pdf',
        'valueGetter': (item) => item.usuarioPorCertificar ? item.usuarioPorCertificar.identificacion : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombre Usuario',
        'valueGetter': (item) => item.usuario ? item.usuario.nombre + ' ' + item.usuario.apellido : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombres de Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Apellidos Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.apellido : '',
        'columnType': 0
      },
      {
        'columnName': 'Tipo Identificación Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.tipoIdenficacion : '',
        'columnType': 0
      },
      {
        'columnName': 'Identificación Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.idenficacion : '',
        'columnType': 0
      },
      {
        'columnName': 'Celular Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.celular : '',
        'columnType': 0
      },
      {
        'columnName': 'Ciudad Infractor',
        'valueGetter': (item) => item.infractor && typeof item.infractor.ciudadId === 'object' && item.infractor.ciudadId !== null ?
          item.infractor.ciudadId.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Ciudad Infractor id',
        'valueGetter': (item) => item.infractor ? typeof item.infractor.ciudadId === 'object' && item.infractor.ciudadId !== null ?
          item.infractor.ciudadId.id : item.infractor.ciudadId : '',
        'columnType': 0
      },
      {
        'columnName': 'Dirección Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.direccion : '',
        'columnType': 0
      },
      {
        'columnName': 'Edad Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.edad : '',
        'columnType': 0
      },
      {
        'columnName': 'Email Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.email : '',
        'columnType': 0
      },
      {
        'columnName': 'Telefono Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.telefono : '',
        'columnType': 0
      },
      {
        'columnName': 'Estados',
        'columnType': 1,
        'actions': [
          {
            'text': 'Ver Estados',
            'class': 'btn btn-outline-light btn-sm icon-btn',
            'iconClass': 'ion ion-md-eye icon-create-edit',
            'getActionParam': (item) => {
              return `comparendo.estados`;
            },
            'getQueryFunction': (item) => {
              console.log(item);
              return {
                comparendoId: item.id
              };
            }
          }
        ]
      }
    ];
    return columns;
  }

  // REPORTE COMPARENDOS PROCESO

  reporteProcesoData(req: { [key: string]: any }): Observable<{ [key: string]: any }> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.resourceUrl}/reporte_comparendos_realizados`, {params: options});
  }

  getReporteProcesoColumnDefinitions = (): ColumnDefinitions[] =>  {
    const columns: ColumnDefinitions[] = [
      {
        'columnName': 'Seleccionar',
        'headerCheckbox': true,
        'selection': 'id',
        'selectMany': true,
        'columnType': 2,
        'getStyle': () => ({'cursor': 'pointer'})
      },
      {
        'columnName': 'Id',
        'valueGetter': (item) => item.id,
        'columnType': 0
      },
      {
        'columnName': 'Rango Consecutivo',
        'valueGetter': (item) => item.numero ? `'${item.numero}` : '',
        'columnType': 0
      },
      {
        'columnName': 'Fecha Infracción',
        'valueGetter': (item) => item.fechaInfraccion ?
          this.datePipe.transform(item.fechaInfraccion, this.dateFormat) : '',
        'columnType': 0
      },
      {
        'columnName': 'Fecha Creación',
        'valueGetter': (item) => item.fechaCreacion ?
          this.datePipe.transform(item.fechaCreacion, this.dateFormat) : '',
        'columnType': 0
      },
      {
        'columnName': 'Camara',
        'valueGetter': (item) => typeof item.camara === 'object' &&
        item.camara !== null && item.camara.nombre ? item.camara.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Camara id',
        'valueGetter': (item) => typeof item.camaraId ? item.camaraId : '',
        'columnType': 0
      },
      {
        'columnName': 'Ciudad',
        'valueGetter': (item) => typeof item.camara === 'object' &&
        item.camara !== null &&
        item.camara.ciudad ? item.camara.ciudad.nombre : item.camaraId,
        'columnType': 0
      },
      {
        'columnName': 'Ciudad id',
        'valueGetter': (item) => typeof item.camara === 'object' &&
        item.camara !== null &&
        item.camara.ciudad ? item.camara.ciudad.id : '',
        'columnType': 0
      },
      {
        'columnName': 'Clase Servicio',
        'valueGetter': (item) => typeof item.claseServicio === 'object' &&
        item.claseServicio !== null &&
        item.claseServicio.descripcion ? item.claseServicio.descripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Clase Servicio id',
        'valueGetter': (item) => typeof item.claseServicio === 'object' &&
        item.claseServicio !== null &&
        item.claseServicio.id ? item.claseServicio.id : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombre Usuario',
        'valueGetter': (item) => item.usuario ? item.usuario.nombre + ' ' + item.usuario.apellido : '',
        'columnType': 0
      },
      {
        'columnName': 'Infraccion',
        'valueGetter': (item) => item.infraccion ? item.infraccion.descripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Infraccion código',
        'valueGetter': (item) => item.infraccion ? item.infraccion.codigo : '',
        'columnType': 0
      },
      {
        'columnName': 'Valor Infracción',
        'valueGetter': (item) => item.infraccion ? /*this.currencyPipe.transform(*/item.infraccion.valor/*, 'COP')*/ : '',
        'columnType': 0
      },
      {
        'columnName': 'Estado del Comparendo',
        'valueGetter': (item) => {

          if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoCreado) {

            return 'Creado';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoPorCertificar) {

            return 'Por certificar';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoCertificado) {

            return 'Certificado';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoEnviadoSIMIT) {

            return 'SIMIT';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoDescertificado) {

            return 'Descertificado';
          } else if (item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoRechazado) {

            return 'Rechazado';
          }


        }, /*item.confirmada === true ? 'Comparendo certificado' :
          item.confirmada === null ? 'Comparendo por certificar' : 'Comparendo rechazado',*/
        'getClass': (item) => item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoCertificado ? 'align-middle text-success' :
          item.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoRechazado ?
            'align-middle text-danger' : 'align-middle text-secondary',
        'columnType': 0
      },
      {
        'columnName': 'Motivo de Rechazo',
        'valueGetter': (item) => item.observacionDescripcion ? item.observacionDescripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Placa',
        'valueGetter': (item) => item.placa,
        'columnType': 0
      },
      {
        'columnName': 'Velocidad',
        'valueGetter': (item) => item.velocidad,
        'columnType': 0
      },
      {
        'columnName': 'Máquina',
        'valueGetter': (item) => typeof item.camara === 'object' &&
        item.camara !== null && item.camara.nombre ? item.camara.nombre : item.camaraId,
        'columnType': 0
      },
      {
        'columnName': 'Modalidad transporte',
        'valueGetter': (item) => typeof item.modalidadTransporte === 'object' &&
        item.modalidadTransporte !== null &&
        item.modalidadTransporte.descripcion ? item.modalidadTransporte.descripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Modalidad transporte id',
        'valueGetter': (item) => typeof item.modalidadTransporte === 'object' &&
        item.modalidadTransporte !== null &&
        item.modalidadTransporte.id ? item.modalidadTransporte.id : '',
        'columnType': 0
      },
      {
        'columnName': 'Tipo vehículo',
        'valueGetter': (item) => typeof item.tipoVehiculo === 'object' &&
        item.tipoVehiculo !== null &&
        item.tipoVehiculo.descripcion ? item.tipoVehiculo.descripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Tipo vehículo id',
        'valueGetter': (item) => typeof item.tipoVehiculo === 'object' &&
        item.tipoVehiculo !== null &&
        item.tipoVehiculo.id ? item.tipoVehiculo.id : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombres usuario firmador',
        'valueGetter': (item) => item.usuario.usuarioFirmador && item.usuario.usuarioFirmador.nombre ?
          item.usuario.usuarioFirmador.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Placa usuario firmador',
        'valueGetter': (item) => item.usuario.usuarioFirmador && item.usuario.usuarioFirmador.placa ?
          item.usuario.usuarioFirmador.placa : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombres usuario certificador',
        'valueGetter': (item) => item.usuarioCertificador ?
          `${item.usuarioCertificador.nombre} ${item.usuarioCertificador.apellido}` : '',
        'columnType': 0
      },
      {
        'columnName': 'Identificación usuario certificador',
        'valueGetter': (item) => item.usuarioCertificador ? item.usuarioCertificador.identificacion : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombres usuario generador pdf',
        'valueGetter': (item) => item.usuarioPorCertificar ?
          `${item.usuarioPorCertificar.nombre} ${item.usuarioPorCertificar.apellido}` : '',
        'columnType': 0
      },
      {
        'columnName': 'Identificación usuario generador pdf',
        'valueGetter': (item) => item.usuarioPorCertificar ? item.usuarioPorCertificar.identificacion : '',
        'columnType': 0
      },
      {
        'columnName': 'Nombres de Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Apellidos Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.apellido : '',
        'columnType': 0
      },
      {
        'columnName': 'Tipo Identificación Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.tipoIdenficacion : '',
        'columnType': 0
      },
      {
        'columnName': 'Identificación Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.idenficacion : '',
        'columnType': 0
      },
      {
        'columnName': 'Celular Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.celular : '',
        'columnType': 0
      },
      {
        'columnName': 'Ciudad Infractor',
        'valueGetter': (item) => item.infractor && typeof item.infractor.ciudadId === 'object' && item.infractor.ciudadId !== null ?
          item.infractor.ciudadId.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Ciudad Infractor id',
        'valueGetter': (item) => item.infractor ? typeof item.infractor.ciudadId === 'object' && item.infractor.ciudadId !== null ?
          item.infractor.ciudadId.id : item.infractor.ciudadId : '',
        'columnType': 0
      },
      {
        'columnName': 'Dirección Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.direccion : '',
        'columnType': 0
      },
      {
        'columnName': 'Edad Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.edad : '',
        'columnType': 0
      },
      {
        'columnName': 'Email Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.email : '',
        'columnType': 0
      },
      {
        'columnName': 'Telefono Infractor',
        'valueGetter': (item) => item.infractor ? item.infractor.telefono : '',
        'columnType': 0
      },
      {
        'columnName': 'Estados',
        'columnType': 1,
        'actions': [
          {
            'text': 'Ver Estados',
            'class': 'btn btn-outline-light btn-sm icon-btn',
            'iconClass': 'ion ion-md-eye icon-create-edit',
            'getActionParam': (item) => {
              return `comparendo.estados`;
            },
            'getQueryFunction': (item) => {
              console.log(item);
              return {
                comparendoId: item.id
              };
            }
          }
        ]
      }
    ];
    return columns;
  }

  getReporteProcesoDataChart(cantidad: number): any {
    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    chart.data.datasets[0].label = 'Evidencias rechazadas';
    chart.options.title.text = 'Evidencias rechazadas';
    chart.data.datasets[0].data.push(cantidad);
    chart.data.datasets[0].backgroundColor.push(this.colors[1]);
    chart.data.labels.push('Comparendos Rechazados');

    console.log('CHART ', chart);
    return chart;
  }

  getReporteEstadosDataChart(estados): any {
    console.log('LOS NUEVOS ESTADOS ', estados);
    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    chart.options.title.text = 'Comparendos creados';
    Object.keys(estados).forEach((key: string, index: number) => {
      // if (true) {
      const splitedKey: string[] = key.split('___');
      chart.data.datasets[0].data.push(estados[key]);
      chart.data.datasets[0].backgroundColor.push(this.colors[index]);
      chart.data.labels.push(splitedKey && splitedKey.length === 2 ? splitedKey[1] : key);
      /*} else if (key === 'rechazados') {
        chart.data.datasets[0].data.push(estados[key]);
        chart.data.datasets[0].backgroundColor.push(this.colors[1]);
        chart.data.labels.push('Comparendos Rechazados');
      } else {
        chart.data.datasets[0].data.push(estados[key]);
        chart.data.datasets[0].backgroundColor.push(this.colors[2]);
        chart.data.labels.push('Comparendos sin certificar');
      }*/
    });

    console.log('CHART ', chart);
    return chart;
  }

  //
  reporteEstadosComparendos(query?: { [key: string]: any }): Observable<any> {
    const options = createRequestOption(query);
    return this.http.get<any>(`${this.resourceUrl}/reporte_estados_comparendos`, { params: options });
  }

  getReporteComparendoActions(): { actions: {
    text: string;
    action: (_context?: any) => void;
  }[], disabledFunction?: (_context?: any) => boolean; } {
    return {
      actions: [{
        text: 'Descargar evidencias',
        action: (_context) => {
          const modalRef = this.modalService.open(LoadingModalComponent, { backdrop: 'static' });
          (<LoadingModalComponent>modalRef.componentInstance).title = 'Cargando';
          (<LoadingModalComponent>modalRef.componentInstance).body = 'Generando archivo zip';
          (<LoadingModalComponent>modalRef.componentInstance).closeMessage = 'Cancelar descarga';
          (<LoadingModalComponent>modalRef.componentInstance).unSubscribe.subscribe(() => {
            _context.customVars = undefined;
          });

          if (_context.selectedItems && _context.selectedItems.length > 0) {
            _context.customVars = { downloading: true };
            const subscription = this.uploadFileService.descargarEvidenciasDeComparendos(_context.selectedItems)
              .subscribe((response) => {
                _context.customVars = { downloading: false };
                console.log(response);
                const link = document.createElement('a');
                link.href = response.url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                modalRef.close();
              }, (error) => {
                _context.customVars = { downloading: false };
                console.log('ERROR DESCARGA DE EVIDENCIAS ', error);
                modalRef.close();
            });
            (<LoadingModalComponent>modalRef.componentInstance).subscription = subscription;
          }
        },
      },
      {
        text: 'Descargar comparendos',
        action: (_context) => {
          const modalRef = this.modalService.open(LoadingModalComponent, { backdrop: 'static' });
          (<LoadingModalComponent>modalRef.componentInstance).title = 'Cargando';
          (<LoadingModalComponent>modalRef.componentInstance).body = 'Generando archivo zip';
          (<LoadingModalComponent>modalRef.componentInstance).closeMessage = 'Cancelar descarga';
          (<LoadingModalComponent>modalRef.componentInstance).unSubscribe.subscribe(() => {
            _context.customVars = undefined;
          });

          if (_context.selectedItems && _context.selectedItems.length > 0) {
            _context.customVars = { downloading: true };
            const subscription = this.uploadFileService.descargarPdfsDeComparendos(_context.selectedItems)
              .subscribe((response) => {
                _context.customVars = { downloading: false };
                console.log(response);
                const link = document.createElement('a');
                link.href = response.url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                modalRef.close();
              }, (error) => {
              _context.customVars = { downloading: false };
              console.log('ERROR DESCARGA DE COMPARENDOS ', error);
              modalRef.close();
            });
            (<LoadingModalComponent>modalRef.componentInstance).subscription = subscription;
          }
        },
      }],
      disabledFunction: (_context) => {
        return !(_context.selectedItems && _context.selectedItems.length > 0) ||
        (_context.customVars !== undefined && _context.customVars.downloading === true);
      }
    };
  }


}
