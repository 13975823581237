import {Component, OnInit} from '@angular/core';
import {AppService} from '@app/app.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(private appService: AppService,
  ) {
    this.appService.pageTitle = 'Spring Boot Admin';

  }

  ngOnInit() {
  }

}
