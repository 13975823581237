import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ObservacionService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';


@Component({
  selector: 'app-new-edit-observacion',
  templateUrl: './new-edit-observacion.component.html',
  styleUrls: ['./new-edit-observacion.component.scss']
})
export class NewEditObservacionComponent implements OnInit {

  @Input() private observacion: any;
  private observacionForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    private formBuilder: FormBuilder,
    public observacionService: ObservacionService,
  ) {
  }

   ngOnInit() {

    this.initForms();
    if (this.observacion) {
      this.patchValues(this.observacion);
    }

  }

  private initForms() {

    this.observacionForm = this.formBuilder.group({
      id: null,
      fechaCreacion: '',
      fechaActualizacion: '',
      eliminado: false,
      descripcion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
    });

  }

  private patchValues(observacion: Object) {
    this.observacionForm.patchValue(observacion);
  }

  public async modalclose() {
    if (this.observacionForm.valid) {
      const itemF = await this.createOrUpdateItem(this.observacionForm.value, this.observacion ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(observacion: any, exits: any): Promise<any> {
    if (!exits) {
      return this.observacionService.create(observacion).toPromise();
    }
    if (exits) {
      return this.observacionService.update(observacion).toPromise();
    }
  }

}


