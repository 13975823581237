import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public resourceUrl = environment.apiUrl + 'user/usuarios';

  constructor(public http: HttpClient) {
  }

  create(usuario: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, usuario);
  }

  update(usuario: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, usuario);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  findCertificadores(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any>(`${this.resourceUrl}/findCertificadores`, {params: options});
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, {params: options});
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

  resetPassword(email: any): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl + '/resetPassword'}/${email}`, {});
  }

  changePassword(token: string, password: string): Observable<any> {
    // const options = createRequestOption({ token: token, password: password });
    return this.http.post<any>(this.resourceUrl + '/changePassword', {}, {params: {token: token, password: password}});
  }

  findByFullName(name: string, limit: number, isAgente: boolean) {
    if (name === '') {
      return of([]);
    }

    return this.http.get<any[]>(`${this.resourceUrl}/find_by_name?name=${name}&limit=${limit}&isAgente=${isAgente}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
}
