import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CustomToastrService } from '@app/commons/providers/custom-toastr.service';

// Own
// Types
import { ColumnDefinitions } from '@app/commons/interfaces/types/column-definitions';
// Providers
import { ConsultasExternasReportesService } from '@app/providers/consultas-externas-reportes.service';
// Utils
import { makeDateInstance, isValidDate, jsonToTabla, getValueFromStringFragmentKeys, getParsedJson } from '@app/commons/utils/general';
// Constants
import { SHOW_ENTRIES_OPTIONS } from '@app/commons/constants/app';
import { RESPUESTA_RUNT_EXCEL_CONFIGURATION } from '@app/commons/constants/app';
import { WebStorageCustomService } from '@app/commons/providers/web-custom-storage.service';

declare var $;

@Component({
  selector: 'app-consultas-externas-log',
  templateUrl: './consultas-externas-log.component.html',
  styleUrls: ['./consultas-externas-log.component.scss']
})
export class ConsultasExternasLogComponent implements OnInit {
  @Output() goBackEmitter = new EventEmitter<any>();
  @Input() queryInput: any;
  @Input() tipoConsulta: any;
  desdeFechaInput: any;
  hastaFechaInput: any;
  page = 1;
  itemsPerPage = 5;
  totalItems: number = null;
  data: any[];

  getParsedJson = getParsedJson;

  totalItemsNoFiltered: number;

  isGenerating = false;
  success = null;
  listedAll = false;
  predicate: any;
  reverse: any;
  permisos: Array<any>;

  dateFormat = 'yyyy/MM/ddThh:mm:ss';

  public showEntriesOptions: number[] = SHOW_ENTRIES_OPTIONS;

  // Filters controls
  public desdeFechaControl: FormControl;
  public desdeFechaSelected: any;
  public hastaFechaSelected: any;
  public hastaFechaControl: FormControl;

  // Data export formats
  public exportFormats: { text: string; value: string; iconClass: string; tableId: string; fileName: string; }[] = [{
    text: 'Excel detalle',
    value: 'xlsx',
    tableId: 'table-consultas-externas-log',
    iconClass: 'fas fa-file-excel text-success',
    fileName: 'detalle_consulta'
  }, {
    text: 'Excel respuesta RUNT',
    value: 'xlsx',
    tableId: 'table-respuesta-runt',
    iconClass: 'fas fa-file-excel text-success',
    fileName: 'respuesta_runt'
  }];

  downloadTableColumnDefinitions: ColumnDefinitions[];

  // Column definition
  columnDefinitions: ColumnDefinitions[] = [
    {
      'columnName': 'Microservicio',
      'valueGetter': (item) => item.microservicio ? item.microservicio : '',
      'columnType': 0
    },
    {
      'columnName': 'Web Service',
      'valueGetter': (item) => item.webService ? item.webService : '',
      'columnType': 0
    },
    {
      'columnName': 'Tipo de llamado',
      'valueGetter': (item) => item.manual !== null ? item.manual ? 'Manual' : 'Automático' : '',
      'columnType': 0
    },
    {
      'columnName': 'Fecha',
      'valueGetter': (item) => item.fechaCreacion ? this.datePipe.transform(item.fechaCreacion, this.dateFormat) : '',
      'columnType': 0
    },
    {
      'columnName': 'Estado',
      'valueGetter': (item) => item.error !== null ? item.error ? 'Fallida' : 'Exitosa' : '',
      'getClass': (item) => {
        return item.error !== null ? item.error ? 'text-danger' : 'text-success' : '';
      },
      'columnType': 0
    },
    {
      'columnName': 'Detalles',
      'getClass': () => {
        return 'text-center text-info';
      },
      'getStyle': (item) => {
        if (item.response || item.query || item.status) {
          return {
            cursor: 'pointer'
          };
        } else {
          return {};
        }
      },
      'valueGetter': (item) => item.response || item.query || item.status ? 'Ver detalles' : '',
      'functionExecution': (params) => {
        this.presentQueryModal(params);
      },
      'columnType': 0
    }
  ];
  currentItem: any;
  public totalPages: number;

  public respuestaRuntColumnas: any[];
  public respuestaRuntItems: any[] = [];

  // Para calcular cantidad maxima de filas por arreglo
  public referenceData: any[];

  public getRespuestaRUNTValue = getValueFromStringFragmentKeys;

  constructor(private consultasExternasReportesService: ConsultasExternasReportesService,
              private exportAsService: ExportAsService,
              public webStorageCustomService: WebStorageCustomService,
              private customToastrService: CustomToastrService,
              private datePipe: DatePipe) {
    this.predicate = 'id';
  }

  private obtenerRespuestaJsonReferencia() {
    this.respuestaRuntColumnas =
      jsonToTabla(RESPUESTA_RUNT_EXCEL_CONFIGURATION.jsonRespuestaRUNTReferencia, null, RESPUESTA_RUNT_EXCEL_CONFIGURATION.maxArrayItems);
    console.log('COLUMNAS RUNT ', this.respuestaRuntColumnas);
    // try {
    //   console.log('<<<<<>>>>>');
    //   this.referenceData = [];
    //   data.forEach((element) => {
    //     if (element.response && element.response[0] === '{' && element.response[element.response.length - 1] === '}') {
    //       this.referenceData.push(this.getParsedJson(element.response));
    //     }
    //   });
    //   console.log('referenceData ', this.referenceData);

    //   data.forEach((element) => {
    //     if (element.response) {
    //       const respuestaJsonReferencia = this.getParsedJson(element.response);
    //       let respuestaRuntColumnas;
    //       if (respuestaJsonReferencia) {
    //         respuestaRuntColumnas = jsonToTabla(respuestaJsonReferencia, null, this.referenceData);
    //       }
    //       console.log('numeroColumnas ', respuestaRuntColumnas.length);
    //       if (!this.respuestaRuntColumnas && !this.respuestaJsonReferencia) {

    //         if (respuestaJsonReferencia) {
    //           this.respuestaJsonReferencia = respuestaJsonReferencia;
    //         }

    //         if (respuestaRuntColumnas) {
    //           this.respuestaRuntColumnas = respuestaRuntColumnas;
    //         }
    //         console.log('this.respuestaJsonReferencia', this.respuestaJsonReferencia);
    //         console.log('this.respuestaRuntColumnas', this.respuestaRuntColumnas);
    //       } else {
    //         if (respuestaRuntColumnas.length > this.respuestaRuntColumnas.length) {
    //           if (respuestaJsonReferencia) {
    //             this.respuestaJsonReferencia = respuestaJsonReferencia;
    //           }

    //           if (respuestaRuntColumnas) {
    //             this.respuestaRuntColumnas = respuestaRuntColumnas;
    //           }
    //           // this.respuestaJsonReferencia = respuestaJsonReferencia;
    //           // this.respuestaRuntColumnas = respuestaRuntColumnas;
    //           console.log('this.respuestaJsonReferencia', this.respuestaJsonReferencia);
    //           console.log('this.respuestaRuntColumnas', this.respuestaRuntColumnas);
    //         }
    //       }
    //       console.log('ITEMSSS ', this.respuestaRuntColumnas);
    //     }
    //   });
    // } catch (error) {
    //   console.log('ERROR TABLA RUNT ', error);
    // }
  }

  public selectedOptionChanged(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.page = 1;
    this.onPaginated();
  }

  public goBack() {
    this.goBackEmitter.emit();
  }

  private presentQueryModal(params) {
    if (params.query || params.response || params.statusCode) {
      this.currentItem = params;
      console.log('currentItem', this.currentItem);
      $('.bd-example-modal-lg').modal('toggle');
    }
  }

  ngOnInit() {
    this.obtenerRespuestaJsonReferencia();

    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.buildDownloadTableColumnDefinitions();
  }

  initTable() {
    this.initControls();
    this.query(this.page, this.itemsPerPage, false);
  }

  query(page: number, itemsPerPage: number, listedAll?: boolean) {
    if (!this.listedAll) {
      console.log('NOT LISTED ALL ', page, itemsPerPage);
      this.queryFunction(this.buildQuery(page, itemsPerPage), listedAll);
    }
  }

  private buildQuery(page?: number, itemsPerPage?: number): any {
    const query = {
      tipoConsulta: this.tipoConsulta,
      sort: this.sort()
    };
    if (page !== null && page !== NaN && page !== undefined
      && itemsPerPage !== null && itemsPerPage !== undefined && itemsPerPage !== NaN) {
      query['page'] = (page - 1),
        query['size'] = itemsPerPage;
    }
    if (this.queryInput) {
      Object.keys(this.queryInput).forEach((key: string) => {
        query[key] = this.queryInput[key];
      });
    }
    if (this.desdeFechaSelected) {
      query['fechaInicio'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFin'] = this.hastaFechaSelected;
    }
    // console.log('ENTRY PAGE: ', page, ' SIZE ', itemsPerPage, ' QUERY BUILDED ', query);
    return query;
  }

  private queryFunction(query: any, listedAll?: boolean) {
    if (!this.isGenerating) {
      this.isGenerating = true;
      this.success = null;

      this.respuestaRuntItems = [];

      this.consultasExternasReportesService.query(query)
        .subscribe((response) => {
          this.data = response.content;
          this.totalItems = response.totalElements;
          this.totalPages = response.totalPages;
          this.isGenerating = false;
          this.success = true;
          if (listedAll !== null) {
            this.listedAll = listedAll;
          }

          // Obtener datos de respuesta de RUNT
          this.respuestaRuntItems = [];
          this.data.forEach((element) => {
            if (element.response) {
              if (element.response[0] === '{' && element.response[element.response.length - 1] === '}') {
                this.respuestaRuntItems.push(this.getParsedJson(element.response));
              }
            }
          });
          console.log('DATOS DE RESPUESTA RUNT ', this.respuestaRuntItems);

          // if (!this.listedAll) {
          //   // Obtener referencia json respuesta RUNT
          //   // console.log('SE LLAMA obtenerRespuestaJsonReferencia');
          //   this.obtenerRespuestaJsonReferencia(response.content);
          //   this.respuestaRuntItems = [];
          //   this.data.forEach((element) => {
          //     if (element.response) {
          //       if (element.response[0] === '{' && element.response[element.response.length - 1] === '}') {
          //         this.respuestaRuntItems.push(this.getParsedJson(element.response));
          //       }
          //     }
          //   });

          //   console.log('REFERENCIA END', this.respuestaJsonReferencia);
          //   console.log('DATA END', this.respuestaRuntItems);
          // }

          // if (listedAll) {
          //   console.log('***. LISTAR TODO');
          //   let referencia = null;
          //   this.data.forEach((element) => {
          //     if (element.response) {
          //       if (element.response[0] === '{' && element.response[element.response.length - 1] === '}') {
          //         let tempReference = this.getParsedJson(element.response);
          //         if (!referencia) {
          //           referencia = tempReference;
          //         } else {
          //           if (Object.keys(tempReference).length > Object.keys(referencia).length) {
          //             referencia = tempReference;
          //           }
          //         }
          //       }
          //     }
          //   });
          //   console.log('**. REFERENCIA ', JSON.stringify(referencia));
          // }

        }, (error) => {
          console.log('Error al consultar detalles > ', error);
          this.isGenerating = false;
          this.success = false;
          this.customToastrService.showToast('error', '', 'Error al consultar detalles');
        });
    }
  }

  sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
  }

  sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    return result;
  }

  public export(tipo: any, tableId: string, fileName: string) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo ? tipo : 'xlsx',
      elementId: tableId,
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, fileName);
  }

  public onPaginated() {
    if (!this.listedAll) {
      this.query(this.page, this.itemsPerPage, false);
    }
  }

  public async listAll() {
    let error = null;
    await this.countItems(this.buildQuery())
      .then((response) => {
        // console.log('COUNT ', response);
        this.totalItems = response;
      })
      .catch((_error) => {
        error = _error;
        // console.log('ERROR COUNT ', error);
      });
    if (!error) {
      this.query(1, this.totalItems, true);
    } else {
      this.customToastrService.showToast('error', '', 'Error al consultar detalles');
    }
  }

  public listPaginated() {
    this.page = 1;
    this.listedAll = false;
    this.query(this.page, this.itemsPerPage, false);
  }

  private async aplicarFiltros() {
    if (this.listedAll) {
      let error = null;
      await this.countItems(this.buildQuery())
        .then((response) => {
          // console.log('COUNT ', response);
          this.totalItems = response;
        })
        .catch((_error) => {
          error = _error;
          // console.log('ERROR COUNT ', error);
        });
      if (!error) {
        this.queryFunction(this.buildQuery(1, this.totalItems), true);
      } else {
        this.customToastrService.showToast('error', '', 'Error al consultar detalles');
      }
    } else {
      this.page = 1;
      this.query(this.page, this.itemsPerPage, false);
    }
  }

  private initControls() {
    this.desdeFechaControl = new FormControl();
    if (this.desdeFechaInput !== null &&
      this.desdeFechaInput !== undefined &&
      typeof this.desdeFechaInput === 'object') {
      this.desdeFechaControl.setValue(this.desdeFechaInput, {emitEvent: false});
      this.desdeFechaSelected = this.datePipe.transform(makeDateInstance(this.desdeFechaInput).toDateString(), 'yyyy-MM-dd');
    }
    this.desdeFechaControl.valueChanges
      .subscribe((val) => {
        if (val) {
          this.desdeFechaSelected = null;
          if (typeof val === 'object') {
            this.desdeFechaSelected = this.datePipe.transform(makeDateInstance(val).toDateString(), 'yyyy-MM-dd');
          } else if (typeof val === 'string' && isValidDate(val)) {
            this.desdeFechaSelected = val;
          }
          this.aplicarFiltros();
        }
      });
    this.hastaFechaControl = new FormControl();
    if (this.hastaFechaInput !== null &&
      this.hastaFechaInput !== undefined &&
      typeof this.hastaFechaInput === 'object') {
      this.hastaFechaControl.setValue(this.hastaFechaInput, {emitEvent: false});
      this.hastaFechaSelected = this.datePipe.transform(makeDateInstance(this.hastaFechaInput).toDateString(), 'yyyy-MM-dd');
    }
    this.hastaFechaControl.valueChanges
      .subscribe((val) => {
        if (val) {
          this.hastaFechaSelected = null;
          if (typeof val === 'object') {
            this.hastaFechaSelected = this.datePipe.transform(makeDateInstance(val).toDateString(), 'yyyy-MM-dd');
          } else if (typeof val === 'string' && isValidDate(val)) {
            this.hastaFechaSelected = val;
          }
          this.aplicarFiltros();
        }
      });
  }

  ejecutarFuncion(column: ColumnDefinitions, item: any) {
    if (column.functionExecution) {
      column.functionExecution(item);
    }
  }

  private buildDownloadTableColumnDefinitions() {
    this.downloadTableColumnDefinitions = [];
    this.columnDefinitions.forEach((column: ColumnDefinitions) => {
      if (column.columnName !== 'Detalles') {
        this.downloadTableColumnDefinitions.push(Object.assign({}, column));
      }
    });
    this.downloadTableColumnDefinitions = this.downloadTableColumnDefinitions.concat([
      {
        'columnName': 'Código de Status',
        'valueGetter': (item) => item.statusCode ? item.statusCode : '',
        'columnType': 0
      },
      {
        'columnName': 'Parámetros',
        'valueGetter': (item) => item.query ? item.query : '',
        'columnType': 0
      },
      {
        'columnName': 'Respuesta',
        'valueGetter': (item) => item.response ? item.response : '',
        'columnType': 0
      }
    ]);
  }

  private countItems(query): Promise<any> {
    return new Promise((resolve, reject) => {
      this.consultasExternasReportesService.queryCount(query)
        .subscribe((res) => {
          resolve(res);
        }, (error) => {
          reject(error);
          console.log('Error');
        });
    });
  }

}
