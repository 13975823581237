import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalidadTransporteService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';

@Component({
  selector: 'app-new-edit-modalidades-transporte',
  templateUrl: './new-edit-modalidades-transporte.component.html',
  styleUrls: ['./new-edit-modalidades-transporte.component.scss']
})

export class NewEditModalidadesTransporteComponent implements OnInit {

  @Input() private modalidadTransporte: any;
  private modalidadTransporteForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    private formBuilder: FormBuilder,
    public modalidadTransporteService: ModalidadTransporteService,
  ) {
  }

   ngOnInit() {

    this.initForms();
    if (this.modalidadTransporte) {
      this.patchValues(this.modalidadTransporte);
    }

  }

  private initForms() {

    this.modalidadTransporteForm = this.formBuilder.group({
      id: null,
      fechaCreacion: null,
      fechaActualizacion: null,
      eliminado: false,
      descripcion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      idSimitMovilixa: [null, [Validators.required, Validators.minLength(1)]],
    });

  }

  private patchValues(modalidadTransporte: Object) {
    this.modalidadTransporteForm.patchValue(modalidadTransporte);
  }

  public async modalclose() {
    if (this.modalidadTransporteForm.valid) {
      const itemF = await this.createOrUpdateItem(this.modalidadTransporteForm.value, this.modalidadTransporte ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(modalidadTransporte: any, exits: any): Promise<any> {
    if (!exits) {
      return this.modalidadTransporteService.create(modalidadTransporte).toPromise();
    }
    if (exits) {
      return this.modalidadTransporteService.update(modalidadTransporte).toPromise();
    }
  }

}

