import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-diff-modal',
  templateUrl: './diff-modal.component.html',
  styleUrls: ['./diff-modal.component.scss']
})
export class DiffModalComponent implements OnInit {

  @Input() entity: any;
  new = '';
  old = '';

  constructor(private modal: NgbActiveModal) {
  }

  ngOnInit() {
    this.new = this.entity.valorNuevo;
    this.old = this.entity.valorOriginal;
  }

}
