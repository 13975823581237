export const getDatesListBetweenRange = (start: Date, end: Date): Date[] => {
    (Date as any).prototype.addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    let dateArray = new Array();
    let currentDate = start;
    while (currentDate <= end) {
        dateArray.push(new Date(currentDate));
        currentDate = (currentDate as any).addDays(1);
    }
    return dateArray;
};

export const makeDateInstance = ({ year, month, day }): Date => {
    month--;
    const fecha = new Date(year, month, day);
    return fecha;
};

export const isValidDate = (dateString: string): boolean => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) { return false; }
    const d = new Date(dateString);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) { return false; }
    return d.toISOString().slice(0, 10) === dateString;
};

export const isArray = (object): boolean => {
    return Object.prototype.toString.call(object) === '[object Array]';
};

export const getValueFromStringFragmentKeys = (key: string, item: any): any => {
    const splitedKey: string[] = key.split('.');
    let value;
    if (splitedKey.length === 1) {
      value = item[splitedKey[0]] ? item[splitedKey[0]] : null;
    } else {
      splitedKey.forEach((fragmento: string) => {
        if (value === undefined) {
          value = item[fragmento] ? item[fragmento] : null;
        } else {
          value = value !== null ? value[fragmento] ? value[fragmento] : null : null;
        }
      });
    }
    return value;
};

export const jsonToTabla = (json: any, parentKey: string, cantArrayItems?: number) => {
    let columns = [];
    console.log(json);
    Object.keys(json)
    .forEach((key) => {
        if (typeof json[key] === 'object' && json[key] !== null && !isArray(json[key])) {
            columns = columns.concat(jsonToTabla(json[key], key));
        } else {
            if (!isArray(json[key])) {
                columns.push({ key: parentKey ? (parentKey + '.' + key) : key, type: 'key' });
            } else {
                console.log('getArrayValues ITEM ', json, ' KEY ', parentKey ? (parentKey + '.' + key) : key);
                const arrayValues = getValueFromStringFragmentKeys(parentKey ? (parentKey + '.' + key) : key, json);
                console.log('arrayValues', arrayValues);
                if (arrayValues && arrayValues.length > 0) {
                    const referenceItem = arrayValues[0];

                    /**
                     * obtener cantidad maxima de items en arreglo
                    */
                   let cantColumnasItems = cantArrayItems ? cantArrayItems : 2;

                   console.log('cantidadMaxima ', cantColumnasItems);

                    for (let index = 0; index < cantColumnasItems; index++) {
                        columns = columns.concat(jsonToTabla(referenceItem,
                        parentKey ? (parentKey + '.' + index + '.' + key) : key + '.' + index));
                    }
                    // columns.push({ key: parentKey ? (parentKey + '.' + key) : key, type: /*'array'*/ 'key' });
                }
            }
        }
    });
    return columns;
};

export const getParsedJson: (jsonString: string) => { [key: string]: any } = (jsonString: string): { [key: string]: any } => {
    if (jsonString) {
        jsonString = jsonString.replace(/\\n/g, '\\n')
            .replace(/\\'/g, '\\\'')
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, '\\&')
            .replace(/\\r/g, '\\r')
            .replace(/\\t/g, '\\t')
            .replace(/\\b/g, '\\b')
            .replace(/\\f/g, '\\f');
        // remove non-printable and other non-valid JSON chars
        jsonString = jsonString.replace(/[\u0000-\u0019]+/g, '');
        try {
            return JSON.parse(jsonString);
        } catch (error) {
            console.log('Error parsing jsonString');
            return null;
        }
    } else {
        console.log('jsonString is not present or is null');
        return null;
    }
};
