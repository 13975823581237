import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ParametrizacionService} from '../../../../providers';

@Component({
  selector: 'app-edit-parametrizacion',
  templateUrl: './edit-parametrizacion.component.html',
  styleUrls: ['./edit-parametrizacion.component.scss']
})
export class EditParametrizacionComponent implements OnInit {

  @Input() private parametrizacion: any;
  private ciudades: Array<any>;
  private parametrizacionForm: FormGroup;

  constructor(
    private modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private parametrizacionService: ParametrizacionService,
    private toastService: CustomToastrService,
  ) {
  }

  async ngOnInit() {
    this.initForms();
    if (this.parametrizacion) {
      this.parametrizacionForm.patchValue(this.parametrizacion);
    }
  }


  private initForms() {
    this.parametrizacionForm = this.formBuilder.group({
      id: null,
      fechaActualizacion: null,
      fechaCreacion: null,
      descripcion: ['', Validators.minLength(4)],
      valor: ['', Validators.minLength(1)]
    });
  }

  private async modalclose() {
    if (this.parametrizacionForm.valid) {
      await this.UpdateItem(this.parametrizacionForm.value);
      this.toastService.showToast('success', 'Se ha guardado con exito', 'Parametrizacion del sistema');
      this.modal.close({}); // itemF
    }
  }

  private async UpdateItem(parametrizacion: any): Promise<any> {
    return this.parametrizacionService.update(parametrizacion).toPromise();
  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }

}
