import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppService} from '../../app.service';
import {LayoutService} from '../layout.service';
import {AuthService, CategoriaService, ModulosService, RolModuloService} from '../../providers';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-layout-sidenav',
  templateUrl: './layout-sidenav.component.html',
  styles: [':host { display: block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LayoutSidenavComponent implements OnInit, AfterViewInit {
  @Input() orientation = 'vertical';

  @HostBinding('class.layout-sidenav') public hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') public hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') public hostClassFlex = false;

  public routes: Array<any>;
  public changeDetectorEnable: boolean;
  public categorias: Array<any>;
  usuarioId = null;

  constructor(
    public appService: AppService,
    public layoutService: LayoutService,
    public authService: AuthService,
    public router: Router,
    public rolModuloService: RolModuloService,
    public categoriaService: CategoriaService,
    public modulosService: ModulosService,
    public webStorageCustomService: WebStorageCustomService,
    public changeDetetor: ChangeDetectorRef
  ) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;
    this.usuarioId = JSON.parse(this.webStorageCustomService.getFromLocal('usuario')).id;

  }

  ngOnInit() {
    this.routes = [];
    this.categorias = [];
  }

  /* ngAfterViewInit  && this.orientation !== 'horizontal'*/

  ngAfterViewInit() {
    // Safari bugfix
    this.categoriaService.categoriaByRol(this.usuarioId).subscribe((res: any) => {

      // Categorias
      this.categorias = res;
      // Segundo llamado de submodulos
      this.layoutService._redrawLayoutSidenav();
      this.setRoutes(res);

    });
    this.changeDetectorEnable = true;
  }

  getClasses() {
    let bg = this.appService.layoutSidenavBg;
    if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '');
    }

    return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
  }

  isActive(url) {
    return this.router.isActive(url, true);
  }

  isMenuActive(url) {
    return this.router.isActive(url, false);
  }

  isMenuOpen(url) {
    return this.router.isActive(url, false);
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  setRoutes(modules: Array<any>) {

    /* modules.forEach(module => {
      if (this.authService.hasPermision(module.identificador + '.ver') && module.mostrar) {
        this.routes.push(module);
      }
    }); */
    // this.routes = this.routes.concat(modules);
    // this.routes = [
    //   { icon: 'ion ion-md-contact', label: 'home', route: '/home' },
    //   { icon: 'ion ion-md-desktop', label: 'usuarios', route: '/usuarios' },
    //   { icon: 'ion ion-md-desktop', label: 'roles', route: '/roles' }
    // ];
    if (this.changeDetectorEnable) {
      this.changeDetetor.detectChanges();
    }
    // you can make anything following cases
    /*
      method 1.
      modules.forEach((moduleRoute: any) => {
        this.routes.push({ label: moduleRoute['key'], route: moduleRoute['anotherkey'] });
      });

      method 2.
      this.routes = modules.map(element => { return { label: element['key'], route: element['anotherkey'] } })
    */
  }

  /* setCategorias(modules: Array<any>) {

    modules.forEach(module => {
      if (this.authService.hasPermision(module.identificador + '.ver') && module.mostrar) {
        this.routes.push(module);
      }
    });
    if (this.changeDetectorEnable) {
      this.changeDetetor.detectChanges();
    }
  } */
}
