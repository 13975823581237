import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CriptUtilService {

  iterationCount = 1000;
  keySize = 128;
  encryptionKey = 'cpit';
  iv = 'dc0da04af8fee58593442bf834b30739';
  salt = 'dc0da04af8fee58593442bf834b30739';

  constructor() {
  }

  generateKey(salt, passPhrase) {
    const key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt),
      {keySize: this.keySize / 32, iterations: this.iterationCount});
    return key;
  }

  decrypt(textToDecript) {
    const key = this.generateKey(this.salt, this.encryptionKey);
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(textToDecript)
    });
    const decrypted = CryptoJS.AES.decrypt(cipherParams, key,
      {iv: CryptoJS.enc.Hex.parse(this.iv)});
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encript(textToEncrypt) {
    const key = this.generateKey(this.salt, this.encryptionKey);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(textToEncrypt), key,
      {iv: CryptoJS.enc.Hex.parse(this.iv)});
     return encrypted.toString();
  }
}
