import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { createRequestOption } from '../helpers/request-util';

@Injectable({
  providedIn: 'root'
})
export class TipoEstadoComparendoService {
  public resourceUrl = `${environment.apiUrl}comparendo/tipo_estado_comparendo`;
  constructor(private http: HttpClient) { }

  findAll() {
    return this.http.get<any[]>(`${this.resourceUrl}/findAll`);
  }

  create(tipoDocumento: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, tipoDocumento);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, { params: options});
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }
}
