import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CustomToastrService {

  options = {
    tapToDismiss: true,
    closeButton: false,
    progressBar: false,
    progressAnimation: false,
    positionClass: 'toast-top-right',
  };

  constructor(private http: HttpClient,
              private toastrService: ToastrService
  ) {
  }

  // el tipo puede ser 'success', 'warning', 'info' ,'error'
  showToast(tipo: string, titulo: string, mensaje: string) {
    // `newestOnTop` and `preventDuplicates` options must be set on global config
    this.toastrService.toastrConfig.newestOnTop = true;
    this.toastrService.toastrConfig.autoDismiss = false;
    this.toastrService.toastrConfig.preventDuplicates = true;

    this.toastrService[tipo](mensaje, titulo, this.options);
  }

}
