import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-file-viewver',
  templateUrl: './file-viewver.component.html',
  styleUrls: ['./file-viewver.component.scss']
})
export class FileViewverComponent implements OnInit {

  @Input() archivo: any;

  constructor(private modal: NgbActiveModal) {
  }

  ngOnInit() {
    console.log(this.archivo.extension.indexOf('image') === -1);
  }

  getHeight() {
    return this.archivo.extension.indexOf('image') === -1 ? '70vh' : '100%!important';
  }

}
