import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { createRequestOption } from '../helpers/request-util';
import { TreeviewItem } from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  public resourceUrl = environment.apiUrl + 'user/roles';
  constructor(public http: HttpClient) { }

  create(rol: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, rol);
  }

  update(rol: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, rol);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, { params: options });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

  getPermisosTree(id: any): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/permisos-tree/${id}`);
  }

  getPermisos(id: any): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/permisos/${id}`);
  }

  updatePermisosTree(id: any, req: any): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/permisos-tree/${id}`, req);
  }

  getItems(data: Array<any>): TreeviewItem[] {
    return data.map((item) => new TreeviewItem(item));
  }
}
