import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, CiudadService, RangoService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-rango',
  templateUrl: './new-rango.component.html',
  styleUrls: ['./new-rango.component.scss']
})
export class NewRangoComponent implements OnInit {
  crearRangoForm: FormGroup;
  itemsPerPage: any;
  page: any;
  predicate: any;
  ciudades: any[] = [];
  ciudadLoading = true;

  constructor(
    private rangoService: RangoService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private toast: CustomToastrService,
    private router: Router,
    private authService: AuthService,
    private ciudadService: CiudadService,
    public modal: NgbActiveModal,
  ) {

    this.itemsPerPage = 15;
    this.page = 1;
    this.predicate = 'rangoInicial';

    this.crearRangoForm = this.formBuilder.group({
      eliminado: false,
      activo: true,
      rangoInicial: [0, [Validators.required]],
      rangoFinal: [0, [Validators.required]],
      ciudad: this.formBuilder.group({
        id: [null, Validators.required]
      }),
      fechaCreacion: [''],
      fechaActualizacion: ['']
    });

  }

  ngOnInit() {
    this.loadCiudad();

  }

  loadCiudad() {
    this.ciudadLoading = false;

    this.ciudadService.activos().subscribe(data => {

      this.ciudades = data;
      this.ciudadLoading = false;

    }, (err) => this.onError(err.message));
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }


  async guardar() {

    await this.rangoService.create(this.crearRangoForm.value).toPromise().then(response => {
      this.toast.showToast('success', '', 'El rango se ha creado satisfactoriamente');
      this.modal.close({response});
    }, error2 => {

      if (error2.status === 400) {
        this.toast.showToast('error', error2.error.message, error2.error.details);
      } else {
        this.toast.showToast('error', 'Error.', 'Error ha ocurrido un error al crear el rango.');
      }

    });

  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }

  public validatePermission(accion: string) {
    // return this.authService.hasPermision(accion);
    return true;
  }


}
