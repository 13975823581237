import { Component, OnInit, Input } from '@angular/core';
import { Comparendo } from '../../commons/interfaces/types/comparendos';

// Constants
import { CONSULTAS_EXTERNAS_URL } from '../../commons/constants/paths';

@Component({
  selector: 'app-consultas-externas',
  templateUrl: './consultas-externas.component.html',
  styleUrls: ['./consultas-externas.component.scss']
})
export class ConsultasExternasComponent implements OnInit {
  data: {
    result: Comparendo[];
    placa: string;
    documento: string;
    date: Date;
  };
  iframeContent = `<iframe src="${CONSULTAS_EXTERNAS_URL}" width="100%" height="100%;" frameBorder="0">
  </iframe>`;
  @Input()
  isInserted: boolean;
  copiedMessageTimeout: any;
  showCopiedMessage = false;
  constructor() { }

  ngOnInit() {
  }

  onQueryComparendos(result: {
    result: Comparendo[];
    placa: string;
    documento: string;
    date: Date;
  }) {
    console.log(result);
    this.data = result;
  }

  onNewQuery() {
    this.data = undefined;
  }

  copiarAlPortapapeles() {
    function fallbackCopyTextToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.textContent = this.iframeContent;
      document.body.appendChild(textarea);

      const selection = document.getSelection();
      const range = document.createRange();
      range.selectNode(textarea);
      selection.removeAllRanges();
      selection.addRange(range);

      if (!document.execCommand('copy')) {
        console.log('Error clipboard');
      } else {
        this.showCopiedMessage = true;
        if (this.copiedMessageTimeout) {
          clearTimeout(this.copiedMessageTimeout);
        }
        this.copiedMessageTimeout = setTimeout(() => {
          this.showCopiedMessage = false;
        }, 2500);
      }
      selection.removeAllRanges();

      document.body.removeChild(textarea);
    }
    if (!(navigator as any).clipboard) {
      fallbackCopyTextToClipboard.bind(this)(this.iframeContent);
      return;
    }
    (navigator as any).clipboard.writeText(this.iframeContent).then(() => {
      console.log('Async: Copying to clipboard was successful!');
      this.showCopiedMessage = true;
      if (this.copiedMessageTimeout) {
        clearTimeout(this.copiedMessageTimeout);
      }
      this.copiedMessageTimeout = setTimeout(() => {
        this.showCopiedMessage = false;
      }, 2500);
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });

  }
}
