import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NewEditListaBlancaComponent } from './modals/new-edit-lista-blanca/new-edit-lista-blanca.component';
import { AuthService } from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';
import { ListaBlancaService } from '../../providers/lista-blanca.service';
import {ExportAsConfig, ExportAsService} from 'ngx-export-as';
import {ModalMostrarTodoComponent} from '../../commons/modals/modal-mostrar-todo/modal-mostrar-todo.component';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-lista-blanca',
  templateUrl: './lista-blanca.component.html',
  styleUrls: ['./lista-blanca.component.scss']
})
export class ListaBlancaComponent implements OnInit {

  private itemsPerPage: number = null;
  private page: number = null;
  private predicate: any;
  private reverse: any;
  private queryCount: number = null;
  private totalPage: number = null;
  private totalItems: number = null;
  private listasBlanca: Array<any>;

  permisos: Array<any>;
  
  private showTable: boolean = false;
  cargarTodo = false;
  cantidadItems = 25;
  constructor(
    private modalService: NgbModal,
    private toast: CustomToastrService,
    private authService: AuthService,
    public webStorageCustomService: WebStorageCustomService,

    private listaBlancaService: ListaBlancaService,
    private exportAsService: ExportAsService

  ) {
    this.itemsPerPage = this.cantidadItems;
    this.page = 1;
    this.predicate = 'id';
  }

   ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.loadAll();
  }

  private loadAll(): void {
    this.listaBlancaService
    .query({
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort()
    })
    .toPromise()
    .then(
      response => {
        this.queryCount = response.numberOfElements;
        this.totalPage = response.totalPages;
        this.totalItems = response.totalElements;
        this.listasBlanca = response.content;
        this.showTable = (response.content.length > 0) ? true : false;
      },
      error => {}
    );
  }

  private sort(): Array<string> {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    return result;
  }

  private transition() {
    this.loadAll();
  }

  private open(permiso: string, listaBlanca?: any): void {
    if (this.validatePermission(permiso)) {
      const modal: NgbModalRef = this.modalService.open(NewEditListaBlancaComponent, {size: 'sm', backdrop: 'static'});
      if (listaBlanca) modal.componentInstance.listaBlanca = listaBlanca;
      modal.result.then(
        success => {
          this.loadAll();
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  private deleteListablanca(id: number) {
    if (this.validatePermission('lista-blanca.eliminar')) {
      this.listaBlancaService.delete(id).subscribe(res => {
        this.loadAll();
      });
    } else {
      this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Parametros del sistema');
    }
  }

  sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.loadAll();
  }

  export(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'table',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'lista_lista_blanca');

  }

  todo() {

    this.cargarTodo = !this.cargarTodo;

    if (this.cargarTodo) {
      this.mostrarTodoModal();
    } else {
      this.itemsPerPage = this.cantidadItems;
      this.loadAll();
    }


  }


  public mostrarTodoModal() {
    const modal: NgbModalRef = this.modalService.open(ModalMostrarTodoComponent, {size: 'sm'});
    modal.componentInstance.texto = this.totalItems;
    modal.result.then(
      (success: Object) => {
        this.itemsPerPage = this.totalItems;
        this.loadAll();
      },
      (error: any) => {
        this.cargarTodo = !this.cargarTodo;
        this.loadAll();
      }
    );

  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }
}
