import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from '../helpers/request-util';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';

import {WebStorageCustomService} from '../commons/providers/web-custom-storage.service';

// Own
// Types
import { ColumnDefinitions } from '../commons/interfaces/types/column-definitions';


@Injectable({
  providedIn: 'root'
})
export class MaquinasReportesService {
  public resourceUrl = environment.apiUrl + 'comparendo/' + 'comparendo';
  private chartBaseObject = {
    type: 'bar',
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: [],
          data: []
        }
      ]
    },
    options: {
      legend: { display: false, position: 'bottom' },
      title: {
        display: true,
        text: ''
      },
      colors: [],
      animation: null,
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
            // OR //
            beginAtZero: true,   // minimum value will be 0.
            precision: 0
          }
        }]
      }
    },
  };
  private colors: string[] = [
    '#800000',
    '#FFA500',
    '#008000',
    '#800080',
    '#008080',
    '#000080',
    '#CD5C5C',
    '#FF4500',
    '#FAFAD2',
    '#4B0082',
    '#556B2F',
    '#8B4513',
    '#2F4F4F',
    '#A0522D',
    '#8B0000',
    '#FF1493',
    '#32CD32',
    '#DC143C',
    '#FA8072',
    '##3e95cd'
  ];
  constructor(private http: HttpClient,
              public webStorageCustomService: WebStorageCustomService) { }

  // REPORTE DETECTADAS

  reporteDetectadasData(req: { [key: string]: any }): Observable<{ [key: string]: any }> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.resourceUrl}/reporte_maquina_detectados`, { params: options });
  }

  getReporteDetectadasColumnDefinitions(): ColumnDefinitions[] {
    return [
      {
        'columnName': 'Nombre máquina',
        'valueGetter': (item: any) => item.nombre ? item.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Código máquina',
        'valueGetter': (item: any) => item.codigo ? item.codigo : '',
        'columnType': 0
      },
      {
        'columnName': 'Número comparendos detectados',
        'valueGetter': (item: any) => item.detectados != null ? item.detectados : '',
        'columnType': 0
      }
    ];
  }

  getReporteDetectadasDataChart(content: { nombre: string; codigo: string; detectados: number; }[]): any {
    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    chart.data.datasets[0].label = 'Evidencias detectadas';
    chart.options.title.text = 'Evidencias detectadas';
    content.forEach((element) => {
      chart.data.datasets[0].data.push(element.detectados);
      chart.data.datasets[0].backgroundColor.push('#000080');
      chart.data.labels.push(element.nombre);
    });
    console.log('GENERAR GRAFICO CON ', content, 'CHART ', chart);
    return chart;
  }

  // REPORTE RECHAZADAS

  reporteRechazadasData(req: { [key: string]: any }): Observable<{ [key: string]: any }[]> {
    const options = createRequestOption(req);
    return this.http.get<{ [key: string]: any }[]>(`${this.resourceUrl}/reporte_maquina_rechazados`, { params: options });
  }

  getReporteRechazadasColumnDefinitions(): ColumnDefinitions[] {
    return [
      {
        'columnName': 'Nombre máquina',
        'valueGetter': (item: any) => item.nombre ? item.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Código máquina',
        'valueGetter': (item: any) => item.codigo ? item.codigo : '',
        'columnType': 0
      },
      {
        'columnName': 'Número comparendos rechazados',
        'valueGetter': (item: any) => item.rechazados != null ? item.rechazados : '',
        'columnType': 0
      }
    ];
  }

  getReporteRechazadasDataChart(content: { nombre: string; codigo: string; rechazados: number; }[]): any {
    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    chart.data.datasets[0].label = 'Evidencias rechazadas';
    chart.options.title.text = 'Evidencias rechazadas';
    content.forEach((element) => {
      chart.data.datasets[0].data.push(element.rechazados);
      chart.data.datasets[0].backgroundColor.push('#000080');
      chart.data.labels.push(element.nombre);
    });
    console.log('GENERAR GRAFICO CON ', content, 'CHART ', chart);
    return chart;
  }

  // REPORTE IMPUESTAS

  reporteImpuestasData(req: { [key: string]: any }): Observable<{ [key: string]: any }[]> {
    const options = createRequestOption(req);
    return this.http.get<{ [key: string]: any }[]>(`${this.resourceUrl}/reporte_maquina_impuestos`, { params: options });
  }

  getReporteImpuestasColumnDefinitions(): ColumnDefinitions[] {
    return [
      {
        'columnName': 'Nombre máquina',
        'valueGetter': (item: any) => item.nombre ? item.nombre : '',
        'columnType': 0
      },
      {
        'columnName': 'Código máquina',
        'valueGetter': (item: any) => item.codigo ? item.codigo : '',
        'columnType': 0
      },
      {
        'columnName': 'Número comparendos impuestos',
        'valueGetter': (item: any) => item.impuestos != null ? item.impuestos : '',
        'columnType': 0
      }
    ];
  }

  getReporteImpuestasDataChart(content: { nombre: string; codigo: string; impuestos: number; }[]): any {
    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    chart.data.datasets[0].label = 'Evidencias impuestas';
    chart.options.title.text = 'Evidencias impuestas';
    content.forEach((element) => {
      chart.data.datasets[0].data.push(element.impuestos);
      chart.data.datasets[0].backgroundColor.push('#000080');
      chart.data.labels.push(element.nombre);
    });
    console.log('GENERAR GRAFICO CON ', content, 'CHART ', chart);
    return chart;
  }

  // REPORTE CONFIABILIDAD

  reporteConfiabilidadData(req: { [key: string]: any }): Observable<{ [key: string]: any }[]> {
    const options = createRequestOption(req);
    return this.http.get<{ [key: string]: any }[]>(`${this.resourceUrl}/reporte_maquina_confiabilidad`, { params: options });
  }

  getReporteConfiabilidadColumnDefinitions(): ColumnDefinitions[] {
    return [
      {
        'columnName': 'Nombre máquina',
        'valueGetter': (item: any) => item['Nombre máquina'] != null ? item['Nombre máquina'] : '',
        'columnType': 0
      },
      {
        'columnName': 'Código máquina',
        'valueGetter': (item: any) => item['Código máquina'] != null ? item['Código máquina'] : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 0 y 5',
        'valueGetter': (item: any) => item.menor_cinco != null ? item.menor_cinco : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 6 y 10',
        'valueGetter': (item: any) => item.mayor_cinco_menorigual_diez != null ? item.mayor_cinco_menorigual_diez : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 11 y 15',
        'valueGetter': (item: any) => item.mayor_diez_menorigual_quince != null ? item.mayor_diez_menorigual_quince : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 16 y 20',
        'valueGetter': (item: any) => item.mayor_quince_menorigual_veinte != null ? item.mayor_quince_menorigual_veinte : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 21 y 25',
        'valueGetter': (item: any) => item.mayor_veinte_menorigual_veinticinco != null ? item.mayor_veinte_menorigual_veinticinco : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 26 y 30',
        'valueGetter': (item: any) => item.mayor_veinticinco_menorigual_treinta != null ? item.mayor_veinticinco_menorigual_treinta : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 31 y 35',
        'valueGetter': (item: any) => item.mayor_treinta_menorigual_treintaycinco != null ? item.mayor_treinta_menorigual_treintaycinco : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 36 y 40',
        'valueGetter': (item: any) => item.mayor_treintaycinco_menorigual_cuarenta != null ? item.mayor_treintaycinco_menorigual_cuarenta : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 41 y 45',
        'valueGetter': (item: any) => item.mayor_cuarenta_menorigual_cuarentaycinco != null ? item.mayor_cuarenta_menorigual_cuarentaycinco : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 46 y 50',
        'valueGetter': (item: any) => item.mayor_cuarentaycinco_menorigual_cincuenta != null ? item.mayor_cuarentaycinco_menorigual_cincuenta : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 51 y 55',
        'valueGetter': (item: any) => item.mayor_cincuenta_menorigual_cincuentaycinco != null ? item.mayor_cincuenta_menorigual_cincuentaycinco : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 56 y 60',
        'valueGetter': (item: any) => item.mayor_cincuentaycinco_menorigual_sesenta != null ? item.mayor_cincuentaycinco_menorigual_sesenta : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 61 y 65',
        'valueGetter': (item: any) => item.mayor_sesenta_menorigual_sesentaycinco != null ? item.mayor_sesenta_menorigual_sesentaycinco : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 66 y 70',
        'valueGetter': (item: any) => item.mayor_sesentaycinco_menorigual_setenta != null ? item.mayor_sesentaycinco_menorigual_setenta : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 71 y 75',
        'valueGetter': (item: any) => item.mayor_setenta_menorigual_setentaycinco != null ? item.mayor_setenta_menorigual_setentaycinco : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 76 y 80',
        'valueGetter': (item: any) => item.mayor_setantaycinco_menorigual_ochenta != null ? item.mayor_setantaycinco_menorigual_ochenta : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 81 y 85',
        'valueGetter': (item: any) => item.mayor_ochenta_menorigual_ochentaycinco != null ? item.mayor_ochenta_menorigual_ochentaycinco : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 86 y 90',
        'valueGetter': (item: any) => item.mayor_ochentaycinco_menorigual_noventa != null ? item.mayor_ochentaycinco_menorigual_noventa : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 91 y 95',
        'valueGetter': (item: any) => item.mayor_noventa_menorigual_noventaycinco != null ? item.mayor_noventa_menorigual_noventaycinco : '',
        'columnType': 0
      },
      {
        'columnName': 'Entre 96 y 100',
        'valueGetter': (item: any) => item.mayor_noventaycinco_menorigual_cien != null ? item.mayor_noventaycinco_menorigual_cien : '',
        'columnType': 0
      }
    ];
  }

  getReporteConfiabilidadDataChart(content: { [key: string]: string }[]): any {

    function getKeyFromIndex(index: number): { key: string; label: string; } {
      let data = {
        key: '',
        label: ''
      };
      switch (index) {
        case 0:
          data = {
            key: 'menor_cinco',
            label: 'Entre 0 y 5'
          };
          break;
        case 1:
          data = {
            key: 'mayor_cinco_menorigual_diez',
            label: 'Entre 6 y 10'
          };
          break;
        case 2:
          data = {
            key: 'mayor_diez_menorigual_quince',
            label: 'Entre 11 y 15'
          };
          break;
        case 3:
          data = {
            key: 'mayor_quince_menorigual_veinte',
            label: 'Entre 16 y 20'
          };
          break;
        case 4:
          data = {
            key: 'mayor_veinte_menorigual_veinticinco',
            label: 'Entre 21 y 25'
          };
          break;
        case 5:
          data = {
            key: 'mayor_veinticinco_menorigual_treinta',
            label: 'Entre 26 y 30'
          };
          break;
        case 6:
          data = {
            key: 'mayor_treinta_menorigual_treintaycinco',
            label: 'Entre 31 y 35'
          };
          break;
        case 7:
          data = {
            key: 'mayor_treintaycinco_menorigual_cuarenta',
            label: 'Entre 36 y 40'
          };
          break;
        case 8:
          data = {
            key: 'mayor_cuarenta_menorigual_cuarentaycinco',
            label: 'Entre 41 y 45'
          };
          break;
        case 9:
          data = {
            key: 'mayor_cuarentaycinco_menorigual_cincuenta',
            label: 'Entre 46 y 50'
          };
          break;
        case 10:
          data = {
            key: 'mayor_cincuenta_menorigual_cincuentaycinco',
            label: 'Entre 51 y 55'
          };
          break;
        case 11:
          data = {
            key: 'mayor_cincuentaycinco_menorigual_sesenta',
            label: 'Entre 56 y 60'
          };
          break;
        case 12:
          data = {
            key: 'mayor_sesenta_menorigual_sesentaycinco',
            label: 'Entre 61 y 65'
          };
          break;
        case 13:
          data = {
            key: 'mayor_sesentaycinco_menorigual_setenta',
            label: 'Entre 66 y 70'
          };
          break;
        case 14:
          data = {
            key: 'mayor_setenta_menorigual_setentaycinco',
            label: 'Entre 71 y 75'
          };
          break;
        case 15:
          data = {
            key: 'mayor_setantaycinco_menorigual_ochenta',
            label: 'Entre 76 y 80'
          };
          break;
        case 16:
          data = {
            key: 'mayor_ochenta_menorigual_ochentaycinco',
            label: 'Entre 81 y 85'
          };
          break;
        case 17:
          data = {
            key: 'mayor_ochentaycinco_menorigual_noventa',
            label: 'Entre 86 y 90'
          };
          break;
        case 18:
          data = {
            key: 'mayor_noventa_menorigual_noventaycinco',
            label: 'Entre 91 y 95'
          };
          break;
        case 19:
          data = {
            key: 'mayor_noventaycinco_menorigual_cien',
            label: 'Entre 96 y 100'
          };
          break;
      }
      return data;
    }

    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    // chart.type = 'horizontalBar';
    chart.options.title.text = 'Confiabilidad';
    chart.options.legend.display = true;
    chart.data.datasets = [];
    content.forEach((element) => {
      chart.data.labels.push(element['Nombre máquina']);
    });

    for (let index = 0; index < 20; index++) {
      const data = [];
      content.forEach((content) => {
        data.push(content[getKeyFromIndex(index).key]);
      });
      chart.data.datasets.push({
        'data': data,
        'backgroundColor': this.colors[index],
        'label': getKeyFromIndex(index).label
      });
    }

    console.log('GENERAR GRAFICO CON ', content, 'CHART ', chart);
    return chart;
  }

  // REPORTE TIPO INFRACCION

  reporteTipoInfraccionData(req: { [key: string]: any }): Observable<{ [key: string]: any }[]> {
    const options = createRequestOption(req);
    return this.http.get<{ [key: string]: any }[]>(`${this.resourceUrl}/reporte_maquina_tipo_infraccion`, { params: options });
  }

  getReporteTipoInfraccionColumnDefinitions(data: any[]): ColumnDefinitions[] {
    const columns: ColumnDefinitions[] = [];
    if (data.length > 0) {
      const reference = data[0];
      Object.keys(reference).forEach((key: string) => {
        // if (key !== 'camaraId' && key !== 'codigo' && key !== 'nombre') {
        columns.push({
          'columnName': key,
          'valueGetter': (item: any) => item[key] !== null ? item[key] : '',
          'columnType': 0
        });
        // }
      });
    }
    let newColumnDefinitions = [];
    newColumnDefinitions.push(columns.filter(e => e.columnName === 'Nombre máquina')[0]);
    newColumnDefinitions.push(columns.filter(e => e.columnName === 'Código máquina')[0]);
    columns.forEach((column) => {
      if (column.columnName !== 'Nombre máquina' && column.columnName !== 'Código máquina') {
        newColumnDefinitions.push(column);
      }
    });
    return newColumnDefinitions;
  }

  getReporteTipoInfraccionDataChart(content: any[], columns: ColumnDefinitions[]): any {
    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    // chart.type = 'horizontalBar';
    chart.options.title.text = 'Tipos de infraccion';
    chart.options.legend.display = true;
    chart.data.datasets = [];
    content.forEach((element) => {
      chart.data.labels.push(element['Nombre máquina']);
    });

    console.log('COLUMNAS TIPO INFRACCION ', columns);

    columns.forEach((column: ColumnDefinitions, index: number) => {
      const data = [];
      content.forEach((content) => {
        data.push(content[column.columnName]);
      });
      if (column.columnName !== 'camaraId' && column.columnName !== 'Nombre máquina' && column.columnName !== 'Código máquina') {
        chart.data.datasets.push({
          'data': data,
          'backgroundColor': this.colors[index],
          'label': column.columnName
        });
      }
    });

    console.log('GENERAR GRAFICO CON ', content, 'CHART ', chart);
    return chart;
  }

  // REPORTE MOTIVOS RECHAZO

  reporteMotivoRechazoData(req: { [key: string]: any }): Observable<{ [key: string]: any }[]> {
    const options = createRequestOption(req);
    return this.http.get<{ [key: string]: any }[]>(`${this.resourceUrl}/reporte_maquina_motivo_rechazo`, { params: options });
  }

  getReporteMotivoRechazoColumnDefinitions(data: any[]): ColumnDefinitions[] {
    const columns: ColumnDefinitions[] = [];
    if (data.length > 0) {
      const reference = data[0];
      Object.keys(reference).forEach((key: string) => {
        // if (key !== 'camaraId' && key !== 'codigo' && key !== 'nombre') {
        columns.push({
          'columnName': key,
          'valueGetter': (item: any) => item[key] !== null ? item[key] : '',
          'columnType': 0
        });
        // }
      });
    }
    let newColumnDefinitions = [];
    newColumnDefinitions.push(columns.filter(e => e.columnName === 'Nombre máquina')[0]);
    newColumnDefinitions.push(columns.filter(e => e.columnName === 'Código máquina')[0]);
    columns.forEach((column) => {
      if (column.columnName !== 'Nombre máquina' && column.columnName !== 'Código máquina') {
        newColumnDefinitions.push(column);
      }
    });
    return newColumnDefinitions;
  }

  getReporteMotivoRechazoDataChart(content: any[], columns: ColumnDefinitions[]): any {
    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    // chart.type = 'horizontalBar';
    chart.options.title.text = 'Motivo de Rechazo';
    chart.options.legend.display = true;
    chart.data.datasets = [];
    content.forEach((element) => {
      chart.data.labels.push(element['Nombre máquina']);
    });

    console.log('COLUMNAS MOTIVO RECHAZO ', columns);

    columns.forEach((column: ColumnDefinitions, index: number) => {
      const data = [];
      content.forEach((content) => {
        data.push(content[column.columnName]);
      });
      if (column.columnName !== 'Nombre máquina' && column.columnName !== 'Código máquina') {
        chart.data.datasets.push({
          'data': data,
          'backgroundColor': this.colors[index],
          'label': column.columnName
        });
      }
    });

    console.log('GENERAR GRAFICO CON ', content, 'CHART ', chart);
    return chart;
  }

  // DOWNLOAD EXCEL

  downloadExcel(callback: Function) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        callback(xmlHttp.responseText);
      }
    };
    xmlHttp.open("POST", `${this.resourceUrl}/excel`, true); // true for asynchronous
    xmlHttp.setRequestHeader('Authorization', `Bearer ${this.webStorageCustomService.getFromLocal('access_token')}`);
    xmlHttp.send(null);
  }
}
