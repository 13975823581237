import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, CamaraService, TipoEstadoCamaraService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MouseEvent} from '@agm/core';
import {CiudadService} from '../../../../providers/ciudad.service';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-new-edit-camara',
  templateUrl: './new-edit-camara.component.html',
  styleUrls: ['./new-edit-camara.component.scss']
})
export class NewEditCamaraComponent implements OnInit {
  @Input() private camara: any;
  crearCamaraForm: FormGroup;
  itemsPerPage: any;
  page: any;
  predicate: any;
  filtroSelect: any;
  tipoVias: any;
  velocidadesMaximas: any[] = [];
  ciudades: any[] = [];
  tipoEstadosCamara: any[] = [];
  markers: any[] = [];
  // google maps zoom level
  zoom = 13;
  // initial center position for the map
  lat = 4.709214222489045;
  lng = -74.0717949099976;
  ciudadLoading = true;
  permisos:Array<any>;

  constructor(
    private camaraService: CamaraService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private toast: CustomToastrService,
    public webStorageCustomService: WebStorageCustomService,
    private tipoEstadoCamaraService: TipoEstadoCamaraService,
    private router: Router,
    private authService: AuthService,
    private ciudadService: CiudadService,
    public modal: NgbActiveModal,
  ) {

    this.itemsPerPage = 15;
    this.page = 1;
    this.predicate = 'identificacion';
    this.filtroSelect = [
      {
        nombre: 'Nombre',
        filtro: 'nombre'
      },
      {
        nombre: 'Identificación',
        filtro: 'identificacion'
      }
      ,
      {
        nombre: 'Fecha Creación',
        filtro: 'fecha'
      }
    ];

    this.tipoVias = [
      {
        nombre: 'AV',
      },
      {
        nombre: 'CL',
      }
      ,
      {
        nombre: 'CR',
      },
      {
        nombre: 'AU',
      }
      ,{
        nombre: 'DG',
      }
      ,{
        nombre: 'TR',
      }
    ];

    this.crearCamaraForm = this.formBuilder.group({
      estado: false,
      manual: false,
      eliminado: false,
      id: [null, [Validators.required, Validators.minLength(4)]],
      codigo: ['', [Validators.required, Validators.minLength(1)]],
      nombre: ['', [Validators.required, Validators.minLength(3)]],
      url: ['', [Validators.required, Validators.minLength(9)]],
      velocidadMaxima: ['', [Validators.required, Validators.minLength(1)]],
      ciudad: this.formBuilder.group({
        id: [null, Validators.required]
      }),
      direccion: ['', [Validators.required]],
      tipoEstado: ['', [Validators.required]],
      lat: ['', Validators.required],
      lng: ['', [Validators.required]],
      fechaCreacion: null,
      fechaActualizacion: null,

      //Campos nuevos 
      viaPrincipalNumNom: ['', [Validators.required]],
      tipoViaPrincipal: ['', [Validators.required]],
      viaSecundariaNumNom: ['', [Validators.required]],
      tipoViaSecundaria: ['', [Validators.required]],
      localidadOComuna: ['', [Validators.required]],

    });
  }

   ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }


    if (this.camara) {
      this.crearCamaraForm.patchValue(this.camara);
      this.lat = this.camara.lat;
      this.lng = this.camara.lng;
    } else {

      this.lat = 4.709214222489045;
      this.lng = -74.0717949099976;
    }
    this.loadVelocidadMaxima();
    this.loadCiudad();
    this.loadTipoEstadoCamara();
  }

  loadVelocidadMaxima() {
    for (let _i = 30; _i < 200; _i += 5) {
      this.velocidadesMaximas.push(_i);
    }
  }

  loadCiudad() {
    this.ciudadLoading = true;
    this.ciudadService.activos().subscribe(data => {
      this.ciudades = data;
      this.ciudadLoading = false;
    }, (err) => this.onError(err.message));
  }

  loadTipoEstadoCamara() {
    this.tipoEstadoCamaraService.query().subscribe(data => {
      this.tipoEstadosCamara = data.content;

    }, (err) => this.onError(err.message));
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {
    this.crearCamaraForm.get('lat').setValue($event.coords.lat);
    this.crearCamaraForm.get('lng').setValue($event.coords.lng);
  }

  markerDragEnd(m: any, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  async guardar() {

    if (this.crearCamaraForm.get('fechaCreacion').value !== null) {

      await this.camaraService.update(this.crearCamaraForm.value).toPromise().then(response => {
        this.toast.showToast('success', '', 'Se ha actualizado la camara satisfactoriamente');
        this.modal.close({response});
      }, error1 => {
        this.toast.showToast('error', 'Error.', 'Error al actualizar la información de la camara');
      });
    } else {
      await this.camaraService.create(this.crearCamaraForm.value).toPromise().then(response => {
        this.toast.showToast('success', '', 'La camara se ha creado satisfactoriamente');
        this.modal.close({response});
      }, error2 => {
        this.toast.showToast('error', 'Error.', 'Error ha ocurrido un error al crear la camara.');
      });
    }
  }

  trackCamaraById(index: number, item: any) {
    return item.id;
  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }

  trackVelocidadMaxima(index: number, item: any) {
    return item;
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }}
