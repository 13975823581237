import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { createRequestOption } from '../helpers/request-util';

// Own
// Types
import { Response } from '../commons/interfaces/types/response';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  public resourceUrl = environment.apiUrl + 'user/notificaciones';
  constructor(public http: HttpClient) { }

  findByUser(userId: number): Observable<any> {
    return this.http.get<any[]>((this.resourceUrl + '/find_by_user?userId=' + userId));
  }

  updateEstados(estado: boolean, usuario: any) {
    return this.http.put<any[]>((this.resourceUrl + '/update_estados?estado=' + estado + "&usuario=" + usuario), null);
  }

  query(userId: number, req?: any): Observable<Response> {
    const options = createRequestOption(req);
    return this.http.get<Response>( `${this.resourceUrl}/query?userId=${userId}`, { params: options });
  }
}
