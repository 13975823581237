import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import {environment} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {CustomToastrService} from '../commons/providers/custom-toastr.service';
import {WebStorageCustomService} from '../commons/providers/web-custom-storage.service';
import {WebSocketService} from '@app/providers/web-socket.service';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public clientId = 'client';
  public resourceUrl = environment.apiUrl + 'auth/';
  public resourceUrlRoles = environment.apiUrl + 'user/roles';

  constructor(public _http: HttpClient,
              private toast: CustomToastrService,
              public router: Router,
              public webStorageCustomService: WebStorageCustomService,
              private webSocketService: WebSocketService
  ) {
  }

  authorize(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', username);
    params.append('password', password);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(this.clientId + ':secret')
      })
    };
    return this._http.post(this.resourceUrl + 'oauth/token', params.toString(), httpOptions);
  }

  login(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': data.token_type + ' ' + data.access_token
      })
    };

    return this._http.post(this.resourceUrl + 'oauth/login', null, httpOptions);
  }

  saveToken(token, rememberme?) {
    if (rememberme) {
      this.webStorageCustomService.saveToLocal('rememberMe', 'yes');
    } else {
      this.webStorageCustomService.deleteFromLocal('rememberMe');
    }
    const expireDate = new Date().getTime() + token.expires_in;

    this.webStorageCustomService.saveToLocal('access_token', token.access_token);
    this.webStorageCustomService.saveToLocal('refresh_token', token.refresh_token);
  }

  saveUser(data) {
    this.webStorageCustomService.saveToLocal('usuario', JSON.stringify(data.usuario));
    this.webStorageCustomService.saveToLocal('permisos', JSON.stringify(data.permisos));
  }

  checkToken() {
    return this._http.post(this.resourceUrl + 'oauth/check_token?token=' + this.webStorageCustomService.getFromLocal('access_token'), {});
  }

  logout() {
    this.webSocketService.disconnect();
    const usuario = JSON.parse(this.webStorageCustomService.getFromLocal('usuario'));
    this._http.post(this.resourceUrl + 'oauth/logout/' + usuario.id, {}).subscribe();
    this.webStorageCustomService.clearLocal();
    this.router.navigate(['/login']);

  }

  hasPermision(data) {

    return true;

    if ( this.webStorageCustomService.checkFromLocal('permisos')) {
      const permisos: Array<string> = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

      if (permisos.includes('root.all') || permisos.includes(data)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;

    }
  }

  reloadPermisos() {

    if (this.webStorageCustomService.checkFromLocal('usuario')) {
      let idRol = JSON.parse(this.webStorageCustomService.getFromLocal('usuario')).rol.id;
      this._http.get<any>(`${this.resourceUrlRoles}/permisos/${idRol}`).subscribe((value) => {
        this.webStorageCustomService.saveToLocal('permisos', JSON.stringify(value));
      });
    }
  }

  findModuloPrincipal() {

    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      const permisos: Array<string> = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));
      let modulo = permisos.find((data) => {
        let newModulo = (data.split('.')[1] === 'ver' && data.split('.')[0] === 'dashboard') ? (data.split('.')[1] === 'ver' && data.split('.')[0] === 'dashboard') : null;
        return newModulo;
      });

      if (modulo) {
        return modulo.split('.')[0] + '/' + modulo.split('.')[0];
      } else {
        return 'dashboard/dashboard';
      }
    } else {
      return 'dashboard/dashboard';
    }

  }


  refreshToken() {
    if (this.webStorageCustomService.checkFromLocal('refresh_token')) {
      const params = new URLSearchParams();
      params.append('grant_type', 'refresh_token');
      params.append('refresh_token', this.webStorageCustomService.getFromLocal('refresh_token'));

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + btoa(this.clientId + ':secret')
        })
      };


      this._http.post(this.resourceUrl + 'oauth/token', params.toString(), httpOptions).subscribe(res => {

        if (this.webStorageCustomService.checkFromLocal('rememberMe')) {
          this.saveToken(res, true);
        } else {
          this.toast.showToast('warning', 'Sesion Expirada', 'La sesion ha expirado, se redirigirá al login');

          this.logout();
        }
      }, err => {

      });
    }

  }

}
