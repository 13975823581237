export * from './accion.service';
export * from './auth.service';
export * from './modulos.services';
export * from './rol.service';
export * from './rolModulo.service';
export * from './uploadFile.service';
export * from './usuario.service';
export * from './tipoDocumento.service';
export * from './camara.service';
export * from './ciudad.service';
export * from './departamento.service';
export * from './pais.service';
export * from './infraccion.service';
export * from './tipoVehiculo.service';
export * from './claseServicio.service';
export * from './modalidadTransporte.service';
export * from './transportePasajeros.service';
export * from './comparendo.service';
export * from './transportePasajeros.service';
export * from './tipoInfractor.service';
export * from './radioAccion.service';
export * from './divipo.service';
export * from './rango.service';
export * from './exclusionRango.service';
export * from './numeroRango.service';
export * from './data.service';
export * from './observacion.service';
export * from './consulta-runt.service';
export * from './entityChangeLog.service';
export * from './categoria.service';
export * from './infractor.service';
export * from './secretaria.service';
export * from './parametrizacion.service';
export * from './tipoEstadoCamara.service';
export * from './archivo.service';
export * from './archivoXml.service';
export * from './estadoComparendoUsuario.service';
export * from './archivoXml.service';
export * from './usuario-archivo.service';
export * from './usuario-tipo-archivo.service';
