import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';

@Injectable({
  providedIn: 'root'
})
export class TransportePasajerosService {

  public resourceUrl = environment.apiUrl + 'comparendo/transporte_pasajero';

  constructor(public http: HttpClient) {
  }

  create(transportePasajero: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, transportePasajero);
  }

  update(transportePasajero: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, transportePasajero);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, {params: options});
  }

  listAll(): Observable<any> {
    return this.http.get<any[]>(`${this.resourceUrl}/list_all`);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

}
