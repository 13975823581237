import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// Own
// Types
import { Notificacion } from '../commons/interfaces/types/notificacion';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  private notificacionSubject: BehaviorSubject<Notificacion> = new BehaviorSubject(null);
  constructor() { }

  getNotificacion(): Observable<Notificacion> {
    return this.notificacionSubject;
  }

  setNotificacion(notificacion: Notificacion): void {
    this.notificacionSubject.next(notificacion);
  }
}
