import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { TransportePasajerosService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';

@Component({
  selector: 'app-new-edit-transporte-pasajeros',
  templateUrl: './new-edit-transporte-pasajeros.component.html',
  styleUrls: ['./new-edit-transporte-pasajeros.component.scss']
})
export class NewEditTransportePasajerosComponent implements OnInit {

  @Input() private transportePasajero: any;
  private transportePasajeroForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    private formBuilder: FormBuilder,
    public transportePasajerosService: TransportePasajerosService,
  ) {
  }

  ngOnInit() {

    this.initForms();
    if (this.transportePasajero) {
      this.patchValues(this.transportePasajero);
    }

  }

  private initForms() {

    this.transportePasajeroForm = this.formBuilder.group({
      id: null,
      fechaCreacion: null,
      fechaActualizacion: null,
      eliminado: false,
      descripcion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      idSimitMovilixa: [null, [Validators.required, Validators.minLength(1)]],
    });

  }

  private patchValues(transportePasajero: Object) {
    this.transportePasajeroForm.patchValue(transportePasajero);
  }

  public async modalclose() {
    if (this.transportePasajeroForm.valid) {
      const itemF = await this.createOrUpdateItem(this.transportePasajeroForm.value, this.transportePasajero ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(transportePasajero: any, exits: any): Promise<any> {
    if (!exits) {
      return this.transportePasajerosService.create(transportePasajero).toPromise();
    }
    if (exits) {
      return this.transportePasajerosService.update(transportePasajero).toPromise();
    }
  }

}
