import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';


@Injectable({
  providedIn: 'root'
})
export class EntityChangeLogService {

  public resourceUrl = environment.apiUrl + 'admin/entityChangeLog';

  constructor(public http: HttpClient) {
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  findSelects(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/findSelects`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, {params: options});
  }

}
