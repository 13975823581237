import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModulosService, RolModuloService, RolService } from '../../../../providers';
import { ToastrService } from 'ngx-toastr';
import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';

@Component({
  selector: 'app-edit-permisos',
  templateUrl: './edit-permisos.component.html',
  styleUrls: ['./edit-permisos.component.scss']
})

export class EditPermisosComponent implements OnInit {

  @Input() public rol: any;
  public dropdownEnabled = true;
  public items: TreeviewItem[];
  public values: number[];

  public config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400
  });

  constructor(
    public modal: NgbActiveModal,
    public toastService: ToastrService,
    public formBuilder: FormBuilder,
    public rolesService: RolService,
  ) { }

  ngOnInit() {
    // this.initForms();
    // if (this.rol) this.patchValues(this.rol);
    this.rolesService.getPermisosTree(this.rol.id).subscribe(data => {
      this.items = this.rolesService.getItems(data);
    }, error => {

    });

    this.rolesService.getPermisos(this.rol.id).subscribe(data => {
      // this.items = this.rolesService.getItems(data);
    }, error => {

    });
  }

  public guardar() {
    this.rolesService.updatePermisosTree(this.rol.id, this.items).subscribe(data => {
      this.modal.close();
    }, error => {

    });
  }

  public async modalclose() {
    this.modal.close();
  }

  // public async createOrUpdateRol(rol: any, exits: any): Promise<any> {
  //   if (!exits) return this.rolesService.create(rol).toPromise();
  //   if (exits) return this.rolesService.update(rol).toPromise();
  // }
}
