import {Component, OnInit} from '@angular/core';
import {AuthService, CamaraService,} from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {MouseEvent} from '@agm/core';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-radares',
  templateUrl: './radares.component.html',
  styleUrls: ['./radares.component.scss']
})
export class RadaresComponent implements OnInit {

  markers: any[] = [];
  permisos: Array<any>;

  constructor(private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private modalService: NgbModal,
              private toast: CustomToastrService,
              private router: Router,
              public webStorageCustomService: WebStorageCustomService,
              private authService: AuthService,
              private camaraService: CamaraService) {
  }

  // google maps zoom level
  zoom = 12;

  // initial center position for the map
  lat = 4.685871;
  lng  = -74.087482;

   ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.findAllPositions();
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  /* mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  } */

  markerDragEnd(m: any, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }


  findAllPositions() {
    this.camaraService.query().subscribe(data => {
      this.markers = data.content;
      console.log('this.markers  >>>>> ', this.markers);
    });
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }}
