import { Component } from '@angular/core';
import { UsuarioService } from '../../providers';
import { Router } from '@angular/router';

@Component({
    selector: 'app-recordar-contrasena',
    templateUrl: './recordar-contrasena.component.html',
    styleUrls: [
        './recordar-contrasena.component.scss',
        '../../../vendor/styles/pages/authentication.scss'
    ]
})

export class RecordarContrasenaComponent {

    // public loginForm: FormGroup;
    public credentials: any = {
        email: ''
    };

    constructor(private usuarioService: UsuarioService, private router: Router) {
    }

    sendPassword() {
        this.usuarioService.resetPassword(this.credentials.email).subscribe(
            data => {
                this.router.navigate(["/login"]);
            },
            err => {
                console.error("ERROR resetPassword")
            }
        );
    }
}
