import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SecretariaService {

  public resourceUrl = environment.apiUrl + 'camera/secretaria';

  constructor(public http: HttpClient) {
  }

  create(secretaria: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, secretaria);
  }

  update(secretaria: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, secretaria);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, {params: options});
  }

  listAll(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/list_all`);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }

  findByNombre(nombre: string, limit: number) {
    if (nombre === '') {
      return of([]);
    }

    return this.http.get<any[]>(`${this.resourceUrl}/find_by_name?name=${nombre}&limit=${limit}`)
    .pipe(
      map(response => {
        return response;
      })
    );
  }

}
