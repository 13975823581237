import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, AfterViewInit {

  public url: Array<string>;
  public changeDetectorEnabled: boolean;

  constructor(
    public changeDetetor: ChangeDetectorRef,
    public router: Router
  ) { }

  ngOnInit() {
    this.url = [];
    this.actualURL();
  }

  ngAfterViewInit() {
    this.changeDetectorEnabled = true;
  }

  actualURL () {
    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        this.url = this.router.url.split('/');
        if (this.changeDetectorEnabled) this.changeDetetor.detectChanges();
      }
    });
  }

}
