import { Component, OnInit, Output, EventEmitter, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';

// Own
// Types
import { Comparendo } from '@app/commons/interfaces/types/comparendos';
import { ColumnDefinitions } from '@app/commons/interfaces/types/column-definitions';
// Services
import { EstadoComparendoUsuarioService } from '@app/providers/estadoComparendoUsuario.service';
// Constants
import { SHOW_ENTRIES_OPTIONS } from '../../commons/constants/app';

@Component({
  selector: 'app-comparendo-estados',
  templateUrl: './comparendo-estados.component.html',
  styleUrls: ['./comparendo-estados.component.scss']
})
export class ComparendoEstadosComponent implements OnInit {
  @Output() goBackEmitter = new EventEmitter<any>();
  comparendoId: number;
  page = 1;
  itemsPerPage = 5;
  itemsPerPageOld: number;
  totalItems: number = null;
  totalPages: number = null;
  data: any[];
  columnDefinitions: ColumnDefinitions[];

  dateFormat = 'yyyy/MM/ddThh:mm:ss';

  isLoading = false;
  tryingListingAll = false;
  listedAll = false;
  showEntriesOptions: number[] = SHOW_ENTRIES_OPTIONS;

  // Data export formats
  public exportFormats: { text: string; value: string; iconClass: string; tableId: string; fileName: string; }[] = [{
    text: 'Excel',
    value: 'xlsx',
    tableId: 'table-estados-comparendo',
    iconClass: 'fas fa-file-excel text-success',
    fileName: 'trazabilidad_estados'
  },
  {
    text: 'CSV',
    value: 'csv',
    tableId: 'table-estados-comparendo',
    iconClass: 'fas fa-file-csv text-success',
    fileName: 'trazabilidad_estados'
  },
  {
    text: 'XML',
    value: 'xml',
    tableId: 'table-estados-comparendo',
    iconClass: 'fas fa-file-code text-danger',
    fileName: 'trazabilidad_estados'
  },
  {
    text: 'Texto',
    value: 'txt',
    tableId: 'table-estados-comparendo',
    iconClass: 'fas fa-file-archive',
    fileName: 'trazabilidad_estados'
  }];
  constructor(private datePipe: DatePipe,
    private exportAsService: ExportAsService,
    private estadoComparendoUsuarioService: EstadoComparendoUsuarioService,
    private zone: NgZone) { }

  ngOnInit() {
    this.buildColumnDefinitions();
    console.log('columnDefinitions ', this.columnDefinitions);
  }

  public listAll(): void {
    this.tryingListingAll = true;
    this.page = 1;
    this.itemsPerPageOld = this.itemsPerPage;
    this.itemsPerPage = this.totalItems;
    this.query(this.buildQuery());
  }

  public listPaginated() {
    this.page = 1;
    this.itemsPerPage = this.itemsPerPageOld;
    this.query(this.buildQuery());
  }

  public goBack(): void {
    this.goBackEmitter.emit();
  }

  public selectedOptionChanged($event: number): void {
    console.log('selectedOptionChanged ', $event);
    this.page = 1;
    this.itemsPerPage = $event;
    this.getItems();
  }

  private buildQuery(): { [key: string]: any } {
    const query = { page: (this.page - 1), size: this.itemsPerPage };
    if (this.comparendoId) {
      query['comparendoId'] = this.comparendoId;
    }
    return query;
  }

  public getItems(): void {
    if (!this.tryingListingAll) {
      this.query(this.buildQuery());
    }
  }

  public ejecutarFuncion(column: ColumnDefinitions, item: any) {

  }

  private async query(query?: any): Promise<any> {
    this.isLoading = true;
    await this.estadoComparendoUsuarioService.findByCriteria(query).toPromise()
    .then((response) => {
      console.log('response ', response);
      this.totalItems = response.totalElements;
      this.totalPages = response.totalPages;
      this.data = response.content.map((item) => {
        if (item.comparendo.numero) {
          item.comparendo.numero = `'${item.comparendo.numero}`;
        }
        return item;
      });
      console.log('new data ', this.data);
      if (this.tryingListingAll) {
        this.listedAll = true;
        this.tryingListingAll = false;
      } else {
        if (this.listedAll) {
          this.listedAll = false;
          console.log(this);
        }
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
    this.isLoading = false;
    this.zone.run(function() {});
  }

  public export(tipo: any, tableId: string, fileName: string) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo ? tipo : 'xlsx',
      elementId: tableId,
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, fileName);
  }

  private buildColumnDefinitions(): void {
    this.columnDefinitions = [
      {
        'columnName': 'Comparendo #',
        'valueGetter': (item) => item.comparendo ? item.comparendo.numero : '',
        'columnType': 0
      },
      {
        'columnName': 'Estado',
        'valueGetter': (item) => item.tipoEstadoComparendo ? item.tipoEstadoComparendo.descripcion : '',
        'columnType': 0
      },
      {
        'columnName': 'Usuario',
        'valueGetter': (item) => item.usuario ? `${item.usuario.nombre} ${item.usuario.apellido}` : '',
        'columnType': 0
      },
      {
        'columnName': 'Fecha',
        'valueGetter': (item) => item.fechaCreacion ? this.datePipe.transform(item.fechaCreacion, this.dateFormat) : '',
        'columnType': 0
      }
    ];
  }

}
