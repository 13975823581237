import {Component, OnInit, forwardRef, Input, OnChanges} from '@angular/core';
import {FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS} from '@angular/forms';
import JSBI from 'jsbi';


export function createCounterRangeValidator(maxValue, minValue) {
  return (c: FormControl) => {

    const maxMin = {
      maxMin: true
    };

    return (JSBI.greaterThan(JSBI.BigInt(c.value), JSBI.BigInt(maxValue)) || JSBI.lessThan(JSBI.BigInt(c.value), JSBI.BigInt(minValue))) ? maxMin : null;
  };
}

@Component({
  selector: 'app-counter-input',
  styleUrls: ['./counter-input.component.scss'],
  templateUrl: './counter-input.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CounterInputComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => CounterInputComponent), multi: true}
  ]
})
export class CounterInputComponent implements ControlValueAccessor, OnChanges {

  propagateChange: any = () => {
  };
  validateFn: any = () => {
  };

  @Input('counterValue') _counterValue = JSBI.BigInt(0);
  @Input() counterRangeMax;
  @Input() counterRangeMin;

  get counterValue() {
    return this._counterValue;
  }

  set counterValue(val) {
    this._counterValue = JSBI.BigInt(val);
    this.propagateChange(JSBI.BigInt(val).toString());
  }

  ngOnChanges(inputs) {
    console.log('inputs', inputs);
    if (inputs.counterRangeMax || inputs.counterRangeMin) {
      this.validateFn = createCounterRangeValidator(this.counterRangeMax, this.counterRangeMin);
      this.propagateChange(this.counterValue.toString());
    }
  }

  writeValue(value) {
    if (value) {
      this.counterValue = JSBI.BigInt(value);
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  increase() {
    this.counterValue = JSBI.add(this.counterValue, JSBI.BigInt(1));
  }

  decrease() {
    this.counterValue = JSBI.subtract(this.counterValue, JSBI.BigInt(1));
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }
}
