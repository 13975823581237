import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService, DivipoService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MouseEvent} from '@agm/core';
import {CiudadService} from '../../../../providers/ciudad.service';
import {WebStorageCustomService} from '../../../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-new-edit-divipo',
  templateUrl: './new-edit-divipo.component.html',
  styleUrls: ['./new-edit-divipo.component.scss']
})
export class NewEditDivipoComponent implements OnInit {
  @Input() private divipo: any;
  crearDivipoForm: FormGroup;
  itemsPerPage: any;
  page: any;
  predicate: any;
  ciudades: any[] = [];
  zoom = 13;

  // initial center position for the map
  lat = 0;
  lng = 0;
  ciudadLoading = true;
  permisos: Array<any>;

  constructor(
    private divipoService: DivipoService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public webStorageCustomService: WebStorageCustomService,
    private parserFormatter: NgbDateParserFormatter,
    private toast: CustomToastrService,
    private router: Router,
    private authService: AuthService,
    private ciudadService: CiudadService,
    public modal: NgbActiveModal,
  ) {

    this.itemsPerPage = 15;
    this.page = 1;
    this.predicate = 'identificacion';

    this.crearDivipoForm = this.formBuilder.group({
      estado: false,
      eliminado: false,
      id: null,
      codigo: ['', [Validators.required, Validators.minLength(1)]],
      ciudad: this.formBuilder.group({
        id: [null, Validators.required]
      }),
      lat: ['', Validators.required],
      lng: ['', [Validators.required]],
      fechaCreacion: [''],
      fechaActualizacion: ['']
    });

  }

  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }

    if (this.divipo) {

      this.crearDivipoForm.patchValue(this.divipo);
      this.lat = this.divipo.lat;
      this.lng = this.divipo.lng;
    } else {

      this.lat = 4.709214222489045;
      this.lng = -74.0717949099976;
    }
    this.loadCiudad();
  }


  loadCiudad() {
    this.ciudadLoading = true;
    this.ciudadService.activos().subscribe(data => {

      this.ciudades = data;
      this.ciudadLoading = false;
    }, (err) => this.onError(err.message));
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  mapClicked($event: MouseEvent) {
    this.crearDivipoForm.get('lat').setValue($event.coords.lat);
    this.crearDivipoForm.get('lng').setValue($event.coords.lng);
  }

  markerDragEnd(m: any, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  async guardar() {

    if (this.crearDivipoForm.get('id').value !== null) {

      await this.divipoService.update(this.crearDivipoForm.value).toPromise().then(response => {
        this.toast.showToast('success', '', 'Se ha actualizado el divipo satisfactoriamente');
        this.modal.close({response});
      }, error1 => {
        this.toast.showToast('error', 'Error.', 'Error al actualizar la información del divipo');
      });
    } else {
      await this.divipoService.create(this.crearDivipoForm.value).toPromise().then(response => {
        this.toast.showToast('success', '', 'El divipo se ha creado satisfactoriamente');
        this.modal.close({response});
      }, error2 => {
        this.toast.showToast('error', 'Error.', 'Error ha ocurrido un error al crear el divipo.');
      });
    }
  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  beforeChange(event) {
    console.log('event', event);
  }

}
