import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {NewEditRolComponent} from './modals/new-edit-rol/new-edit-rol.component';
import {EditPermisosComponent} from './modals/edit-permisos/edit-permisos.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthService, RolService} from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';
import {ExportAsConfig, ExportAsService} from 'ngx-export-as';
import {ModalMostrarTodoComponent} from '../../commons/modals/modal-mostrar-todo/modal-mostrar-todo.component';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';

const modals = {
  NEW_EDIT_ROL: NewEditRolComponent,
  EDIT_PERMISOS: EditPermisosComponent
};

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})

export class RolesComponent implements OnInit {

  public filter: FormGroup;
  public roles: Array<any>;
  public predicate: string;
  public reverse: boolean;
  public page: number;
  public itemsPerPage: number;
  public totalItems: number;
  public queryCount: number;
  public totalPages: number;
  private show = false;
  cargarTodo = false;
  cantidadItems = 25;
  permisos: Array<any>;

  constructor(
    public formBuilder: FormBuilder,
    public modalService: NgbModal,
    public webStorageCustomService: WebStorageCustomService,
    public toastService: CustomToastrService,
    public rolService: RolService,
    public authService: AuthService,
    private exportAsService: ExportAsService

  ) {
  }

   ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.roles = [];
    this.initForms();
    this.initVariables();
    this.loadAll();
  }

  /** initialize reactive form */
  public initForms() {
    this.filter = this.formBuilder.group({
      for: ['', [Validators.required]]
    });
  }

  /** initialize variable */
  public initVariables() {
    this.page = 1;
    this.itemsPerPage = this.cantidadItems;
    this.predicate = 'id';
  }

  /** load all data from endpoints */
  public loadAll() {
    this.rolService
      .query({
        page: this.page - 1,
        size: this.itemsPerPage,
        sort: this.sort()
      })
      .subscribe(
        (response) => this.paginateRolModulos(response),
        (error) => this.onError(error)
      );
  }

  public transition() {
    this.loadAll();
  }

  public sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.loadAll();
  }

  public sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    return result;
  }

  public paginateRolModulos(data: any) {

    if (data.totalElements > 0) {
      this.show = true;
    } else {
      this.show = false;
    }

    this.totalItems = data.totalElements as number;
    this.queryCount = data.numberOfElements as number;
    this.totalPages = data.totalPages as number;
    this.roles = data.content as Array<any>;
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  public openModal(name: string, permiso: string, rol?: Object) {
    if (this.validatePermission(permiso)) {
      const modal: NgbModalRef = this.modalService.open(modals[name], {size: 'sm', backdrop: 'static'});
      if (rol) {
        modal.componentInstance.rol = rol;
      }
      modal.result.then(
        (success: Object) => {
          this.toastService.showToast('success', 'Información', 'Se han guardado los cambios.');
          this.loadAll();
        },
        (error: any) => {
          // console.error(error);
          // this.toastService.showToast('error', 'Ha sucedido algún problema', 'Administración de roles');
        }
      );
    } else {
      this.toastService.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de roles');
    }
  }

  public deleteRol(rol: Object) {
    if (this.validatePermission('roles.eliminar')) {
      this.rolService.delete(rol['id']).subscribe(
        (response: any) => {
          this.toastService.showToast('success', 'El rol ha sido eliminado satisfactoriamente', 'Administración de roles');
          this.loadAll();
        },
        (error) => {
          this.toastService.showToast('error', 'Ha sucedido algún problema', 'Administración de roles');
        }
      );
    } else {
      this.toastService.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de roles');
    }
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  export(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'table',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'lista_roles');

  }

  todo() {

    this.cargarTodo = !this.cargarTodo;

    if (this.cargarTodo) {
      this.mostrarTodoModal();
    } else {
      this.itemsPerPage = this.cantidadItems;
      this.loadAll();
    }


  }


  public mostrarTodoModal() {
    const modal: NgbModalRef = this.modalService.open(ModalMostrarTodoComponent, {size: 'sm'});
    modal.componentInstance.texto = this.totalItems;
    modal.result.then(
      (success: Object) => {
        this.itemsPerPage = this.totalItems;
        this.loadAll();
      },
      (error: any) => {
        this.cargarTodo = !this.cargarTodo;
        this.loadAll();
      }
    );

  }
}
