import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';

// Services
import { RadioAccionService } from '../../providers/radioAccion.service';
import { TipoVehiculoService } from '../../providers/tipoVehiculo.service';
import { ClaseServicioService } from '../../providers/claseServicio.service';
import { ModalidadTransporteService } from '../../providers/modalidadTransporte.service';
import { PicoPlacaService } from '../../providers/pico-placa.service';
import { DetallePicoPlacaService } from '../../providers/detalle-pico-placa.service';
import { TransportePasajerosService } from '../../providers/transportePasajeros.service';
import { SecretariaService } from '../../providers/secretaria.service';
// Utils
import { getDatesListBetweenRange, makeDateInstance } from '../../commons/utils/general';
// Types
import { PicoPlaca } from '../../commons/interfaces/types/pico-placa';
import { DetallePicoPlaca } from '../../commons/interfaces/types/detalle-pico-placa';

@Component({
  selector: 'app-pico-placa-ficha',
  templateUrl: './pico-placa-ficha.component.html',
  styleUrls: ['./pico-placa-ficha.component.scss']
})
export class PicoPlacaFichaComponent implements OnInit {
  detallesRestriccion: DetallePicoPlaca[] = [];
  term_placas = [];
  radiosDeAcccionList: any[];
  tiposDeVehiculoList: any[];
  clasesDeServicioList: any[];
  secretariaList : any[];
  transportesPasajerosList: any[];
  modalidadesTransporteList: any[];
  desdeFechaControl: FormControl;
  hastaFechaControl: FormControl;
  desdeHoraControl: FormControl;
  hastaHoraControl: FormControl;
  transportePasajeroControl: FormControl;
  identificadorControl: FormControl;
  tipoVehiculoControl: FormControl;
  claseServicioControl: FormControl;
  radioAccionControl: FormControl;
  secretariaControl: FormControl;
  modalidadTransporteControl: FormControl;
  detallesParams: { itemsPerPage: number; page: number; totalPages: number } = {
    itemsPerPage: 10,
    page: 1,
    totalPages: null
  };
  picoPlacaForm: FormGroup;
  isSaving = false;

  //Edition
  isEdition: boolean;
  baseDetallesRestriccion: DetallePicoPlaca[];
  detallesRestriccionToDelete: any[] = [];
  restriccionToEdit: PicoPlaca;
  constructor(private radioAccionService: RadioAccionService,
    private tipoVehiculoService: TipoVehiculoService,
    private claseServicioService: ClaseServicioService,
    private modalidadTransporteService: ModalidadTransporteService,
    private picoPlacaService: PicoPlacaService,
    private detallePicoPlacaService: DetallePicoPlacaService,
    private transportePasajerosService: TransportePasajerosService,
    private secretariaService: SecretariaService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.checkIfIsEdition();
    this.getInstances();
    this.setTermPlacas();
    this.initControls();
  }

  compare(val1, val2) {
    if (val1 && val2) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

  private checkIfIsEdition() {
    const paths: string[] = this.router.url.split('pico-placa');
    if (paths[1] !== '/ficha') {
      this.isEdition = true;
      this.activatedRoute.paramMap.subscribe(async (response) => {
        const id = (response as any).params.id;

        // Restriccion a editar
        let restriccion: PicoPlaca = null;
        await this.picoPlacaService.get(id).toPromise()
          .then((response: PicoPlaca) => {
            /* console.log('Restriccion a editar ', response); */
            restriccion = response;
            this.restriccionToEdit = restriccion;
            this.setControlsFromInstance(response);
          }).catch((err) => {
            console.log('Error al consultar el detalle de restriccion para editar ', err);
          });

        if (restriccion) {
          // Detalles de restriccion
          await this.detallePicoPlacaService.findByRestriccionId(restriccion.id).toPromise()
            .then((response: DetallePicoPlaca[]) => {
              response.forEach((e) => {
                e.terminacionPlacas = e.terminacionPlacas.split(',');
                e.fecha = new Date(e.fecha);
              });
              this.baseDetallesRestriccion = response;
              this.detallesRestriccion = this.baseDetallesRestriccion;
              /* console.log('Editar, detalles restriccion ', this.detallesRestriccion); */
            })
            .catch((error) => {
              console.log('Editar, error detalles de restriccion ', error);
            });
        }

      });
    } else {
      this.isEdition = false;
    }
  }

  private setDetallesInEdition(fechaInicio: { year: number; month: number; day: number; },
    fechaFin: { year: number; month: number; day: number; }) {
    this.setDetallesRestriccion(fechaInicio, fechaFin);
    /* console.log(this.detallesRestriccion); */
    if (this.baseDetallesRestriccion) {
      this.detallesRestriccionToDelete = [];
      this.baseDetallesRestriccion.forEach((detalle: DetallePicoPlaca) => {
        let isPresent = false;
        for (let index = 0; index < this.detallesRestriccion.length; index++) {
          const element = this.detallesRestriccion[index];
          if (detalle.fecha.getTime() === element.fecha.getTime()) {
            isPresent = true;
            this.detallesRestriccion[index] = detalle;
            break;
          }
        }
        if (!isPresent) {
          this.detallesRestriccionToDelete.push(detalle);
        }
      });
      /* console.log(this.detallesRestriccionToDelete); */
    }
  }

  private initControls() {
    this.desdeFechaControl = new FormControl('', Validators.required);
    // Listenning changes to build the detalles restriccion
    this.desdeFechaControl.valueChanges.subscribe((val) => {
      if (this.hastaFechaControl.value && val && !this.isEdition) {
        this.setDetallesRestriccion(val, this.hastaFechaControl.value);
      }
      if (this.hastaFechaControl.value && val && this.isEdition) {
        this.setDetallesInEdition(val, this.hastaFechaControl.value);
      }
    });
    this.hastaFechaControl = new FormControl('', Validators.required);
    // Listenning changes to build the detalles restriccion
    this.hastaFechaControl.valueChanges.subscribe((val) => {
      if (this.desdeFechaControl.value && val && !this.isEdition) {
        this.setDetallesRestriccion(this.desdeFechaControl.value, val);
      }
      if (this.desdeFechaControl.value && val && this.isEdition) {
        this.setDetallesInEdition(this.desdeFechaControl.value, val);
      }
    });
    this.desdeHoraControl = new FormControl('', Validators.required);
    this.hastaHoraControl = new FormControl('', Validators.required);
    this.identificadorControl = new FormControl('', Validators.required);
    this.tipoVehiculoControl = new FormControl();
    this.claseServicioControl = new FormControl();
    this.radioAccionControl = new FormControl();
    this.modalidadTransporteControl = new FormControl();
    this.transportePasajeroControl = new FormControl();
    this.secretariaControl = new FormControl();
  }

  private setControlsFromInstance(restriccion: PicoPlaca) {
    this.identificadorControl.setValue(restriccion.identificador);
    this.tipoVehiculoControl.setValue(restriccion.tipoVehiculo);
    this.claseServicioControl.setValue(restriccion.claseServicio);
    this.radioAccionControl.setValue(restriccion.radioAccion);
    this.modalidadTransporteControl.setValue(restriccion.modalidadTransporte);
    this.transportePasajeroControl.setValue(restriccion.transportePasajero);
    this.secretariaControl.setValue(restriccion.secretaria);

    // Set dates
    if (restriccion.desdeFecha) {
      const date = new Date(restriccion.desdeFecha);
      this.desdeFechaControl.setValue({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() });
    }
    if (restriccion.desdeFecha) {
      const date = new Date(restriccion.hastaFecha);
      this.hastaFechaControl.setValue({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() });
    }

    // Set times
    if (restriccion.desdeHora) {
      const unidadesTiempo: string[] = restriccion.desdeHora.split(':');
      this.desdeHoraControl.setValue({
        hour: parseInt(unidadesTiempo[0], null),
        minute: parseInt(unidadesTiempo[1], null),
        second: parseInt(unidadesTiempo[2], null)
      });
    }
    if (restriccion.hastaHora) {
      const unidadesTiempo: string[] = restriccion.hastaHora.split(':');
      this.hastaHoraControl.setValue({
        hour: parseInt(unidadesTiempo[0], null),
        minute: parseInt(unidadesTiempo[1], null),
        second: parseInt(unidadesTiempo[2], null)
      });
    }
  }

  private getInstances() {
    this.getRadiosDeAccion();
    this.getTiposDeVehiculo();
    this.getClasesDeServicio();
    this.getModalidadesTransporte();
    this.getTransportesPasajeros();
    this.getSecretarias();
  }

  private getSecretarias() {
    this.secretariaService.query()
      .subscribe((_secretariaList) => {
        this.secretariaList = _secretariaList.content;
      }, (error) => {
        console.log('ERROR getSecretarias: ', error);
      });
  }

  private getRadiosDeAccion() {
    this.radioAccionService.listAll()
      .subscribe((_radiosDeAcccionList) => {
        this.radiosDeAcccionList = _radiosDeAcccionList;
      }, (error) => {
        console.log('ERROR getRadiosDeAccion: ', error);
      });
  }

  private getTiposDeVehiculo() {
    this.tipoVehiculoService.listAll()
      .subscribe((_tiposDeVehiculoList) => {
        this.tiposDeVehiculoList = _tiposDeVehiculoList;
      }, (error) => {
        console.log('ERROR getTiposDeVehiculo: ', error);
      });
  }

  private getClasesDeServicio() {
    this.claseServicioService.listAll()
      .subscribe((_clasesDeServicioList) => {
        this.clasesDeServicioList = _clasesDeServicioList;
      }, (error) => {
        console.log('ERROR getClasesDeServicio: ', error);
      });
  }

  private getTransportesPasajeros() {
    this.transportePasajerosService.listAll()
      .subscribe((_transportesPasajerosList) => {
        this.transportesPasajerosList = _transportesPasajerosList;
      }, (error) => {
        console.log('ERROR getTransportesPasajeros: ', error);
      });
  }

  private getModalidadesTransporte() {
    this.modalidadTransporteService.listAll()
      .subscribe((_modalidadesTransporteList) => {
        this.modalidadesTransporteList = _modalidadesTransporteList;
      }, (error) => {
        console.log('ERROR getModalidadesTransporte: ', error);
      });
  }

  private setTermPlacas() {
    this.term_placas = [];
    for (let index = 0; index < 10; index++) {
      this.term_placas.push(index.toString());
    }
  }

  private setDetallesRestriccion(fechaInicio: { year: number; month: number; day: number; },
    fechaFin: { year: number; month: number; day: number; }) {
    if (fechaInicio && fechaFin) {
      const previousValue: DetallePicoPlaca[] = [];
      if (this.detallesRestriccion && this.detallesRestriccion.length > 0) {
        this.detallesRestriccion.forEach((e: DetallePicoPlaca) => {
          previousValue.push(Object.assign({}, e));
        });
      }

      const fInicio: Date = makeDateInstance(fechaInicio);
      const fFin: Date = makeDateInstance(fechaFin);
      const datesList: Date[] = getDatesListBetweenRange(fInicio, fFin);
      this.detallesParams.totalPages = null;
      if (datesList.length > 0) {
        this.detallesRestriccion = [];
        datesList.forEach((date: Date) => {
          // Set the previous values
          let isPresent = false;
          if (previousValue.length > 0) {
            for (let index = 0; index < previousValue.length; index++) {
              const element = previousValue[index];
              if (date.getTime() === element.fecha.getTime()) {
                this.detallesRestriccion.push(element);
                isPresent = true;
                break;
              }
            }
          }

          if (!isPresent) {
            this.detallesRestriccion.push({
              fecha: date,
              terminacionPlacas: [],
              festivo: false,
              noAplica: false,
              restriccionPicoPlaca: null,
              observaciones: null
            });
          }

        });
        this.detallesParams.totalPages = Math.ceil(datesList.length / 10);
        /* console.log(this.detallesRestriccion); */
      }
    }
  }

  termPlacaChecked(detalleRestriccion: DetallePicoPlaca, term: string) {
    return detalleRestriccion.terminacionPlacas.indexOf(term) !== -1;
  }

  relacionarTerminacion(detalleRestriccion: DetallePicoPlaca, term: string) {
    const isPresent = this.termPlacaChecked(detalleRestriccion, term);
    if (isPresent) {
      (detalleRestriccion as any).terminacionPlacas.splice(detalleRestriccion.terminacionPlacas.indexOf(term), 1);
    } else {
      (detalleRestriccion as any).terminacionPlacas.push(term);
    }
  }

  transition($event: number) {
    this.detallesParams.page = $event;
  }

  getPaginatedDetalleRestriccion() {
    return this.detallesRestriccion.slice(
      (this.detallesParams.page - 1) * this.detallesParams.itemsPerPage,
      (this.detallesParams.itemsPerPage * this.detallesParams.page));
  }

  getDateAsString(date: Date): string {
    return this.datePipe.transform(date, 'dd/MM/yy');
  }

  disabledPerformSave(): boolean {
    return this.identificadorControl && this.identificadorControl.valid &&
      this.transportePasajeroControl && this.transportePasajeroControl.valid &&
      this.desdeFechaControl && this.desdeFechaControl.valid &&
      this.hastaFechaControl && this.hastaFechaControl.valid &&
      this.desdeHoraControl && this.desdeHoraControl.valid &&
      this.hastaHoraControl && this.hastaHoraControl.valid;
  }

  async performSave() {
    this.isSaving = true;

    let restriccionPicoPlaca: PicoPlaca = {
      identificador: this.identificadorControl.value,
      desdeFecha: makeDateInstance(this.desdeFechaControl.value),
      hastaFecha: makeDateInstance(this.hastaFechaControl.value),
      desdeHora: this.getUnitsOfTimeAsString(this.desdeHoraControl.value),
      hastaHora: this.getUnitsOfTimeAsString(this.hastaHoraControl.value),
      tipoVehiculo: this.tipoVehiculoControl.value,
      claseServicio: this.claseServicioControl.value,
      radioAccion: this.radioAccionControl.value,
      modalidadTransporte: this.modalidadTransporteControl.value,
      transportePasajero: this.transportePasajeroControl.value,
      secretaria: this.secretariaControl.value,
      estado: this.isEdition ? this.restriccionToEdit.estado : true
    };

    let error = null;

    if (!this.isEdition) {
      await this.picoPlacaService.create(restriccionPicoPlaca).toPromise()
        .then((response: PicoPlaca) => {
          /* console.log('guardo ', response); */
          restriccionPicoPlaca = response;
        })
        .catch((err) => {
          console.log('Error al guardar las restriccion ', err);
          error = err;
        });

      this.detallesRestriccion.forEach((detalle: DetallePicoPlaca) => {
        detalle.restriccionPicoPlaca = restriccionPicoPlaca;
        detalle.terminacionPlacas = detalle.terminacionPlacas.length > 0 ? detalle.terminacionPlacas.toString() : '';
      });

      await this.detallePicoPlacaService.createMany(this.detallesRestriccion).toPromise()
        .then((response: any) => {
          console.log('guardo detalles', response);
        })
        .catch((err) => {
          console.log('Error al guardar los de talles de restriccion ', err);
          error = err;
        });
    } else {

      restriccionPicoPlaca.id = this.restriccionToEdit.id;
      this.detallesRestriccion.forEach((detalle: DetallePicoPlaca) => {
        detalle.restriccionPicoPlaca = this.restriccionToEdit;
        detalle.terminacionPlacas = detalle.terminacionPlacas.length > 0 ? detalle.terminacionPlacas.toString() : '';
      });

      // Perform restriccion update
      await this.picoPlacaService.update(restriccionPicoPlaca).toPromise()
        .then(() => {
          console.log('Restriccion de pico y placa actualizada');
        })
        .catch((err) => {
          error = err;
          console.log('Error al actualizar la restriccion ', error);
        });

      if (this.detallesRestriccionToDelete.length > 0) {
        // Delete detalles restriccion
        const ids: number[] = [];
        this.detallesRestriccionToDelete.forEach((detalle: DetallePicoPlaca) => {
          ids.push(detalle.id);
        });

        await this.detallePicoPlacaService.deleteMany(ids.toString()).toPromise()
          .then(() => {
            console.log('Detalles de restriccion eliminados');
          })
          .catch((err) => {
            error = err;
            console.log('Error al eliminar detalles de la restriccion ', err);
          });
      }

      if (this.detallesRestriccion.length > 0) {
        const detallesCrear: DetallePicoPlaca[] = this.detallesRestriccion.filter((detalle: DetallePicoPlaca) => !detalle.id);
        const detallesActualizar: DetallePicoPlaca[] = this.detallesRestriccion.filter((detalle: DetallePicoPlaca) => detalle.id);

        // Perform detalles restriccion save
        if (detallesCrear.length > 0) {
          await this.detallePicoPlacaService.createMany(detallesCrear).toPromise()
            .then(() => {
              console.log('Detalles de restriccion creados ');
            })
            .catch((err) => {
              error = err;
              console.log('Error al crear detalles de la restriccion');
            });
        }

        // Perform detalles restriccion update
        if (detallesActualizar.length > 0) {
          await this.detallePicoPlacaService.updateMany(detallesActualizar).toPromise()
            .then(() => {
              console.log('Detalles de restriccion actualizados ');
            })
            .catch((err) => {
              error = err;
              console.log('Error al atualizar detalles de la restriccion');
            });
        }
      }
    }

    if (!error) {
      this.router.navigate(['/parametros-comparendos/pico-placa']);
    }

    this.isSaving = false;

  }

  private getUnitsOfTimeAsString(value: { hour: number; minute: number; second: number }): string {
    let unit: string = '';
    if (value.hour.toString().length < 2) {
      unit += `0${value.hour}:`;
    } else {
      unit += `${value.hour}:`;
    }
    if (value.minute.toString().length < 2) {
      unit += `0${value.minute}:`;
    } else {
      unit += `${value.minute}:`;
    }
    if (value.second.toString().length < 2) {
      unit += `0${value.second}`;
    } else {
      unit += `${value.second}`;
    }
    return unit.toString();
  }

  getMonth(date: Date): string {
    const weekDayName = moment(date).format('dddd');
    let dayString: string;
    switch (weekDayName) {
      case 'Monday':
        dayString = 'Lunes';
        break;
      case 'Tuesday':
        dayString = 'Martes';
        break;
      case 'Wednesday':
        dayString = 'Miércoles';
        break;
      case 'Thursday':
        dayString = 'Jueves';
        break;
      case 'Friday':
        dayString = 'Viernes';
        break;
      case 'Saturday':
        dayString = 'Sábado';
        break;
      case 'Sunday':
        dayString = 'Domingo';
        break;
      default:
        dayString = '';
        break;
    }
    return dayString;
  }

}
