import {Injectable} from '@angular/core';
import {ModalHelperComponent} from './modal-helper/modal-helper.component';
import {NgbModal, NgbModalRef, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalHelperService {

  constructor(private modalService: NgbModal, private ngbActiveModal: NgbActiveModal) {
  }

  modal: NgbModalRef = null;

  public open(
    icon: string,
    iconClass: string,
    titulo: string,
    mensaje: string,
    headerClass: string,
    confirmButtonClass: string,
    enableDismissButton: boolean,
    dissmisButtonClass?: string) {

    this.modal = this.modalService.open(ModalHelperComponent, {size: 'sm', centered: true, backdrop: 'static'});

    this.modal.componentInstance.titulo = titulo;
    this.modal.componentInstance.mensaje = mensaje;
    this.modal.componentInstance.headerClass = headerClass;
    this.modal.componentInstance.enableDismissButton = enableDismissButton;
    this.modal.componentInstance.dissmisButtonClass = dissmisButtonClass;
    this.modal.componentInstance.confirmButtonClass = confirmButtonClass;
    this.modal.componentInstance.icon = icon;
    this.modal.componentInstance.iconClass = iconClass;


    return this.modal;

  }

  isActive() {
    return this.modal != null;
  }

  getModalService() {
    return this.modalService;
  }

}
