import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TipoInfractorService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';

@Component({
  selector: 'app-new-edit-tipo-infractor',
  templateUrl: './new-edit-tipo-infractor.component.html',
  styleUrls: ['./new-edit-tipo-infractor.component.scss']
})
export class NewEditTipoInfractorComponent implements OnInit {

  @Input() private tipoInfractor: any;
  private tipoInfractorForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    private formBuilder: FormBuilder,
    public tipoInfractorService: TipoInfractorService,
  ) {
  }

  ngOnInit() {

    this.initForms();
    if (this.tipoInfractor) {
      this.patchValues(this.tipoInfractor);
    }

  }

  private initForms() {

    this.tipoInfractorForm = this.formBuilder.group({
      id: null,
      fechaCreacion: null,
      fechaActualizacion: null,
      eliminado: false,
      descripcion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      idSimitMovilixa: [null, [Validators.required, Validators.minLength(1)]],

    });

  }

  private patchValues(tipoInfractor: Object) {
    this.tipoInfractorForm.patchValue(tipoInfractor);
  }

  public async modalclose() {
    if (this.tipoInfractorForm.valid) {
      const itemF = await this.createOrUpdateItem(this.tipoInfractorForm.value, this.tipoInfractor ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(tipoInfractor: any, exits: any): Promise<any> {
    if (!exits) {
      return this.tipoInfractorService.create(tipoInfractor).toPromise();
    }
    if (exits) {
      return this.tipoInfractorService.update(tipoInfractor).toPromise();
    }
  }

}

