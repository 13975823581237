import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WebStorageCustomService} from '../commons/providers/web-custom-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private webStorageCustomService: WebStorageCustomService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt accessToken if available
    if (this.webStorageCustomService.checkFromLocal('access_token') && request.url.search('/token') === -1) {
      request = request.clone({
        setHeaders: {
          // 'Content-type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${this.webStorageCustomService.getFromLocal('access_token')}`
        }
      });
    }

    return next.handle(request);
  }
}
