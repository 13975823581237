import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TipoEstadoCamaraService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';

@Component({
  selector: 'app-new-edit-tipo-estado-camara',
  templateUrl: './new-edit-tipo-estado-camara.component.html',
  styleUrls: ['./new-edit-tipo-estado-camara.component.scss']
})
export class NewEditTipoEstadoCamaraComponent implements OnInit {

  @Input() private item: any;
  private itemForm: FormGroup;

  @Input() private modulo: string;

  private services: any;
  private items: any;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    public tipoEstadoCamaraService: TipoEstadoCamaraService,
    private formBuilder: FormBuilder,
  ) {
    this.services = {
      Tipo_estado_camara: this.tipoEstadoCamaraService
    };
  }

   ngOnInit() {

    this.initForms();
    /* this.load(); */

    if (this.item) {
      this.patchValues(this.item);
    }

  }

  private initForms() {

    this.itemForm = this.formBuilder.group({
      id: null,
      fechaCreacion: '',
      fechaActualizacion: '',
      eliminado: [false],
      nombre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      estado: [false]
    });
  }

  private patchValues(item: Object) {
    this.itemForm.patchValue(item);
  }

  public async modalclose() {
    if (this.itemForm.valid) {
      const itemF = await this.createOrUpdateItem(this.itemForm.value, this.item ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(item: any, exits: any): Promise<any> {
    if (!exits) {
      return this.services[this.modulo].create(item).toPromise();
    }
    if (exits) {
      return this.services[this.modulo].update(item).toPromise();
    }
  }

  trackItemById(index: number, item: any) {
    return item.id;
  }

  /*  load() {
       this.services[this.modulo].query().subscribe(data => {
         this.items = data;
       }, (err) => {
         this.toastService.showToast('error', `Error al cargar la lista de tipos estado camara`, 'Administración de Camara');
       });
   } */

}
