import {Component, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EditParametrizacionComponent} from './modals/edit-parametrizacion/edit-parametrizacion.component';
import {AuthService} from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';
import {ParametrizacionService} from '../../providers/parametrizacion.service';
import {ExportAsConfig, ExportAsService} from 'ngx-export-as';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-parametrizacion',
  templateUrl: './parametrizacion.component.html',
  styleUrls: ['./parametrizacion.component.scss']
})
export class ParametrizacionComponent implements OnInit {

  private itemsPerPage: number = null;
  private page: number = null;
  private predicate: any;
  private reverse: any;
  private queryCount: number = null;
  private totalPage: number = null;
  private totalItems: number = null;
  private parametrizaciones: Array<any>;
  permisos: Array<any>;
  private showTable: boolean = false;
  private activateButton: boolean = false;
  private cantidadItems = 15;

  constructor(private modalService: NgbModal,
              public webStorageCustomService: WebStorageCustomService,
              private toast: CustomToastrService, private authService: AuthService,
              private parametrizacionService: ParametrizacionService, private exportAsService: ExportAsService
  ) {
    this.itemsPerPage = this.cantidadItems;
    this.page = 1;
    this.predicate = 'id';
    this.reverse = 'desc';
  }

   ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.loadAll();
  }

  private loadAll(): void {
    this.parametrizacionService.query({
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort()
    }).toPromise().then(
      response => {
        this.queryCount = response.numberOfElements;
        this.totalPage = response.totalPages;
        this.totalItems = response.totalElements;
        this.parametrizaciones = response.content;
        this.showTable = (response.content.length > 0) ? true : false;
        this.activateButton = (response.content.length > 0) ? true : false;
      },
      error => {
      }
    );
  }

  private sort(): Array<string> {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    return result;
  }

  private transition() {
    this.loadAll();
  }

  private open(permiso: string, parametrizacion?: any): void {
    if (this.validatePermission(permiso)) {
      const modal: NgbModalRef = this.modalService.open(EditParametrizacionComponent, {size: 'sm', backdrop: 'static'});
      if (parametrizacion) {
        modal.componentInstance.parametrizacion = parametrizacion;
      }
      modal.result.then(
        success => {
          this.ngOnInit();
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  private deleteParametrizacion(id: number) {
    if (this.validatePermission('parametrizacion.eliminar')) {
      this.parametrizacionService.delete(id).subscribe(res => {
        this.loadAll();
      });
    } else {
      this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Parametros del sistema');
    }
  }

  sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.loadAll();
  }

  export(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'table',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'lista_lista_blanca');

  }

  trackCiudadById(index: number, item: any) {
    return item.id;
  }

}
