import {Component, OnInit} from '@angular/core';
import {AuthService, CamaraService} from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {NewEditCamaraComponent} from './modals/new-edit-camara/new-edit-camara.component';
import {CiudadService} from '../../providers/ciudad.service';
import {ExportAsConfig, ExportAsService} from 'ngx-export-as';
import {ModalMostrarTodoComponent} from '../../commons/modals/modal-mostrar-todo/modal-mostrar-todo.component';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-camara',
  templateUrl: './camara.component.html',
  styleUrls: ['./camara.component.scss']
})
export class CamaraComponent implements OnInit {

  ciudades: any;
  totalItems: number = null;
  queryCount: number = null;
  itemsPerPage: number = null;
  page: number = null;
  predicate: any;
  reverse: any;
  camaras: any;
  totalPage: number = null;
  filtroSelect: any[];
  filterInput: any = '';
  filterCamara: any = {
    nombre: null,
    filtro: null
  };
  showTable = false;
  cargarTodo = false;
  cantidadItems = 25;
  permisos: Array<any>;

  constructor(
    private ciudadService: CiudadService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toast: CustomToastrService,
    private router: Router,
    private authService: AuthService,
    private camaraService: CamaraService,
    public webStorageCustomService: WebStorageCustomService,
    private exportAsService: ExportAsService
  ) {
    this.itemsPerPage = this.cantidadItems;
    this.page = 1;
    this.predicate = 'id';
    this.filtroSelect = [
      {
        nombre: 'Codigo',
        filtro: 'codigo'
      },
      {
        nombre: 'Nombre',
        filtro: 'nombre'
      }
      ,
      {
        nombre: 'Ciudad',
        filtro: 'idCiudad'
      }
    ];
  }

  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }
    this.loadCiudades();
    this.loadAll();
  }

  loadCiudades() {
    this.ciudadService.query().subscribe(data => {
      this.ciudades = data.content;
    }, (err) => this.onError(err.message));
  }

  loadAll() {
    const query = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort()
    };

    if (this.filterCamara !== null) {

      if (this.filterCamara && this.filterCamara.filtro !== null && this.filterInput !== null) {
        query[this.filterCamara.filtro] = this.filterInput;
      }

    }
    this.camaraService.query(query).subscribe(data => {
        this.queryCount = data.numberOfElements;
        this.totalPage = data.totalPages;
        this.totalItems = data.totalElements;
        this.camaras = data.content;
        if (data.content.length > 0) {
          this.showTable = true;
        } else {
          this.showTable = false;
        }
      }, (err) => this.onError(err.message)
    );
  }

  sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.loadAll();
  }

  transition() {
    this.loadAll();
  }

  sort() {
    return [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  filtrar() {
    this.loadAll();
  }

  public open(camara?: any, permiso?: string) {
    if (this.validatePermission(permiso)) {
      const modal: NgbModalRef = this.modalService.open(NewEditCamaraComponent, {});
      if (camara) {
        modal.componentInstance.camara = camara;
      }
      modal.result.then(
        (success: Object) => {
          this.loadAll();
        },
        (error: any) => {
          //  console.error(error);
        }
      );
    } else {
      this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de camaras');
    }
  }

  deleteCamara(id: number) {
    if (this.validatePermission('camaras.eliminar')) {
      this.camaraService.delete(id).subscribe(res => {
        this.loadAll();
      });
    } else {
      this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de camaras');
    }
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  export(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'table',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'lista_camaras');

  }

  todo() {

    this.cargarTodo = !this.cargarTodo;

    if (this.cargarTodo) {
      this.mostrarTodoModal();
    } else {
      this.itemsPerPage = this.cantidadItems;
      this.loadAll();
    }


  }


  public mostrarTodoModal() {
    const modal: NgbModalRef = this.modalService.open(ModalMostrarTodoComponent, {size: 'sm'});
    modal.componentInstance.texto = this.totalItems;
    modal.result.then(
      (success: Object) => {
        this.itemsPerPage = this.totalItems;
        this.loadAll();
      },
      (error: any) => {
        this.cargarTodo = !this.cargarTodo;
        this.loadAll();
      }
    );

  }

}
