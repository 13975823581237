import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {WebStorageCustomService} from './web-custom-storage.service';
import {ParametrizacionService} from '../../providers';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalInactividadComponent} from '../modals/modal-inactividad/modal-inactividad.component';
import {Idle, DEFAULT_INTERRUPTSOURCES, DocumentInterruptSource, InterruptSource} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

@Injectable({
  providedIn: 'root'
})
export class NgIdleHelperService {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;


  // Tiempo de inactividad por defecto
  private tiempoInactividad: any = {
    descripcion: 'Tiempo de inactividad(Minutos)',
    fechaActualizacion: null,
    fechaCreacion: null,
    id: 4,
    valor: 60,
  };
  private inactivityModal: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private route: Router,
    private ngZone: NgZone,
    private idle: Idle, private keepalive: Keepalive,
    private webStorageCustomService: WebStorageCustomService,
    private parametrizacionService: ParametrizacionService) {

  }

  getInactividadParametrizacion() {

    return this.parametrizacionService.find(3).toPromise();

  }


  stopTimer() {
    this.idle.stop();
  }

  async start() {

    if (this.webStorageCustomService.checkFromLocal('usuario')) {
      await this.getInactividadParametrizacion().then(
        response => {

          this.setIdle(response.valor);

        },
        error => {

          this.setIdle(this.tiempoInactividad.valor);
        }
      );
    }

    console.log(this.idle.getIdle());

  }


  reset() {
    this.ngZone.runOutsideAngular(() => {
      this.idle.watch();
    });
    this.ngZone.run(() => {
      this.idleState = 'Started.';
      console.log('started');
      this.timedOut = false;
    });
  }

  public open() {

    this.modalService.dismissAll();
    this.inactivityModal = this.modalService.open(ModalInactividadComponent, {backdrop: 'static', centered: true, size: 'sm'});
    this.inactivityModal.result.then(
      (success: Object) => {
        this.inactivityModal = undefined;
        this.reset();
      },
      (error: any) => {
        // console.error(error);
      }
    );

  }


  setIdle(timeout: any) {
      console.log('timeout', timeout * 60);

    this.ngZone.runOutsideAngular(() => {
      this.idle.setIdle(timeout * 60);
      this.idle.setTimeout(5);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    });

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      console.log('this.idleState', 'No longer idle.');
    });
    this.idle.onTimeout.subscribe(() => {
      console.log('Timed out!', 'Timed out!');
      this.ngZone.run(() => {
        this.reset();
        this.route.routerState.root.children[0].url.subscribe((url) => {

          if (this.inactivityModal === undefined && this.webStorageCustomService.checkFromLocal('usuario') && url[0].path !== 'login') {

            this.open();

          }
        });

        this.idleState = 'Timed out!';
        this.timedOut = true;
      });

    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      console.log('You\'ve gone idle!');
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    // this.ngZone.runOutsideAngular(() => {
    //
    //   this.keepalive.interval(15);
    //
    // });
    //
    //
    // this.keepalive.onPing.subscribe(() => {
    //
    //   this.ngZone.run(() => {
    //     this.lastPing = new Date();
    //   });
    //
    // });

    this.reset();

  }

}
