import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ArchivoService, AuthService, ComparendoService} from '../../../providers'; //findByXml
import {CustomToastrService} from '../../../commons/providers/custom-toastr.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EditPlacaComponent} from '../modals/edit-placa/edit-placa.component';
import {DescartarComparendoComponent} from '../modals/descartar-comparendo/descartar-comparendo.component';
import {FormBuilder} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';

import {WebStorageCustomService} from '../../../commons/providers/web-custom-storage.service';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {ESTADOCOMPARENDOS} from '../../../commons/constants/app';
import {FileViewverComponent} from '../../../commons/modals/file-viewver/file-viewver.component';
import {load} from '@angular/core/src/render3';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {AppService} from '@app/app.service';

declare var window;

@Component({
  selector: 'app-ver-comparendos',
  templateUrl: './ver-comparendos.component.html',
  styleUrls: ['./ver-comparendos.component.scss',
    '../../../../vendor/libs/spinkit/spinkit.scss',
    '../../../../vendor/libs/ng2-nouislider/ng2-nouislider.scss',
    '../../../../vendor/libs/ngx-swiper-wrapper/ngx-swiper-wrapper.scss']
})
export class VerComparendosComponent implements OnInit, OnDestroy, AfterViewInit {


  @BlockUI() blockUIPage: NgBlockUI;

  swiper3dCoverflowEffect: SwiperConfigInterface = {
    effect: 'coverflow',
    grabCursor: false,
    allowTouchMove: false,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  fullConfig = {
    connect: true,
    behaviour: 'tap-drag',
    step: 1,
    tooltips: true,
    range: {
      'min': 0,
      'max': 200
    },
    direction: 'ltr'
  };

  fullValue1 = 100;


  private comparendo: any;
  images: any;
  image: any;
  totalItems: number = null;
  queryCount: number = null;
  itemsPerPage = 15;
  page: number = null;
  predicate = 'id';
  reverse: any;
  totalPage: number = null;
  today = Date.now();
  comparendos: any;
  comparendoPlaca: any;
  sizecomparendosCertificados: any;
  sizecomparendosTotal: any;
  sizecomparendosSinCertificar: any;
  valuemax: any;
  idComparendo: any = null;
  pdfUno: string;
  pdfDos: string;
  sizeComparendosPorCertificar: number;
  permisoCertificar: any;
  buttonRegeneraPDF: any = false;
  buttonDescertificar: any = false;

  //Constante
  EstadoComaprendo: any;
  readonly ESTADOCOMPARENDOS: typeof ESTADOCOMPARENDOS = ESTADOCOMPARENDOS;
  estadoTipoComparendoActual: any;

  // Id del usuario actual
  usuarioId = null;
  permisosUsuario = null;
  editarPlacaPermiso: any;
  permisoUsuario: any;
  imagenResponse: any;
  listImagenCliche: Array<any> = [];
  listImagenCrop: Array<any> = [];
  listVideos: Array<any> = [];
  private lupa = false;
  permisos: Array<any>;
  permisoCreados: any;
  private comparendoPrevNext: any;
  private cargando: boolean = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private modalService: NgbModal,
              private toast: CustomToastrService,
              private authService: AuthService,
              private _sanitizer: DomSanitizer,
              private comparendoService: ComparendoService,
              private webStorageCustomService: WebStorageCustomService,
              private route: ActivatedRoute,
              private appService: AppService,
              private archivoService: ArchivoService) {
    this.page = 1;

    this.route.params.subscribe(params => {
      this.idComparendo = params.id;
      console.log('params ', this.estadoTipoComparendoActual);

    });

    this.usuarioId = JSON.parse(this.webStorageCustomService.getFromLocal('usuario')).id;
    this.permisosUsuario = this.webStorageCustomService.checkFromLocal('permisos') ? JSON.parse(this.webStorageCustomService.getFromLocal('permisos')) : null;

    this.editarPlacaPermiso = (this.permisosUsuario.includes('dashboard.editar'));
    this.permisoUsuario = this.permisosUsuario.includes('dashboard.certificar');

    this.permisoCreados = this.permisosUsuario.includes('dashboard.creados_propios');
    this.appService.pageTitle = 'Ver Comparendo';


  }


  async ngOnInit() {


    /* if (this.webStorageCustomService.checkFromLocal('permisos')) {
      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));
    } */


    this.findComparendo(this.idComparendo);
  }

  async findComparendo(id: number) {
    this.cargando = true;
    this.comparendoPlaca = null;
    this.listImagenCliche = [];
    this.listImagenCrop = [];
    this.listVideos = [];
    await this.comparendoService.find(id).toPromise().then(data => {

        this.comparendoPlaca = data;
        this.idComparendo = data.id;
        this.estadoTipoComparendoActual = data.tipoEstadoComparendo.id;

        (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdf')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdf)[0] : (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdfBlank')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdfBlank)[0] : '';

        this.archivoService.findByXml(this.comparendoPlaca.archivoXml.id).toPromise().then((res: any) => {

            console.log('res archivos', res);
            this.pushToSlide(res);
          },
          (err) => {
            this.onError(err.message);
          }
        );

        this.findPrevNextComparendo();
        this.cargando = false;

      }, (err) => this.onError(err.message)
    );


  }


  ngAfterViewInit() {

  }

  getStatusFile(element) {

    return new Promise(async (resolve, reject) => {

      let responseBool = false;
      var request = new Request(element.url);
      await fetch(request, {mode: 'cors'})
        .then(
          (response) => {
            if (response.status == 200) {
              responseBool = true;
            }
          }
        )
        .catch((err) => {
          responseBool = false;
          console.log('Fetch Error :-S', err);
        });


      return resolve(responseBool);
    });

  }

  upload(path: string, onLoad?: Function, onSuccess?: Function, onError?: Function) {
    const data = new FormData();

    const request = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        let resp: { status: number | string; data: any };
        try {
          resp = {
            status: request.status,
            data: JSON.parse(request.response)
          };
        } catch (e) {
          resp = {
            status: 'error',
            data: 'Unknown error occurred: [' + request.responseText + ']'
          };
        }
        if (resp.status === 200) {
          onSuccess(resp);
        } else {
          onError(resp);
        }

      }
    };

    // request.open('POST', `http://10.30.0.21:8082/archivos/upload`);
    request.open('GET', `${path}`);
    request.send(data);
  }

  abrirPDF() {
    /* window.open(this.pdfUno, '_blank'); */

    let modal = this.modalService.open(FileViewverComponent, {backdrop: 'static', centered: true, size: 'lg'});

    modal.componentInstance.archivo = {url: this.pdfUno, extension: 'application/pdf', nombre: 'PDF'};

    modal.result.then(
      (success: Object) => {

      },
      (error: any) => {
        // console.error(error);
      }
    );

    /* const win = window.open(this.pdfUno, '_blank');
    win.focus(); */
  }

  generarPDF(comparendoPlaca: any) {
    // this.comparendoPlaca.usuarioId = this.usuarioId;

    if (comparendoPlaca.infractor !== undefined) {

      this.buttonRegeneraPDF = true;
      this.blockUIPage.start();
      this.comparendoService.generaPdf(comparendoPlaca).subscribe((result) => {

        this.comparendoPlaca = result;
        this.buttonRegeneraPDF = false;
        this.blockUIPage.stop();

        (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdf')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdf)[0] : (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdfBlank')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdfBlank)[0] : '';

        this.toast.showToast('success', 'Informacion', 'Se actualizo el PDF.');
      }, (err) => {
        this.toast.showToast('error', 'Error', 'No se pudo actualizar el PDF.');
        this.buttonRegeneraPDF = false;
        this.blockUIPage.stop();
        this.onError(err.message);
      });
    } else {
      this.toast.showToast('error', 'Error', 'No se puede generar pdf debido a que el comparendo no tiene infractor');

    }

  }

  validarPDF(comparendoPlaca: any) {
    this.buttonRegeneraPDF = true;

    comparendoPlaca.usuarioId = this.usuarioId;
    this.comparendoService.validarPDF(comparendoPlaca).subscribe((result) => {

      this.comparendoPlaca = result;
      this.buttonRegeneraPDF = false;
      (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdf')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdf)[0] : (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdfBlank')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdfBlank)[0] : '';
      this.toast.showToast('success', 'Informacion', 'Se validó y actualizo el estado del comparendo a Por certificar');
    }, (err) => {
      this.toast.showToast('error', 'Error', 'No se pudo validar y actualizar el estado del comparendo.');
      this.buttonRegeneraPDF = false;
      this.onError(err.message);
    });
  }

  descertificar(comparendoPlaca: any) {
    this.buttonDescertificar = true;
    comparendoPlaca.usuario = this.usuarioId;
    this.comparendoService.descertificar(comparendoPlaca).subscribe((result) => {
      this.comparendoPlaca = result;
      (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdf')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdf)[0] : (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdfBlank')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdfBlank)[0] : '';

      this.buttonDescertificar = false;
      this.toast.showToast('success', 'Informacion', 'Se descertificó el comparendo.');
    }, (err) => {
      this.toast.showToast('error', 'Error', 'No se pudo descertificar el comparendo.');
      this.buttonDescertificar = false;
      this.onError(err.message);
    });
  }

  toPorCertificar(comparendoPlaca: any) {
    this.buttonDescertificar = true;
    comparendoPlaca.usuario = this.usuarioId;
    this.comparendoService.porCertificar(comparendoPlaca).subscribe((result) => {
      this.comparendoPlaca = result;
      (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdf')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdf)[0] : (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdfBlank')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdfBlank)[0] : '';

      this.buttonDescertificar = false;
      this.toast.showToast('success', 'Informacion', 'Se actualizó el comparendo.');
    }, (err) => {
      this.toast.showToast('error', 'Error', 'No se pudo actualizar el estado del comparendo.');
      this.buttonDescertificar = false;
      this.onError(err.message);
    });
  }

  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  public open(comparendoPlaca?: any, permiso?: string) {

    if (this.validatePermission(permiso)) {
      const modal: NgbModalRef = this.modalService.open(EditPlacaComponent, {});
      if (comparendoPlaca) {
        modal.componentInstance.comparendo = comparendoPlaca;
      }
      modal.result.then(
        (success: Object) => {
          this.webStorageCustomService.saveToLocal('comparendoId', success['response'].id);
          this.idComparendo = success['response'].id;
          this.estadoTipoComparendoActual = success['response'].tipoEstadoComparendo.id;
          this.listImagenCliche = [];
          this.listImagenCrop = [];
          this.listVideos = [];
          this.router.navigate([`/ver-comparendos/${this.idComparendo}`]).then(() => {
            this.ngOnInit();

            // window.location.reload();
          });
        },
        (error: any) => {
          //  console.error(error);
        }
      );
    } else {
      this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Editar placas');
    }
  }

  public openObservaciones(comparendoPlaca?: any, accionComparendo?: boolean, tipoAccion?: any) {

    const modal: NgbModalRef = this.modalService.open(DescartarComparendoComponent, {backdrop: 'static'});
    if (comparendoPlaca) {
      modal.componentInstance.comparendo = comparendoPlaca;
      modal.componentInstance.accionComparendo = accionComparendo;
      modal.componentInstance.tipoAccion = tipoAccion != null ? tipoAccion : '';
    }
    modal.result.then(
      (success: any) => {
        this.toast.showToast('success', '', 'Se ha actualizado el estado satisfactoriamente');

        this.comparendoPlaca = success.response;
        (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdf')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdf)[0] : (this.comparendoPlaca != null && this.comparendoPlaca.hasOwnProperty('pdfBlank')) ? this.pdfUno = JSON.parse(this.comparendoPlaca.pdfBlank)[0] : '';

        /* this.idComparendo != null ? this.router.navigate(['/dashboard/dashboard']) : this.ngOnInit(); */
      },
      (error: any) => {
        /*         console.log("estadoAnterior ",  error); */

        error = !null ? this.comparendoPlaca.tipoEstadoComparendo.id = error.id : this.comparendoPlaca.tipoEstadoComparendo.id = error.estadoAnterior;

        /*  this.toast.showToast('error', 'Error.', 'Error al actualizar la información de la placa'); */
      }
    );

  }


  public validatePermission(accion: string) {

    if (this.permisosUsuario.length > 0) {

      if (this.permisosUsuario.includes('root.all') || this.permisosUsuario.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  magnify(id: any, archivo: any) {

    console.log('id', id);
    archivo.lupa = true;
    this.lupa = true;
    const zoom = 5;
    let img, glass, w, h, bw;
    img = id;
    /* Create magnifier glass: */
    glass = document.createElement('div');
    glass.setAttribute('class', 'img-magnifier-glass');
    /* Insert magnifier glass: */
    img.style.cursor = 'none';
    img.parentElement.insertBefore(glass, img);
    /* Set background properties for the magnifier glass: */
    glass.style.backgroundImage = 'url(\'' + img.src + '\')';
    glass.style.backgroundRepeat = 'no-repeat';
    glass.style.width = img.width / 2.5 + 'px ';
    glass.style.height = img.height / 3 + 'px ';
    glass.style.backgroundSize = (img.width * zoom) + 'px ' + (img.height * zoom) + 'px';
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    const moveMagnifier = (e) => {
      let pos, x, y;
      /* Prevent any other actions that may occur when moving over the image */
      e.preventDefault();
      /* Get the cursor's x and y positions: */
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      /* Prevent the magnifier glass from being positioned outside the image: */
      if (x > img.width - (w / zoom)) {
        x = img.width - (w / zoom);
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - (h / zoom)) {
        y = img.height - (h / zoom);
      }
      if (y < h / zoom) {
        y = h / zoom;
      }
      /* Set the position of the magnifier glass: */
      glass.style.left = (x - w) + 'px';
      glass.style.filter = 'brightness(' + archivo.brightness + '%)';
      glass.style.top = (y - h) + 'px';
      /* Display what the magnifier glass "sees": */
      glass.style.backgroundPosition = '-' + ((x * zoom) - w + bw) + 'px -' + ((y * zoom) - h + bw) + 'px';
    };
    /* Execute a function when someone moves the magnifier glass over the image: */
    glass.addEventListener('mousemove', moveMagnifier);
    img.addEventListener('mousemove', moveMagnifier);
    /*and also for touch screens:*/
    glass.addEventListener('touchmove', moveMagnifier);
    img.addEventListener('touchmove', moveMagnifier);
    const getCursorPos = (e) => {
      let a, x = 0, y = 0;
      e = e || window.event;
      /* Get the x and y positions of the image: */
      a = img.getBoundingClientRect();
      /* Calculate the cursor's x and y coordinates, relative to the image: */
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /* Consider any page scrolling: */
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return {x: x, y: y};
    };

  }

  ngOnDestroy(): void {

    this.comparendo = null;
    this.images = null;
    this.image = null;
    this.totalItems = null;
    this.queryCount = null;
    this.itemsPerPage = null;
    this.page = null;
    this.predicate = null;
    this.reverse = null;
    this.totalPage = null;
    this.today = Date.now();
    this.comparendos = null;
    this.comparendoPlaca = null;
    this.sizecomparendosCertificados = null;
    this.sizecomparendosTotal = null;
    this.sizecomparendosSinCertificar = null;
    this.valuemax = null;
    this.idComparendo = null;
    this.pdfUno = null;
    this.pdfDos = null;

  }

  async siguienteEstado() {

    this.router.navigate([`/ver-comparendos/${this.comparendoPrevNext.next}`]).then(() => {
      this.ngOnInit();

      // window.location.reload();
    });
  }

  async anteriorEstado() {

    this.router.navigate([`/ver-comparendos/${this.comparendoPrevNext.prev}`]).then(() => {
      this.ngOnInit();
      // window.location.reload();
    });


  }


  getfilters(archivo) {
    return this._sanitizer.bypassSecurityTrustStyle('brightness(' + archivo.brightness + '%)');
  }

  pushToSlide(array: any) {

    if (array.length > 0) {

      array.forEach(async (element) => {
        if (element.url != null) {
          const status = await this.getStatusFile(element);

          if (status) {

            if (element.tipoArchivo.id === 2) {

              element.lupa = false;
              element.brightness = 100;
              this.listImagenCrop.push(element);

            } else if (element.tipoArchivo.id === 3) {
              this.listVideos.push(element);
            }
          }
        }


      });

    }

  }


  async findPrevNextComparendo() {

    let req = {
      comparendoId: this.idComparendo,
      tipoEstadoComparendoId: this.estadoTipoComparendoActual
    };

    if (this.permisoCreados) {
      req['usuarioId'] = this.usuarioId;
    }
    await this.comparendoService.findPrevNextComparendo(req).toPromise().then(async (data) => {

        this.comparendoPrevNext = data;
        console.log('comparendo prev next', data);
      }, (err) => this.onError(err.message)
    );
  }

}
