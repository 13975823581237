import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TipoDocumentoService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';

@Component({
  selector: 'app-new-edit-tipo-documento',
  templateUrl: './new-edit-tipo-documento.component.html',
  styleUrls: ['./new-edit-tipo-documento.component.scss']
})
export class NewEditTipoDocumentoComponent implements OnInit {

  @Input() private tipoDocumento: any;
  private tipoDocumentoForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    private formBuilder: FormBuilder,
    public tipoDocumentoService: TipoDocumentoService,
  ) {
  }

   ngOnInit() {

    this.initForms();
    if (this.tipoDocumento) {
      this.patchValues(this.tipoDocumento);
    }

  }

  private initForms() {

    this.tipoDocumentoForm = this.formBuilder.group({
      id: null,
      nombre: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      descripcion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      eliminado: false
    });

  }

  private patchValues(tipoDocumento: Object) {
    this.tipoDocumentoForm.patchValue(tipoDocumento);
  }

  public async modalclose() {
    if (this.tipoDocumentoForm.valid) {
      const itemF = await this.createOrUpdateItem(this.tipoDocumentoForm.value, this.tipoDocumento ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(tipoDocumento: any, exits: any): Promise<any> {
    if (!exits) {
      return this.tipoDocumentoService.create(tipoDocumento).toPromise();
    }
    if (exits) {
      return this.tipoDocumentoService.update(tipoDocumento).toPromise();
    }
  }

}


