import { Component, OnInit, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit, OnDestroy {

  @Output() unSubscribe = new EventEmitter<any>();

  title: string;
  body: string;
  closeMessage: string;
  subscription: Subscription;

  suffix = '';
  suffixInterval;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this._setSuffix();
  }

  _setSuffix(): void {
    this.suffixInterval = setInterval(() => {
      console.log('Interval execution');
      if (this.suffix.length < 3) {
        this.suffix += '.';
      } else {
        this.suffix = '';
      }
    }, 1000);
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
    if (this.subscription && !this.subscription.closed) {
      console.log('Cancelar subscripcion');
      this.subscription.unsubscribe();
      this.unSubscribe.emit();
    } else {
      this.unSubscribe.emit();
    }
    if (this.suffixInterval) {
      clearInterval(this.suffixInterval);
    }
  }

}
