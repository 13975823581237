import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from '../helpers/request-util';
import {environment} from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// Own
// Types
import { ColumnDefinitions } from '../commons/interfaces/types/column-definitions';

@Injectable({
  providedIn: 'root'
})
export class TiposVehiculoReportesService {

  public resourceUrl = environment.apiUrl + 'comparendo/' + 'tipo_vehiculo';

  constructor(private http: HttpClient) { }

  // REPORTE TIPOS INFRACCION

  reporteTipoInfraccionData(req: { [key: string]: any }): Observable<{ [key: string]: any }[]> {
    const options = createRequestOption(req);
    return this.http.get<{ [key: string]: any }[]>(`${this.resourceUrl}/reporte_tipo_vehiculo_tipo_infraccion`, { params: options });
  }

  getReporteTipoInfraccionColumnDefinitions(data: any[]): ColumnDefinitions[] {
    const columns: ColumnDefinitions[] = [];
    if (data.length > 0) {
      const reference = data[0];
      Object.keys(reference).forEach((key: string) => {
        // if (key !== 'camaraId' && key !== 'codigo' && key !== 'nombre') {
          columns.push({
            'columnName': key,
            'valueGetter': (item: any) => item[key] !== null ? item[key] : '',
            'columnType': 0
          });
        // }
      });
    }
    let newColumnDefinitions = [];
      newColumnDefinitions.push(columns.filter(e => e.columnName === 'Tipo Vehiculo')[0]);
      columns.forEach((column) => {
        if (column.columnName !== 'Tipo Vehiculo') {
          newColumnDefinitions.push(column);
        }
      });
    return newColumnDefinitions;
  }

  getReporteTipoInfraccionDataChart(content: any[], columns: ColumnDefinitions[], chartBaseObject: any, colors: string[]): any {
    const chart = JSON.parse(JSON.stringify(chartBaseObject));
    // chart.type = 'horizontalBar';
    chart.options.title.text = 'Tipos de infraccion';
    chart.options.legend.display = true;
    chart.data.datasets = [];
    content.forEach((element) => {
      chart.data.labels.push(element['Tipo Vehiculo']);
    });

    console.log('COLUMNAS TIPO INFRACCION ', columns);

    columns.forEach((column: ColumnDefinitions, index: number) => {
      const data = [];
      content.forEach((content) => {
        data.push(content[column.columnName]);
      });
      if (column.columnName !== 'Tipo Vehiculo') {
        chart.data.datasets.push({
          'data': data,
          'backgroundColor': colors[index],
          'label': column.columnName
        });
      }
    });

    console.log('GENERAR GRAFICO CON ', content, 'CHART ', chart);
    return chart;
  }

}
