import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';
import {CriptUtilService} from '../../helpers/criptUtil.service';

@Injectable({
  providedIn: 'root'
})
export class WebStorageCustomService {


  constructor(@Inject(LOCAL_STORAGE) private local: StorageService,
              @Inject(SESSION_STORAGE) private session: StorageService,
              private criptUtilService: CriptUtilService) {

  }

  public deleteFromLocal(key: string) {

    this.local.remove(key);

  }

  public getFromLocal(key: string) {

    if (this.local.has(key)) {
      const valuedecripted = this.criptUtilService.decrypt(this.local.get(key));
      return valuedecripted;
    } else {
      return null;
    }
  }

  public saveToLocal(key: string, value: any) {

    const valueEncrypted = this.criptUtilService.encript(value);
    this.local.set(key, valueEncrypted);
  }

  public checkFromLocal(key: string) {
    return this.local.has(key);
  }

  public clearLocal() {
    this.local.clear();
  }


  public deleteFromSession(key: string) {

    this.session.remove(key);

  }

  public saveToSession(key: string, value: any) {
    const valueEncrypted = this.criptUtilService.encript(value);
    this.session.set(key, valueEncrypted);

  }

  public checkFromSession(key: string) {
    return this.session.has(key);
  }

  public clearSession() {
    this.session.clear();
  }

  public getFromSession(key: string) {

    if (this.session.has(key)) {
      const valuedecripted = this.criptUtilService.decrypt(this.session.get(key));
      return valuedecripted;
    } else {
      return null;
    }

  }

}
