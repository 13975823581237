import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
// import { AppService } from '../../app.service';
import { AuthService } from '../../providers/auth.service';

@Injectable()
export class UnauthenticatedGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.authService.checkToken().subscribe((data) => {
                this.router.navigate(['/' + this.authService.findModuloPrincipal()]);
                resolve(false);
            }, (error) => {
                resolve(true);
            });
        });
        return promise;
    }
}
