import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {createRequestOption} from '../helpers/request-util';

@Injectable({
  providedIn: 'root'
})
export class TipoDocumentoService {

  public resourceUrl = environment.apiUrl + 'user/tipoDocumentos';
  constructor(private http: HttpClient) { }

  create(tipoDocumento: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, tipoDocumento);
  }

  update(tipoDocumento: any): Observable<any> {
    return this.http.put<any>(this.resourceUrl, tipoDocumento);
  }

  find(id: number): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/${id}`);
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(this.resourceUrl, { params: options});
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`);
  }
}
