import {Component, OnInit} from '@angular/core';
import {UsuarioService} from '../../providers';
import {Router, ActivatedRoute} from '@angular/router';
import {CustomToastrService} from '@app/commons/providers/custom-toastr.service';

@Component({
  selector: 'app-cambiar-contrasena',
  templateUrl: './cambiar-contrasena.component.html',
  styleUrls: [
    './cambiar-contrasena.component.scss',
    '../../../vendor/styles/pages/authentication.scss'
  ]
})

export class CambiarContrasenaComponent implements OnInit {

  // public loginForm: FormGroup;
  private errorPassword: boolean = false;
  public credentials: any = {
    contrasena: '',
    confirmarContrasena: ''
  };

  constructor(private usuarioService: UsuarioService,
              public toastService: CustomToastrService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {

  }

  changePassword() {
    if (this.credentials.contrasena === this.credentials.confirmarContrasena) {
      const token: string = this.route.snapshot.queryParamMap.get('token');

      this.usuarioService.changePassword(token, this.credentials.contrasena).subscribe(
        data => {
          this.errorPassword = false;
          this.toastService.showToast('success', 'Información', 'La contraseña ha sido cambiada correctamente.');

          this.router.navigate(['/login']);
        },
        err => {
          this.toastService.showToast('error', 'Error', 'Ha ocurrido un error al cambiar la contraseña');

          this.credentials = {contrasena: '', confirmarContrasena: ''};
          this.router.navigate(['/login']);
        }
      );
    } else {
      this.errorPassword = true;
      this.credentials = {contrasena: '', confirmarContrasena: ''};
    }
  }

  closeAlert() {
    this.errorPassword = false;
  }

}
