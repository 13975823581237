import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RadioAccionService} from '../../../../providers';
import {CustomToastrService} from '../../../../commons/providers/custom-toastr.service';

@Component({
  selector: 'app-new-edit-radio-accion',
  templateUrl: './new-edit-radio-accion.component.html',
  styleUrls: ['./new-edit-radio-accion.component.scss']
})
export class NewEditRadioAccionComponent implements OnInit {

  @Input() private radioAccion: any;
  private radioAccionForm: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private toastService: CustomToastrService,
    private formBuilder: FormBuilder,
    public radioAccionService: RadioAccionService,
  ) {
  }

  ngOnInit() {

    this.initForms();
    if (this.radioAccion) {
      this.patchValues(this.radioAccion);
    }

  }

  private initForms() {

    this.radioAccionForm = this.formBuilder.group({
      id: null,
      fechaCreacion: null,
      fechaActualizacion: null,
      eliminado: false,
      descripcion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      idSimitMovilixa: [null, [Validators.required, Validators.minLength(1)]],
    });

  }

  private patchValues(radioAccion: Object) {
    this.radioAccionForm.patchValue(radioAccion);
  }

  public async modalclose() {
    if (this.radioAccionForm.valid) {
      const itemF = await this.createOrUpdateItem(this.radioAccionForm.value, this.radioAccion ? true : false);
      this.modal.close({itemF});
    }
  }

  private async createOrUpdateItem(radioAccion: any, exits: any): Promise<any> {
    if (!exits) {
      return this.radioAccionService.create(radioAccion).toPromise();
    }
    if (exits) {
      return this.radioAccionService.update(radioAccion).toPromise();
    }
  }

}

