import {Component, OnInit} from '@angular/core';
import {
  AuthService,
  RolService,
  TipoDocumentoService,
  UploadFileService,
  UsuarioService
} from '../../providers';
import {CustomToastrService} from '../../commons/providers/custom-toastr.service';

import {ActivatedRoute, Router} from '@angular/router';
import {NgbDateParserFormatter, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder} from '@angular/forms';
import {NewEditUsuarioComponent} from './modals/new-edit-usuario/new-edit-usuario.component';
import {ExportAsConfig, ExportAsService} from 'ngx-export-as';
import {ModalMostrarTodoComponent} from '../../commons/modals/modal-mostrar-todo/modal-mostrar-todo.component';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';
import {TourService} from 'ngx-tour-ng-bootstrap';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
  fechaInicio: any;
  fechaFin: any;
  totalItems: number = null;
  queryCount: number = null;
  itemsPerPage: number = null;
  page: number = null;
  predicate: any;
  reverse: any;
  users: any;
  totalPage: number = null;
  filtroSelect: any[];
  filterInput: any = '';
  filterUser: any = {
    nombre: null,
    filtro: null
  };
  showTable = false;
  cargarTodo = false;
  cantidadItems = 25;
  permisos: Array<string>;

  constructor(
    private usuarioService: UsuarioService,
    private rolService: RolService,
    private uploadService: UploadFileService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private parserFormatter: NgbDateParserFormatter,
    private tipoDocumentoService: TipoDocumentoService,
    private toast: CustomToastrService,
    private router: Router,
    public tourService: TourService,
    public webStorageCustomService: WebStorageCustomService,
    private authService: AuthService,
    private exportAsService: ExportAsService
  ) {

    this.itemsPerPage = this.cantidadItems;
    this.page = 1;
    this.predicate = 'id';
    this.filtroSelect = [
      {
        nombre: 'Nombre',
        filtro: 'nombre'
      },
      {
        nombre: 'Identificación',
        filtro: 'identificacion'
      }
      ,
      {
        nombre: 'Fecha Creación',
        filtro: 'fecha'
      }
    ];

    this.tourService.initialize([
      {
        anchorId: 'tour-1',
        title: 'Crear usuario',
        content: 'Boton que sirve para la creacion de un nuevo usuario del sistema.',
        placement: 'left'
      },
      {
        anchorId: 'tour-2',
        title: 'Filtro de resultados',
        content: 'Select para seleccionar alguno de los filtros.'
      },
      {
        anchorId: 'tour-3',
        title: 'Buscar Usuarios',
        content: 'Boton que sirve para aplicar los filtros a la tabla.',
        placement: 'left'
      },
      {
        anchorId: 'tour-4',
        title: 'Boton para descargar',
        content: 'Boton que sirve para descargar la información de la tabla en diferente tipo de archivos.'
      },
      {
        anchorId: 'tour-5',
        title: 'Boton Cargar Todo',
        content: 'Boton que sirve para mostrar todos los registros en la tabla.',
        placement: 'left'
      },
      {
        anchorId: 'tour-6',
        title: 'Boton Estado',
        content: 'Boton que sirve para activar o desactivar un usuario del sistema.',
        placement: 'left'
      },
      {
        anchorId: 'tour-7',
        title: 'Boton Editar',
        content: 'Boton que sirve para editar la información de un usuario del sistema.',
        placement: 'left'
      },
      {
        anchorId: 'tour-8',
        title: 'Boton Eliminar',
        content: 'Boton que sirve para eliminar de un usuario del sistema.',
        placement: 'left'
      }
    ], {
      prevBtnTitle: 'Anterior.',
      nextBtnTitle: 'Siguiente',
      endBtnTitle: 'Finalizar'
    });

  }


  ngOnInit() {


    if (this.webStorageCustomService.checkFromLocal('permisos')) {

      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));

    }

    this.loadAll();


  }

  async loadAll() {
    const query = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort()
    };

    if (this.filterUser !== null) {

      if (this.filterUser && this.filterUser.filtro !== 'fecha' && this.filterUser.filtro !== null) {
        query[this.filterUser.filtro] = this.filterInput;
      } else if (this.filterUser.filtro === 'fecha') {
        query['fechaInicio'] = this.parserFormatter.format(this.fechaInicio);
        query['fechaFin'] = this.parserFormatter.format(this.fechaFin);
      }

    }


    await this.usuarioService
      .query(query)
      .toPromise().then(
        (res: any) => {
          this.queryCount = res.numberOfElements;
          this.totalPage = res.totalPages;
          this.totalItems = res.totalElements;
          this.users = res.content;

          if (res.content.length > 0) {
            this.showTable = true;
          } else {
            this.showTable = false;
          }

        },
        (err) => {
          this.showTable = false;
          this.onError(err.message);
        }
      );
  }

  sortlist(predicate: string) {
    this.predicate = predicate;
    this.reverse = !this.reverse;
    this.loadAll();
  }

  transition() {
    this.loadAll();
  }

  sort() {
    const result = [this.predicate + ',' + (this.reverse ? 'asc' : 'desc')];
    return result;
  }


  public onError(errorMessage: string) {
    console.error(errorMessage, null, null);
  }

  filtrar() {
    this.loadAll();
  }

  public open(user?: any, permiso?: string) {
    if (this.validatePermission(permiso)) {
      const modal: NgbModalRef = this.modalService.open(NewEditUsuarioComponent, {backdrop: 'static', centered: true, container: 'body'});
      modal.componentInstance.fromUsers = true;
      if (user) {
        user.file = null;
        modal.componentInstance.usuario = user;
      }
      modal.result.then(
        (success: Object) => {
          this.loadAll();
        },
        (error: any) => {
          // console.error(error);
        }
      );
    } else {
      this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de roles');
    }
  }

  deleteUser(id: number) {
    if (this.validatePermission('usuarios.eliminar')) {
      this.usuarioService.delete(id).subscribe(res => {

        this.loadAll();
      });
    } else {
      this.toast.showToast('error', 'No posee permisos para realizar esta accion', 'Administración de usuarios');
    }
  }

  public validatePermission(accion: string) {

    if (this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }


  }

  export(tipo: any) {

    const exportAsConfig: ExportAsConfig = {
      type: tipo,
      elementId: 'table',
      options: {
        orientation: 'landscape'
      }
    };
    this.exportAsService.save(exportAsConfig, 'lista_usuarios');

  }

  todo() {

    this.cargarTodo = !this.cargarTodo;

    if (this.cargarTodo) {
      this.mostrarTodoModal();
    } else {
      this.itemsPerPage = this.cantidadItems;
      this.loadAll();
    }


  }


  public mostrarTodoModal() {
    const modal: NgbModalRef = this.modalService.open(ModalMostrarTodoComponent, {size: 'sm'});
    modal.componentInstance.texto = this.totalItems;
    modal.result.then(
      (success: Object) => {
        this.itemsPerPage = this.totalItems;
        this.loadAll();
      },
      (error: any) => {
        this.cargarTodo = !this.cargarTodo;
        this.loadAll();
      }
    );

  }


  updateUsuario(user: any) {

    this.usuarioService.update(user).subscribe((res) => {
      this.toast.showToast('success', 'Informacion', 'Usuario actualizado satistactoriamente.');
    });

  }

  startTour() {
    this.tourService.start();
  }
}
