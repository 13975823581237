import {Component, OnInit, ViewChild, NgZone, ComponentFactoryResolver, Type} from '@angular/core';
import {DatePipe} from '@angular/common';
import {FormControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, tap, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';

// Types
import {Usuario} from '@app/models/usuario';
import {Comparendo} from '@app/commons/interfaces/types/comparendos';
import {ColumnDefinitions} from '@app/commons/interfaces/types/column-definitions';
import {TipoEstadoComparendo} from '@app/commons/interfaces/types/tipo-estado-comparendo';
// Services
import {CamaraService} from '@app/providers/camara.service';
import {SecretariaService} from '@app/providers/secretaria.service';
import {CiudadService} from '@app/providers/ciudad.service';
import {InfraccionService} from '@app/providers/infraccion.service';
import {InfractorService} from '@app/providers/infractor.service';
import {UsuarioService} from '@app/providers/usuario.service';
import {TiposConsultaService} from '@app/providers/tipos-consulta.service';
import {MaquinasReportesService} from '@app/providers/maquinas-reportes.service';
import {TiposServicioReportesService} from '@app/providers/tipos-servicio-reportes.service';
import {TiposVehiculoReportesService} from '@app/providers/tipos-vehiculo-reportes.service';
import {AgentesReportesService} from '@app/providers/agentes-reportes.service';
import {ConsultasExternasReportesService} from '@app/providers/consultas-externas-reportes.service';
import {ComparendosReportesService} from '@app/providers/comparendos-reportes.service';
import {UploadFileService} from '@app/providers/uploadFile.service';
import {TipoEstadoComparendoService} from '@app/providers/tipo-estado-comparendo.service';
import {CustomToastrService} from '@app/commons/providers/custom-toastr.service';
import {EstadoComparendoUsuarioService} from '@app/providers/estadoComparendoUsuario.service';
import {WebStorageCustomService} from '@app/commons/providers/web-custom-storage.service';

import {TipoVehiculoService} from '@app/providers/tipoVehiculo.service';
import {ClaseServicioService} from '@app/providers/claseServicio.service';

// Utils
import {makeDateInstance, isValidDate} from '../../commons/utils/general';
// Components
import {ResultadoReporteComponent} from '@app/components/reportes/resultado-reporte/resultado-reporte.component';
import {ConsultasExternasLogComponent} from '@app/components/consultas-externas-log/consultas-externas-log.component';
import {ComparendoEstadosComponent} from '@app/components/comparendo-estados/comparendo-estados.component';
// Directives
import {DetailContainerDirective} from '@app/commons/directives/detail-container.directive';
// Constants
import {ESTADOCOMPARENDOS} from '@app/commons/constants/app';

interface TipoReporte {
  text: string;
  reports: OpcionReporte[];
}

interface OpcionReporte {
  key: string;
  text: string;
  params?: any;
  filters: string[];
}

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})
export class ReportesComponent implements OnInit {
  // Reporte result instance
  @ViewChild(ResultadoReporteComponent) resultadoReporteComponent: ResultadoReporteComponent;

  // Container ref
  @ViewChild(DetailContainerDirective, ({static: true} as any)) detailContainerDirective: DetailContainerDirective;

  private permisos: Array<string>;

  public disabledDownload = true;
  public isRTL = false;
  public opcionesReportes: OpcionReporte[] = [];
  public opcionReporteControl: FormControl;
  public tipoReporteControl: FormControl;
  public selectedFilters: string[] = [];
  public selectedOption: OpcionReporte;
  public generating = false;
  public tiposConsultas: any[];

  // Instances to apply filters
  public maquinaFilterControl: FormControl;
  public maquinaSelected: any;
  public ciudadFilterControl: FormControl;
  public ciudadSelected: any;
  public secretariaFilterControl: FormControl;
  public secretariaSelected: any;
  public infraccionFilterControl: FormControl;
  public infraccionSelected: any;
  public agenteFilterControl: FormControl;
  public agenteSelected: any;
  public infractorFilterControl: FormControl;
  public infractorSelected: any;
  public desdeFechaControl: FormControl;
  public desdeFechaSelected: any;
  public hastaFechaSelected: any;
  public hastaFechaControl: FormControl;
  public placaControl: FormControl;
  public velocidadControl: FormControl;
  public rangoControl: FormControl;

  public tipoServicioFilterControl: FormControl;
  public tipoServicioSelected: any;

  public tipoVehiculoFilterControl: FormControl;
  public tipoVehiculoSelected: any;

  public tipoConsultaControl: FormControl;

  public filterConfirmada: boolean;
  public queryParams: any;
  public pagination = false;
  public paginationLength = 10;
  public columnDefinitionsFunction: Function;
  public dataChartOptionsFunction: Function;

  // Data to make the report
  public columnDefinitions: ColumnDefinitions[];
  public queryFunction;
  public dataChartOptions;
  public data;
  public detalleParam: string;
  public reportActions: {
    actions: {
      text: string;
      action: (_context?: any) => void;
    }[], disabledFunction?: (_context?: any) => boolean;
  };

  // Data export formats
  public exportFormats: { text: string; value: string; iconClass: string }[] = [{
    text: 'Excel',
    value: 'xlsx',
    iconClass: 'fas fa-file-excel text-success'
  }, {
    text: 'CSV',
    value: 'csv',
    iconClass: 'fas fa-file-csv text-success'
  }, {
    text: 'XML',
    value: 'xml',
    iconClass: 'fas fa-file-code text-danger'
  }, {
    text: 'Texto',
    value: 'txt',
    iconClass: 'fas fa-file-archive'
  }];

  prefijoNombreReporte: string;

  /*
    Reportes filas vs columnas
  */
  public reporteFilas: TipoReporte[] = [{
    text: 'Máquinas',
    reports: [
      {
        key: 'maquina.tipo_infraccion',
        text: 'Tipos de infraccion',
        filters: ['fechaInicio', 'fechaFin', 'agente', 'maquina', 'tipoVehiculo', 'tipoServicio']
      },
      {
        key: 'maquina.detectada',
        text: 'Detectadas',
        filters: ['fechaInicio', 'fechaFin', 'maquina']
      },
      {
        key: 'maquina.impuesta',
        text: 'Impuestas',
        filters: ['fechaInicio', 'fechaFin', 'maquina']
      },
      {
        key: 'maquina.rechazada',
        text: 'Rechazadas',
        filters: ['fechaInicio', 'fechaFin', 'maquina']
      },
      {
        key: 'maquina.confiabilidad',
        text: 'Nivel de confiabilidad',
        filters: ['fechaInicio', 'fechaFin', 'maquina']
      },
      {
        key: 'maquina.motivo_rechazo',
        text: 'Motivos de rechazo',
        filters: ['fechaInicio', 'fechaFin', 'agente', 'maquina']
      }
    ]
  }, {
    text: 'Tipos de Servicio',
    reports: [
      {
        key: 'tipo_servicio.tipo_infraccion',
        text: 'Tipos de infraccion',
        filters: ['fechaInicio', 'fechaFin', 'agente', 'maquina', 'tipoVehiculo', 'tipoServicio']
      }
    ]
  }, {
    text: 'Tipos de Vehiculo',
    reports: [
      {
        key: 'tipo_vehiculo.tipo_infraccion',
        text: 'Tipos de infraccion',
        filters: ['fechaInicio', 'fechaFin', 'agente', 'maquina', 'tipoVehiculo', 'tipoServicio']
      }
    ]
  }, {
    text: 'Agentes',
    reports: [
      {
        key: 'agente.tipo_infraccion',
        text: 'Tipos de infraccion',
        filters: ['fechaInicio', 'fechaFin', 'agente', 'maquina', 'tipoVehiculo', 'tipoServicio']
      },
      {
        key: 'agente.motivo_rechazo',
        text: 'Motivos de rechazo',
        filters: ['fechaInicio', 'fechaFin', 'agente', 'maquina']
      }
    ]
  }, {
    text: 'Bases de datos externas',
    reports: [
      {
        key: 'base_datos_externa.estado',
        text: 'Estados',
        filters: ['tipoConsulta', 'fechaInicio', 'fechaFin']
      }
    ]
  }, {
    text: 'Comparendos',
    reports: [
      /*{
        key: 'comparendos.comparendos_realizados',
        text: 'Comparendos realizados',
        filters: [
          'fechaInicio',
          'fechaFin',
          'agente',
          'maquina',
          'placa',
          'velocidad',
          'infractor',
          'infraccion',
          'rango',
          'ciudad',
          'secretaria'
        ],
      },
      {
        key: 'comparendos.comparendos_rechazados',
        text: 'Evidencias Rechazadas',
        filters: [
          'fechaInicio',
          'fechaFin',
          'agente',
          'maquina',
          'placa',
          'velocidad',
          'infractor',
          'infraccion',
          'rango',
          'ciudad',
          'secretaria'
        ],
      }*/
    ]
  }];
  public reporteColumnas: any[];

  constructor(private camaraService: CamaraService,
              private infraccionService: InfraccionService,
              private infractorService: InfractorService,
              private usuarioService: UsuarioService,
              private ciudadService: CiudadService,
              private maquinasReportesService: MaquinasReportesService,
              private tiposServicioReportesService: TiposServicioReportesService,
              private tiposVehiculoReportesService: TiposVehiculoReportesService,
              private tipoVehiculoService: TipoVehiculoService,
              private claseServicioService: ClaseServicioService,
              private agentesReportesService: AgentesReportesService,
              private consultasExternasReportesService: ConsultasExternasReportesService,
              private comparendosReportesService: ComparendosReportesService,
              private secretariaService: SecretariaService,
              private tipoEstadoComparendoService: TipoEstadoComparendoService,
              private estadoComparendoUsuarioService: EstadoComparendoUsuarioService,
              private toast: CustomToastrService,
              private datePipe: DatePipe,
              private router: Router,
              private zone: NgZone,
              private tiposConsultaService: TiposConsultaService,
              private webStorageCustomService: WebStorageCustomService,
              private componentFactoryResolver: ComponentFactoryResolver) {
  }

  public ngOnInit() {
    this.buildComparendosReports();

    this.initControls();
    this.validateDefaultReport();
    this.tiposConsultaService.listAll()
      .subscribe((res) => {
        this.tiposConsultas = res;
      });

    if (this.webStorageCustomService.checkFromLocal('permisos')) {
      this.permisos = JSON.parse(this.webStorageCustomService.getFromLocal('permisos'));
    }
  }

  public onAction($event: {
    param: {
      key: string,
      param: number
    };
    query?: any;
  }) {
    console.log('onAction > ', $event);
    const param = $event.param.key.toLocaleLowerCase();
    if (param) {
      console.log(param)
      let componentRef = null;
      switch (param) {
        case 'consulta_externa':
          componentRef = this.loadComponent(ConsultasExternasLogComponent);
          (<ConsultasExternasLogComponent>componentRef.instance).desdeFechaInput = this.desdeFechaControl.value;
          (<ConsultasExternasLogComponent>componentRef.instance).tipoConsulta = this.tipoConsultaControl.value.id;
          (<ConsultasExternasLogComponent>componentRef.instance).hastaFechaInput = this.hastaFechaControl.value;
          (<ConsultasExternasLogComponent>componentRef.instance).initTable();
          (<ConsultasExternasLogComponent>componentRef.instance).goBackEmitter.subscribe(() => {
            this.onGoBackDetail();
            this.clearComponent();
          });
          this.detalleParam = param;
          break;
        case 'comparendo.estados':
          componentRef = this.loadComponent(ComparendoEstadosComponent);
          (<ComparendoEstadosComponent>componentRef.instance).comparendoId = $event.query.comparendoId;
          (<ComparendoEstadosComponent>componentRef.instance).getItems();
          (<ComparendoEstadosComponent>componentRef.instance).goBackEmitter.subscribe(() => {
            this.onGoBackDetail();
            this.clearComponent();
          });
          this.detalleParam = param;
          break;
      }
    }

    console.log(this.detalleParam);
  }

  private buildComparendosReports() {
    console.log('<<<<<>>>>> buildComparendosReports execution');
    let report: { key: string; text: string; filters: string[]; params?: any } = {key: null, text: null, filters: null, params: null};
    report.key = 'comparendos.comparendos_proceso';
    report.text = 'Todos';
    report.filters = [
      'fechaInicio',
      'fechaFin',
      'maquina',
      'placa',
      'velocidad',
      'infractor',
      'infraccion',
      'rango',
      'ciudad',
      'secretaria'
    ];
    this.reporteFilas[this.reporteFilas.length - 1].reports.push(report);
    // console.log('Reportes comparendos filas: ', JSON.stringify(this.reporteFilas));
    this.tipoEstadoComparendoService.findAll()
      .subscribe((tiposEstados: TipoEstadoComparendo[]) => {
        tiposEstados.forEach((estado: TipoEstadoComparendo) => {
          let report: { key: string; text: string; filters: string[]; params?: any } = {key: null, text: null, filters: null, params: null};
          const filters = [
            'fechaInicio',
            'fechaFin',
            'maquina',
            'placa',
            'velocidad',
            'infractor',
            'infraccion',
            'rango',
            'ciudad',
            'secretaria'
          ];
          if (estado.id === ESTADOCOMPARENDOS.idComparendoCertificado) {
            filters.push('agente');
            report.key = 'comparendos.comparendos_realizados';
          } else {
            report.key = 'comparendos.comparendos_proceso';
          }

          report.text = estado.descripcion;
          report.filters = filters;
          report.params = estado;
          this.reporteFilas[this.reporteFilas.length - 1].reports.push(report);
        });
        console.log('LOS REPORTES ', this.reporteFilas);
      });
  }

  private validateDefaultReport() {
    const url: string = this.router.url;
    switch (url) {
      case '/reportes/reportes-consultas-externas':
        (() => {
          this.tipoReporteControl.setValue(this.reporteFilas[4]);
          this.opcionReporteControl.setValue(this.reporteFilas[4].reports[0]);
          this.generateReport();
        })();
        break;
      default:
        console.log('NINGUN REPORTE POR DEFECTO');
        break;
    }
  }

  public onGoBackDetail() {
    this.detalleParam = null;
    console.log('Volver this.detalleParam = ', this.detalleParam);
    // this.clearComponent();
  }

  public generateReport() {
    this.selectedOption = undefined;
    this.generating = true;
    this.filterConfirmada = false;
    this.pagination = false;
    this.queryFunction = undefined;
    this.reportActions = undefined;
    this.queryParams = undefined;
    this.data = undefined;
    this.dataChartOptions = undefined;
    this.columnDefinitionsFunction = undefined;
    this.detalleParam = null;
    switch (this.opcionReporteControl.value.key) {
      case 'maquina.detectada':
        this.maquinaDetectada();
        break;
      case 'maquina.rechazada':
        this.maquinaRechazada();
        break;
      case 'maquina.impuesta':
        this.maquinaImpuesta();
        break;
      case 'maquina.confiabilidad':
        this.maquinaConfiabilidad();
        break;
      case 'maquina.tipo_infraccion':
        this.maquinaTiposInfraccion();
        break;
      case 'maquina.motivo_rechazo':
        this.maquinaMotivosRechazo();
        break;
      case 'tipo_servicio.tipo_infraccion':
        this.tipoServicioTiposInfraccion();
        break;
      case 'tipo_vehiculo.tipo_infraccion':
        this.tipoVehiculoTiposInfraccion();
        break;
      case 'agente.tipo_infraccion':
        this.agenteTiposInfraccion();
        break;
      case 'agente.motivo_rechazo':
        this.agenteMotivosRechazo();
      case 'base_datos_externa.estado':
        this.baseDatosExternaEstado();
        break;
      case 'comparendos.comparendos_realizados':
        this.comparendosComparendosRealizados();
        break;
      case 'comparendos.comparendos_proceso':
        this.comparendosComparendosProceso(this.opcionReporteControl.value.params);
        break;
      default:
        break;

    }
  }

  public getOpcionReporteControlValue(): string {
    return this.selectedOption ? this.selectedOption.key : '';
  }

  public filtersIsAvaliable(filter: string): boolean {
    return this.selectedFilters.indexOf(filter) !== -1;
  }

  public onDisabledDownload($event: boolean) {
    this.disabledDownload = $event;
  }

  // REPORTE MAQUINAS - DETECTADAS
  private async maquinaDetectada() {
    this.prefijoNombreReporte = 'maquina_detectados';
    const query = {};

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.maquinasReportesService.getReporteDetectadasColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.maquinasReportesService.reporteDetectadasData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.maquinasReportesService.getReporteDetectadasDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE MAQUINAS - IMPUESTAS
  private async maquinaImpuesta() {
    this.prefijoNombreReporte = 'maquina_impuestos';
    const query = {};

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.maquinasReportesService.getReporteImpuestasColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.maquinasReportesService.reporteImpuestasData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.maquinasReportesService.getReporteImpuestasDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE MAQUINAS - RECHAZADA
  private async maquinaRechazada() {
    this.prefijoNombreReporte = 'maquina_rechazados';
    const query = {};

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.maquinasReportesService.getReporteRechazadasColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.maquinasReportesService.reporteRechazadasData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.maquinasReportesService.getReporteRechazadasDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE MAQUINAS - CONFIABILIDAD
  private async maquinaConfiabilidad() {
    this.prefijoNombreReporte = 'maquina_confiabilidad';
    const query = {};

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.maquinasReportesService.getReporteConfiabilidadColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.maquinasReportesService.reporteConfiabilidadData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.maquinasReportesService.getReporteConfiabilidadDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE MAQUINAS - TIPO INFRACCION
  private async maquinaTiposInfraccion() {
    this.prefijoNombreReporte = 'maquina_tipo_infraccion';
    const query = {};

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }
    if (this.tipoServicioSelected !== undefined && typeof this.tipoServicioSelected === 'object') {
      query['tipoServicio'] = this.tipoServicioSelected.id;
    }
    if (this.tipoVehiculoSelected !== undefined && typeof this.tipoVehiculoSelected === 'object') {
      query['tipoVehiculo'] = this.tipoVehiculoSelected.id;
    }
    if (this.agenteSelected !== undefined && typeof this.agenteSelected === 'object') {
      query['agente'] = this.agenteSelected.id;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.maquinasReportesService.getReporteTipoInfraccionColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.maquinasReportesService.reporteTipoInfraccionData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.maquinasReportesService.getReporteTipoInfraccionDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE MAQUINAS - MOTIVO RECHAZO
  private async maquinaMotivosRechazo() {
    this.prefijoNombreReporte = 'maquina_motivos_rechazo';
    const query = {};

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }
    if (this.agenteSelected !== undefined && typeof this.agenteSelected === 'object') {
      query['agente'] = this.agenteSelected.id;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.maquinasReportesService.getReporteMotivoRechazoColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.maquinasReportesService.reporteMotivoRechazoData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.maquinasReportesService.getReporteMotivoRechazoDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE TIPOS SERVICIO - TIPO INFRACCION
  private async tipoServicioTiposInfraccion() {
    this.prefijoNombreReporte = 'tipos_servicio_infraccion';
    const query = {};

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }
    if (this.tipoServicioSelected !== undefined && typeof this.tipoServicioSelected === 'object') {
      query['tipoServicio'] = this.tipoServicioSelected.id;
    }
    if (this.tipoVehiculoSelected !== undefined && typeof this.tipoVehiculoSelected === 'object') {
      query['tipoVehiculo'] = this.tipoVehiculoSelected.id;
    }
    if (this.agenteSelected !== undefined && typeof this.agenteSelected === 'object') {
      query['agente'] = this.agenteSelected.id;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.tiposServicioReportesService.getReporteTipoInfraccionColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.tiposServicioReportesService.reporteTipoInfraccionData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.tiposServicioReportesService.getReporteTipoInfraccionDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE TIPOS VEHICULOS - TIPO INFRACCION
  private async tipoVehiculoTiposInfraccion() {
    this.prefijoNombreReporte = 'tipos_vehiculo_infraccion';
    const query = {};

    if (this.maquinaSelected && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }
    if (this.tipoServicioSelected !== undefined && typeof this.tipoServicioSelected === 'object') {
      query['tipoServicio'] = this.tipoServicioSelected.id;
    }
    if (this.tipoVehiculoSelected !== undefined && typeof this.tipoVehiculoSelected === 'object') {
      query['tipoVehiculo'] = this.tipoVehiculoSelected.id;
    }
    if (this.agenteSelected !== undefined && typeof this.agenteSelected === 'object') {
      query['agente'] = this.agenteSelected.id;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.tiposVehiculoReportesService.getReporteTipoInfraccionColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.tiposVehiculoReportesService.reporteTipoInfraccionData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.tiposVehiculoReportesService.getReporteTipoInfraccionDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE AGENTE - TIPO INFRACCION
  private async agenteTiposInfraccion() {
    this.prefijoNombreReporte = 'agente_tipos_infraccion';
    const query = {};

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }
    if (this.tipoServicioSelected !== undefined && typeof this.tipoServicioSelected === 'object') {
      query['tipoServicio'] = this.tipoServicioSelected.id;
    }
    if (this.tipoVehiculoSelected !== undefined && typeof this.tipoVehiculoSelected === 'object') {
      query['tipoVehiculo'] = this.tipoVehiculoSelected.id;
    }
    if (this.agenteSelected !== undefined && typeof this.agenteSelected === 'object') {
      query['agente'] = this.agenteSelected.id;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.agentesReportesService.getReporteTipoInfraccionColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.agentesReportesService.reporteTipoInfraccionData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.agentesReportesService.getReporteTipoInfraccionDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE AGENTE - MOTIVOS RECHAZO
  private async agenteMotivosRechazo() {
    this.prefijoNombreReporte = 'agente_motivos_rechazo';
    const query = {};

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }
    if (this.agenteSelected !== undefined && typeof this.agenteSelected === 'object') {
      query['agente'] = this.agenteSelected.id;
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.agentesReportesService.getReporteMotivoRechazoColumnDefinitions;

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise((resolve, reject) => {
        this.agentesReportesService.reporteMotivoRechazoData(query)
          .subscribe((response) => {
            resolve(response);
          }, (err) => {
            reject(err);
          });
      });
    };

    this.dataChartOptionsFunction = this.agentesReportesService.getReporteMotivoRechazoDataChart;
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
    }, 0);
  }

  // REPORTE BASE DE DATOS EXTERNA - ESTADO
  private async baseDatosExternaEstado() {
    this.prefijoNombreReporte = 'bases_externas_estados';
    const query = {};

    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }
    if (this.tipoConsultaControl.value) {
      query['base'] = this.tipoConsultaControl.value.id;
    }

    this.columnDefinitions = this.consultasExternasReportesService.getReporteEstadosColumnDefinitions();

    this.queryParams = query;

    await this.consultasExternasReportesService.reporteEstadosData(query).toPromise()
      .then(response => this.data = response.content)
      .catch((error) => {
        console.error('Error reporteEstadosData', error);
      });

    this.dataChartOptions = this.consultasExternasReportesService.getReporteEstadosDataChart(this.data ? this.data : []);
    console.log(this.dataChartOptions);
    setTimeout(() => {
      this.selectedOption = this.opcionReporteControl.value;
      this.zone.run(() => {
      });
    }, 0);
  }

  // REPORTE COMPARENDOS - COMPARENDOS REALIZADOS
  private async comparendosComparendosRealizados() {
    this.prefijoNombreReporte = 'comparendos';
    const query = {estado: ESTADOCOMPARENDOS.idComparendoCertificado};

    this.reportActions = this.comparendosReportesService.getReporteComparendoActions();

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }
    if (this.agenteSelected !== undefined && typeof this.agenteSelected === 'object') {
      query['agente'] = this.agenteSelected.id;
    }
    if (this.infractorSelected !== undefined && typeof this.infractorSelected === 'object') {
      query['infractor'] = this.infractorSelected.id;
    }
    if (this.ciudadSelected !== undefined && typeof this.ciudadSelected === 'object') {
      query['ciudad'] = this.ciudadSelected.id;
    }
    if (this.secretariaSelected !== undefined && typeof this.secretariaSelected === 'object') {
      query['secretaria'] = this.secretariaSelected.id;
    }
    if (this.placaControl.value) {
      query['placa'] = this.placaControl.value;
    }
    if (this.velocidadControl.value) {
      query['velocidad'] = this.velocidadControl.value;
    }
    if (this.rangoControl.value) {
      query['rango'] = this.rangoControl.value;
    }
    if (this.infraccionSelected !== undefined && typeof this.infraccionSelected === 'object') {
      query['infraccion'] = this.infraccionSelected.id;
    }
    if (this.validatePermission('reportes.creados_propios')) {
      const usuario = this.getLoggedUser();
      if (usuario !== null && usuario !== undefined) {
        query['usuarioId'] = usuario.id;
      }
    }


    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitionsFunction = this.comparendosReportesService.getReporteRealizadosColumnDefinitions;

    // Consulta cantidad de reportes relizados
    let responseCantidad = null;
    await this.comparendosReportesService.reporteEstadosComparendos(query).toPromise()
      .then((response) => {
        responseCantidad = response;
        console.log('REPORTE ESTADOS ', responseCantidad);
      }, (error) => {
        console.log('ERROR ', error);
      });

    this.dataChartOptions = this.comparendosReportesService.getReporteEstadosDataChart(responseCantidad);

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise(async (resolve, reject) => {
        let comparendos: Comparendo[];
        let response;
        await this.comparendosReportesService.reporteRealizadosData(query)
          .toPromise().then((_response) => {
            response = _response;
            comparendos = _response.content;
          })
          .catch((err) => {
            reject(err);
          });
        if (comparendos != null) {
          for (let index = 0; index < comparendos.length; index++) {
            const comparendo: Comparendo = comparendos[index];
            try {
              await this.estadoComparendoUsuarioService.findLastByComparendoId(comparendo.id)
                .toPromise().then((state) => {
                  console.log(`Comparendo id #${comparendo.id} last state: `, state);
                  comparendo.tipoEstadoComparendo = state.tipoEstadoComparendo;
                });
            } catch (error) {
              console.log('Error obtener el ultimo estado ', error);
            }
            try {
              // Set camara
              /*this.camaraService.find(comparendo.camaraId)
              .toPromise().then((response) => {
                comparendo.camaraId = response;
              });*/
              // query states
              this.estadoComparendoUsuarioService.findByCriteria({comparendoId: comparendo.id})
                .toPromise().then((states) => {
                const estadoCertificado = states.content
                  .find(state => state.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoCertificado);
                const estadoPorCertificar = states.content
                  .find(state => state.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoPorCertificar);
                if (estadoCertificado) {
                  Object.defineProperty(comparendo, 'usuarioCertificador', {
                    enumerable: true,
                    configurable: true,
                    writable: true,
                    value: estadoCertificado.usuario
                  });
                }
                if (estadoPorCertificar) {
                  Object.defineProperty(comparendo, 'usuarioPorCertificar', {
                    enumerable: true,
                    configurable: true,
                    writable: true,
                    value: estadoPorCertificar.usuario
                  });
                }
                console.log({
                  comparendo,
                  estados: states.content,
                  estadoCertificado,
                  estadoPorCertificar
                });
              });
            } catch (error) {
              console.error(error);
            }
            if (comparendo.infractor) {
              if (comparendo.infractor.ciudadId) {
                try {
                  // Set ciudad infractor
                  await this.ciudadService.find(comparendo.infractor.ciudadId)
                    .toPromise().then((response) => {
                      comparendo.infractor.ciudadId = response;
                    });
                } catch (error) {
                  console.error(error);
                }
              }
            }
          }
        }
        resolve(response);
      });
    };

    // this.dataChartOptionsFunction = this.agentesReportesService.getReporteMotivoRechazoDataChart;
    setTimeout(() => {
      console.log('VER RESULTADO REPORTE');
      this.selectedOption = this.opcionReporteControl.value;
      this.zone.run(() => {
      });
    }, 0);
  }

  // REPORTE COMPARENDOS - COMPARENDOS PROCESO
  private async comparendosComparendosProceso(tipoEstadoComparendo?: TipoEstadoComparendo) {
    this.prefijoNombreReporte = /*!tipoEstadoComparendo ?*/ 'comparendos'/* :
      `reporte_comparendos_${tipoEstadoComparendo.descripcion.toLocaleLowerCase()}`*/;
    const query = {};

    if (tipoEstadoComparendo) {
      query['estado'] = tipoEstadoComparendo.id;
    }

    this.reportActions = this.comparendosReportesService.getReporteComparendoActions();

    if (this.maquinaSelected !== undefined && typeof this.maquinaSelected === 'object') {
      query['maquina'] = this.maquinaSelected.id;
    }
    if (this.desdeFechaSelected) {
      query['fechaInicial'] = this.desdeFechaSelected;
    }
    if (this.hastaFechaSelected) {
      query['fechaFinal'] = this.hastaFechaSelected;
    }
    if (this.agenteSelected !== undefined && typeof this.agenteSelected === 'object') {
      query['agente'] = this.agenteSelected.id;
    }
    if (this.infractorSelected !== undefined && typeof this.infractorSelected === 'object') {
      query['infractor'] = this.infractorSelected.id;
    }
    if (this.ciudadSelected !== undefined && typeof this.ciudadSelected === 'object') {
      query['ciudad'] = this.ciudadSelected.id;
    }
    if (this.secretariaSelected !== undefined && typeof this.secretariaSelected === 'object') {
      query['secretaria'] = this.secretariaSelected.id;
    }
    if (this.placaControl.value) {
      query['placa'] = this.placaControl.value;
    }
    if (this.velocidadControl.value) {
      query['velocidad'] = this.velocidadControl.value;
    }
    if (this.rangoControl.value) {
      query['rango'] = this.rangoControl.value;
    }
    if (this.infraccionSelected !== undefined && typeof this.infraccionSelected === 'object') {
      query['infraccion'] = this.infraccionSelected.id;
    }
    if (this.validatePermission('reportes.creados_propios')) {
      const usuario = this.getLoggedUser();
      if (usuario !== null && usuario !== undefined) {
        query['usuarioId'] = usuario.id;
      }
    }

    this.pagination = true;
    this.paginationLength = 5;
    this.columnDefinitions = this.comparendosReportesService.getReporteProcesoColumnDefinitions();

    // Consulta cantidad de reportes rechazados
    let responseCantidad = null;
    await this.comparendosReportesService.reporteEstadosComparendos(query).toPromise()
      .then((response) => {
        responseCantidad = response;
        console.log('REPORTE ESTADOS ', responseCantidad);
      }, (error) => {
        console.log('ERROR ', error);
      });

    this.dataChartOptions = this.comparendosReportesService.getReporteEstadosDataChart(responseCantidad);

    this.queryParams = query;

    this.queryFunction = (query) => {
      return new Promise(async (resolve, reject) => {
        let comparendos: Comparendo[];
        let response;
        await this.comparendosReportesService.reporteProcesoData(query)
          .toPromise().then((_response) => {
            response = _response;
            comparendos = _response.content;
          })
          .catch((err) => {
            reject(err);
          });
        if (comparendos != null) {
          for (let index = 0; index < comparendos.length; index++) {
            const comparendo: Comparendo = comparendos[index];
            try {
              await this.estadoComparendoUsuarioService.findLastByComparendoId(comparendo.id)
                .toPromise().then((state) => {
                  console.log(`Comparendo id #${comparendo.id} last state: `, state);
                  comparendo.tipoEstadoComparendo = state.tipoEstadoComparendo;
                });
            } catch (error) {
              console.log('Error obtener el ultimo estado ', error);
            }
            try {
              // Set camara
              /*this.camaraService.find(comparendo.camaraId)
              .toPromise().then((response) => {
                comparendo.camaraId = response;
              });*/
              this.estadoComparendoUsuarioService.findByCriteria({comparendoId: comparendo.id})
                .toPromise().then((states) => {
                const estadoCertificado = states.content
                  .find(state => state.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoCertificado);
                const estadoPorCertificar = states.content
                  .find(state => state.tipoEstadoComparendo.id === ESTADOCOMPARENDOS.idComparendoPorCertificar);
                if (estadoCertificado) {
                  Object.defineProperty(comparendo, 'usuarioCertificador', {
                    enumerable: true,
                    configurable: true,
                    writable: true,
                    value: estadoCertificado.usuario
                  });
                }
                if (estadoPorCertificar) {
                  Object.defineProperty(comparendo, 'usuarioPorCertificar', {
                    enumerable: true,
                    configurable: true,
                    writable: true,
                    value: estadoPorCertificar.usuario
                  });
                }
                console.log({
                  comparendo,
                  estados: states.content,
                  estadoCertificado,
                  estadoPorCertificar
                });
              });
            } catch (error) {
              console.error(error);
            }
            if (comparendo.infractor) {
              if (comparendo.infractor.ciudadId) {
                try {
                  // Set ciudad infractor
                  await this.ciudadService.find(comparendo.infractor.ciudadId)
                    .toPromise().then((response) => {
                      comparendo.infractor.ciudadId = response;
                    });
                } catch (error) {
                  console.error(error);
                }
              }
            }
          }
        }
        resolve(response);
      });
    };

    // this.dataChartOptionsFunction = this.agentesReportesService.getReporteMotivoRechazoDataChart;
    setTimeout(() => {
      console.log('VER RESULTADO REPORTE');
      this.selectedOption = this.opcionReporteControl.value;
      this.zone.run(() => {
      });
    }, 0);
  }

  private initControls(): void {
    this.opcionReporteControl = new FormControl('');
    this.opcionReporteControl.valueChanges
      .subscribe((val: OpcionReporte) => {
        if (val) {
          this.selectedFilters = val.filters;
        } else {
          this.selectedFilters = [];
          this.selectedOption = undefined;
        }
      });
    this.maquinaFilterControl = new FormControl();
    this.ciudadFilterControl = new FormControl();
    this.secretariaFilterControl = new FormControl();
    this.infraccionFilterControl = new FormControl();
    this.agenteFilterControl = new FormControl();
    this.infractorFilterControl = new FormControl();
    this.tipoVehiculoFilterControl = new FormControl();
    this.tipoServicioFilterControl = new FormControl();
    this.desdeFechaControl = new FormControl();
    this.placaControl = new FormControl();
    this.placaControl.valueChanges.subscribe((val: string) => {
      if (val) {
        this.placaControl.setValue(val.toUpperCase(), {emitEvent: false});
      }
    });
    this.velocidadControl = new FormControl();
    this.velocidadControl.valueChanges.subscribe((val: string) => {
      if (val) {
        this.velocidadControl.setValue(val.replace(/[^0-9]+/g, ''), {emitEvent: false});
      }
    });
    this.rangoControl = new FormControl();
    this.rangoControl.valueChanges.subscribe((val: string) => {
      if (val) {
        this.rangoControl.setValue(val.replace(/[^0-9]+/g, ''), {emitEvent: false});
      }
    });
    this.desdeFechaControl.valueChanges
      .subscribe((val) => {
        if (val) {
          this.desdeFechaSelected = null;
          if (typeof val === 'object') {
            this.desdeFechaSelected = this.datePipe.transform(makeDateInstance(val).toDateString(), 'yyyy-MM-dd');
          } else if (typeof val === 'string' && isValidDate(val)) {
            this.desdeFechaSelected = val;
          }
        }
      });
    this.hastaFechaControl = new FormControl();
    this.hastaFechaControl.valueChanges
      .subscribe((val) => {
        if (val) {
          this.hastaFechaSelected = null;
          if (typeof val === 'object') {
            this.hastaFechaSelected = this.datePipe.transform(makeDateInstance(val).toDateString(), 'yyyy-MM-dd');
          } else if (typeof val === 'string' && isValidDate(val)) {
            this.hastaFechaSelected = val;
          }
        }
      });
    this.tipoReporteControl = new FormControl();
    this.tipoReporteControl.valueChanges.subscribe((val: TipoReporte) => {
      this.opcionReporteControl.setValue(null, {emitEvent: false});
      if (val) {
        this.opcionesReportes = val.reports;
      } else {
        this.selectedOption = undefined;
        this.opcionesReportes = [];
        this.selectedFilters = [];
      }
    });
    this.tipoConsultaControl = new FormControl();
  }

  public hasAnyFilter(): boolean {
    return (this.maquinaFilterControl.value ||
      this.ciudadFilterControl.value ||
      this.secretariaFilterControl.value ||
      this.infraccionFilterControl.value ||
      this.placaControl.value ||
      this.velocidadControl.value ||
      this.rangoControl.value ||
      this.agenteFilterControl.value ||
      this.infractorFilterControl.value ||
      this.tipoVehiculoFilterControl.value ||
      this.tipoServicioFilterControl.value ||
      this.desdeFechaControl.value ||
      this.hastaFechaControl.value ||
      this.tipoConsultaControl.value ||
      (this.agenteSelected != null && typeof this.agenteSelected === 'object') ||
      (this.ciudadSelected != null && typeof this.ciudadSelected === 'object') ||
      (this.secretariaSelected != null && typeof this.secretariaSelected === 'object') ||
      (this.infractorSelected != null && typeof this.infractorSelected === 'object') ||
      (this.maquinaSelected != null && typeof this.maquinaSelected === 'object') ||
      (this.infraccionSelected != null && typeof this.infraccionSelected === 'object') ||
      (this.tipoVehiculoSelected != null && typeof this.tipoVehiculoSelected === 'object') ||
      (this.tipoServicioSelected != null && typeof this.tipoServicioSelected === 'object')) && this.opcionReporteControl.value;
  }

  public cleanFilters() {
    this.maquinaFilterControl.setValue(null, {emitEvent: false});
    this.ciudadFilterControl.setValue(null, {emitEvent: false});
    this.secretariaFilterControl.setValue(null, {emitEvent: false});
    this.infraccionFilterControl.setValue(null, {emitEvent: false});
    this.placaControl.setValue(null, {emitEvent: false});
    this.velocidadControl.setValue(null, {emitEvent: false});
    this.rangoControl.setValue(null, {emitEvent: false});
    this.maquinaSelected = undefined;
    this.secretariaSelected = undefined;
    this.infraccionSelected = undefined;
    this.ciudadSelected = undefined;
    this.agenteFilterControl.setValue(null, {emitEvent: false});
    this.agenteSelected = undefined;
    this.infractorFilterControl.setValue(null, {emitEvent: false});
    this.infractorSelected = undefined;
    this.tipoVehiculoFilterControl.setValue(null, {emitEvent: false});
    this.tipoVehiculoSelected = undefined;
    this.tipoServicioFilterControl.setValue(null, {emitEvent: false});
    this.tipoServicioSelected = undefined;
    this.desdeFechaControl.setValue(null, {emitEvent: false});
    this.desdeFechaSelected = null;
    this.hastaFechaControl.setValue(null, {emitEvent: false});
    this.hastaFechaSelected = null;
    this.tipoConsultaControl.setValue(null, {emitEvent: false});
  }

  private loadComponent(component: Type<any>): any {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.detailContainerDirective.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    return componentRef;
    // (<AdComponent>componentRef.instance).data = adItem.data;
  }

  private clearComponent(): void {
    const viewContainerRef = this.detailContainerDirective.viewContainerRef;
    viewContainerRef.clear();
  }

  public resultResolved() {
    console.log('RESULT RESVOLVED');
    this.generating = false;
  }

  public descargarReporte(tipo: string) {
    this.resultadoReporteComponent.descargarReporte(tipo);
  }

  public disabledDownloadReport(): boolean {
    return !this.resultadoReporteComponent || !this.selectedOption;
  }

  private getLoggedUser(): { [key: string]: any } {
    return this.webStorageCustomService.getFromLocal('usuario') ?
      <Usuario>JSON.parse(this.webStorageCustomService.getFromLocal('usuario')) : null;
  }

  private validatePermission(accion: string) {
    if (this.permisos && this.permisos.length > 0) {

      if (this.permisos.includes('root.all') || this.permisos.includes(accion)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  getMaquinas = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => {
      }),
      switchMap(term =>
        this.camaraService.findByName(term, 5).pipe(
          tap(() => {
          }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => {
      })
    );

  getAgentes = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => {
      }),
      switchMap(term =>
        this.usuarioService.findByFullName(term, 5, true).pipe(
          tap(() => {
          }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => {
      })
    );

  getInfracciones = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => {
      }),
      switchMap(term =>
        this.infraccionService.findByDescripcion(term, 5).pipe(
          tap(() => {
          }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => {
      })
    );

  getInfractores = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => {
      }),
      switchMap(term =>
        this.infractorService.findByFullName(term, 5).pipe(
          tap(() => {
          }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => {
      })
    );

  getTiposVehiculo = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => {
      }),
      switchMap(term =>
        this.tipoVehiculoService.findByFullDescripcion(term, 5).pipe(
          tap(() => {
          }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => {
      })
    );

  getClasesServicio = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => {
      }),
      switchMap(term =>
        this.claseServicioService.findByFullDescripcion(term, 5).pipe(
          tap(() => {
          }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => {
      })
    );

  getCiudades = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => {
      }),
      switchMap(term =>
        this.ciudadService.findByNombre(term, 5).pipe(
          tap(() => {
          }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => {
      })
    );

  getSecretarias = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(0),
      distinctUntilChanged(),
      tap(() => {
      }),
      switchMap(term =>
        this.secretariaService.findByNombre(term, 5).pipe(
          tap(() => {
          }),
          catchError(() => {
            return of([]);
          }))
      ),
      tap(() => {
      })
    );

  formatterMaquina = (m: any) => m.nombre;

  formatterInfraccion = (i: any) => i.descripcion;

  formatterCiudad = (c: any) => c.nombre;

  formatterSecretaria = (s: any) => s.nombre;

  formatterAgente = (a: Usuario) => `${a.nombre} ${a.apellido}`;

  formatterInfractor = (i: Usuario) => `${i.nombre} ${i.apellido}`;

  formatterTipoVehiculo = (t: any) => t.descripcion;

  formatterClaseServicio = (t: any) => t.descripcion;

  disabledDownloadButton = (): boolean => true;

}
