import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {
  ModulosService,
  RolModuloService,
  RolService
} from '../../../../providers';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-edit-rol',
  templateUrl: './new-edit-rol.component.html',
  styleUrls: ['./new-edit-rol.component.scss']
})
export class NewEditRolComponent implements OnInit {

  @Input() private rol: any;
  private rolForm: FormGroup;
  private rolModuloForm: FormGroup;
  private modulosObservable: Observable<any>;
  private modulos: Array<any>;
  private rolModulos: Array<any>;
  private userRolModulos: Array<any>;

  constructor(
    public modal: NgbActiveModal,
    private toastService: ToastrService,
    private formBuilder: FormBuilder,
    private rolesService: RolService,
  ) { }

  ngOnInit() {
    this.initForms();
    if (this.rol) this.patchValues(this.rol);
  }

  private initForms() {
    this.rolForm = this.formBuilder.group({
      id: null,
      fechaCreacion: null,
      fechaActualizacion: null,
      nombre: ['', [Validators.required, Validators.minLength(4)]],
      descripcion: ['', [Validators.required, Validators.minLength(4)]],
      eliminado: [false],
      estado: [false],
      esAgente: [false]
    });
  }

  private patchValues(rol: Object) {
    this.rolForm.patchValue(rol);
  }

  public showPopover(field: string, pop: NgbPopover) {
    if (this.rolForm.get(field).invalid) pop.open();
  }

  public async modalclose() {
    if (this.rolForm.valid) {
      let rolF = await this.createOrUpdateRol(this.rolForm.value, this.rol ? true : false);
      this.modal.close({ rolF });
    }
  }

  private async createOrUpdateRol(rol: any, exits: any): Promise<any> {
    if (!exits) return this.rolesService.create(rol).toPromise();
    if (exits) return this.rolesService.update(rol).toPromise();
  }
}
